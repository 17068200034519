import Input from "common/UIKit/Input";
import { stepsUi } from "../../components";
import {
  UseFormRegister,
  FieldErrors,
  Control,
  UseFormWatch,
  UseFormSetValue,
  FieldValues,
} from "react-hook-form";
import { Inputs } from "../TherapistStep3_1";
import { DateSelect } from "../blocks/DateSelect/DateSelect";
import { StateSelect } from "../blocks/StateSelect/StateSelect";
import { useState } from "react";
import styles from "./TherapistStepLicense.module.scss";

interface Props {
  register: UseFormRegister<Inputs>;
  errors: FieldErrors;
  control: Control<Inputs, FieldValues>;
  disabled?: boolean;
  watch: UseFormWatch<Inputs>;
  setValue: UseFormSetValue<Inputs>;
  id?: string;
  isVerified?: boolean;
}

export const TherapistStepLicensed = ({
  errors,
  register,
  control,
  disabled,
  watch,
  setValue,
  id,
  isVerified,
}: Props) => {
  const [withVerified, setWithVerified] = useState(false);

  return (
    <>
      <stepsUi.Row label="License Number" className={styles.row}>
        <Input
          type="text"
          {...register("license_number")}
          error={errors.license_number?.message}
          disabled={disabled}
          maxLength={160}
        />
      </stepsUi.Row>
      <stepsUi.Row
        label="License State"
        className={styles.row}
        onClick={
          Boolean(id && isVerified) ? () => setWithVerified(true) : undefined
        }
      >
        {Boolean(id && isVerified && !withVerified) && (
          <div className={styles.stateWrapper} />
        )}
        <StateSelect
          control={control}
          setValue={setValue}
          disabled={disabled}
          name="license_state"
        />
        {withVerified && (
          <p className={styles.error}>
            State change will initiate a new license verification
          </p>
        )}
      </stepsUi.Row>
      <stepsUi.Row label="License Expiration" className={styles.row}>
        <DateSelect
          watch={watch}
          control={control}
          disabled={disabled}
          name="license_expiration"
          setValue={setValue}
        />
      </stepsUi.Row>
    </>
  );
};
