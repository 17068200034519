import { FC } from "react";
import { TestTextBox } from "types/testProcess.types";
import Input from "common/UIKit/Input";
import { useTest } from "hooks/useTest";
import styles from "./TextBox.module.scss";

interface Props {
  question: TestTextBox;
  disabled?: boolean;
}

export const TextBox: FC<Props> = ({ question, disabled }) => {
  const { registerQuestion, errors } = useTest();

  return (
    <Input
      placeholder={question.placeholder}
      adornment={question.adornment}
      className={styles.textBox}
      {...registerQuestion(question)}
      error={errors[question.id]?.message}
      disabled={disabled}
    />
  );
};
