import { AxiosResponse } from "axios";
import { ListResponse } from "types/common.types";
import { BaseTherapistItem, Therapist } from "types/therapist.types";
import { proxy } from "valtio";
import { authService } from "./authService";
import { HttpService } from "./httpService";
import { modalService } from "./modalService";
import { generalLogicService } from "./generalLogicService";

export type ContactTherapistValues = {
  message: string;
  captcha: string | null;
};

type ContactArgs = {
  type: "sms" | "email";
  text: string;
  captchaToken: string | null;
};

export type Receiver = (BaseTherapistItem | Therapist) & {
  user_id: string;
  first_name: string;
  last_name: string;
};

type Phone = {
  id: number;
  number: string;
};

class CommunicationService extends HttpService {
  receiver: Receiver | null = null;
  senderPhone: string | null = null;
  error: string | null = null;

  async contactTherapist(args: ContactArgs) {
    try {
      if (args.type === "sms") {
        await this.http.post("/v1/sms_messages/", {
          receiver: this.receiver?.user_id,
          sender_phone: this.senderPhone,
          text: args.text,
          captcha_token: args.captchaToken,
        });
      } else {
        await this.http.post("/v1/email_messages/", {
          receiver: this.receiver?.user_id,
          text: args.text,
          captcha_token: args.captchaToken,
        });
      }
    } catch (error) {
      const { data, status } = error as AxiosResponse<{ errors: string[] }>;

      if (status === 401) {
        localStorage.setItem("redirect_to", `/td/${this.receiver?.id}`);
        localStorage.setItem("receiver", `${this.receiver?.id}`);
        modalService.open("LOGGED_OUT_PATIENT");
        throw new Error("Error");
      }

      this.error = data.errors.join("\n");
    }
  }

  async openPopup(receiver: BaseTherapistItem | Therapist) {
    localStorage.setItem("therapistIdForOpenModal", String(receiver.id));

    try {
      await authService.verify();

      if ("user" in receiver) {
        this.receiver = {
          ...receiver,
          ...receiver.user,
          user_id: receiver.user.id,
        };
      } else {
        this.receiver = receiver;
      }
      this.error = null;

      modalService.open("MESSAGE");

      //      if (receiver.chat_status !== "offline") {
      //        const { results } = await this.http.get<any, ListResponse<Phone[]>>(
      //          "/v1/phone-numbers/",
      //          {
      //            params: {
      //              page: 1,
      //              page_size: 10,
      //              therapist_id: this.receiver?.user_id,
      //            },
      //          }
      //        );
      //
      //        this.senderPhone = results[0].number!;
      //      }
    } catch (error) {
      const { status } = error as AxiosResponse;
      if (status === 401) {
        generalLogicService.setError({
          status,
          message: "You must be registered to Chat Connect with a therapist.",
          type: "auth",
        });
        localStorage.setItem("redirect_to", window.location.pathname);
        throw error;
      }
    }
  }

  closePopup() {
    this.receiver = null;
    modalService.closeAll();
  }

  async searchPhone(search: string) {
    const { results } = await this.http.get<any, ListResponse<Phone[]>>(
      "/v1/phone-numbers/",
      {
        params: {
          page: 1,
          page_size: 10,
          therapist_id: this.receiver?.user_id,
          search,
        },
      }
    );

    return results;
  }
}

export const communicationService = proxy(new CommunicationService());
