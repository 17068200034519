import { useState } from "react";
import { useSnapshot } from "valtio";
import isEmail from "validator/lib/isEmail";
import { Link, Navigate } from "react-router-dom";
import { EmailSent } from "common/components/EmailSent/EmailSent";
import { useQuery } from "hooks/useQuery";
import { authService } from "services/authService";
import styles from "./ConfirmEmail.module.scss";

export const confirmEmailPath = "/confirm-email";

export const ConfirmEmail = () => {
  const q = useQuery();
  const [isResending, setIsResending] = useState(false);
  const { prevSessionUserType } = useSnapshot(authService);

  const email = q.get("email") || "";
  if (!email || !isEmail(email)) {
    return <Navigate to={`/${prevSessionUserType}/login`} />;
  }
  const handleResend = async () => {
    setIsResending(true);
    await authService.sendResetPassword(email);
    setIsResending(false);
  };

  return (
    <EmailSent
      email={email}
      type="reset password"
      onResend={handleResend}
      isResending={isResending}
    >
      <p className={styles.sign_in}>
        Already reset your password?{" "}
        <Link className="g-underline" to={`/${prevSessionUserType}/login`}>
          Sign in
        </Link>
      </p>
    </EmailSent>
  );
};
