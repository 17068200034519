import React, { ReactNode } from "react";
import styles from "../TherapistSteps.module.scss";

export const TherapistStepsRowShort = ({ children, label }: { children: ReactNode | string, label?: string;}) => (
  <div className={styles.rowShort}>
    {label && (
      <div className={styles.rowShortLabel}>
        {label}
      </div>
    )}
    {children}
  </div>
)