import { FC } from "react";
import { DashboardTab } from "./useDashboardTabs";
import { useResponsive } from "hooks/useResponsive";
import { DashboardLayoutDesktopAside } from "./DesktopAside";
import { DashboardLayoutMobileHeader } from "./MobileHeader";
import { DashboardLayoutDesktopHeader } from "./DesktopHeader";
import { DashboardNavigationProvider } from "providers/DashboardNavigationProvider";
import { Footer } from "pages/Auth/components/Footer/Footer";
import styles from "./DashboardLayout.module.scss";

interface Props {
  tabs: DashboardTab[];
  className?: string;
}

export const DashboardLayout: FC<Props> = ({ children, tabs, className }) => {
  const { isMobile } = useResponsive();

  return (
    <DashboardNavigationProvider>
      <div className={styles.root}>
        {!isMobile && <DashboardLayoutDesktopAside tabs={tabs} />}
        <div>
          {isMobile ? (
            <DashboardLayoutMobileHeader tabs={tabs} />
          ) : (
            <DashboardLayoutDesktopHeader />
          )}
          <main className={className}>{children}</main>
          {!isMobile && (
            <Footer className={styles.footer} variant="dashboard" />
          )}
        </div>
        {isMobile && <Footer className={styles.footer} />}
      </div>
    </DashboardNavigationProvider>
  );
};
