import { useNavigate } from "react-router-dom";
import { useModal } from "hooks/useModal";
import { tokenService } from "services/tokenService";
import { LoggedOutContainer } from "../LoggedOutContainer/LoggedOutContainer";
import { authService } from "services/authService";
import styles from "./LoggedAsTherapist.module.scss";
import { esaTokenService } from "services/esaTokenService";

export const LoggedAsTherapist = () => {
  const navigate = useNavigate();
  const { isOpen, close } = useModal("LOGGED_AS_THERAPIST");

  const onClose = () => {
    close();
    authService.changeAuthStatus("logoutToLogin");
    tokenService.remove();
    esaTokenService.remove();
    navigate("/patient/login");
  };

  const handleButtonClick = () => {
    onClose();
  };

  return (
    <LoggedOutContainer
      subTitle={
        <div className={styles.textContainer}>
          <span>You are logged in as a Therapist.</span>
          <span>
            Please{" "}
            <button className={styles.button} type="button" onClick={onClose}>
              login as
            </button>{" "}
            a Client
          </span>
          <span>to contact other Therapist.</span>
        </div>
      }
      className={styles.loggedOutToPatients}
      isOpen={isOpen}
      close={() => close()}
      onButtonClick={handleButtonClick}
    />
  );
};
