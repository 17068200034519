import { PatientStepsActions } from "./PatientStepsActions";
import { PatientStepsForm } from "./PatientStepsForm";
import { PatientStepsHeader } from "./PatientStepsHeader";
import { PatientStepsHint } from "./PatientStepsHint";
import { PatientStepsLayout } from "./PatientStepsLayout";
import { PatientStepsRow } from "./PatientStepsRow";
import { PatientStepsList } from "./PatientStepsList";

export const stepsUi = {
  Actions: PatientStepsActions,
  Form: PatientStepsForm,
  Header: PatientStepsHeader,
  Hint: PatientStepsHint,
  Layout: PatientStepsLayout,
  Row: PatientStepsRow,
  List: PatientStepsList,
};
