import classNames from "classnames";
import { FC } from "react";
import Layout from "../Layout/Layout";
import "./VerificationMessage.scss";

interface Props {
  text: string;
  caption?: string;
  status?: "pending" | "success" | "error";
}

export const VerificationMessage: FC<Props> = ({
  text,
  caption,
  status = "success",
}) => (
  <Layout withoutHeader mainClass="success-message">
    <section className="success-message-container">
      <div className="success-message-wrapper">
        {text && (
          <h1
            className={classNames(
              "success-message-title__caption",
              "title",
              status
            )}
          >
            {text}
          </h1>
        )}
        {caption && (
          <h3 className="success-message-title__posttitle">{caption}</h3>
        )}
      </div>
    </section>
  </Layout>
);
