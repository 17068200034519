import ReactGA from "react-ga";
import { useSnapshot } from "valtio";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

import { generalLogicService } from "services/generalLogicService";
import { useAuth } from "hooks/useAuth";
import { ErrorPage } from "pages/ErrorPage";
import { CTAPopup } from "pages/Auth/components/CTAPopup";
import { Routing } from "pages/Routing";
import { withProviders } from "utils/withProviders";

const TRACKING_ID = "G-15SQC2PS01";
ReactGA.initialize(TRACKING_ID, { debug: true });

function App() {
  const { error } = useSnapshot(generalLogicService);
  const { user } = useAuth();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (error?.type === "critical") {
    return <ErrorPage />;
  }

  return (
    <>
      <Routing />
      {!user && <CTAPopup />}
      <ToastContainer
        position="top-right"
        hideProgressBar
        autoClose={5000}
        closeButton={false}
        className="toast"
        toastClassName="toast__item"
        bodyClassName="toast__body"
      />
    </>
  );
}

export default withProviders(App);
