import { useNavigate } from "react-router-dom";

import { step10FullPath } from "../TherapistStep10";
import useVideoUpload from "hooks/useVideoUpload";
import useSkipSteps from "../useSkipSteps";

function useTherapistStep9_2() {
  const navigate = useNavigate();
  const onSkip = useSkipSteps();

  function onSubmit() {
    navigate(step10FullPath);
  }

  const { existingFilesLogic, recordedVideoLogic, modeSwitchingLogic } =
    useVideoUpload(onSubmit, onSkip);

  return {
    existingFilesLogic,
    recordedVideoLogic,
    modeSwitchingLogic,
  };
}

export default useTherapistStep9_2;
