import styles from "../ArticlesSection.module.scss";
import Default from "assets/images/articles/default.jpg";
import { formatArticleDate } from "common/utils";
import { FormatProfileField } from "common/components/FormatProfileField";
import { articlePath } from "pages/Articles/Article";
import { Link } from "react-router-dom";
import { Category } from "types/articles.types";
import { ReactComponent as LogInIcon } from "assets/images/icons/login.svg";
import { articlesService } from "services/articlesService";

interface Props {
  title: string;
  published_at: string;
  files: any;
  id: string;
  categories: readonly Category[];
  author_name: string;
}

export const ArticleItem = ({
  title,
  published_at,
  files,
  id,
  categories,
  author_name,
}: Props) => {
  return (
    <Link to={articlePath.replace(":id", String(id))}>
      <li className={styles.columnItem}>
        <>
          <div className={styles.columnImage}>
            <img src={files[0]?.file || Default} alt={title} />
          </div>
          <div style={{ width: "100%" }}>
            <p className={styles.columnTitle}>
              <FormatProfileField maxLength={70}>{title}</FormatProfileField>
            </p>
            <div>
              <div className={styles.captions}>
                <p className={styles.date}>{formatArticleDate(published_at)}</p>
                <div className={styles.author}>
                  <LogInIcon />
                  <p>{author_name}</p>
                </div>
              </div>
              <div>
                <div className={styles.categoryLinks}>
                  {categories.map((el, i) => {
                    if (i === categories.length - 1) {
                      return (
                        <Link
                          key={el.title}
                          to="/articles"
                          className={styles.categoryLink}
                          onClick={() => {
                            articlesService.setCurrentCategoryTitle(el.title);
                            articlesService.setCurrentCategory(el.code);
                          }}
                        >
                          <p>{el.title}</p>
                        </Link>
                      );
                    } else {
                      return (
                        <Link
                          key={el.title}
                          to="/articles"
                          className={styles.categoryLink}
                          onClick={() => {
                            articlesService.setCurrentCategoryTitle(el.title);
                            articlesService.setCurrentCategory(el.code);
                          }}
                        >
                          <p>{el.title},</p>
                        </Link>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      </li>
    </Link>
  );
};
