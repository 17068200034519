import { FC } from "react";
import { Controller } from "react-hook-form";
import { EditPatientInformationForm } from "../useEditPatientInformation";
import InputSearch from "common/UIKit/InputSearch";
import { ShowMore } from "common/UIKit/ShowMore";
import Checkbox from "common/UIKit/Checkbox";
import { sortItems } from "common/utils";
import styles from "../EditPatientInformation.module.scss";

export const TherapistsExperience: FC<EditPatientInformationForm> = ({
  control,
  issues: { data, searchString, setSearchString },
}) => {
  return (
    <Controller
      control={control}
      name="issues"
      render={({ field: { value, onChange } }) => (
        <>
          <InputSearch
            className={styles.search}
            value={searchString}
            onChange={(e) => setSearchString(e.currentTarget.value)}
            selectedCount={value.length}
          />

          <ShowMore
            showMoreText="Show More Issues"
            showLessText="Show Less Issues"
            className={styles.showmore}
            maxHeight={418}
          >
            {data.length > 0 ? (
              sortItems(data, "order").map((option) => (
                <Checkbox
                  key={option.code}
                  className="patient-step__checkbox"
                  id={`issue-${option.code}`}
                  name={option.code}
                  checked={value.includes(option.code)}
                  onChange={(e) => {
                    const noPreferenceCode = "no-preference";
                    if (
                      option.code === noPreferenceCode &&
                      e.currentTarget.checked
                    ) {
                      onChange([noPreferenceCode]);
                    } else {
                      const valueWithoutNone = value.filter(
                        (v) => v !== noPreferenceCode
                      );
                      const newValue = e.currentTarget.checked
                        ? [...valueWithoutNone, option.code]
                        : valueWithoutNone.filter((v) => v !== option.code);

                      onChange(newValue);
                    }
                  }}
                >
                  {option.name}
                </Checkbox>
              ))
            ) : (
              <div className={styles.no_results}>No results</div>
            )}
          </ShowMore>
        </>
      )}
    />
  );
};
