import { proxy } from "valtio";

import { TherapistFeaturedItem, TherapistId } from "../types/therapist.types";
import { CrudService } from "./crudService";

export class RecommendationsService extends CrudService<
  TherapistId,
  TherapistFeaturedItem
> {
  constructor(args?: any) {
    super({ ...args, prefix: "/v1/therapists/recommend" });
  }
}

export const recommendationsService = proxy(new RecommendationsService());
recommendationsService.init();
