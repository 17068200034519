import { Therapist, TherapistId, TherapistFile } from "types/therapist.types";
import { Pagination } from "types";
import { proxy } from "valtio";
import { HttpService } from "./httpService";
import { Area } from "types/areas.types";
import { getAvatar } from "../common/utils";

interface TherapistProfile extends Therapist {
  avatar?: TherapistFile;
  video?: TherapistFile;
  photos: TherapistFile[];
  credentialFiles: TherapistFile[];
  additionalAreas: Area[];
  longitude: number;
  latitude: number;
  is_available: boolean;
}

type Cache = { [key: TherapistId]: TherapistProfile };

class TherapistProfileService extends HttpService {
  private cache: Cache = {};

  isFetching = false;

  currentProfile: TherapistProfile | null = null;

  async getById(id: TherapistId): Promise<Therapist> {
    this.isFetching = true;
    if (this.cache[id]) {
      this.currentProfile = this.cache[id];
    } else {
      this.currentProfile = null;
    }
    const therapist: Therapist = await this.http.get(`/v1/therapists/${id}/`);

    const { results: files }: Pagination<TherapistFile> = await this.http.get(
      `/v1/therapists/${id}/files/`
    );
    const { results: additionalAreas }: Pagination<Area> = await this.http.get(
      `/v1/therapists/${id}/additional-areas/`
    );

    const avatar = getAvatar(files);
    const video = files.find(({ mime }) => mime.includes("video"));
    const photos = files.filter(
      ({ mime, type }) =>
        type === "photo_and_video_intro" && mime.includes("image")
    );
    const credentialFiles = files.filter(({ type }) => type === "credential");

    this.cache[id] = {
      ...therapist,
      avatar,
      video,
      photos,
      credentialFiles,
      additionalAreas,
      is_available:
        therapist.telephone_session_availability ||
        therapist.video_session_availability ||
        therapist.online_chat_sessions_availability ||
        therapist.in_person_session_availability,
    };

    this.isFetching = false;

    return (this.currentProfile = this.cache[id]);
  }

  async like() {
    if (this.currentProfile === null) return;

    this.currentProfile.is_my_favourite = !this.currentProfile.is_my_favourite;

    await this.http.post(
      `/v1/therapists/${this.currentProfile.id}/favourite/`,
      {
        is_my_favourite: !this.currentProfile.is_my_favourite,
      }
    );
  }

  async getByEmail(email: string): Promise<Therapist> {
    this.isFetching = true;
    if (this.cache[email]) {
      this.currentProfile = this.cache[email];
    } else {
      this.currentProfile = null;
    }
    const therapist: Therapist = await this.http.get(`/v1/therapists?email=${email}/`);

    this.cache[email] = {
      ...therapist,
      avatar: undefined,
      video: undefined,
      photos: [],
      credentialFiles: [],
      additionalAreas: [],
      is_available:
        therapist.telephone_session_availability ||
        therapist.video_session_availability ||
        therapist.online_chat_sessions_availability ||
        therapist.in_person_session_availability,
    };

    this.isFetching = false;

    return (this.currentProfile = this.cache[email]);
  }
}

export const therapistProfileService = proxy(new TherapistProfileService());
