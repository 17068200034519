import cn from "classnames";
import { useFormContext } from "react-hook-form";

import styles from "./StatusSwitch.module.scss";
import { useSnapshot } from "valtio";
import { authService} from "services/authService";
import { Therapist } from "types/therapist.types";

export const StatusSwitch = () => {
  const { getValues, setValue } = useFormContext();
  const { chat_status } = getValues();
  const therapist = useSnapshot(authService).user as Therapist;

  return (
    <div className={styles.switch}>
      <button
        onClick={() => setValue("chat_status", "available")}
        className={cn(styles.available, {
          [styles.selected]: chat_status === "available" && therapist.online_chat_sessions_availability,
        })}
      >
        <div className={cn(styles.circle, styles.green, !therapist.online_chat_sessions_availability && styles.disabled)} />
        Available
      </button>
      <button
        onClick={() => setValue("chat_status", "wait")}
        className={cn(styles.wait, {
          [styles.selected]: chat_status === "wait" && therapist.online_chat_sessions_availability,
        })}
      >
        <div className={cn(styles.circle, styles.yellow, !therapist.online_chat_sessions_availability && styles.disabled)} />
        Wait
      </button>
      <button
        onClick={() => setValue("chat_status", "offline")}
        className={cn(styles.offline, {
          [styles.selected]: chat_status === "offline" && therapist.online_chat_sessions_availability,
        })}
      >
        <div className={cn(styles.circle, styles.red, !therapist.online_chat_sessions_availability && styles.disabled)} />
        Offline
      </button>
    </div>
  );
};
