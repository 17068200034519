import React from 'react';
import { VideoData } from '../util';
import playIcon from 'assets/images/icons/play.svg';
import pauseIcon from 'assets/images/icons/pause.svg';
import styles from './Controls.module.scss';


function PlayButton({ pausedData }: { pausedData: VideoData<boolean> }) {
  const { val: isPaused, set: setIsPaused } = pausedData;
  return (
    <button
      className={styles.playButton}
      onClick={() => setIsPaused(curr => !curr)}
    >
      <img src={isPaused ? playIcon : pauseIcon} alt='play-button' />
    </button>
  );
}

export default PlayButton;