import { useNavigate } from "react-router-dom";
import animation from "assets/images/TheraPass-phone-lottie.json";
import { InputSmartSearch } from "common/UIKit/InputSmartSearch";
import { therapistListingService } from "services/therapistListingService";
import { useEffect } from "react";
import Lottie from "lottie-react";
import { ReactComponent as IconTiktok } from "assets/images/icons/social/tiktok.svg";
import { ReactComponent as IconFacebook } from "assets/images/icons/social/facebook.svg";
import { ReactComponent as IconInstagram } from "assets/images/icons/social/instagram.svg";
import { ReactComponent as IconCozi } from "assets/images/icons/social/cozi-tv.svg";
import { ReactComponent as IconInvest } from "assets/images/icons/social/investigation-discovery.svg";
import { ReactComponent as IconFetv } from "assets/images/icons/social/fetv.svg";
import { ReactComponent as IconNatioanal } from "assets/images/icons/social/national-geographic.svg";
import { ReactComponent as IconAnimal } from "assets/images/icons/social/animal-planet.svg";
import { useResponsive } from "hooks/useResponsive";
import { Slider } from "common/components/Slider/Slider";
import styles from "./LeadSection.module.scss";

export const LeadSection = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const featuredIcons = [
    <IconTiktok />,
    <IconFacebook />,
    <IconInstagram />,
    <IconCozi />,
    <IconInvest />,
    <IconFetv />,
    <IconNatioanal />,
    <IconAnimal />,
  ];

  const featuredIconsSliderNodes = featuredIcons.map((item) => {
    return <div className={styles.logotypes}>{item}</div>;
  });

  const handleSelectPlace = (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
  }) => {
    therapistListingService.setPlaceFilter({
      ...(args?.city && { city: args.city }),
      ...(args?.search && { search: args.search }),
      ...(args?.state && { state: args.state }),
      ...(args?.zip && { zip: args.zip }),
      withoutRequest: true,
    });
  };

  useEffect(() => {
    handleSelectPlace({ search: "" });
  }, []);

  return (
    <section className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Connect to a therapist{" "}
            <span>
              right now – <span>for free</span>
            </span>
          </h1>
          <div className={styles.subtitle}>
            Instantly connect to a therapist – without any up-front fees or
            memberships.
          </div>
          <div className={styles.find}>
            <InputSmartSearch
              placeholder="Enter city or zip code"
              onSelectPlace={handleSelectPlace}
              maxLength={200}
              max={200}
              withButton
              buttonClass={styles.bottomBtn}
              onButtonClick={() =>
                navigate("/listing", { state: { withScroll: true } })
              }
            />
          </div>
        </div>
        <Lottie
          animationData={animation}
          loop={true}
          className={styles.image}
          alt="Good morning! How can I help you today?"
        />
      </div>
      <div className={styles.feel}>
        <h2 className={styles.secondTitle}>Feel better, subscription-free</h2>
        <p className={styles.secondText}>
          No pricey commitments here. With Therapass, you pay only for the
          therapy you use.
          <span>
            Directly reach licensed pros matched to your therapy needs without
            scheduling a session.
          </span>
        </p>
      </div>
      <div className={styles.featuredIcons}>
        <h3 className={styles.featuredIconTitle}>As Seen On</h3>
        {isMobile ? (
            <div className={styles.iconsSlider}>
              <Slider
                  nodes={featuredIconsSliderNodes}
                  slidesPerGroup={3}
                  slidesPerView={3}
                  sliderClassname={styles.slider}
              />
            </div>
        ) : (
            <div className={styles.logotypes}>
              <IconTiktok />
              <IconFacebook />
              <IconInstagram />
              <IconCozi />
              <IconInvest />
              <IconFetv />
              <IconNatioanal />
              <IconAnimal />
            </div>
        )}
      </div>
    </section>
  );
};
