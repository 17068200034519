import { FC } from "react";
import cn from "classnames";
import styles from "../TherapistSteps.module.scss";

interface Props {
  className?: string;
  mobileHidden?: boolean;
}

export const TherapistStepsHint: FC<Props> = ({
  children,
  className,
  mobileHidden,
}) => (
  <div
    className={cn(styles.hint, className, {
      [styles.mobile_hidden]: mobileHidden,
    })}
  >
    {" "}
    {children}{" "}
  </div>
);
