import classNames from "classnames";
import { FC } from "react";

type Props = {
  className?: string;
};

export const PatientStepsHeader: FC<Props> = ({ children, className }) => (
  <h1 className={classNames("patient-step__title", "title", className)}>
    {children}
  </h1>
);
