import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDelayedFn, useMountedState } from 'hooks/misc';
import { AbstractFunc, getUpd, Nullable } from 'common/utils';
import { VideoData, UPD_RATE } from './util';
import useVideoEffect, { VideoRef } from '../videoCommon';


function useThrottledState<T>(
  initialValue: T,
  updRate: number
): { val: T, respondToEvent: AbstractFunc, forceNewState: Dispatch<SetStateAction<T>> } {
  const [state, setState] = useMountedState<T>(initialValue);
  const respondToEvent = useDelayedFn(setState, updRate, 'throttle');

  return { val: state, respondToEvent, forceNewState: setState };
}

function useCurrentTime(
  videoRef: VideoRef,
  updRate: number = UPD_RATE
): VideoData<number> {
  const { val, respondToEvent, forceNewState } = useThrottledState<number>(0, updRate);

  useVideoEffect(videoRef, video => {
    forceNewState(video.currentTime);
    video.ontimeupdate = () => respondToEvent(video.currentTime);

    return () => video.ontimeupdate = null;
  });

  function set(upd: SetStateAction<number>) {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    video.currentTime = getUpd<number>(upd, video.currentTime);
  }

  return { val, set };
}

function usePaused(videoRef: VideoRef): VideoData<boolean> {
  const [paused, setPaused] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const enqueued = useRef<Nullable<boolean>>(null);

  useVideoEffect(videoRef, video => {
    setPaused(video.paused);
    video.onpause = () => setPaused(true);
    video.onplay = () => setPaused(false);

    return () => {
      video.onpause = null;
      video.onplay = null;
    }
  });

  function change(shouldBePaused: boolean) {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    if (shouldBePaused && !video.paused) {
      video.pause()
    } else if (!shouldBePaused && video.paused) {
      setCanUpdate(false);
      video.play().then(() => setCanUpdate(true));
    }
  }

  useEffect(() => {
    if (canUpdate && enqueued.current != null) {
      change(enqueued.current);
      enqueued.current = null;
    }
  }, [canUpdate]);

  function set(upd: SetStateAction<boolean>) {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    const shouldBePaused = getUpd<boolean>(upd, video.paused);

    if (!canUpdate) {
      enqueued.current = shouldBePaused;
      return;
    }

    change(shouldBePaused);
  }

  return { val: paused, set };
}

function useTotalDuration(videoRef: VideoRef): Nullable<number> {
  const [totalDuration, setTotalDuration] = useMountedState<Nullable<number>>(null);

  const changeDuration = useDelayedFn(setTotalDuration, 250, 'debounce', false);
  useVideoEffect(videoRef, video => {
    video.ondurationchange = () => changeDuration(video.duration);

    return () => { video.ondurationchange = null; };
  });

  return totalDuration;
}

export {
  useCurrentTime,
  usePaused,
  useTotalDuration
}