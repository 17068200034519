import { AreaShort } from "types/areas.types";
import { isEmpty, trim } from "lodash";

export type Address = {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
};

export function addressToString(address: Address) {
  return `${[address.address_line_1, address.address_line_2]
    .filter(Boolean)
    .join(" ")}, ${address.city}, ${address.state} ${address.zip_code}`;
}

export function areaToString(location: AreaShort) {
  return [location.city, `${location.state} ${location.zip_code || ""}`]
    .map((item) => trim(item))
    .filter((item) => !isEmpty(item))
    .join(", ");
}
