import { FC } from "react";
import { useSnapshot } from "valtio";
import { Modal } from "common/components/Modal";
import tickCircle from "assets/images/tick-circle-green.png";
import { useModal } from "hooks/useModal";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { emailService } from "services/emailService";
import { tokenService } from "services/tokenService";
import styles from "./EmailSuccessPopup.module.scss";

export const EmailSuccessPopup: FC = () => {
  const { isOpen, close } = useModal("CHANGE_EMAIL_SUCCESS");
  const { newToken } = useSnapshot(emailService);

  const onClose = () => {
    tokenService.set(newToken);

    return close();
  };

  return (
    <Modal className={styles.root} visible={isOpen}>
      <div className={styles.emailSuccess}>
        <button className={styles.popupCloseButton} onClick={onClose}>
          <CloseIcon />
        </button>
        <img src={tickCircle} alt="success" />
        <h1>Your email has been changed</h1>
        <small>Thank you!</small>
      </div>
    </Modal>
  );
};
