import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import { AbstractFunc } from 'common/utils';

function useDelayedFn(
  fn: AbstractFunc, updRate: number, delayer: 'throttle' | 'debounce', leading = true
): AbstractFunc {
  const delayedFnRef = useRef(_[delayer](fn, updRate, { leading }));

  return (...args) => {
    delayedFnRef.current.cancel();
    delayedFnRef.current(...args);
  };
}

function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false };
  }, []);

  return isMounted;
}

function useMountedState<T>(initialState: T): [T, Dispatch<SetStateAction<T>>] {
  const isMounted = useIsMounted();
  const [state, setState] = useState<T>(initialState);

  function setStateIfMounted(upd: SetStateAction<T>) {
    if (isMounted.current) {
      setState(upd);
    }
  }

  return [state, setStateIfMounted];
}

export { useDelayedFn, useMountedState, useIsMounted };