import React from 'react';
import cn from "classnames";
import styles from "./Step.module.scss";
import { Scale } from "../../Scale";

export interface StepProps {
  isActive: boolean;
  shortLabel: string;
  longLabel: string;
  caption: string;
  progress?: number;
}

export function Step({ progress, isActive, longLabel, shortLabel, caption }: StepProps) {
  return (
    <div className={styles.step}>
      <div className={styles.tagWrapper}>
        <div className={cn(styles.tag, {[styles.tagActive]: isActive})}> { shortLabel }</div>
      </div>
      <div className={styles.info}>
        <div className={cn(styles.title, {[styles.titleActive]: isActive})}> { longLabel } </div>
        <div className={cn(styles.subTitle, {[styles.subTitleActive]: isActive})}> {caption}</div>
        { progress != null && <Scale className={styles.scale} scaleValue={progress} /> }
      </div>
    </div>
  );
}
