import { useNavigate } from "react-router-dom";
import useFileUpload from "hooks/useFileUpload";
import { useResponsive } from "hooks/useResponsive";
import { EditHeader } from "common/components/EditHeader";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { pathPhotosAndVideo } from "../PhotosAndVideos";
import styles from "./PhotosEdit.module.scss";
import ControlledFileUploadProfile from "common/components/ControlledFileUploadProfile";

export const PhotosEdit = () => {
    const navigate = useNavigate();
    const { goBack } = useDashboardNavigation("Upload New Photos", {
        goBack: () => navigate(`./../..${pathPhotosAndVideo}`),
    });

    const { isMobile } = useResponsive();
    const { fileUploadProps, handleSubmit,  } = useFileUpload(
        "photo_and_video_intro",
        ["image"],
        { onSuccess: goBack, instantUpload: true }
    );

    return (
        <div className={styles.photosEdit}>
            <EditHeader />
            <form onSubmit={handleSubmit}>
                <ControlledFileUploadProfile
                    accept="image/jpg,image/gif,image/png,image/tiff,image/jpeg"
                    multiple
                    caption={isMobile ? undefined : "Drag photos here to start uploading"}
                    className={styles.dropContainer}
                    {...fileUploadProps}
                />
            </form>
        </div>
    );
};