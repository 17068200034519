import Checkbox from "common/UIKit/Checkbox/Checkbox";
import InputSearch from "common/UIKit/InputSearch/InputSearch";
import { ShowMore } from "common/UIKit/ShowMore";
import { stepsFullPath } from "../stepsPath";
import styles from "./PatientStep3.module.scss";
import { usePatientStep3 } from "./usePatientStep3";
import { stepsUi } from "../components";
import { step2FullPath } from "../PatientStep2";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { useResponsive } from "hooks/useResponsive";

export const step3Path = "/3";
export const step3FullPath = [stepsFullPath, step3Path].join("");

export const PatientStep3 = () => {
  const {
    search,
    issues,
    selectedIssues,
    isSubmitting,
    error,
    handleSearch,
    handleChange,
    handleSubmit,
  } = usePatientStep3();
  const { isMobile } = useResponsive();

  return (
    <>
      <stepsUi.Header className={styles.title}>
        I’d prefer a therapist with experience in:
      </stepsUi.Header>
      <stepsUi.Hint>Check all that apply</stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={
          <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <stepsUi.Actions
              buttons={[
                {
                  type: "button",
                  children: "Back",
                  className: "btn-color-blue",
                  isLink: true,
                  to: step2FullPath,
                },
                {
                  type: "submit",
                  children: isMobile ? "Continue" : "Next",
                  isLoading: isSubmitting,
                },
              ]}
            />
          </>
        }
      >
        <InputSearch
          className={styles.search}
          value={search}
          onChange={handleSearch}
          selectedCount={selectedIssues.length}
        />
        <ShowMore
          showMoreText="Show More Issues"
          showLessText="Show Less Issues"
          className={styles.showmore}
          maxHeight={418}
          isTwoColumns={!isMobile}
        >
          {issues.length > 0 ? (
            issues.map((issue) => (
              <Checkbox
                key={issue.code}
                className="patient-step__checkbox"
                id={issue.code}
                name={issue.code}
                checked={selectedIssues.includes(issue.code)}
                onChange={handleChange}
              >
                {issue.name}
              </Checkbox>
            ))
          ) : (
            <div className={styles.no_results}>No results</div>
          )}
        </ShowMore>
      </stepsUi.Form>
    </>
  );
};
