import React from "react";
import clsx from "clsx";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import { Typography } from "@mui/material";
import useIsTrackSwitchedOff from "hooks/useIsTrackSwitchedOff";
import usePublications from "hooks/usePublications";
import useTrack from "hooks/useTrack";
import useVideoContext from "hooks/useVideoContext";
import AvatarIcon from "icons/AvatarIcon";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import styles from "./MainParticipantInfo.module.scss";

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  children,
}: MainParticipantInfoProps) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const isLocal = localParticipant === participant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(
    (p) => !p.trackName.includes("screen") && p.kind === "video"
  );
  const screenSharePublication = publications.find((p) =>
    p.trackName.includes("screen")
  );

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={clsx(styles.container, {
        [styles.fullWidth]: true,
      })}
    >
      <div className={styles.infoContainer}>
        <div style={{ display: "flex" }}>
          <div className={styles.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Typography variant="body1" color="inherit">
              {participant.identity}
              {isLocal && " (You)"}
              {screenSharePublication && " - Screen"}
            </Typography>
          </div>
          <NetworkQualityLevel participant={participant} />
        </div>
      </div>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div className={styles.avatarContainer}>
          <AvatarIcon />
        </div>
      )}
      {children}
    </div>
  );
}
