import Button from "common/UIKit/Button";
import Input, {InputSizeType} from "common/UIKit/Input";
import { ReactComponent as CrossIcon } from "assets/images/icons/close-square.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { chatCommandsService } from "services/chatCommandsService";
import React, {CSSProperties, useEffect, useState} from "react";
import { toast } from "react-toastify";
import { catchFormErrors } from "common/utils";
import { AxiosResponse } from "axios";
import styles from "./MacrosCustomItem.module.scss";

interface Props {
  id?: number;
  textValue?: string;
  nameValue?: string;
}

interface Inputs {
  name: string;
  text: string;
}

export interface InputProps
    extends Omit<React.HTMLProps<HTMLInputElement>, "size"> {
  size?: InputSizeType;
  label?: string;
  error?: string;
  optional?: string;
  adornment?: string;
  description?: string;
  adornmentVariant?: "black" | "gray";
  descriptionAlignment?: "left" | "right";
  isValid?: boolean;
  inputStyles?: CSSProperties;
  withLeftAdornment?: boolean;
  hashtag?: boolean
}

export const MacrosCustomItem = ({ id, nameValue, textValue }: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<Inputs>();
  const [formError, setFormError] = useState([]);

  useEffect(() => {
    if (id) {
      nameValue && setValue("name", nameValue);
      textValue && setValue("text", textValue);
    }
  }, [id, nameValue, textValue, setValue]);

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    try {
      id
        ? await chatCommandsService
            .patch(id, values)
            .then(() => toast("Macros was updated."))
        : await chatCommandsService
            .create(values)
            .then(() => toast("Macros was created."));
    } catch (error) {
      const e = error as AxiosResponse;
      catchFormErrors(e, setError);
      setFormError(e.data.errors);
    }
  };

  const onDelete = () => {
    id
      ? chatCommandsService
          .delete(id)
          .then(() => toast("Macros was removed.", { type: "error" }))
      : chatCommandsService.fetch(false);
  };

  return (
      <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
        <Input
          withLeftAdornment
          adornment="#"
          label="Name"
          className={styles.input}
          {...register("name", {
            setValueAs: (value) => '#' + value,
            validate: {
              whitespace: (value) =>
                !/\s/.test(value) || "Name shouldn't had whitespace",
            },
            minLength: { value: 2, message: "Minimum 2 characters" },
          })}
          error={errors.name?.message || formError.join(" ")}
          maxLength={64}
          hashtag={true}
        />
        <Input
            label="Text"
            className={styles.input}
            {...register("text", {required: "This field is required."})}
            error={errors.text?.message}
            maxLength={160}
        />
        <div className={styles.row}>
          <Button type="submit" className={styles.button}>
            Save
          </Button>
          <button type="button" className={styles.closeButton} onClick={onDelete}>
            <CrossIcon/>
            <span>Remove</span>
          </button>
        </div>
      </form>
  );
};
