import { ReactComponent as StarIcon } from "assets/images/icons/star-fill.svg";
import styles from "./TestimonialCard.module.scss";
import { TestimonialsListItem } from "types/testimonials.types";
import avatar from "assets/images/avatar.png";

export const TestimonialCard = ({ user, text, rank }: TestimonialsListItem) => {
  return (
    <div className={styles.card}>
      <img src={user.image || avatar } alt={user.first_name} className={styles.avatar} />
      <div className={styles.stars}>
        {[...Array(rank)].map((_, ind) => (
          <StarIcon key={ind} />
        ))}
      </div>
      <p className={styles.cardText}>{text}</p>
      <p className={styles.cardTitle}>{`${user.first_name} ${user.last_name}`}</p>
    </div>
  );
};
