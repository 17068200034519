import React, { useEffect } from "react";
import { LoadingHOC } from "common/components/LoadingHOC";
import { ArticlesLayout } from "../ArticlesLayout";
import { useSnapshot } from "valtio";
import { articlesService } from "services/articlesService";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./Article.module.scss";
import Default from "assets/images/articles/default.jpg";
import { formatArticleDate } from "common/utils";
import { ArticleCard } from "../components/ArticleCard/ArticleCard";
import { Back } from "common/components/Back";
import { ReactComponent as LogInIcon } from "assets/images/icons/login.svg";
import { ReactComponent as Calendar } from "assets/images/icons/calendar2.svg";
import { ReactComponent as Clock } from "assets/images/icons/clock-article.svg";

export const articlePath = "/article/:id";
const Article = () => {
  const { article, isFetching, featuredArticles } =
    useSnapshot(articlesService);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      articlesService.getArticleById(id);
    }
    articlesService.list();
  }, [id]);

  const titleImage = article?.files.find((el) => el.type === "title");

  return (
    <LoadingHOC
      isLoading={isFetching}
      className={styles.listingCatalog}
      text="Searching for articles..."
    >
      <ArticlesLayout>
        <section className={styles.root}>
          <div className={styles.navigations}>
            <Back className={styles.back} />
            <div
              onClick={() => navigate("/articles")}
              className={styles.linkToAll}
            >
              All articles
            </div>
          </div>
          <div className={styles.titleImage}>
            <img src={titleImage?.file || Default} />
          </div>
          <h2>{article?.title}</h2>
          <div className={styles.captions}>
            {article?.author_name && (
              <div className={styles.author}>
                <LogInIcon />
                {article?.author_name}
              </div>
            )}
            {article?.published_at && (
              <div className={styles.date}>
                <Calendar />
                <p>{formatArticleDate(article?.published_at, true)}</p>
              </div>
            )}
            {Number(article?.time_to_read) > 0 && (
              <div className={styles.timeToRead}>
                <Clock />
                <p>{`${article?.time_to_read} minutes to read`}</p>
              </div>
            )}
          </div>
          {article?.body && (
            <div
              className={styles.mainText}
              dangerouslySetInnerHTML={{ __html: article?.body }}
            />
          )}
          <div className={styles.categoryLinks}>
            {article?.categories.map((el: any, i: any) => {
              if (i === article.categories.length - 1) {
                return (
                  <Link
                    to="/articles"
                    className={styles.categoryLink}
                    onClick={() => {
                      articlesService.setCurrentCategoryTitle(el.title);
                      articlesService.setCurrentCategory(el.code);
                    }}
                  >
                    <p>{el.title}</p>
                  </Link>
                );
              } else {
                return (
                  <Link
                    to="/articles"
                    className={styles.categoryLink}
                    onClick={() => {
                      articlesService.setCurrentCategoryTitle(el.title);
                      articlesService.setCurrentCategory(el.code);
                    }}
                  >
                    <p>{el.title},</p>
                  </Link>
                );
              }
            })}
          </div>
          {featuredArticles.length > 0 && (
            <>
              <h2>Featured Articles</h2>
              <div className={styles.cards}>
                {[...featuredArticles].slice(0, 3).map((item) => {
                  return (
                    <ArticleCard
                      className={styles.cardInArticle}
                      src={item.files[0]?.file}
                      title={item.title}
                      date={item.published_at}
                      id={item.id}
                      categories={item.categories}
                      author_name={item.author_name}
                    />
                  );
                })}
              </div>
            </>
          )}
        </section>
      </ArticlesLayout>
    </LoadingHOC>
  );
};

export default Article;
