import { capitalize } from "lodash";
import { stepsFullPath } from "../stepsPath";
import { stepsUi } from "../components";
import { step3_2FullPath } from "../TherapistStep3_2";
import AddButton from "common/UIKit/AddButton";
import Card from "common/UIKit/Card";
import { useTherapistStep3_3 } from "./useTherapistStep3_3";
import {
  NUMBER_FIELD,
  NUMBER_LABEL,
  ORGANIZATION_FIELD,
  ORGANIZATION_LABEL,
} from "constants/fields";
import { useResponsive } from "hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DocumentIcon } from "assets/images/icons/document.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/pencil.svg";
import styles from "./TherapistStep3_3.module.scss";

export const step3_3Path = "/3_3";
export const step3_3FullPath = `${stepsFullPath}${step3_3Path}`;

export function TherapistStep3_3() {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { handleSubmit, handleSkip, handleRemove, credentials, isSubmitting } =
    useTherapistStep3_3();

  return (
    <>
      <stepsUi.Header>Credentials</stepsUi.Header>
      <stepsUi.Hint className={`therapist-step-3__hint ${styles.hint}`}>
        add other credentials 3/3
      </stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={
          <>
            <stepsUi.Actions
              onSkip={handleSkip}
              buttons={[
                {
                  type: "button",
                  children: "Back",
                  className: "btn-color-blue",
                  isLink: true,
                  to: step3_2FullPath,
                },
                {
                  type: "submit",
                  children: isMobile ? "Continue" : "Next",
                  isLoading: isSubmitting,
                },
              ]}
            />
          </>
        }
      >
        {credentials.map((c) => (
          <Card
            key={c.id}
            values={[
              { caption: "CREDENTIAL TYPE:", text: capitalize(c.type) },
              {
                caption: c.type ? ORGANIZATION_LABEL[c.type].toUpperCase() : "",
                text: c[ORGANIZATION_FIELD[c.type]] || "-",
              },
              {
                caption: c.type ? NUMBER_LABEL[c.type].toUpperCase() : "",
                text: c[NUMBER_FIELD[c.type]] || "-",
              },
              { caption: "YEAR", text: c.year || "-" },
            ]}
            icon={<DocumentIcon />}
            editIcon={<EditIcon />}
            buttonClass={styles.cardButton}
            onRemove={() => handleRemove(c.id)}
            onEdit={() => navigate(`add/${String(c.id)}`)}
          />
        ))}
        <AddButton type="button" onClick={() => navigate("add")}>
          Add another credential
        </AddButton>
      </stepsUi.Form>
    </>
  );
}
