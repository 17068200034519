import { stepsFullPath } from "../stepsPath";
import { TherapistFields } from "../util";

export const coveredFields: TherapistFields = [
  "primary_credential_type",
  "verified_credential_type",
  "mental_health_role",
  "license_number",
  "license_state",
  "license_expiration",
  "supervisor_name",
  "supervisor_license_number",
  "supervisor_license_state",
  "supervisor_license_expiration",
  "school_graduated",
  "degree_diploma",
  "year_graduated",
  "main_other_credential_for_no_license",
];
export const step3_1Path = "/3_1";
export const step3_1FullPath = `${stepsFullPath}${step3_1Path}`;
