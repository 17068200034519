import ControlledMultiCardCheckbox, {
  ListOptionWithIcon,
} from "common/components/ControlledMultiCardCheckbox";
import { Control } from "react-hook-form";
import { ModalitiesEnum } from "types/therapist.types";
import { ReactComponent as PersonIcon } from "assets/images/icons/single-person.svg";
import { ReactComponent as CouplesIcon } from "assets/images/icons/couples.svg";
import { ReactComponent as FamilyIcon } from "assets/images/icons/family.svg";
import { ReactComponent as GroupIcon } from "assets/images/icons/group.svg";

interface Props {
  className?: string;
  control: Control<any>;
  label?: string;
  name?: string;
}

const modalityOptions: ListOptionWithIcon[] = [
  {
    value: ModalitiesEnum.individuals,
    label: "Individuals",
    icon: <PersonIcon />,
  },
  { value: ModalitiesEnum.couples, label: "Couples", icon: <CouplesIcon /> },
  { value: ModalitiesEnum.family, label: "Family", icon: <FamilyIcon /> },
  { value: ModalitiesEnum.group, label: "Groups", icon: <GroupIcon /> },
];

export const ModalityCardCheckbox = ({
  className,
  control,
  label = "Modality",
  name = "modalities",
}: Props) => {
  return (
    <ControlledMultiCardCheckbox
      name={name}
      control={control}
      options={modalityOptions}
      className={className}
      label={label}
    />
  );
};
