import { forwardRef, HTMLProps } from "react";
import cn from "classnames";

import "./ToggleCheckbox.scss";

interface Props extends HTMLProps<HTMLInputElement> {}

export const ToggleCheckbox = forwardRef<HTMLInputElement, Props>(({ className, ...restProps }, ref) => {
  return <input ref={ref} type="checkbox" className={cn("toggle-check", className)} {...restProps} />;
});

export default ToggleCheckbox;
