import { HttpService } from "./httpService";
import { DeepPartial } from "common/utils";
import { ListResponse } from "types/common.types";
import { tokenService } from "./tokenService";

interface IWithId<TId> {
  id: TId;
}

type Filters = Record<string, any>;

export class CrudService<
  TId,
  TEntity extends IWithId<TId>
> extends HttpService {
  protected prefix: string;

  cache: Record<number, TEntity[]> = {};

  entity: TEntity | null = null;
  data: TEntity[] = [];

  isFetched: boolean = false;
  isProcessing: boolean = false;

  filters: Filters = {};
  pageSize: number = 1000;
  pageCount: number = 1;
  page: number = 1;

  constructor({
    prefix,
    pageSize,
    ...other
  }: {
    prefix: string;
    pageSize?: number;
  }) {
    super(other);
    this.prefix = prefix;
    this.pageSize = pageSize || 1000;
  }

  init() {
    tokenService.sync((newToken) => newToken === null && this.reset());
  }

  async getById(id: TId, useCache: boolean = true): Promise<TEntity> {
    const cached = this.data.find((entity) => entity.id === id);
    this.entity =
      useCache && cached
        ? cached
        : ((await this.http.get(`${this.prefix}/${id}/`)) as TEntity);

    return this.entity;
  }

  async patch(id: TId, diff: DeepPartial<TEntity>): Promise<TEntity> {
    const updatedEntity = await this.http.patch<DeepPartial<TEntity>, TEntity>(
      `${this.prefix}/${id}/`,
      diff
    );
    this.data = this.data.map((entity) =>
      entity.id === id ? updatedEntity : entity
    );
    return updatedEntity;
  }

  async create(data: DeepPartial<TEntity>): Promise<TEntity> {
    const newEntity = await this.http.post<DeepPartial<TEntity>, TEntity>(
      `${this.prefix}/`,
      data
    );
    this.data.push(newEntity);
    return newEntity;
  }

  async list(useCache: boolean = true, params: any = {}): Promise<TEntity[]> {
    this.isProcessing = true;
    if (!(useCache && this.cache[this.page])) {
      const pagination = this.pageSize
        ? { page: this.page, page_size: this.pageSize }
        : {};
      const response = (await this.http.get(`${this.prefix}/`, {
        params: { ...pagination, ...this.filters, ...params },
      })) as ListResponse<TEntity[]> | TEntity[];
      this.cache[this.page] =
        "results" in response ? response.results : response;

      this.isFetched = true;
    }
    this.data = this.cache[this.page];

    this.isProcessing = false;
    return this.data;
  }

  setFilters(filters: Filters) {
    this.page = 1;
    this.filters = filters;
    this.cache = {};
    this.list();
  }

  goToPage(page: number) {
    this.page = page;
    this.list();
  }

  async delete(id: TId): Promise<void> {
    await this.http.delete(`${this.prefix}/${id}/`);
    this.data = this.data.filter((entity) => entity.id !== id);
  }

  reset() {
    this.cache = [];
    this.entity = null;
    this.data = [];
    this.isFetched = false;
    this.isProcessing = false;
    this.filters = {};
    this.pageSize = 1000;
    this.pageCount = 1;
    this.page = 1;
  }
}
