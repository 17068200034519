import { proxy } from "valtio";

import { TherapistFeaturedItem, TherapistId } from "../types/therapist.types";
import { CrudService } from "./crudService";

export class FavoriteTherapistsService extends CrudService<
  TherapistId,
  TherapistFeaturedItem
> {
  constructor(args?: any) {
    super({ ...args, prefix: "/v1/therapists/favourite" });
  }
}

export const favoriteTherapistsService = proxy(new FavoriteTherapistsService());
favoriteTherapistsService.init();
