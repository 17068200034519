import { useAuth } from "hooks/useAuth";
import { Route, Routes } from "react-router-dom";
import { Therapist } from "types/therapist.types";
import { ColumnPage } from "../components/ColumnPage/ColumnPage";
import { editPath } from "../helpers";
import { paymentsCardData } from "./helpers";
import { isStripeAccess } from "services/authService";
import {
  pathRateCards,
  EditRateCards,
  EditStripe,
  pathStripe,
  EditFees,
  pathFees,
  PurchaseHistory,
  pathPurchaseHistory,
} from "./pages";

export const paymentsPath = "/payments";
export const fullPaymentsPath = "/therapists/dashboard/payments";

export const Payments = () => {
  const user = useAuth().user as Therapist;

  return (
    <Routes>
      <Route
        path="*"
        element={<ColumnPage data={paymentsCardData} title="Payments" />}
      />
      <Route path={editPath(pathFees)} element={<EditFees />} />
      {isStripeAccess(user) && (
        <>
          <Route path={editPath(pathStripe)} element={<EditStripe />} />
          <Route path={editPath(pathRateCards)} element={<EditRateCards />} />
          <Route path={pathPurchaseHistory} element={<PurchaseHistory />} />
        </>
      )}
    </Routes>
  );
};
