import { useEffect } from "react";
import { Nullable } from "common/utils";
import cn from "classnames";
import VideoPlayer from "common/components/Video/Player";
import { ImagePreview } from "common/components/ImagePreview";
import { useMountedState } from "hooks/misc";
import { useResponsive } from "hooks/useResponsive";
import { TherapistFile } from "types/therapist.types";
import { Src } from "types";
import styles from "./parts.module.scss";

type BlindFn = () => void;

type AutosizeImageProps = { src: string; alt: string };

function AutosizeImage({ src, alt }: AutosizeImageProps) {
  const [isVertical, setIsVertical] = useMountedState<Nullable<boolean>>(null);

  useEffect(() => {
    setIsVertical(null);
    const image = new Image();
    image.onload = () => {
      const { width, height } = image;
      setIsVertical(height > width);
    };
    image.src = src;
  }, [src]);

  if (isVertical == null) {
    return null;
  }

  return (
    <img
      src={src}
      className={isVertical ? styles.verticalImage : styles.horizontalImage}
      alt={alt}
    />
  );
}

type DocumentPreviewProps = {
  file: TherapistFile | Src;
};

function DocumentPreview({ file }: DocumentPreviewProps) {
  return (
    <ImagePreview
      file={file}
      alt={`credential-${file.type}`}
      className={styles.documentPreview}
    />
  );
}

export type PreviewProps = {
  large?: boolean;
  openRemovalOverlay: BlindFn;
  onIconClick?: () => void;
  file: TherapistFile | Src;
};

export function Preview({
  large,
  openRemovalOverlay,
  onIconClick,
  file,
}: PreviewProps) {
  const { isMobile } = useResponsive();
  const src = "src" in file && file.src;

  let previewContent;

  switch (file.type) {
    case "image":
      previewContent = src && (
        <>
          <AutosizeImage src={src} alt="preview-image" />
        </>
      );
      break;
    case "application":
      previewContent = src && <DocumentPreview file={file} />;
      break;
    case "video":
      previewContent = src && <VideoPlayer src={src} />;
      break;
    case "photo_and_video_intro":
      previewContent = src && <VideoPlayer src={src} />;
      break;
    default:
      return <p>Preview unavailable</p>;
  }

  return (
    <div
      className={cn(styles.preview, large && styles.preview_large)}
      onClick={() => isMobile && openRemovalOverlay()}
      onContextMenu={(event) => event.preventDefault()}
    >
      {previewContent}
    </div>
  );
}
