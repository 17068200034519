import React from "react";
import { Typography, Grid } from "@mui/material";
import EndCallButton from "common/components/Buttons/EndCallButton/EndCallButton";
import useVideoContext from "hooks/useVideoContext";
import styles from "./MobileTopMenuBar.module.scss";

export default function MobileTopMenuBar() {
  const { room } = useVideoContext();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={styles.container}
    >
      <Typography variant="subtitle1">{room!.name}</Typography>
      <div>
        <EndCallButton className={styles.endCallButton} />
      </div>
    </Grid>
  );
}
