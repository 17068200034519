import { FC, useState } from "react";
import { useResponsive } from "hooks/useResponsive";
import { Nullable } from "common/utils";
import { FullScreenContainer } from "../FullScreenContainer";
import VideoPlayer from "../Video/Player";
import { MoveOnButton } from "pages/Therapists/TherapistSteps/TherapistStep9_2";
import VideoRecorder from "../Video/Recorder";
import frameLeftImage from "assets/images/frames/frame-left.png";
import frameImage from "assets/images/frames/frame.png";
import frameRightBlueImage from "assets/images/frames/frame-right-blue.png";
import frameBottomImage from "assets/images/frames/frame_bottom.png";
import removeIcon from "assets/images/icons/remove.svg";
import styles from "./RecordVideoModal.module.scss";

interface Props {
  onClose: () => void;
  recordingResult: Nullable<Blob>;
  onRecorded: (recording: Blob) => void;
  isUploading: boolean;
  uploadRecording: () => void;
  error: Nullable<Error>;
}

export const RecordVideoModal: FC<Props> = ({
  onClose,
  recordingResult,
  onRecorded,
  isUploading,
  uploadRecording,
}) => {
  const { isMobile } = useResponsive();
  const [isFullScreenAccepted, setIsFullScreenAccepted] = useState(false);

  const onEnterFullScreen = () => setIsFullScreenAccepted(true);

  const onRecordingApprove = () => {
    uploadRecording();
    onClose();
  };

  return (
    <div className={styles.recordVideoModal}>
      <img
        src={frameLeftImage}
        alt="frameLeft"
        className={styles.frameLeftImage}
      />
      <img src={frameImage} alt="frame" className={styles.frameImage} />
      <img
        src={frameRightBlueImage}
        alt="frameRightBlue"
        className={styles.frameRightBlueImage}
      />
      <img
        src={frameBottomImage}
        alt="frameBottom"
        className={styles.frameBottomImage}
      />

      <div className={styles.content}>
        <h1>Recording Video</h1>
        <FullScreenContainer
          isFullscreen={isMobile}
          className={styles.container}
          onEnter={onEnterFullScreen}
        >
          <button
            type="button"
            className={styles.cancelButton}
            onClick={onClose}
          >
            <img src={removeIcon} alt="remove" />
          </button>

          {recordingResult ? (
            <VideoPlayer
              className={styles.videoPlayer}
              isFullscreen={isMobile}
              src={URL.createObjectURL(recordingResult)}
              adornment={
                <MoveOnButton
                  onClick={onRecordingApprove}
                  disabled={isUploading}
                />
              }
            />
          ) : (
            <VideoRecorder
              onRecorded={onRecorded}
              isFullscreen={isMobile}
              isDisabled={!isFullScreenAccepted}
            />
          )}
        </FullScreenContainer>
      </div>
    </div>
  );
};
