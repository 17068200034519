import { Spinner } from "common/components/Spinner";
import { useEffect } from "react";
import { Redirect } from "../Redirect";
import styles from "./LoadingWithRedirect.module.scss";

export const loadingPath = "/loading";

export const LoadingWithRedirect = () => {
  const withRedirect = localStorage.getItem("redirect_to_new_window");

  useEffect(() => {
    return () => localStorage.removeItem("redirect_to_new_window");
  }, []);

  if (!withRedirect) return <Redirect to="/" />;

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <Spinner />
        <h1 className={styles.title}>Redirect to Stripe</h1>
      </div>
    </div>
  );
};
