import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useResponsive } from "hooks/useResponsive";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { ReactComponent as IconSearch } from "assets/images/icons/search-black.svg";
import { InfoCard } from "../../Profile/pages/InfoCard";
import { useState, ChangeEvent } from "react";
import InputSearch from "common/UIKit/InputSearch";
import { ProfileCard } from "pages/Therapists/TherapistsDashboard/helpers";
import styles from "./ColumnPage.module.scss";

interface Props {
  title: string;
  data: ProfileCard[];
}

export const ColumnPage = ({ title, data }: Props) => {
  useDashboardNavigation(title, {
    goBack: "pop_path",
  });
  const { isMobile } = useResponsive();
  const [searchValue, setSearchValue] = useState("");
  const [cardData, setCardData] = useState([...data]);
  useDocumentTitle(`TheraPass - ${title}`);

  return (
    <div className={styles.profile}>
      <section className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <InputSearch
          searchIcon={!isMobile ? <IconSearch /> : undefined}
          placeholder={!isMobile ? "Search" : "Search issues"}
          className={styles.search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setSearchValue(value);

            if (value.length > 1) {
              setCardData([
                ...data.filter((card) =>
                  card.name.toLowerCase().includes(value.toLowerCase())
                ),
              ]);
            } else setCardData([...data]);
          }}
        />
      </section>
      <section className={styles.infoCards}>
        {cardData.length > 0 ? (
          cardData.map((profileCard) => (
            <InfoCard
              info={profileCard}
              key={profileCard.id}
              {...profileCard}
            />
          ))
        ) : (
          <p>{`"${searchValue}" Not found.`}</p>
        )}
      </section>
    </div>
  );
};
