import { proxy } from "valtio";
import { Id } from "types";
import { RatePurchase } from "types/ratePurchase.types";
import { ListService } from "services/listService";

class RatePurchaseService extends ListService<Id, RatePurchase> {
  isInvoiceFetched = true;

  constructor(props?: any) {
    super({ ...props, prefix: "/v1/rate-cards-purchases" });
  }

  async getInvoice(id: Id) {
    this.isInvoiceFetched = false;
    try {
      const data = (await this.http.get(
        `${this.prefix}/${id}/invoice/`
      )) as RatePurchase;
      this.isInvoiceFetched = true;
      return data;
    } catch (error) {
      this.isInvoiceFetched = true;
      return error;
    }
  }
}

export const ratePurchaseService = proxy(new RatePurchaseService());
