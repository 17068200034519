import { useEffect, useState } from "react";

import { useModal } from "hooks/useModal";

import { communicationService } from "services/communicationService";
import { ListOption } from "constants/options";
import { formatPhone } from "common/utils";

export default function useChangePhone() {
  const { isOpen, close: closePhoneModal } = useModal("CHANGE_PHONE_NUMBER");
  const { open: openMessageModal } = useModal("MESSAGE");
  const [options, setOptions] = useState<ListOption[]>([]);
  const [input, setInput] = useState("");
  const [selectedOption, setSelectedOption] = useState<ListOption | null>(null);
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      if (isOpen && !options.length) {
        const results = await communicationService.searchPhone("");
        setOptions(
          results.map((option) => ({
            value: option.number,
            label: formatPhone(option.number),
          }))
        );
      }
    })();
  }, [isOpen]);

  const onClose = () => {
    closePhoneModal();
    openMessageModal();
  };

  const onSave = () => {
    if (selectedOption?.value) {
      communicationService.senderPhone = selectedOption.value;
      onClose();
    } else {
      setError("This field is required.");
    }
  };

  const onInputChange = (newValue: string) => {
    setTimeout(async () => {
      const results = await communicationService.searchPhone(newValue);
      setOptions(
        results.map((option) => ({
          value: option.number,
          label: formatPhone(option.number),
        }))
      );
    })
    setInput(newValue);
  };

  const onChange = (nextValue: unknown) => {
    setSelectedOption(nextValue as ListOption);
    setError("");
  };

  return {
    isOpen,
    options,
    input,
    selectedOption,
    error,
    onSave,
    onInputChange,
    onChange,
    onClose,
  };
}
