import { FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSteps } from "hooks/useSteps";
import { useAuth } from "hooks/useAuth";
import { AppointmentDate, Patient } from "types/patient.types";

export const usePatientStep10 = () => {
  const { isSubmitting, error, submit } = useSteps();
  const { setIsProfileCompleted } = useAuth();
  const patient = useAuth().user as Patient;
  const [selectedDate, setSelectedDate] = useState<AppointmentDate>(
    patient?.appointment_date || "immediately"
  );

  const navigate = useNavigate();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    try {
      setIsProfileCompleted(true);
      await submit({
        diff: {
          appointment_date: selectedDate,
          user: {
            status: "completed",
          },
        },
        onSuccess: () => navigate("/patients"),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    isSubmitting,
    error,
    selectedDate,
    setSelectedDate,
    handleSubmit,
  };
};
