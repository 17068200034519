import { proxy } from "valtio";
import { TherapistCollectionService } from "./therapistCollectionService";
import { Education, EducationId } from "../types/education.types";

class EducationService extends TherapistCollectionService<
  EducationId,
  Education
> {
  constructor(props?: any) {
    super({ ...props, collectionString: "/educations" });
  }
}

export const educationService = proxy(new EducationService());
