import { FC } from "react";
import cn from "classnames";
import { testResultLevelToColor } from "common/utils";
// @ts-ignore
import arc from "svg-arc";

import styles from "./TestScore.module.scss";
import { ReactComponent as ChartScorePointer } from "assets/images/chart-score-pointer.svg";

interface Props {
  points?: number;
  score: number;
  maxScore: number;
  radius?: number;
  segmentWidth?: number;
  segmentSeparation?: number;
  barsSeparation?: number;
  backdropRadius?: number;
  className?: string;
}

export const TestScore: FC<Props> = ({
  points,
  score,
  maxScore,
  radius = 48,
  segmentWidth = 4,
  segmentSeparation = 1,
  barsSeparation = 3,
  backdropRadius = 28,
  className,
}) => {
  const arrowRotationAngle = (180 * (score - 1) + 90) / maxScore;

  return (
    <div className={cn(className, styles.testScore)}>
      <svg
        width={radius * 2}
        height={radius + backdropRadius}
        viewBox={`0 0 ${radius * 2} ${radius + backdropRadius}`}
        className={styles.sectors}
      >
        {[...Array(maxScore)].map((_, index) => (
          <path
            key={index}
            d={arc({
              x: radius,
              y: radius,
              R: radius,
              r: radius - segmentWidth,
              start:
                (index * 180) / maxScore -
                90 +
                (index === 0 ? 0 : segmentSeparation),
              end:
                ((index + 1) * 180) / maxScore -
                90 -
                (index === maxScore - 1 ? 0 : segmentSeparation),
            })}
            fill={testResultLevelToColor(index + 1, maxScore)}
          />
        ))}
        <path
          d={arc({
            x: radius,
            y: radius,
            R: radius - segmentWidth - barsSeparation,
            r: 0,
            start: -90,
            end: 90,
          })}
          fill="url(#gradient)"
        />
        <defs>
          <radialGradient id="gradient" cx="50%" cy="100%" r={1}>
            <stop offset={0} stopColor="#EAEDFF" />
            <stop offset={1} stopColor="#F5F7FF" />
          </radialGradient>
        </defs>
      </svg>

      <div
        className={styles.result}
        style={{
          width: backdropRadius * 2,
          height: backdropRadius * 2,
          top: radius,
        }}
      >
        <div className={styles.backdrop} style={{boxShadow: `0 ${radius * 0.25}px ${radius * 0.25}px rgba(197, 209, 226, 0.2)`}} />
        <ChartScorePointer
          className={styles.chartScorePointer}
          style={{
            width: radius * 0.3,
            height: radius * 0.3,
            transform: `rotate(${arrowRotationAngle}deg) translateX(-${backdropRadius + radius * 0.07}px) `,
          }}
        />
        <div
          className={styles.ratingName}
          style={{ width: backdropRadius * 1.8, height: backdropRadius * 1.8 }}
        >
          {points}
        </div>
      </div>
    </div>
  );
};
