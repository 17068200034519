import { useIsMounted } from 'hooks/misc';
import { RefObject, useEffect } from 'react';


export type VideoRef = RefObject<HTMLVideoElement>;

function useVideoEffect(
  videoRef: VideoRef,
  effect: (video: HTMLVideoElement) => void,
  extraDeps: any[] = []
) {
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!videoRef.current || !isMounted.current) {
      return;
    }

    return effect(videoRef.current);
  }, [videoRef, ...extraDeps]);
}

export default useVideoEffect;