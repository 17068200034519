import { InputPhone } from "common/UIKit/InputPhone/InputPhone";
import Button from "common/UIKit/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import { phoneVerifyService } from "services/phoneVerifyService";
import { useAuth } from "hooks/useAuth";
import { Modal } from "../../../Modal";
import { useModal } from "hooks/useModal";
import styles from "./ChangePhoneWithVerifyPopup.module.scss";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { useState } from "react";
import cn from "classnames";
import VerificationInput from "react-verification-input";
import { useInterval } from "hooks/useInterval";
import { authService } from "services/authService";
import { toast } from "react-toastify";
import { useResponsive } from "hooks/useResponsive";

const ONE_SECOND = 1000;
const ONE_MINUTE = 60;

interface Config {
  mobile_phone: string;
}

export const ChangePhoneWithVerifyPopup = () => {
  const { baseUser } = useAuth();
  const user = useAuth().user;
  const { isOpen, close } = useModal("CHANGE_PHONE_NUMBER_IN_PROFILE");
  const [confirmScreen, setConfirmScreen] = useState(false);
  const [verifyError, setVerifyError] = useState<string | null>();
  const [newPhone, setNewPhone] = useState<string | null>();
  const [timeLeft, setTimeLeft] = useInterval(ONE_MINUTE, ONE_SECOND);
  const { isMobile } = useResponsive();

  const { handleSubmit, setError, setValue, control } = useForm<Config>();

  const onSubmit: SubmitHandler<Config> = async (data) => {
    if (data) {
      try {
        setValue("mobile_phone", data.mobile_phone);
        await phoneVerifyService.changePhone(
          String(baseUser?.id) || String(user?.id),
          data.mobile_phone,
          "registration"
        );

        if (authService.user) {
          authService.user.user.mobile_phone = data.mobile_phone;
        }

        setTimeLeft(ONE_MINUTE);
        setConfirmScreen(true);
        setNewPhone(data.mobile_phone);

        toast("Information has been updated", {
          type: "info",
          autoClose: isMobile ? 3000 : 5000,
          position: isMobile ? "top-center" : "top-right",
        });
      } catch (error: any) {
        if ("errors" in error.data) {
          setError(
            "mobile_phone",
            { message: error.data.errors[0] },
            { shouldFocus: true }
          );
        } else {
          setError(
            "mobile_phone",
            { message: error.data.mobile_phone[0] },
            { shouldFocus: true }
          );
        }
      }
    }
  };

  const onChange = async (e: string) => {
    if (e.length === 4) {
      try {
        await phoneVerifyService.confirmPhone(newPhone, e, "registration");
        close();
      } catch (e: any) {
        setVerifyError(e.data.errors[0]);
      }
    } else {
      setVerifyError(null);
    }
  };

  const resend = async () => {
    setTimeLeft(ONE_MINUTE);
    if (newPhone) {
      await phoneVerifyService.resendCode(newPhone, "registration");
    }
  };

  return (
    <Modal className={styles.root} visible={isOpen}>
      <div className={styles.popup}>
        <button
          className={styles.popupCloseButton}
          onClick={() => {
            setConfirmScreen(false);
            close();
          }}
        >
          <CloseIcon />
        </button>
        <h3 className={styles.popupTitle}>Change mobile phone</h3>
        <sub className={styles.popupSubtitle}>
          {confirmScreen ? "Enter code from SMS" : "New mobile phone"}
        </sub>
        {confirmScreen ? (
          <div className={styles.confirmScreen}>
            <VerificationInput
              length={4}
              placeholder=""
              classNames={{
                container: styles.container,
                character: cn(styles.character, verifyError && styles.error),
                characterInactive: styles.inactive,
                characterSelected: styles.selected,
              }}
              onChange={onChange}
            />
            {verifyError && (
              <p className={styles.errorText}>PIN you entered is not correct</p>
            )}
            {timeLeft > 0 && (
              <p className={styles.timerText}>
                You can resend PIN in {timeLeft} seconds...
              </p>
            )}
            <a
              className={cn(
                styles.resendLink,
                verifyError && styles.withError,
                timeLeft > 0 && styles.disabled
              )}
            >
              <span onClick={resend}>Resend PIN</span>
            </a>
          </div>
        ) : (
          <form
            id="changePhoneProfile"
            action=""
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <InputPhone
              id="phone"
              name="mobile_phone"
              control={control}
              rules={{ required: "Required field" }}
            />
            <Button type="submit" text="Save" className={styles.popupButton} />
          </form>
        )}
      </div>
    </Modal>
  );
};
