import { useEffect } from "react";
import { SubmitHandler, useForm, Path } from "react-hook-form";
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { credentialService } from "services/credentialService";
import { step4_1FullPath } from "../TherapistStep4_1";
import { CredentialId, CredentialType } from "types/credential.types";
import { goThroughErrors } from "common/utils";
import useSkipSteps from "../useSkipSteps";
import { useAuth } from "hooks/useAuth";
import { ORGANIZATION_ERRORS, ORGANIZATION_FIELD } from "constants/fields";

const FIELDS: (keyof Inputs)[] = [
  "membership_organization",
  "membership_id",
  "license_issuer",
  "license_number",
  "accrediting_institution",
  "certificate_number",
  "diploma_school_graduated",
  "diploma_degree_type",
];

type Inputs = {
  type: CredentialType | undefined;
  year?: string;
  membership_organization?: string;
  membership_id?: string;
  license_issuer?: string;
  license_number?: string;
  accrediting_institution?: string;
  certificate_number?: string;
  diploma_school_graduated?: string;
  diploma_degree_type?: string;
};

const defaultValues: Inputs = {
  type: undefined,
  year: "",
  membership_organization: "",
  membership_id: "",
  license_issuer: "",
  license_number: "",
  accrediting_institution: "",
  certificate_number: "",
  diploma_school_graduated: "",
  diploma_degree_type: "",
};

export const useTherapistStep3_3 = () => {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting },
    getValues,
    clearErrors,
  } = useForm<Inputs>({ defaultValues });
  const type = watch("type");

  useEffect(() => {
    user && credentialService.list(user.id);
  }, [user]);

  useEffect(() => {
    if (type) {
      clearErrors("type");
    }
  }, [type]);

  const navigate = useNavigate();
  const { data } = useSnapshot(credentialService);
  const skipSteps = useSkipSteps();

  const submitHandler: SubmitHandler<Inputs> = async (values) => {
    try {
      if (FIELDS.some((field) => values[field])) {
        user &&
          (await credentialService.create(user.id, {
            ...values,
            year: values.year ? Number(values.year) : null,
          }));
      }
      navigate(step4_1FullPath);
    } catch (error) {
      const { data } = error as AxiosResponse;

      goThroughErrors(data, (fieldName, message) =>
        setError(fieldName as Path<Inputs>, { message, type: "custom" })
      );
    }
  };

  const skipHandler: SubmitHandler<Inputs> = async (values) => {
    try {
      if (FIELDS.some((field) => values[field])) {
        user &&
          (await credentialService.create(user.id, {
            ...values,
            year: values.year ? Number(values.year) : null,
          }));
      }
      await skipSteps();
    } catch (error) {
      const { data } = error as AxiosResponse;

      goThroughErrors(data, (fieldName, message) =>
        setError(fieldName as Path<Inputs>, { message, type: "custom" })
      );
    }
  };

  const addAnotherHandler: SubmitHandler<Inputs> = async (values) => {
    if (!values.type) {
      setError("type", { message: "Select value" });
    }

    if (
      !values.membership_organization ||
      !values.diploma_school_graduated ||
      !values.accrediting_institution ||
      !values.license_issuer
    ) {
      if (type && !values[ORGANIZATION_FIELD[type]]) {
        setError(ORGANIZATION_FIELD[type], {
          message: ORGANIZATION_ERRORS[type],
        });
      }
    }

    try {
      if (FIELDS.some((field) => values[field])) {
        user &&
          (await credentialService.create(user.id, {
            ...values,
            year: values.year ? Number(values.year) : null,
          }));
        reset({ ...defaultValues, type });
      }
    } catch (error) {
      const { data } = error as AxiosResponse;

      goThroughErrors(data, (fieldName, message) =>
        setError(fieldName as Path<Inputs>, { message, type: "custom" })
      );
    }
  };

  const handleRemove = async (id: CredentialId) => {
    try {
      user && (await credentialService.delete(user.id, id));
      reset({ ...defaultValues, type });
    } catch (error) {
      console.log(error);
    }
  };

  function setValues(v: Partial<Inputs>) {
    Object.entries(v).forEach(([fName, fVal]) =>
      setValue(fName as keyof Inputs, fVal)
    );
  }

  return {
    register,
    handleSubmit: handleSubmit(submitHandler),
    handleSkip: handleSubmit(skipHandler),
    handleAddAnother: handleSubmit(addAnotherHandler),
    handleRemove,
    credentials: data,
    setValues,
    type,
    control,
    isSubmitting,
    errors,
    typeHasValue: getValues("type"),
  };
};
