import {HttpService} from "./httpService";
import { UserType} from "../types/auth.types";
import {proxy} from "valtio";
import {TherapistId} from "../types/therapist.types";

export type RemoveModifyArgs = {
    firstName: string;
    lastName: string;
    email: string;
    mobile_phone: string;
    type: UserType;
    therapist_id: TherapistId
};

class RemoveModifyService extends HttpService {

    private endpoint = "/v1/profile/";
    async removeModify({
      firstName,
      lastName,
      email,
      mobile_phone,
      therapist_id,
      type
      }: RemoveModifyArgs) {
        const formData = {
            first_name: firstName,
            last_name: lastName,
            email,
            mobile_phone,
            therapist_id,
        }
        await this.http.post(`${this.endpoint}${type}/`, formData)
        return this
    }
}

export const removeModifyService = proxy(new RemoveModifyService())