import { Modal } from "../Modal/Modal";
import { useModalDrag } from "hooks/useModalDrag";
import { useModal } from "hooks/useModal";
import cn from "classnames";
import { ReactComponent as IconCross } from "assets/images/icons/green-cross.svg";
import { messagesService } from "services/messagesService";
import { useSnapshot } from "valtio";
import styles from "./FilterPopup.module.scss";

export const FilterMessagesPopup = () => {
  const { isOpen, close } = useModal("FILTER_MESSAGES");
  const { percentageToClose, bind, transformStyle } = useModalDrag({
    moveDistanceToClose: 300,
    onClose: close,
  });
  const { sort } = useSnapshot(messagesService);

  const onFilter = (filter: string, order: "asc" | "desc") => {
    return messagesService.setOrder(filter, { order }, true);
  };

  const onClear = (filter: string) => {
    return messagesService.clearOrder(filter);
  };

  return (
    <Modal visible={isOpen} onClose={close} fadePercentage={percentageToClose}>
      <div className={styles.root} style={transformStyle} {...bind()}>
        <div className={styles.mobileLine} />
        <h6 className={styles.title}>Sort by:</h6>
        <fieldset className={styles.row}>
          <label className={styles.label}>Therapist name:</label>
          <div
            className={cn(
              styles.field,
              sort?.receiver?.order === "asc" && styles.active
            )}
          >
            <IconCross onClick={() => onClear("receiver")} />
            <button
              className={styles.button}
              type="button"
              onClick={() => onFilter("receiver", "asc")}
            >
              A-Z
            </button>
          </div>
          <div
            className={cn(
              styles.field,
              sort?.receiver?.order === "desc" && styles.active
            )}
          >
            <IconCross onClick={() => onClear("receiver")} />
            <button
              className={styles.button}
              type="button"
              onClick={() => onFilter("receiver", "desc")}
            >
              Z-A
            </button>
          </div>
        </fieldset>
        <fieldset className={styles.row}>
          <label className={styles.label}>Time sent:</label>
          <div
            className={cn(
              styles.field,
              sort?.created_at?.order === "desc" && styles.active
            )}
          >
            <IconCross onClick={() => onClear("created_at")} />
            <button
              className={styles.button}
              type="button"
              onClick={() => onFilter("created_at", "desc")}
            >
              Recent
            </button>
          </div>
          <div
            className={cn(
              styles.field,
              sort?.created_at?.order === "asc" && styles.active
            )}
          >
            <IconCross onClick={() => onClear("created_at")} />
            <button
              className={styles.button}
              type="button"
              onClick={() => onFilter("created_at", "asc")}
            >
              Oldest
            </button>
          </div>
        </fieldset>
      </div>
    </Modal>
  );
};
