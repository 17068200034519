import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import Button from "common/UIKit/Button";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { FormatProfileField } from "common/components/FormatProfileField";
import { formatPhone } from "common/utils";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./AddressContact.module.scss";

export const pathAddressContact = "/address-contact";

export const AddressContact = () => {
  const { tabName, edit } = useDashboardNavigation("Business Info", {
    edit: "append_edit",
    goBack: "pop_path",
  });
  const auth = useAuth();
  const user = auth.user as Therapist | null;

  return (
    <section className={styles.contact}>
      <h1 className={styles.contactTitle}>{tabName}</h1>
      <div className={styles.contactInner}>
        <div className={styles.contactInfo}>
          <div className={styles.contactBlock}>
            <p className={styles.contactBlockTitle}>Street Address</p>
            <p className={styles.contactBlockText}>
              <FormatProfileField maxLength={30}>
                {user?.street_address}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.contactBlock}>
            <p className={styles.contactBlockTitle}>State</p>
            <p className={styles.contactBlockText}>
              <FormatProfileField>{user?.state}</FormatProfileField>
            </p>
          </div>
          <div className={styles.contactBlock}>
            <p className={styles.contactBlockTitle}>Phone + Extension</p>
            <p className={styles.contactBlockText}>
              <FormatProfileField maxLength={25}>
                {`${formatPhone(user?.phone)} ${user?.phone_extension}`}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.contactBlock}>
            <p className={styles.contactBlockTitle}>
              Hide this street address from public view
            </p>
            <p className={styles.contactBlockText}>
              {user?.hide_street_address ? "Yes" : "No"}
            </p>
          </div>
          <div className={styles.contactBlock}>
            <p className={styles.contactBlockTitle}>City</p>
            <p className={styles.contactBlockText}>
              <FormatProfileField>{user?.city}</FormatProfileField>
            </p>
          </div>
          <div className={styles.contactBlock}>
            <p className={styles.contactBlockTitle}>Zip</p>
            <p className={styles.contactBlockText}>
              <FormatProfileField>{user?.zip_code}</FormatProfileField>
            </p>
          </div>
        </div>
        <Button className={styles.contactButton} onClick={edit}>
          <EditIcon />
          Edit information
        </Button>
      </div>
    </section>
  );
};
