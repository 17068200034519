import React, { CSSProperties, forwardRef, useState } from "react";
import cn from "classnames";

import styles from "./Input.module.scss";

export enum InputSize {
  full = "full",
  auto = "auto",
}

export type InputSizeType = `${InputSize}`;

export interface InputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "size"> {
  size?: InputSizeType;
  label?: string;
  error?: string;
  optional?: string;
  adornment?: string;
  description?: string;
  adornmentVariant?: "black" | "gray";
  descriptionAlignment?: "left" | "right";
  isValid?: boolean;
  inputStyles?: CSSProperties;
  withLeftAdornment?: boolean;
  hashtag?: boolean;
  aboveDescription?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size,
      label,
      id,
      className,
      error,
      optional,
      adornment,
      style,
      description,
      adornmentVariant = "gray",
      descriptionAlignment = "right",
      isValid = true,
      inputStyles,
      withLeftAdornment,
      hashtag,
      aboveDescription = false,
      ...props
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);

    return (
      <div
        className={cn(
          styles.field,
          className,
          { [styles.inputOptional]: optional },
          props.disabled && styles.disabled
        )}
      >
        {label && (
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
        )}
          {aboveDescription && <p className={styles.aboveText}>This is your Therapass profile link.
              If you have a personal or business website enter the URL here.
          </p>}
        <div
          className={cn(
            styles.inputHolder,
            {
              [styles.error]: error || !isValid,
            },
            isActive && styles.active
          )}
          data-optional={optional}
        >
          {withLeftAdornment && !!adornment && (
            <div
              className={cn(
                styles.inputAdornmentLeft,
                styles[adornmentVariant],
                hashtag && styles.hashtag
              )}
            >
              {adornment}
            </div>
          )}
          <input
            ref={ref}
            id={id}
            {...props}
            className={cn(
              `btn-size-${size}`,
              styles.input,
              hashtag && styles.hashtagInput
            )}
            onFocus={(e) => {
              setIsActive(true);
              if (props?.onFocus) {
                props.onFocus(e);
              }
            }}
            onBlur={(e) => {
              setIsActive(false);
              if (props?.onBlur) {
                props.onBlur(e);
              }
            }}
            style={inputStyles}
          />
          {!withLeftAdornment && !!adornment && (
            <div
              className={cn(
                styles.inputAdornmentLeft,
                styles[adornmentVariant]
              )}
            >
              {adornment}
            </div>
          )}
        </div>
        {description && !error && (
          <p
            className={cn(styles.description, cn(styles[descriptionAlignment]))}
          >
            {description}
          </p>
        )}
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    );
  }
);

Input.defaultProps = {
  size: InputSize.full,
  type: "text",
};

export default Input;
