import { ratePurchaseService } from "services/ratePurchaseService";

export const stringToDate = (date: string) =>
  new Date(date).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

export const getFullName = (user: { first_name: string; last_name: string }) =>
  `${user.first_name} ${user.last_name}`;

export const onSortChange = (sortBy: "paid_at" | "fee") => {
  if (sortBy === "paid_at") {
    ratePurchaseService.clearOrder("fee", false);
  } else {
    ratePurchaseService.clearOrder("paid_at", false);
  }
  ratePurchaseService.setOrder(sortBy);
};
