import { Footer } from "pages/Auth/components/Footer/Footer";
import { LandingHeader } from "pages/Auth/components/LandingHeader/LandingHeader";
import styles from "./TermsOfUse.module.scss";

export const termsOfUsePath = "/terms";

export const TermsOfUse = () => {
  return (
    <div className={styles.root}>
      <LandingHeader />
      <main className={styles.main}>
        <h1 className={styles.title}>Terms of Use</h1>
        <section className={styles.section}>
          <p>Last Modified: September 12, 2022</p>
          <p>
            These terms and conditions are entered into by and between you (“End
            User,” “you,” or “your”) and TheraPass, Inc. (“TheraPass,” “we,”
            “our,” or “us”) and govern your access to and use of the TheraPass
            on-demand mental health and wellness platform (the “Platform”),
            including any content, functionality and services offered on or
            through the Platform. Unless the context clearly suggests otherwise,
            the terms “End User,” “you,” or “your” include all individuals
            accessing and using the Platform, including therapists participating
            in the TheraPass therapist network (as described in more detail
            below).
          </p>
          <p>
            The following terms and conditions, together with our Privacy
            Policy, available at{" "}
            <a href="https://therapass.com/privacy">
              https://therapass.com/privacy
            </a>{" "}
            (“Privacy Policy”), collectively constitute the entire “Terms of
            Use” between you and TheraPass.
          </p>
          <p>
            Please read these Terms of Use carefully before you start to use the
            Platform. BY USING THE PLATFORM YOU ACCEPT AND AGREE TO BE BOUND AND
            ABIDE BY THE TERMS OF USE. IN ACCEPTING THESE TERMS OF USE, YOU: (A)
            ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS OF USE;
            (B) CONSENT TO THESE TERMS OF USE; AND (C) AGREE TO BE LEGALLY BOUND
            BY THESE TERMS OF USE. IF YOU DO NOT TO AGREE TO THESE TERMS OF USE
            OR THE PRIVACY POLICY, PLEASE DO NOT ACCESS OR USE THE PLATFORM.
          </p>
        </section>
        <section className={styles.section}>
          <h2>1. The Platform Does Not Provide Medical Advice</h2>
          <div>
            <ul>
              <li>
                <b>
                  THE PLATFORM IS A TOOL FOR INDIVIDUALS TO SEARCH FOR AND
                  CONNECT AND COMMUNICATE WITH THERAPISTS. HOWEVER, THE PLATFORM
                  DOES NOT PROVIDE MEDICAL DIAGNOSES OR RECOMMENDATIONS
                  REGARDING MEDICAL TREATMENT. THE PLATFORM AND THE CONTENT
                  APPEARING ON THE PLATFORM ARE NOT SUBSTITUTES FOR PROFESSIONAL
                  MEDICAL ADVICE, DIAGNOSIS OR TREATMENT. THE CONTENT OF THIS
                  PLATFORM IS MADE AVAILABLE TO YOU FOR INFORMATIONAL PURPOSES
                  ONLY.
                </b>
              </li>
              <li>
                <b>
                  IN AN EMERGENCY, CALL 911 OR YOUR LOCAL EMERGENCY ASSISTANCE
                  NUMBER.
                </b>
              </li>
              <li>
                <b>
                  NEITHER THE PLATFORM NOR YOUR USE OF THE PLATFORM CREATES A
                  DOCTOR/PATIENT RELATIONSHIP.
                </b>
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.section}>
          <h2>2. TheraPass Therapist Network</h2>
          <div>
            <p>
              Therapists desiring to participate as part of the TheraPass
              therapist network can choose between three available membership
              plans:
            </p>
            <ul>
              <li>
                FREE – a therapist may list their practice on the site at no
                charge.
              </li>
              <li>
                PREFERRED – a therapist may choose the PREFERRED membership,
                which will entitle their profile to receive preferred placement
                on the search page results.
              </li>
              <li>
                PREMIUM - a therapist may choose the PREMIUM membership, which
                will entitle their profile to receive preferred placement on the
                search page results and will allow them to have access to the
                PATIENT-CHAT-CONNECT messaging platform.
              </li>
            </ul>
            <p>
              Fees and payment terms for the membership plans are set forth on
              the Platform and subject to change. Unless otherwise expressly set
              forth in these Terms of Use or the Platform, all fees are
              non-refundable.
            </p>
            <p>
              To participate as a PREFERRED or PREMIUM therapist in the
              TheraPass therapist network, a therapists must satisfy the
              following minimum requirements:
            </p>
            <ol>
              <li>
                Be a licensed clinical social worker (LCSW), a licensed marriage
                and family therapist (LMFT), a licensed professional clinical
                counselor (LPCC), or possess a PhD in clinical psychology;
              </li>
              <li>
                Maintain professional malpractice liability insurance in such
                amounts as required by applicable law;
              </li>
              <li>
                Submit a fully completed and signed Council for Affordable
                Quality Healthcare application;
              </li>
              <li>Possess and maintain an individual NPI number; and</li>
              <li>Maintain a reliable internet connection.</li>
            </ol>
            <p>
              TheraPass will inspect and verify the credentials uploaded by
              PREFERRED and PREMIUM therapists and such therapists will receive
              a verified designation on the Platform.
            </p>
            <p>
              All therapists participating in the TheraPass therapist network
              are independent third parties and not employees or contractors of
              TheraPass. To the extent you independently retain a therapist for
              health and wellness services, such services are provided by the
              therapist and not TheraPass. Your decision to establish a
              relationship with a therapist is your own and you are under no
              obligation to retain or maintain a relationship with any therapist
              you communicate with using the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>3. TheraPass Patient-Chat-Connect</h2>
          <div>
            <p>
              The Platform allows End Users to communicate with certain
              therapists in the TheraPass network using our PATIENT-CHAT-CONNECT
              messaging, which utilizes short message service (SMS) texting. SMS
              texting is an unencrypted form of communication that presents a
              greater degree of risk of unpermitted access by third parties. As
              such, users of PATIENT-CHAT-CONNECT messaging should limit the
              disclosure of sensitive information to the minimum amount
              necessary to achieve the purpose of the communication. By using
              PATIENT-CHAT-CONNECT, you consent to receiving SMS text messages
              from the therapist you are contacting. Additionally, you agree to
              comply with all applicable data privacy laws in relation to your
              use of the Platform.
            </p>
            <p>
              Sending a message or an inquiry to a therapist does not establish
              a doctor/patient relationship and each therapist retains the right
              to respond to or communicate with any individual End User.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>4. Fees and Other Payments</h2>
          <div>
            <p>
              The Platform allows you to make online payments (e.g., membership
              payments from therapists to TheraPass and payments from End Users
              to therapists for services rendered). If you use the Platform to
              make payments online, you acknowledge that we use a third party
              payment processor to process such payments. In connection with the
              processing of such payments, we do not collect or retain any
              credit card numbers or other payment information. Rather, all such
              payment information is provided directly to our third party
              payment processor, whose use of your personal information is
              governed by their privacy policy and their agreement with your
              bank credit card issuer. You agree to comply with any terms and
              conditions and the privacy policies of the third-party payment
              processor in connection with your use of such third-party payment
              processor services.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>5. Eligibility</h2>
          <div>
            <p>
              The Platform is intended for End Users who are 13 years of age or
              older. If you are under the age of 18, you may only use the
              Platform in conjunction with your parents or legal guardian or
              with the consent of your parents or legal guardian. By using the
              Platform you represent and warrant that you are 18 years of age or
              older or meet all of the foregoing eligibility requirements. You
              further represent that you are of legal age in the jurisdiction in
              which you live to enter into a legally binding contract with
              TheraPass. If you do not meet all of these requirements, you must
              not access or use the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>6. Changes to Terms of Use</h2>
          <div>
            <p>
              We reserve the right, at our discretion, to update, change,
              modify, add, or remove portions of these Terms of Use at any time
              in order to conform them to our business practices. All changes
              are effective immediately when we post them, and apply to all
              access to and use of the Platform thereafter. Your continued use
              of the Platform thereafter constitutes your acceptance of such
              changes.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>7. Accessing the Platform and Account Security</h2>
          <div>
            <p>
              We reserve the right to withdraw or amend this Platform, and any
              service or material we provide on the Platform, in our sole
              discretion without notice. We will not be liable if for any reason
              all or any part of the Platform is unavailable at any time or for
              any period. From time to time, we may restrict access to some
              parts of the Platform, or the entire Platform, to users, including
              registered users.
            </p>
            <p>
              To access the Platform or some of the resources it offers, you may
              be asked to provide certain registration details or other
              information. It is a condition of your use of the Platform that
              all the information you provide on the Platform is correct,
              current, and complete. You agree that all information you provide
              to register with this Platform or otherwise, including, but not
              limited to, through the use of any interactive features on the
              Platform, is governed by our Privacy Policy{" "}
              <a href="https://therapass.com/privacy">
                {" "}
                https://therapass.com/privacy
              </a>
              , and you consent to all actions we take with respect to your
              information consistent with our Privacy Policy.
            </p>
            <p>
              If you choose, or are provided with, a user name, password, or any
              other piece of information as part of our security procedures, you
              must treat such information as confidential, and you must not
              disclose it to any other person or entity. You also acknowledge
              that your account is personal to you and agree not to provide any
              other person with access to this Platform or portions of it using
              your user name, password, or other security information. You agree
              to notify us immediately of any unauthorized access to or use of
              your user name or password or any other breach of security. You
              also agree to ensure that you exit from your account at the end of
              each session. You should use particular caution when accessing
              your account from a public or shared computer so that others are
              not able to view or record your password or other personal
              information.
            </p>
            <p>
              We have the right to disable any user name, password, or other
              identifier, whether chosen by you or provided by us, at any time
              if, in our opinion, you have violated any provision of these Terms
              of Use.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>8. Data Protection and Privacy</h2>
          <div>
            <p>
              You agree that all information you provide through the Platform is
              governed by the Privacy Policy{" "}
              <a href="https://therapass.com/privacy">
                https://therapass.com/privacy
              </a>{" "}
              , and you consent to all actions we take with respect to your
              information consistent with the Privacy Policy.
            </p>
            <p>
              We are not responsible for the acts of third parties who may
              access the Platform and information through your mobile device.
              You should use all security features of your mobile device,
              including any password, locking, or encryption features, to
              protect against unauthorized access and use of the Platform and
              your information.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>9. Intellectual Property Rights</h2>
          <div>
            <p>
              The Platform and its entire contents, features, and functionality
              (including but not limited to all information, headers, stories,
              software, text, displays, photos, images, video and audio, and the
              design, selection and arrangement thereof), are owned by
              TheraPass, its licensors or other providers of such material and
              are protected by United States and international copyright,
              trademark, patent, trade secret and other intellectual property or
              proprietary rights laws.
            </p>
            <p>
              Certain products, services or company designations for companies
              other than TheraPass may be mentioned or displayed on the Platform
              for identification purposes only. Third-party trademarks, trade
              names, logos, product or services names contained on the Platform
              are the trademarks, registered or unregistered, of their
              respective owners.
            </p>
            <p>
              We grant you a non-commercial, non-exclusive, non-transferable,
              limited, terminable license to use the Platform, subject to your
              compliance with these Terms of Use. The Platform is licensed, not
              sold, to you, solely for your personal use. You must not
              reproduce, distribute, modify, create derivative works of,
              publicly display, publicly perform, republish, download, store, or
              transmit any of the material on the Platform.{" "}
            </p>
            <p>
              These Terms of Use and the notices outlined herein are not
              intended to and do not create any contractual or other legal
              rights in or on behalf of any third party.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>10. Prohibited Uses</h2>
          <div>
            <p>
              You may use the Platform only for lawful purposes and in
              accordance with these Terms of Use and the Privacy Policy. You
              agree not to use the Platform in any of the following ways:
            </p>
            <ul>
              <li>
                In any way that violates any applicable federal, state or
                international law or regulation (including, without limitation,
                any laws regarding the export of data or software to and from
                the United States or other countries).
              </li>
              <li>
                For the purpose of exploiting, harming or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content, asking for personal or sensitive information or
                otherwise.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail,” “chain letter,”
                or “spam” or any other similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate TheraPass, a TheraPass
                employee, another user, or any other person or entity
                (including, without limitation, by using e-mail associated with
                any of the foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of the Platform, or which, as
                determined by us, may harm TheraPass or users of the Platform or
                expose them to liability.
              </li>
            </ul>
            <p>Additionally, you agree not to do any of the following:</p>
            <ul>
              <li>
                Use the Platform in any manner that could disable, overburden,
                damage, or impair the Platform or interfere with any other
                party’s use of the Platform, including its ability to engage in
                real time activities through the Platform.
              </li>
              <li>
                Use any robot, spider, “scraper,” “crawler,” or other automatic
                device, process, or means to access the Platform for any
                purpose, including monitoring or copying any of the material on
                the Platform.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on
                the Platform or for any other unauthorized purpose without our
                prior written consent.
              </li>
              <li>
                Use any device, software, or routine that interferes with the
                proper working of the Platform.
              </li>
              <li>
                Introduce any viruses, trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Platform, the server on which the
                Platform are stored, or any server, computer, or database
                connected to the Platform.
              </li>
              <li>
                Attack the Platform via a denial-of-service attack or a
                distributed denial-of-service attack.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of the
                Platform.
              </li>
              <li>Modify copies of any materials from the Platform.</li>
              <li>
                Use any illustrations, photographs, video or audio sequences or
                any graphics from the Platform or any text.
              </li>
              <li>
                Delete or alter any copyright, trademark, or other proprietary
                rights notices from copies of materials from the Platform.
              </li>
            </ul>
            <p>
              No right, title, or interest in or to the Platform or any content
              on the Platform are transferred to you, and TheraPass reserves all
              rights not expressly granted in these Terms of Use. Any use of the
              Platform not expressly permitted by these Terms of Use is a breach
              of these Terms of Use and may violate copyright, trademark, and
              other laws.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>11. Reliance on Information Posted</h2>
          <div>
            <p>
              The information and content presented on or through the Platform
              is made available solely for general information purposes. We do
              not warrant the accuracy, completeness, or usefulness of this
              information and content. Any reliance you place on such
              information or content is strictly at your own risk. We disclaim
              all liability and responsibility arising from any reliance placed
              on such materials by you or any other visitor to the Platform, or
              by anyone who may be informed of any of their contents.
            </p>
            <p>
              To the extent the Platform may contain content or information
              provided by a third party, all statements and/or opinions
              expressed in these materials, and all articles and responses to
              questions and other content, other than the content provided by
              TheraPass, are solely the opinions and the responsibility of the
              third party providing those materials. These materials do not
              necessarily reflect the opinion of TheraPass. We are not
              responsible, or liable to you or any third party, for the content
              or accuracy of any materials provided by any third parties.
            </p>
            <p>
              In no event will TheraPass be liable for any decision made or
              action taken in reliance upon the information provided through the
              Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>12. Information About You and Your Visits to the Platform</h2>
          <div>
            <p>
              The Platform is governed by and operated in accordance with United
              States law. If you are located outside of the United States, you
              use this Service at your own risk. Your information will be
              transferred to and processed in the United States where many of
              our central databases operate. By using the Platform, you: (a)
              acknowledge that the data protection laws of other countries,
              including the United States, may provide a less comprehensive or
              protective standard of protection than those in your country, and
              (b) consent to all actions taken by us with respect to your
              information in compliance with the Privacy Policy and applicable
              privacy laws.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>13. Third-Party Services</h2>
          <div>
            <p>
              Your use of the Platform may involve services made available to
              you by third parties. The use of such services or functionalities
              may be subject to additional terms and conditions and/or privacy
              policies provided by such third parties and we have no control
              over such terms and conditions. These Terms of Use and our Privacy
              Policy apply only to the Platform and not to any third-party
              website, platform or service. While we expect our business
              partners and affiliates to respect the privacy of our users, we
              cannot be responsible for the actions of third parties.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>14. Electronic Communications</h2>
          <div>
            <p>
              When you send emails or other electronic messages to us or in
              connection with the Platform, you are communicating with us
              electronically and consent to our review and analysis of such
              messages and to receive return communications, if any, from us
              electronically, including via SMS text message. You agree that all
              agreements, notices, disclosures, and other communications that we
              provide to you electronically satisfy any legal requirement that
              such communications be in writing.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>15. Disclaimer of Warranties</h2>
          <div>
            <p>
              You understand that we cannot and do not guarantee or warrant that
              files available for downloading from the internet or the Platform
              will be free of viruses or other destructive code. You are
              responsible for implementing sufficient procedures and checkpoints
              to satisfy your particular requirements for anti-virus protection
              and accuracy of data input and output, and for maintaining a means
              external to the Platform for any reconstruction of any lost data.
              WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
              DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
              TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
              EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL
              DUE TO YOUR USE OF THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED
              THROUGH THE PLATFORM OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED
              ON IT, OR ON ANY PLATFORM LINKED TO IT.
            </p>
            <p>
              YOUR USE OF THE PLATFORM, ITS CONTENT AND ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE PLATFORM ARE AT YOUR OWN RISK. THE PLATFORM,
              ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              PLATFORM ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
              WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
              NEITHER THERAPASS NOR ANY PERSON ASSOCIATED WITH THERAPASS MAKES
              ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
              SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
              PLATFORM. WITHOUT LIMITING THE FOREGOING, NEITHER THERAPASS NOR
              ANYONE ASSOCIATED WITH THERAPASS REPRESENTS OR WARRANTS THAT THE
              PLATFORM, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH
              THE PLATFORM WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
              UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE PLATFORM
              OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS OR THAT THE PLATFORM OR ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE PLATFORM WILL OTHERWISE MEET YOUR NEEDS OR
              EXPECTATIONS.
            </p>
            <p>
              THERAPASS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
              LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
              AND FITNESS FOR PARTICULAR PURPOSE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>16. Indemnification</h2>
          <div>
            <p>
              You agree to defend, indemnify and hold harmless TheraPass, its
              affiliates, licensors, and service providers, and its and their
              respective officers, directors, employees, contractors, agents,
              licensors, suppliers, successors and assigns from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses or fees (including reasonable attorneys’ fees) arising
              out of or relating to your violation of these Terms of Use or your
              use of the Platform, including, but not limited to any use of the
              Platform’s content, and other services and products, other than as
              expressly authorized in these Terms of Use, or your use of any
              information obtained from the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>17. Limitation of Liability</h2>
          <div>
            <p>
              IN NO EVENT WILL THERAPASS BE LIABLE TO YOU OR TO ANY THIRD PARTY
              FOR ANY LOSS OF USE, REVENUE OR PROFIT OR LOSS OF DATA OR FOR ANY
              CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR
              PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT
              (INCLUDING NEGLIGENCE) OR OTHERWISE, REGARDLESS OF WHETHER SUCH
              DAMAGE WAS FORESEEABLE AND WHETHER OR NOT THERAPASS HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ADDITIONALLY, IN NO
              EVENT WILL THE LIABILITY OF THERAPASS ARISING OUT OF OR RELATED TO
              THESE TERMS OF USE OR THE PLATFORM, WHETHER ARISING OUT OF OR
              RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
              OTHERWISE, EXCEED THE AGGREGATE AMOUNTS PAID OR PAYABLE TO
              THERAPASS BY YOU, IF ANY, IN THE SIX (6) MONTH PERIOD PRECEDING
              THE EVENT GIVING RISE TO THE CLAIM.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>18. Governing Law and Jurisdiction</h2>
          <div>
            <p>
              All matters relating to the Platform and these Terms of Use and
              any dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              the State of Delaware without giving effect to any choice or
              conflict of law provision or rule (whether of the State of
              Delaware or any other jurisdiction).
            </p>
            <p>
              Any legal suit, action or proceeding arising out of, or related
              to, these Terms of Use or the Platform shall be instituted
              exclusively in the federal courts of the United States or the
              courts of the State of Delaware, although we retain the right to
              bring any suit, action or proceeding against you for breach of
              these Terms of Use in your country of residence or any other
              relevant country. You waive any and all objections to the exercise
              of jurisdiction over you by such courts and to venue in such
              courts.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>19. Arbitration</h2>
          <div>
            <p>
              In our sole discretion, we may require you to submit any disputes
              arising from the use of these Terms of Use or the Platform,
              including disputes arising from or concerning their
              interpretation, violation, invalidity, nonperformance, or
              termination, to final and binding arbitration under the Rules of
              Arbitration of the American Arbitration Association applying
              Delaware law.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>20. Limitation on Time to File Claims</h2>
          <div>
            <p>
              <b>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THESE TERMS OF USE OR THE PLATFORM MUST BE COMMENCED
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES;
                OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
              </b>
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>21. Waiver and Severability</h2>
          <div>
            <p>
              No waiver by TheraPass of any term or condition set forth in these
              Terms of Use shall be deemed a further or continuing waiver of
              such term or condition or a waiver of any other term or condition,
              and any failure of TheraPass to assert a right or provision under
              these Terms of Use shall not constitute a waiver of such right or
              provision.
            </p>
            <p>
              If any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal, or
              unenforceable for any reason, such provision shall be, in the
              first instance, reformed or limited to the minimum extent such
              that the relevant provision is enforceable, and the remaining
              provisions of these Terms of Use will continue in full force and
              effect. If such reformation or limitation cannot cure the
              deficiency of such provision, it shall be eliminated from these
              Terms of Use and the remaining provisions of these Terms of Use
              will continue in full force and effect.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>22. Entire Agreement</h2>
          <div>
            <p>
              These Terms of Use, the Privacy Policy, and any additional terms
              incorporated by reference constitute the sole and entire agreement
              between you and TheraPass with respect to the Platform, and
              supersede all prior and contemporaneous understandings,
              agreements, representations, and warranties, both written and
              oral, with respect to the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>21. Your Comments and Concerns</h2>
          <div>
            <p>The Platform is operated by:</p>
            <p>
              <span>TheraPass, Inc.</span>
              <span>5237 Summerlin Commons #367</span>
              <span>Fort Myers, FL 33907</span>
            </p>
            <p>
              All feedback, comments, requests for technical support, and other
              communications relating to the Platform should be directed to{" "}
              <a href="mailto:notice&#64;therapass&#46;com">notice&#64;therapass&#46;com</a>.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};
