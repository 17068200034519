import { Modal } from "common/components/Modal";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { useModal } from "hooks/useModal";
import Button from "common/UIKit/Button";
import Textarea from "common/UIKit/Textarea/Textarea";
import { Control, Controller } from "react-hook-form";
import { RateCard } from "types/rateCards.types";
import styles from "./RateDescription.module.scss";

interface Props {
  control: Control<RateCard>;
  type?: string;
}

export const RateDescription = ({ control, type }: Props) => {
  const { isOpen, close, closeAll } = useModal("RATE_CARD_DESCRIPTION");
  const { open: openDetailsModal } = useModal("RATE_CARD_DETAILS");

  const onBackClick = () => {
    openDetailsModal();
    close();
  };

  return (
    <Modal className={styles.wrapper} visible={isOpen} onClose={closeAll}>
      <div className={styles.root}>
        <button className={styles.closeButton} onClick={closeAll}>
          <CloseIcon />
        </button>
        <h1 className={styles.title}>Add a {type} rate card</h1>
        <p className={styles.subtitle}>Step 3 - Enter the description:</p>
        <Controller
          name={"description"}
          control={control}
          render={({
            field: { value, onChange, ref },
            formState: { errors },
          }) => (
            <Textarea
              className={styles.textarea}
              maxLength={500}
              placeholder="Type something..."
              currentLength={value.length}
              error={errors.description?.message}
              onChange={(event) =>
                onChange((event.target as HTMLTextAreaElement).value || "")
              }
              ref={ref}
              value={value}
            />
          )}
        />
        <div className={styles.rowButtons}>
          <Button
            className={styles.button}
            color="green-light"
            onClick={onBackClick}
          >
            Back
          </Button>
          <Button className={styles.button} type="submit">
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
};
