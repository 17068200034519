import React, { useEffect, useState } from "react";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import styles from "./DeviceSelectionScreen.module.scss";
import { CircularProgress, Grid, Hidden, Typography } from "@mui/material";
import Button from "common/UIKit/Button";
import useVideoContext from "hooks/useVideoContext";
import ToggleAudioButton from "common/components/Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "common/components/Buttons/ToggleVideoButton/ToggleVideoButton";
import { useSearchParams } from "react-router-dom";
import { ErrorPage } from "pages/ErrorPage";
import axios from "axios";
import { esaApiEndpoint } from "utils/env";

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
}: DeviceSelectionScreenProps) {
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
  } = useVideoContext();
  const [isFetching, setIsFetching] = useState(false);
  const disableButtons = isAcquiringLocalTracks || isConnecting;
  const [params] = useSearchParams();

  const sessionId = params.get("SessionID");
  if (!sessionId) {
    return <ErrorPage />;
  }

  const handleJoin = () => {
    setIsFetching(true);
    axios
      .post(`${esaApiEndpoint}/videoSession/GetToken/get_token/${sessionId}`)
      .then((data) => {
        let chatToken = "";
        if (params.get("isTherapist")) {
          chatToken = data.data.TherapistToken;
        } else {
          chatToken = data.data.ClientToken;
        }
        // const roomId = data.data.roomid;
        setIsFetching(false);
        videoConnect(chatToken, roomName);
      })
      .catch((error) => console.log(error));
  };

  if (isFetching || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={styles.gutterBottom}>
        Join {roomName}{" "}
        <Button data-cy-join-now onClick={handleJoin} disabled={disableButtons}>
          Join
        </Button>
      </Typography>

      <Grid container justifyContent="left">
        <div className={styles.mobileButtonBar}>
          <ToggleAudioButton
            className={styles.mobileButton}
            disabled={disableButtons}
          />
          <ToggleVideoButton
            className={styles.mobileButton}
            disabled={disableButtons}
          />
        </div>
        <div className={styles.localPreviewContainer}>
          <LocalVideoPreview identity={name} />
        </div>
      </Grid>
    </>
  );
}
