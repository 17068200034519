import { useEffect, useState } from "react";
import { ReactComponent as NotificationIcon } from "assets/images/icons/notification.svg";
import { ReactComponent as NotificationAccentIcon } from "assets/images/icons/notification-accent.svg";
import cn from "classnames";
import { notificationsService } from "services/notificationsService";
import { useSnapshot } from "valtio";
import { NotificationItem } from "./components/NotificationItem";
import { useResponsive } from "hooks/useResponsive";
import { ReactComponent as CrossIcon } from "assets/images/icons/close-square.svg";
import styles from "./Notification.module.scss";
import { esaProfileService } from "services/esaProfileService";
import { Status } from "types/notifications.types";
import { useAuth } from "hooks/useAuth";

interface Props {
  className?: string;
  menuClassName?: string;
}

export const Notification = ({ className, menuClassName }: Props) => {
  const { isMobile } = useResponsive();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { unread, notifications, isFetching } =
    useSnapshot(notificationsService);
  const { unreadNotifications, esaNotifications, isLoaded } =
    useSnapshot(esaProfileService);
  const isPatient = useAuth().baseUser?.patient ? true : false;

  useEffect(() => {
    notificationsService.fetchUnread();
  }, [isFetching]);

  useEffect(() => {
    if (isPatient) {
      esaProfileService.getProfile();
    }
  }, [isPatient, isLoaded]);

  useEffect(() => {
    if (isOpenMenu) {
      notificationsService.list();
    }
  }, [isOpenMenu, isFetching]);

  const mergedNotifications = [...notifications];
  const mergedUnread = unread || unreadNotifications.length > 0;
  esaNotifications.forEach((val) => {
    mergedNotifications.push({
      id: val.ClientNotificationId,
      notification: {
        id: val.ClientNotificationId,
        type: "esa",
        custom_text: val.MessageBody,
        subject: val.MessageSubject,
      },
      is_viewed: val.MessageStatus == Status.READ,
      created_at: val.DateSent,
    });
  });

  return (
    <div className={cn(styles.root, className)}>
      <button
        className={styles.notification}
        type="button"
        onClick={() => setIsOpenMenu((prevState) => !prevState)}
      >
        <NotificationIcon className={styles.bell} />
        {mergedUnread && <NotificationAccentIcon className={styles.accent} />}
      </button>
      {isOpenMenu && (
        <ul className={cn(styles.menu, menuClassName)}>
          {isMobile && (
            <li className={styles.menuTitle}>
              <span>Notifications</span>
              <CrossIcon onClick={() => setIsOpenMenu(false)} />
            </li>
          )}
          {mergedNotifications.length > 0 ? (
            mergedNotifications.map((notify) => (
              <NotificationItem notify={notify} key={notify.id} />
            ))
          ) : (
            <li className={cn(styles.item, styles.itemCenter)}>
              No Notifications Yet
            </li>
          )}
        </ul>
      )}
      {isOpenMenu && (
        <div className={styles.layout} onClick={() => setIsOpenMenu(false)} />
      )}
    </div>
  );
};
