import { FC, useState } from "react";
import { DashboardNavigationContext } from "contexts/DashboardNavigationContext";

export const DashboardNavigationProvider: FC = ({ children }) => {
  const [tabName, setTabName] = useState("");
  const [isMainTab, setIsMainTab] = useState(true);
  const [isNavigationHidden, setIsNavigationHidden] = useState(false);
  const [goBack, setGoBack] = useState<() => void>();
  const [edit, setEdit] = useState<() => void>();

  return (
    <DashboardNavigationContext.Provider
      value={{
        tabName,
        setTabName,
        isMainTab,
        setIsMainTab,
        isNavigationHidden,
        setIsNavigationHidden,
        goBack,
        setGoBack,
        edit,
        setEdit,
      }}
    >
      {children}
    </DashboardNavigationContext.Provider>
  );
};
