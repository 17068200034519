import { ListOption } from "constants/options";
import { StylesConfig } from "react-select";

export const colorStyles: StylesConfig = {
  option: (styles, { data }) => {
    //@ts-ignore
    switch (data?.value) {
      case "available":
        return {
          ...styles,
          backgroundColor: "transparent",
          color: "#1f342f",
          ":hover": {
            ...styles[":active"],
            backgroundColor: "rgb(38,	213,	182, 0.7)",
          },
        };
      case "wait":
        return {
          ...styles,
          backgroundColor: "transparent",
          color: "#1f342f",
          ":hover": {
            ...styles[":active"],
            backgroundColor: "rgb(181,	139,	0, 0.7)",
          },
        };
      case "offline":
        return {
          ...styles,
          backgroundColor: "transparent",
          color: "#1f342f",
          ":hover": {
            ...styles[":active"],
            backgroundColor: "rgb(254,	73,	73, 0.7)",
          },
        };
      default:
        return { ...styles };
    }
  },
};

export const CHAT_STATUS_OPTIONS: ListOption[] = [
  {
    label: "Available",
    value: "available",
  },
  {
    label: "Wait",
    value: "wait",
  },
  {
    label: "Offline",
    value: "offline",
  },
];
