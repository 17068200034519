import { ReactNode } from "react";
import styles from "../TherapistSteps.module.scss";
import cn from "classnames";

type Props = {
  children: ReactNode | string;
  className?: string;
  label?: string;
  subLabel?: string;
  onClick?: () => void;
};

export const TherapistStepsRow = ({
  children,
  className,
  label,
  subLabel,
  onClick,
}: Props) => (
  <div className={cn(styles.row, className)} onClick={onClick}>
    {label && <div className={styles.rowLabel}>{label}</div>}
    {subLabel && <div className={styles.rowSubLabel}>{subLabel}</div>}
    {children}
  </div>
);
