import { forwardRef, useState } from "react";
import cn from "classnames";

import { ReactComponent as EyeSlash } from "assets/images/eye-slash.svg";

import "./InputPassword.scss";

export const SPECIAL_CHAR_REGEXP = /[!@#$%^&*()"'\-_{[\]}\\|:;?/>.<,~`]/g;

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, "size"> {
  size?: "full" | "auto";
  name?: string;
  label?: string;
  className?: string;
  validateStrength?: boolean;
  error?: string;
}

const getPasswordRating = (password: string): number => {
  let rating = 0;

  if (password.length === 0) return 0;
  if (password.length < 8) return 1;
  if (password.match(/[a-z]/g)) rating += 1;
  if (password.match(/[A-Z]/g)) rating += 1;
  if (password.match(SPECIAL_CHAR_REGEXP)) rating += 1;
  if (password.match(/\d/g)) rating += 1;

  return rating;
};

const getRatingText = (rating: number) => {
  if (rating === 0) return "";
  if (rating <= 2) return "Weak password";
  if (rating <= 3) return "Fine password";
  return "Strong password";
};

const getRatingLabel = (rating: number) => {
  if (rating <= 2) return "weak";
  if (rating <= 3) return "fine";
  return "strong";
};

const InputPassword = forwardRef<HTMLInputElement, Props>(
  (
    {
      size = "full",
      onChange,
      label,
      id,
      name,
      className,
      validateStrength,
      error,
      ...restProps
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = useState(false);
    const [passwordRating, setPasswordRating] = useState(0);
    const [isActive, setIsActive] = useState(false);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      const rating = getPasswordRating(event.currentTarget.value);
      setPasswordRating(rating);
      onChange && onChange(event);
    };

    return (
      <div className={cn("field", className)}>
        <label htmlFor={id} className={cn("label")}>
          {label}
        </label>
        <div
          className={cn("password", error && "invalid", isActive && "active")}
        >
          <input
            onChange={handleChange}
            id={id}
            name={name}
            className={cn(`input-size-${size}`, "password__input", {
              "--error": error,
            })}
            type={isVisible ? "text" : "password"}
            placeholder="Password"
            ref={ref}
            {...restProps}
            onFocus={(e) => {
              setIsActive(true);
              if (restProps?.onFocus) {
                restProps.onFocus(e);
              }
            }}
            onBlur={(e) => {
              setIsActive(false);
              if (restProps?.onBlur) {
                restProps.onBlur(e);
              }
            }}
          />
          <span
            className="password__visible"
            onClick={() => setIsVisible((prevValue) => !prevValue)}
          >
            <EyeSlash />
          </span>
        </div>
        {error && <p className="error_msg">{error}</p>}
        {validateStrength && (
          <>
            <div className="password__tip">
              Must contain at least 8 characters including
              <span>at least 1 special character.</span>
            </div>
            <div className={cn("password__strength")}>
              <div
                className={cn(
                  "password__status",
                  `--${getRatingLabel(passwordRating)}`
                )}
              >
                {[1, 2, 3, 4].map((key) => (
                  <div
                    key={key}
                    className={cn("password__status-line", `line${key}`, {
                      true: key <= passwordRating,
                    })}
                  />
                ))}
              </div>
              <div
                className={cn(
                  "password__strength_text",
                  `--${getRatingLabel(passwordRating)}`
                )}
              >
                {getRatingText(passwordRating)}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default InputPassword;
