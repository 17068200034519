import React, { MouseEventHandler, ReactNode } from "react";
import "./FilterTag.scss";
import cn from "classnames";

type Props = {
  className?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  buttonIcon?: ReactNode;
};

function FilterTag({ className, children, onClick, onButtonClick, buttonIcon }: Props) {
  const withButton = !!buttonIcon;
  return (
    <div className={cn("filter-tag", className, !!onClick && "filter-tag--interactive")} onClick={onClick}>
      <span className="filter-tag__text">{children}</span>
      {withButton && (
        <button
          type="button"
          className="filter-tag__button"
          onClick={e => {
            e.stopPropagation();
            onButtonClick?.(e);
          }}
        >
          {buttonIcon}
        </button>
      )}
    </div>
  );
}

FilterTag.defaultProps = {
  className: "",
  children: "",
  onButtonClick: null,
  onClick: null,
};

export default FilterTag;
