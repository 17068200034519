import { useEffect, useState } from "react";
import Input from "common/UIKit/Input";
import { stepsUi } from "../../components";
import {
  UseFormRegister,
  FieldErrors,
  Control,
  UseFormGetValues,
  FieldValues,
} from "react-hook-form";
import { Inputs } from "../TherapistStep3_1";
import Checkbox from "common/UIKit/Checkbox/Checkbox";
import { TherapistStepLicensed } from "./TherapistStepLicensed";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { DateSelect } from "../blocks/DateSelect/DateSelect";
import { StateSelect } from "../blocks/StateSelect/StateSelect";
import styles from "./TherapistStepLicense.module.scss";

interface Props {
  register: UseFormRegister<Inputs>;
  errors: FieldErrors;
  control: Control<Inputs, FieldValues>;
  watch: UseFormWatch<Inputs>;
  setValue: UseFormSetValue<Inputs>;
  getValues: UseFormGetValues<Inputs>;
  id?: string;
  isVerified?: boolean;
}

export const TherapistStepPreLicensed = ({
  control,
  errors,
  register,
  watch,
  setValue,
  getValues,
  id,
  isVerified,
}: Props) => {
  const [withInfo, setWithInfo] = useState(true);

  useEffect(() => {
    if (!withInfo) {
      setValue("license_number", "");
      setValue("license_state", "");
      setValue("license_expiration", null);
    }
  }, [withInfo, setValue]);

  useEffect(() => {
    const licenseNumber = getValues("license_number");
    const licenseState = getValues("license_state");
    const licenseExpiration = getValues("license_expiration");
    setWithInfo(Boolean(licenseNumber || licenseState || licenseExpiration));
  }, [getValues]);

  return (
    <>
      <stepsUi.Row label="Supervisor’s Name" className={styles.row}>
        <Input
          type="text"
          {...register("supervisor_name")}
          error={errors.supervisor_name?.message}
          maxLength={160}
        />
      </stepsUi.Row>
      <stepsUi.Row label="Supervisor’s License Number" className={styles.row}>
        <Input
          type="text"
          {...register("supervisor_license_number")}
          error={errors.supervisor_license_number?.message}
          maxLength={160}
        />
      </stepsUi.Row>
      <stepsUi.Row label="Supervisor’s State" className={styles.row}>
        <StateSelect
          control={control}
          name="supervisor_license_state"
          setValue={setValue}
        />
      </stepsUi.Row>
      <stepsUi.Row
        label="Supervisor’s License Expiration"
        className={styles.row}
      >
        <DateSelect
          watch={watch}
          control={control}
          name="supervisor_license_expiration"
          setValue={setValue}
        />
      </stepsUi.Row>
      <stepsUi.Row>
        <Checkbox
          id="checkbox"
          type="checkbox"
          checked={withInfo}
          onChange={() => {
            setWithInfo((prevState) => !prevState);
          }}
          label="Enter my pre-license info"
        />
      </stepsUi.Row>
      <TherapistStepLicensed
        register={register}
        control={control}
        errors={errors}
        disabled={!withInfo}
        watch={watch}
        setValue={setValue}
        id={id}
        isVerified={isVerified}
      />
    </>
  );
};
