import { AccessController } from "common/components/Layout/AccessController";
import { useAuth } from "hooks/useAuth";
import { CTA } from "./Auth/CTA";

export const homePath = "/";

export const HomePage = () => {
  const { user } = useAuth();

  if (!user) {
    return <CTA />;
  }

  return (
    <AccessController therapist="allowed" patient="allowed">
      <CTA />
    </AccessController>
  );
};
