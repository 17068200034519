import { useEffect } from "react";
import { Controller } from "react-hook-form";
import Textarea from "common/UIKit/Textarea/Textarea";
import RangePicker from "common/UIKit/Range";
import { stepsUi } from "../components";
import { coveredFields } from "./step3_2Config";
import { step3_3FullPath } from "../TherapistStep3_3";
import { step3_1FullPath } from "../TherapistStep3_1";
import { useAuth } from "hooks/useAuth";
import { useSnapshot } from "valtio";
import { educationService } from "services/educationService";
import useAnyTherapistStep from "../useAnyTherapistStep";
import AddButton from "common/UIKit/AddButton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DocumentIcon } from "assets/images/icons/document.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/pencil.svg";
import Card from "common/UIKit/Card";
import styles from "./TherapistStep3_2.module.scss";

type Inputs = {
  how_long_practicing: number;
  about_qualifications: string;
};

export function TherapistStep3_2() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data } = useSnapshot(educationService);
  const { handleSubmit, control, actionsProps } = useAnyTherapistStep<Inputs>(
    coveredFields,
    step3_3FullPath,
    {
      prevStep: step3_1FullPath,
      toFields: (v) => ({
        ...v,
        how_long_practicing: v.how_long_practicing || 1,
      }),
    }
  );

  useEffect(() => {
    user && educationService.list(user?.id);
  }, [user]);

  return (
    <div className={styles.root}>
      <stepsUi.Header>Credentials</stepsUi.Header>
      <stepsUi.Hint className={`therapist-step-3__hint ${styles.hint}`}>
        Education And Experience 2/3
      </stepsUi.Hint>
      <h2 className={styles.title}>Education</h2>
      <div className={styles.mobileRow}>
        {data.length && user?.id
          ? data.map((education) => (
              <Card
                key={education.id}
                className={styles.item}
                values={[
                  {
                    caption: "SCHOOL GRADUATED",
                    text: education.school_graduated,
                  },
                  {
                    caption: "DEGREE / DIPLOMA",
                    text: education.degree_diploma,
                  },
                  { caption: "YEAR GRADUATED", text: education.year_graduated },
                ]}
                icon={<DocumentIcon />}
                editIcon={<EditIcon />}
                buttonClass={styles.cardButton}
                onEdit={() => navigate(`add/${String(education.id)}`)}
                onRemove={() => educationService.delete(user?.id, education.id)}
              />
            ))
          : null}
        <AddButton className={styles.addButton} onClick={() => navigate("add")}>
          Add education
        </AddButton>
      </div>
      <stepsUi.Form
        className={styles.inner}
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <h2 className={styles.title}>Experience</h2>
        <stepsUi.Row
          label="How long have you been practicing?"
          className={styles.longRow}
        >
          <Controller
            name="how_long_practicing"
            control={control}
            render={({ field }) => (
              <RangePicker value={field.value} onChange={field.onChange} />
            )}
          />
        </stepsUi.Row>
        <stepsUi.Row
          label="Tell us more about your qualifications"
          className={styles.longRow}
        >
          <Controller
            control={control}
            name="about_qualifications"
            rules={{
              maxLength: { value: 200, message: "Max length is 200" },
            }}
            render={({ field, fieldState }) => (
              <Textarea
                className={styles.textarea}
                placeholder="Type something..."
                {...field}
                currentLength={field?.value?.length}
                error={fieldState.error?.message}
                maxLength={200}
              />
            )}
          />
        </stepsUi.Row>
      </stepsUi.Form>
    </div>
  );
}
