import { useLocation } from "react-router-dom";
import RoundScale from "common/UIKit/RoundScale/RoundScale";
import { ReactComponent as ClockIcon } from "assets/images/icons/transparent-clock.svg";
import { Steps } from "common/UIKit/Steps/Steps";
// Workaround for cyclic dependency
import { step1FullPath, step1Caption } from "../TherapistStep1/step1Config";
import { step2FullPath, step2Caption } from "../TherapistStep2";
import { step3_1FullPath } from "../TherapistStep3_1";
import { step3_3FullPath } from "../TherapistStep3_3";
import { step3_2FullPath } from "../TherapistStep3_2";
import { step4_1FullPath } from "../TherapistStep4_1";
import { step4_2FullPath } from "../TherapistStep4_2";
import { step5FullPath, step5Caption } from "../TherapistStep5";
import { step6FullPath, step6Caption } from "../TherapistStep6";
import { step7FullPath, step7Caption } from "../TherapistStep7";
import { step8FullPath, step8Caption } from "../TherapistStep8";
import { step9_1FullPath } from "../TherapistStep9_1";
import { step9_2FullPath } from "../TherapistStep9_2";
import { step10Caption, step10FullPath } from "../TherapistStep10";
import { step11Caption, step11FullPath } from "../TherapistStep11";
import { step12Caption, step12FullPath } from "../TherapistStep12";
import { padStart } from "lodash";
import { useResponsive } from "hooks/useResponsive";
import styles from "./TherapistStepsProgress.module.scss";
import { Scale } from "common/UIKit/Scale";

type StepType = {
  path: string[];
  caption: string;
  progressListenable?: {
    subscribe: (cb: (progress: number) => void) => Function;
  };
};

const stepsConfig: StepType[] = [
  {
    path: [step1FullPath],
    caption: step1Caption,
  },
  {
    path: [step2FullPath],
    caption: step2Caption,
  },
  {
    path: [step3_1FullPath, step3_2FullPath, step3_3FullPath],
    caption: "Credentials",
  },
  {
    path: [step4_1FullPath, step4_2FullPath],
    caption: "Specialties & Treatment Preferences",
  },
  {
    path: [step5FullPath],
    caption: step5Caption,
  },
  {
    path: [step6FullPath],
    caption: step6Caption,
  },
  {
    path: [step7FullPath],
    caption: step7Caption,
  },
  {
    path: [step8FullPath],
    caption: step8Caption,
  },
  {
    path: [step9_1FullPath, step9_2FullPath],
    caption: "Photo & Video Intro",
  },
  //{
  //  path: [step10FullPath],
  //  caption: step10Caption,
  //},
  {
    path: [step10FullPath],
    caption: step11Caption,
  },
  //{
  //  path: [step11FullPath],
  //  caption: step12Caption,
  //},
];

interface StepsProps {
  contentHeight: number;
}

export function TherapistStepsProgress({ contentHeight }: StepsProps) {
  const { isMobile } = useResponsive();
  const location = useLocation();
  const activeIndex = stepsConfig.findIndex(({ path }) =>
    path.includes(location.pathname)
  );
  const steps = stepsConfig.map(({ path, caption }, index) => {
    const label = padStart((index + 1).toString(), 2, "0");
    return {
      shortLabel: label,
      longLabel: `Step ${index + 1}`,
      caption,
      progress:
        path.length > 1
          ? ((path.indexOf(location.pathname) + 1) / path.length) * 100
          : undefined,
    };
  });

  if (!isMobile) {
    return (
      <div className={styles.surveyProgress}>
        <RoundScale
          scaleValue={Math.round((100 * (activeIndex + 1)) / steps.length)}
        />
        <div className={styles.title}>Complete your profile</div>
        <div className={styles.subTitle}>
          <ClockIcon />
          <span>It will take you about 30 minutes</span>
        </div>
        <Steps
          height={contentHeight}
          activeIndex={activeIndex}
          steps={steps}
          className={styles.steps}
        />
      </div>
    );
  }

  return (
    <div className={styles.surveyProgressMobile}>
      <div className={styles.info}>
        <div className={styles.mobileTitle}>
          <span>
            {steps[activeIndex]?.caption && steps[activeIndex].caption}
          </span>
        </div>
        <div className={styles.mobileStepText}>
          <span>In progress</span> | <span>{`Step ${activeIndex + 1}`}</span>
        </div>
        {location.pathname.includes("steps/3") && (
          <div className={styles.row}>
            <Scale
              scaleValue={Math.round(
                100 /
                  (+location.pathname[location.pathname.length - 1] === 1
                    ? 3
                    : +location.pathname[location.pathname.length - 1] === 2
                    ? 2
                    : 1)
              )}
              className={styles.scale}
            />
            {`${location.pathname[location.pathname.length - 1]}/3`}
          </div>
        )}
      </div>
      <RoundScale
        scaleValue={Math.round((100 * (activeIndex + 1)) / steps.length)}
        scaleOptions={{
          diameter: 70,
          strokeWidth: 2,
          textFontSize: 22,
        }}
      />
    </div>
  );
}
