import { proxy } from "valtio";

import { ListService } from "./listService";
import { UserId } from "types/auth.types";
import { ListResponse } from "types/common.types";
import { SmsSession, SmsSessionId } from "types/smsSession.types";

class SmsSessionService extends ListService<SmsSessionId, SmsSession> {
  currentSession: SmsSession | null = null;

  async getCurrentSession() {
    if (!this.currentSession) {
      this.isFetching = true;
      const { results } = (await this.http.get(`${this.prefix}/`, {
        params: { ...this.filters, not_stopped: true },
      })) as ListResponse<SmsSession[]>;
      this.currentSession = results[0];
      this.isFetching = false;
    }

    return this.currentSession;
  }

  async start(patientUserId: UserId) {
    this.currentSession = (await this.http.post(`${this.prefix}/`, {
      patient: patientUserId,
    })) as SmsSession;
    return this.currentSession;
  }

  async pause(id: SmsSessionId) {
    this.currentSession = (await this.http.post(
      `${this.prefix}/${id}/pause/`
    )) as SmsSession;
    return this.currentSession;
  }

  async resume(id: SmsSessionId) {
    this.currentSession = (await this.http.post(
      `${this.prefix}/${id}/resume/`
    )) as SmsSession;
    return this.currentSession;
  }

  async terminate(id: SmsSessionId) {
    await this.http.delete(`${this.prefix}/${id}/`);
    this.currentSession = null;
    return this.currentSession;
  }
}

export const smsSessionService = proxy(
  new SmsSessionService({
    prefix: "/v1/therapists/sms-sessions",
    pageSize: 1,
  })
);
