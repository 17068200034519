import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import { confirmEmailPath } from "../ConfirmEmail";

type Values = {
  email: string;
};

export const useForgot = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Values> = async (values) => {
    try {
      setIsLoading(true);
      await authService.sendResetPassword(values.email);
      navigate({
        pathname: confirmEmailPath,
        search: `?email=${values.email}`,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    isLoading,
  };
};
