import { useSnapshot } from "valtio";

import Button from "common/UIKit/Button";
import { useSessionControls } from "./useSessionControls";
import { patientProfileService } from "services/patientProfileService";
import { isPatient } from "services/authService";
import { useAuth } from "hooks/useAuth";
import { ButtonProps } from "common/UIKit/Button/Button";
import styles from "./SessionControls.module.scss";

export const SessionControls = () => {
  const user = useAuth().user;
  const { currentProfile } = useSnapshot(patientProfileService);
  const {
    sessions,
    currentSession,
    start,
    pause,
    resume,
    terminate,
    isLoading,
    error,
  } = useSessionControls(currentProfile?.user.id);

  if (!user || isPatient(user)) {
    return null;
  }

  const hasSessions = sessions.length > 0;
  const buttonProps: ButtonProps = user?.subscription_plan.chat_access
    ? {}
    : {
        disabled: true,
        title: "Managing SMS sessions requires a premium membership",
      };

  return hasSessions ? (
    <div className={styles.sessionControls}>
      <div className={styles.buttons}>
        {currentSession ? (
          <>
            {currentSession.status === "active" ? (
              <Button
                className={styles.button}
                color="yellow"
                onClick={pause}
                isLoading={isLoading}
                {...buttonProps}
              >
                Pause Session
              </Button>
            ) : (
              <Button
                className={styles.button}
                onClick={resume}
                isLoading={isLoading}
                {...buttonProps}
              >
                Resume Session
              </Button>
            )}
            <Button
              className={styles.button}
              color="red"
              onClick={terminate}
              isLoading={isLoading}
              {...buttonProps}
            >
              Terminate Session
            </Button>
          </>
        ) : (
          <Button
            className={styles.button}
            onClick={start}
            isLoading={isLoading}
            {...buttonProps}
          >
            Start Session
          </Button>
        )}
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  ) : null;
};
