import { proxy } from "valtio";
import { ListService } from "./listService";
import { Message } from "../types/messages.types";

export class MessagesService extends ListService<number, Message> {
  constructor(args?: any) {
    super({
      ...args,
      prefix: "/v1/sms-logs",
      pageSize: 10,
      sortBy: "created_at",
      order: "desc",
    });
  }
}

export const messagesService = proxy(new MessagesService());
