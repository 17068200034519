import { proxy } from "valtio";
import { Nullable } from "common/utils";

type ErrorType = "auth" | "critical";

type ErrorData = Nullable<{
  status: number;
  type: ErrorType;
  message?: string;
}>;

class GeneralLogicService {
  error: ErrorData = null;

  setError(error: ErrorData) {
    this.error = error;
  }

  clearError() {
    this.setError(null);
  }
}

export const generalLogicService = proxy(new GeneralLogicService());
