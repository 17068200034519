import React from "react";
import ReactDOM from "react-dom";
import "index.scss";
import App from "App";
import * as Sentry from "@sentry/react";
import { SentryFallbackPage } from "./common/components/SentryFallbackPage";
import { datadogRum } from "@datadog/browser-rum";
import {
  sentryEnvironment,
  sentryDsn,
  datadogApplicationId,
  datadogClientToken,
  datadogService,
  datadogEnv,
} from "./utils/env";

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    attachStacktrace: true,
    environment: sentryEnvironment,
  });
}

// datadogRum.init({
//   applicationId: datadogApplicationId,
//   clientToken: datadogClientToken,
//   site: "datadoghq.com",
//   sampleRate: 100,
//   premiumSampleRate: 100,
//   service: datadogService,
//   env: datadogEnv,
// });

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<SentryFallbackPage />}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
