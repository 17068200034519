import { ListService } from "./listService";
import { TestAttempt, TestAttemptId } from "../types/testAttempt.types";
import { proxy } from "valtio";

class TestAttemptListService extends ListService<TestAttemptId, TestAttempt> {}

export const testAttemptListService = proxy(
  new TestAttemptListService({
    prefix: "/v1/test-attempts",
    filters: { status: "finished" },
    pageSize: 10,
    sort: {
      finished_at: {
        order: "desc",
      },
    },
  })
);
