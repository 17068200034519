import { Button } from "@mui/material";
import useLocalAudioToggle from "hooks/useLocalAudioToggle";
import useVideoContext from "hooks/useVideoContext";
import MicIcon from "icons/MicIcon";
import MicOffIcon from "icons/MicOffIcon";
import React from "react";

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some((track) => track.kind === "audio");

  return (
    <Button
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      data-cy-audio-toggle
    >
      {!hasAudioTrack ? "No Audio" : isAudioEnabled ? "Mute" : "Unmute"}
    </Button>
  );
}
