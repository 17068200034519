import { Route, Routes } from "react-router-dom";
import { TherapistsHome } from "./TherapistsHome";
import { TherapistSteps, stepsPath } from "./TherapistSteps";
import { ErrorPage } from "../ErrorPage";
import { congratulationsPath, Congratulations } from "./Congratulations";
import {
  therapistDashboardPath,
  TherapistsDashboard,
} from "./TherapistsDashboard";
import { SubscriptionPlans, subscriptionPlansPath } from "./SubscriptionPlans";
import {
  therapistsCompetedPath,
  TherapistsCompleted,
} from "./TherapistsCompleted";
import { TherapistPatient, therapistPatientPath } from "./TherapistPatient";
import { TherapistPatientTest } from "./TherapistPatientTest/TherapistPatientTest";
import {
  TherapistVideoLanding,
  therapistVideoLandingPath,
} from "./TherapistVideoLanding";

export { therapistsPath } from "./therapistsPath";

export const Therapists = () => {
  return (
    <Routes>
      <Route path={therapistsCompetedPath} element={<TherapistsCompleted />} />
      <Route path="/" element={<TherapistsHome />} />
      <Route
        path={`${therapistDashboardPath}/*`}
        element={<TherapistsDashboard />}
      />
      <Route
        path={`${subscriptionPlansPath}/*`}
        element={<SubscriptionPlans />}
      />
      <Route
        path={`${therapistPatientPath}/:id`}
        element={<TherapistPatient />}
      />
      <Route
        path={`${therapistPatientPath}/:userId/test/:id`}
        element={<TherapistPatientTest />}
      />
      <Route path={congratulationsPath} element={<Congratulations />} />
      <Route path={`${stepsPath}/*`} element={<TherapistSteps />} />
      <Route
        path={therapistVideoLandingPath}
        element={<TherapistVideoLanding />}
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
