import { Layout } from "common/components/Layout/Layout";
import ComingSoonIcon from "assets/images/almost-ready.png";
import "./AlmostReady.scss";

export const almostReadyPath = "/almost-ready";

function AlmostReady() {
  return (
    <Layout withoutHeader mainClass="almostready">
      <section className="almostready-container">
        <div className="almostready-wrapper">
          <h1 className="almostready-title__caption title">Almost ready</h1>
          <h3 className="almostready-title__posttitle">
            A better and smarter therapy platform is on its way.
          </h3>
          <img
            className="almostready-title__img"
            src={ComingSoonIcon}
            alt="Coming soon"
          />
        </div>
      </section>
    </Layout>
  );
}

export { AlmostReady };
