import { ChatStatus } from "types/therapist.types";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { useState } from "react";
import { authService } from "services/authService";
import { useForm, SubmitHandler } from "react-hook-form";
import { useResponsive } from "hooks/useResponsive";

type Inputs = {
  in_person_session_availability: boolean;
  online_chat_sessions_availability: boolean;
  telephone_session_availability: boolean;
  video_session_availability: boolean;
  offer_free_call: boolean;
  chat_status: ChatStatus;
};

export const useTherapistStep11 = () => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { setIsProfileCompleted } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    setIsSubmitting(true);
    try {
      await authService.patch({
        diff: { user: { status: "completed" }, ...values },
      });
      setIsProfileCompleted(true);
      navigate("/therapists/completed");
    } catch (error) {}
    setIsSubmitting(false);
  };

  return {
    control,
    errors,
    register,
    handleSubmit: handleSubmit(onSubmit),
    isMobile,
    isSubmitting,
  };
};
