import { Route, Routes, Navigate } from "react-router-dom";
import { DashboardLayout } from "common/components/DashboardLayout";
import { AccessController } from "common/components/Layout/AccessController";
import { myAccountPath } from "./MyAccount";
import { patientsPath } from "../patientsPath";
import { tabs } from "./tabs";
import { EmailPopup } from "common/components/EmailPopup";
import { useAuth } from "hooks/useAuth";
import { DashboardTab } from "./useDashboardTabs";

export const patientsDashboardPath = "/dashboard";
export const patientsDashboardFullPath = patientsPath + patientsDashboardPath;
export const patientsDashboardMainPage =
  patientsDashboardFullPath + myAccountPath;

export const PatientsDashboard = () => {
  const is_esa_user = useAuth().baseUser?.is_esa_user;
  let availableTabs: DashboardTab[];
  if (!is_esa_user) {
    availableTabs = tabs.filter((tab) => !tab.esaOnly);
  } else {
    availableTabs = tabs;
  }
  
  return (
    <AccessController patient="allowed">
      <DashboardLayout tabs={availableTabs}>
        <Routes>
          {tabs.map(({ value, component: Element }) => (
            <Route path={`${value}/*`} element={<Element />} key={value} />
          ))}
          <Route
            path="*"
            element={<Navigate replace to={patientsDashboardMainPage} />}
          />
        </Routes>
        <EmailPopup />
      </DashboardLayout>
    </AccessController>
  );
};
