import { createContext, Dispatch, SetStateAction, useContext } from 'react';

type ContextType = {
  progressDisplayHidden: boolean,
  setProgressDisplayHidden: Dispatch<SetStateAction<boolean>>
}
const StepsLayoutContext = createContext<ContextType>({
  progressDisplayHidden: false,
  setProgressDisplayHidden: () => {}
});

function useStepsLayoutContext() {
  return useContext(StepsLayoutContext);
}

export { StepsLayoutContext, useStepsLayoutContext };