import styles from "./ArticlesSliderCard.module.scss";
import { ReactComponent as Calendar } from "assets/images/icons/calendar2.svg";
import { ReactComponent as Clock } from "assets/images/icons/clock-article.svg";
import { ReactComponent as Arrow } from "assets/images/icons/arrow-article.svg";
import { Link } from "react-router-dom";
import Default from "assets/images/articles/default.jpg";
import { formatArticleDate } from "common/utils";
import { articlePath } from "../../Article";
import { FormatProfileField } from "common/components/FormatProfileField";

interface Props {
  title: string;
  published_at: string;
  files: any;
  time_to_read: number;
  id: string;
}

export const ArticlesSliderCard = ({
  title,
  published_at,
  files,
  time_to_read,
  id,
}: Props) => {
  return (
    <div className={styles.card}>
      <div className={styles.text}>
        <Link to={articlePath.replace(":id", String(id))}>
          <h2>
            <FormatProfileField maxLength={70}>{title}</FormatProfileField>
          </h2>
        </Link>
        <div className={styles.date}>
          <Calendar />
          <p>{formatArticleDate(published_at)}</p>
          <div className={styles.time}>
            <Clock />
            <p>{`${time_to_read} minutes to read`}</p>
          </div>
        </div>
        <Link to={articlePath.replace(":id", String(id))}>
          <div className={styles.link}>
            <span>Read</span>
            <Arrow />
          </div>
        </Link>
      </div>
      <div className={styles.image}>
        <img src={files[0]?.file ? files[0]?.file : Default} />
      </div>
    </div>
  );
};
