import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import { isTherapist } from "services/authService";
import { therapistDashboardFullPath } from "pages/Therapists/TherapistsDashboard";
import { patientsDashboardFullPath } from "pages/Patients/PatientsDashboard";

export type DashboardTab = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  value: string;
  component: React.FC;
};

export function useDashboardTabs(tabs: DashboardTab[]) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const prefix =
    user && isTherapist(user)
      ? therapistDashboardFullPath
      : patientsDashboardFullPath;
  const currentTab =
    tabs.slice(1).find(({ value }) => location.pathname.includes(value)) ||
    tabs[0];

  const changeRoute = (newRoute: string) => navigate(`${prefix}${newRoute}`);

  return { currentTab, changeRoute };
}
