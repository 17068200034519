import cn from "classnames";
import { FC } from "react";
import { ReactComponent as IconLeft } from "assets/images/icons/chevron-left-Regular.svg";
import { useResponsive } from "hooks/useResponsive";
import "./Pagination.scss";

type Props = {
  className?: string;
  pageCount: number;
  currentPage: number;
  goTo: (page: number) => void;
  withoutEnd?: boolean;
};

export const Pagination: FC<Props> = ({
  className,
  pageCount,
  currentPage,
  goTo,
  withoutEnd,
}) => {
  const { isMobile } = useResponsive();
  const pageArray = () => {
    let maxPages = 7;
    let startPage = 1;
    let endPage = pageCount;

    if (isMobile) {
      maxPages = 5;

      if (currentPage > 99) {
        maxPages = 3;
      }
    }

    if (pageCount <= maxPages) {
      return Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      );
    }

    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= pageCount) {
      startPage = pageCount - maxPages + 1;
      endPage = pageCount;
    } else {
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
    return Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );
  };

  if (pageCount <= 1) return null;

  return (
    <div className={cn("pagination", className)}>
      {currentPage !== 1 && (
        <button
          className="pagination__arrow left"
          aria-label="Back"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            goTo(currentPage - 1);
          }}
        />
      )}
      <div className="pagination__pages">
        {currentPage !== 1 && (
          <button
            className={cn("pagination__double")}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              goTo(1);
            }}
          >
            <span>
              <IconLeft />
              <IconLeft />
            </span>
          </button>
        )}
        {pageArray().map((page) => (
          <button
            key={page}
            className={cn(
              "pagination__page",
              page === currentPage && "current"
            )}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              goTo(page);
            }}
          >
            {page}
          </button>
        ))}
        {!withoutEnd && currentPage !== pageCount ? (
          <button
            className={cn("pagination__double right")}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              goTo(pageCount);
            }}
          >
            <span>
              <IconLeft />
              <IconLeft />
            </span>
          </button>
        ) : null}
      </div>
      {currentPage !== pageCount && (
        <button
          className="pagination__arrow right"
          aria-label="Next"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            goTo(currentPage + 1);
          }}
        />
      )}
    </div>
  );
};
