import { pick } from "lodash";
import { DeepPartial } from "common/utils";
import { FileDataField } from "common/components/ControlledFileUpload";
import { Therapist, TherapistFile } from "types/therapist.types";
import { BaseUser } from "types/auth.types";

type PureTherapistField = keyof Therapist;
export type TherapistFields = (PureTherapistField | `user.${keyof BaseUser}`)[];
export type FileDataInputs = {
  fileData: FileDataField<TherapistFile>;
};

function distinguishPaths(paths: TherapistFields): {
  nestedFields: PureTherapistField[];
  shallowFields: PureTherapistField[];
} {
  const nestedFields: PureTherapistField[] = [];
  const shallowFields: PureTherapistField[] = [];
  paths.forEach(p =>
    (p.startsWith("user") ? nestedFields : shallowFields).push(p.replace("user.", "") as PureTherapistField)
  );
  return { nestedFields, shallowFields };
}

function makeDefaultValues(therapist: Therapist, paths: TherapistFields): DeepPartial<Therapist> {
  const { nestedFields, shallowFields } = distinguishPaths(paths);
  return { ...pick(therapist, shallowFields), ...pick(therapist.user, nestedFields) };
}

function makeDiffToSend(values: object, paths: TherapistFields): DeepPartial<Therapist> {
  const { nestedFields, shallowFields } = distinguishPaths(paths);
  const res = pick(values, shallowFields);
  if (nestedFields.length) {
    (res as DeepPartial<Therapist>).user = pick(values, nestedFields);
  }
  return res;
}

export { makeDefaultValues, makeDiffToSend };
