import { FC } from "react";
import { history } from "./history";
import { AuthProvider } from "providers/AuthProvider";
import { CustomRouter } from "common/components/CustomRouter";

export const withProviders = (Component: FC) => () => {
  return (
    <CustomRouter history={history}>
      <AuthProvider>
        <Component />
      </AuthProvider>
    </CustomRouter>
  );
};
