import { Dispatch, SetStateAction, useState } from 'react';


function useOptionalExternalState<S>(
  initialValue: S, externalValue?: S, setExternalValue?: Dispatch<SetStateAction<S>>
): [S, Dispatch<SetStateAction<S>>] {
  const shouldUseExternal = !!setExternalValue;
  const [internalValue, setInternalValue] = useState<S>(initialValue);

  const setState: Dispatch<SetStateAction<S>> = value =>
    shouldUseExternal ? setExternalValue(value) : setInternalValue(value);

  const state = shouldUseExternal ? externalValue as S : internalValue;
  return [state, setState];
}

export default useOptionalExternalState;