import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { ReactComponent as StarIcon } from "assets/images/icons/golden-star.svg";
import { Plan } from "types/plan.types";
import { therapistDashboardFullPath } from "pages/Therapists/TherapistsDashboard";
import { subscriptionPath } from "pages/Therapists/TherapistsDashboard/Subscription";
import Button from "common/UIKit/Button";
import styles from "./MembershipBadge.module.scss";

interface Props {
  membership: Plan;
}

const MembershipBadge = ({ membership }: Props) => {
  const navigate = useNavigate();
  const isNotFree = !membership.name.match("Free");

  return (
    <Button
      className={cn(styles.root, isNotFree ? styles.premium : styles.free)}
      onClick={() =>
        navigate(`${therapistDashboardFullPath}${subscriptionPath}`)
      }
    >
      {isNotFree && <StarIcon />}
      {membership.name.toUpperCase()} MEMBERSHIP
    </Button>
  );
};

export default MembershipBadge;
