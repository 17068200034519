import { stepsUi } from "../components";
import { step7FullPath } from "../TherapistStep7";
import Textarea from "common/UIKit/Textarea/Textarea";
import useAnyTherapistStep from "../useAnyTherapistStep";
import { coveredFields } from "./step8Config";
import { step9_1FullPath } from "../TherapistStep9_1";
import { Controller } from "react-hook-form";

type Inputs = {
  personal_statement_short_description: string;
};

export function TherapistStep8() {
  const { handleSubmit, actionsProps, control } = useAnyTherapistStep<Inputs>(
    coveredFields,
    step9_1FullPath,
    {
      prevStep: step7FullPath,
    }
  );

  return (
    <>
      <stepsUi.Header>Personal Statement</stepsUi.Header>
      <stepsUi.Hint>
        We recommend focusing on your potential clients’ needs.
      </stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <stepsUi.Row
          label="Short description"
          subLabel="How can you help? Talk about your specialities and what you offer."
        >
          <Controller
            control={control}
            name="personal_statement_short_description"
            render={({ field, fieldState }) => (
              <Textarea
                placeholder="Type something..."
                {...field}
                currentLength={field.value.length}
                error={fieldState.error?.message}
                maxLength={500}
              />
            )}
          />
        </stepsUi.Row>
      </stepsUi.Form>
    </>
  );
}
