import { Controller, useForm, SubmitHandler, Path } from "react-hook-form";
import { mentalHealthRoles, primaryCredentialOptions } from "constants/options";
import { goThroughErrors } from "common/utils";
import Radiobutton from "common/UIKit/Radiobutton";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { PrimaryCredentialType } from "types/therapist.types";
import { CredentialType } from "types/credential.types";
import Button from "common/UIKit/Button";
import { EditHeader } from "common/components/EditHeader";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { TherapistStepNoLicensed } from "pages/Therapists/TherapistSteps/TherapistStep3_1/components/TherapistStepNoLicensed";
import { TherapistStepPreLicensed } from "pages/Therapists/TherapistSteps/TherapistStep3_1/components/TherapistStepPreLicensed";
import { TherapistStepLicensed } from "pages/Therapists/TherapistSteps/TherapistStep3_1/components/TherapistStepLicensed";
import AppSelect from "common/UIKit/Select/Select";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "hooks/useAuth";
import { stateLicenseService } from "services/stateLicenseService";
import { StateLicense } from "types/stateLicense.types";
import { fullProfilePath } from "../../Profile";
import { AxiosResponse } from "axios";
import _ from "lodash";
import ControlledFileUpload from "common/components/ControlledFileUpload/ControlledFileUpload";
import useFileUpload from "hooks/useFileUpload";
import styles from "./AddStateLicensing.module.scss";
import { toast } from "react-toastify";
import { useResponsive } from "hooks/useResponsive";

interface Inputs {
  state_license_type: PrimaryCredentialType;
  license_number: string;
  license_state: string;
  license_expiration: string | null;
  mental_health_role: string;
  supervisor_name: string;
  supervisor_license_number: string;
  supervisor_license_expiration: string | null;
  supervisor_license_state: string;
  verified_credential_type: string;
  state_license_is_verified: boolean;
  no_license_type?: CredentialType;
  no_license_year: number | null;
  no_license_membership_organization: string;
  no_license_membership_id: string;
  no_license_license_issuer: string;
  no_license_license_number: string;
  no_license_accrediting_institution: string;
  no_license_certificate_number: string;
  no_license_diploma_school_graduated: string;
  no_license_diploma_degree_type: string;
}

const defaultValues: Inputs = {
  license_expiration: null,
  license_number: "",
  license_state: "",
  mental_health_role: "",
  state_license_type: "i_am_licensed",
  supervisor_license_expiration: null,
  supervisor_license_number: "",
  supervisor_license_state: "",
  supervisor_name: "",
  verified_credential_type: "certificate",
  no_license_accrediting_institution: "",
  no_license_certificate_number: "",
  no_license_diploma_degree_type: "",
  no_license_diploma_school_graduated: "",
  no_license_license_issuer: "",
  no_license_license_number: "",
  no_license_membership_id: "",
  no_license_membership_organization: "",
  no_license_type: undefined,
  no_license_year: null,
  state_license_is_verified: false,
};

export const AddStateLicensing = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  useDashboardNavigation(`${id ? "Edit" : "Add"} State Licensing`, {
    goBack: () => navigate("./../", { state: { empty: true } }),
  });
  const { user } = useAuth();
  const { isMobile } = useResponsive();
  const [stateLicense, setStateLicense] = useState<StateLicense | null>(null);
  const { fileUploadProps, handleSubmit: handleSubmitFiles } = useFileUpload(
    "credential",
    ["image", "pdf"],
    {},
    stateLicense?.files || []
  );
  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm<Inputs>({ defaultValues });
  const typeWatcher = watch("state_license_type");

  useEffect(() => {
    if (id && user) {
      stateLicenseService
        .getById(user.id, Number(id))
        .then(setStateLicense)
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id, user]);

  useEffect(() => {
    if (id && stateLicense) {
      reset({ ..._.omitBy(stateLicense, _.isNil) });
    }
  }, [id, stateLicense, reset]);

  const submitHandler: SubmitHandler<Inputs> = async (values) => {
    if (!user) return;

    const data = {
      ..._.omitBy(values, _.isNil),
    };

    try {
      if (stateLicense) {
        await stateLicenseService.patch(user.id, stateLicense.id, data);
        await handleSubmitFiles(undefined, id);
      } else {
        await stateLicenseService
          .create(user.id, data)
          .then(
            async (res) => await handleSubmitFiles(undefined, String(res.id))
          );
      }
      navigate(`${fullProfilePath}/state-licensing/edit`);
      toast("Information has been updated", {
        type: "info",
        autoClose: isMobile ? 3000 : 5000,
        position: isMobile ? "top-center" : "top-right",
      });
    } catch (error) {
      const { data } = error as AxiosResponse;
      goThroughErrors(data, (fieldName, errorMessage) =>
        setError(fieldName as Path<Inputs>, {
          message: errorMessage,
          type: "custom",
        })
      );
    }
  };

  const tabs = () => {
    switch (typeWatcher) {
      case "i_am_licensed":
        return (
          <TherapistStepLicensed
            control={control}
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            id={id}
            isVerified={stateLicense?.is_verified}
          />
        );
      case "i_am_pre_licensed":
        return (
          <TherapistStepPreLicensed
            control={control}
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            id={id}
            isVerified={stateLicense?.is_verified}
          />
        );
      case "i_have_no_license":
        return (
          <TherapistStepNoLicensed
            control={control}
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
          />
        );
      default:
        return null;
    }
  };

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <form className={styles.inner} onSubmit={handleSubmit(submitHandler)}>
        <stepsUi.Row label="Verified Credential">
          <stepsUi.Line>
            {primaryCredentialOptions.map((c) => (
              <Radiobutton
                key={c.value}
                isOutlined
                className={styles.radio}
                {...register("state_license_type")}
                value={c.value}
              >
                {c.label}
              </Radiobutton>
            ))}
          </stepsUi.Line>
          <ErrorMessage>{errors.state_license_type?.message}</ErrorMessage>
        </stepsUi.Row>
        <stepsUi.Row label="Mental Health Role" className={styles.row}>
          <Controller
            control={control}
            name="mental_health_role"
            render={({ field, fieldState: { error } }) => (
              <AppSelect
                error={error?.message}
                placeholder="Select"
                options={mentalHealthRoles}
                value={mentalHealthRoles.find((o) => o.value === field.value)}
                onChange={(nextValue) => {
                  const { value } = nextValue as {
                    label: string;
                    value: string;
                  };
                  setValue("mental_health_role", value);
                }}
              />
            )}
          />
        </stepsUi.Row>
        {tabs()}
        <ControlledFileUpload
          className={styles.upload}
          isSubmitting={isSubmitting}
          accept="image/jpg,image/gif,image/png,image/tiff,image/jpeg,application/pdf"
          multiple
          caption="Drag your documents here to start uploading"
          {...fileUploadProps}
        />
        <Button className={styles.button} type="submit">
          Save
        </Button>
      </form>
    </section>
  );
};
