import { ReactComponent as ToddlerIcon } from "assets/images/icons/toddler.svg";
import { ReactComponent as ChildrenIcon } from "assets/images/icons/children.svg";
import { ReactComponent as TeenIcon } from "assets/images/icons/teen.svg";
import { ReactComponent as AdultIcon } from "assets/images/icons/adult.svg";
import { ReactComponent as ElderIcon } from "assets/images/icons/elder.svg";
import ControlledMultiCardCheckbox, {
  ListOptionWithIcon,
} from "common/components/ControlledMultiCardCheckbox";
import { Control } from "react-hook-form";

export const AGE_OPTIONS: ListOptionWithIcon[] = [
  {
    label: "Toddlers / Preschoolers",
    subLabel: "0-5 years",
    icon: <ToddlerIcon />,
    value: "toddler",
  },
  {
    label: "Children",
    subLabel: "6-10 years",
    icon: <ChildrenIcon />,
    value: "child",
  },
  {
    label: "Preteen",
    subLabel: "10-12 years",
    icon: <TeenIcon />,
    value: "preteen",
  },
  {
    label: "Teen",
    subLabel: "13-17 years",
    icon: <TeenIcon />,
    value: "teen",
  },
  {
    label: "Adults",
    subLabel: "18-65 years",
    icon: <AdultIcon />,
    value: "adult",
  },
  {
    label: "Seniors",
    subLabel: "+65 years",
    icon: <ElderIcon />,
    value: "elder",
  },
];

interface Props {
  className?: string;
  control: Control<any>;
  label?: string;
  name?: string;
}

export const AgeCardCheckbox = ({
  className,
  control,
  name = "ages",
  label = "Client Age:",
}: Props) => {
  return (
    <ControlledMultiCardCheckbox
      ageCards
      className={className}
      name={name}
      control={control}
      options={AGE_OPTIONS}
      label={label}
      compact
    />
  );
};
