import { EditHeader } from "common/components/EditHeader/EditHeader";
import { useNavigate } from "react-router-dom";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import AddButton from "common/UIKit/AddButton";
import { fullProfilePath } from "../../Profile";
import Card from "common/UIKit/Card";
import { ReactComponent as DocumentIcon } from "assets/images/icons/document.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/pencil.svg";
import { useAuth } from "hooks/useAuth";
import { useSnapshot } from "valtio";
import { stateLicenseService } from "services/stateLicenseService";
import { useEffect } from "react";
import { FormatProfileField } from "common/components/FormatProfileField";
import {
  NO_LICENSE_LABEL,
  NO_LICENSE_ORGANIZATION_LABEL,
  NO_LICENSE_ORGANIZATION_NUMBER,
  NO_LICENSE_YEAR,
  NUMBER_FIELD,
  ORGANIZATION_FIELD,
  STATE_LICENSE_LABEL,
} from "constants/fields";
import styles from "./EditStateLicensing.module.scss";

export const pathStateLicensing = "/state-licensing";

export const EditStateLicensing = () => {
  const navigate = useNavigate();
  useDashboardNavigation("Edit State Licensing", {
    goBack: () => navigate(fullProfilePath),
    isNavigationHidden: true,
  });
  const { user } = useAuth();
  const { data } = useSnapshot(stateLicenseService);

  useEffect(() => {
    user && stateLicenseService.list(user.id, undefined, false);
  }, [user]);

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <div className={styles.mobileRow}>
        {data.length && user
          ? data.map((credential) => (
              <Card
                key={credential.id}
                className={styles.item}
                iconClassName={
                  credential.is_verified ||
                  credential.state_license_type === "i_have_no_license"
                    ? ""
                    : styles.noVerified
                }
                values={[
                  {
                    caption: `${
                      credential.is_verified ? "VERIFIED " : ""
                    }CREDENTIAL`,
                    text: (
                      <FormatProfileField maxLength={30}>
                        {credential.no_license_type &&
                        credential.state_license_type === "i_have_no_license"
                          ? NO_LICENSE_LABEL[credential.no_license_type]
                          : STATE_LICENSE_LABEL[credential.state_license_type]}
                      </FormatProfileField>
                    ),
                  },
                  ...(credential.no_license_type &&
                  credential.state_license_type === "i_have_no_license"
                    ? [
                        {
                          caption:
                            NO_LICENSE_ORGANIZATION_LABEL[
                              credential.no_license_type
                            ],
                          text: (
                            <FormatProfileField>
                              {
                                credential[
                                  `no_license_${
                                    ORGANIZATION_FIELD[
                                      credential.no_license_type
                                    ]
                                  }`
                                ]
                              }
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption:
                            NO_LICENSE_ORGANIZATION_NUMBER[
                              credential.no_license_type
                            ],
                          text: (
                            <FormatProfileField>
                              {
                                credential[
                                  `no_license_${
                                    NUMBER_FIELD[credential.no_license_type]
                                  }`
                                ]
                              }
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: NO_LICENSE_YEAR[credential.no_license_type],
                          text: (
                            <FormatProfileField>
                              {credential.no_license_year}
                            </FormatProfileField>
                          ),
                        },
                      ]
                    : []),
                  ...(credential.state_license_type === "i_am_pre_licensed"
                    ? [
                        {
                          caption: "SUPERVISOR’S LICENSE",
                          text: (
                            <FormatProfileField>
                              {credential.supervisor_license_number}
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: "SUPERVISOR’S STATE",
                          text: (
                            <FormatProfileField>
                              {credential.supervisor_license_state}
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: "EXPIRATION",
                          text: (
                            <FormatProfileField>
                              {credential.supervisor_license_expiration}
                            </FormatProfileField>
                          ),
                        },
                      ]
                    : []),
                  ...(credential.state_license_type === "i_am_licensed"
                    ? [
                        {
                          caption: "LICENSE NUMBER",
                          text: (
                            <FormatProfileField>
                              {credential.license_number}
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: "LICENSE STATE",
                          text: (
                            <FormatProfileField>
                              {credential.license_state}
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: "EXPIRATION",
                          text: (
                            <FormatProfileField>
                              {credential.license_expiration}
                            </FormatProfileField>
                          ),
                        },
                      ]
                    : []),
                ]}
                icon={<DocumentIcon />}
                onEdit={() => navigate(String(credential.id))}
                onRemove={() =>
                  stateLicenseService.delete(user.id, credential.id)
                }
                editIcon={<EditIcon />}
                buttonClass={styles.cardButton}
              />
            ))
          : null}
        <AddButton className={styles.addButton} onClick={() => navigate("add")}>
          Add a state licensing
        </AddButton>
      </div>
    </section>
  );
};
