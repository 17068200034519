import { HttpService } from "./httpService";
import { ListResponse } from "types/common.types";
import { tokenService } from "./tokenService";

interface IWithId<TId> {
  id: TId;
}

export type Filters = Record<string, any>;

export type Order = "asc" | "desc";
export type SortOptions = { order?: Order; priority?: number };
export type Sort = Record<string, SortOptions>;

export class ListService<
  TId,
  TEntity extends IWithId<TId>
> extends HttpService {
  protected prefix: string;

  cache: Record<number, TEntity[]> = {}; // cache pages
  cacheToken: string | null = null; // save user token to manage chace

  data: TEntity[] = []; // current page
  totalCount: number = 0;
  pageSize: number = 100;
  pageCount: number = 1;

  isFetching = false;

  filters: Filters = {};
  sort: Sort = {};

  page: number = 1;

  constructor({
    prefix,
    pageSize,
    filters,
    sort,
    ...other
  }: {
    prefix: string;
    pageSize?: number;
    filters?: Filters;
    sort?: Sort;
  }) {
    super(other);

    this.prefix = prefix;
    this.pageSize = pageSize || 100;
    this.filters = filters || {};
    this.sort = sort || {};
  }

  async fetch(useCache = true, otherSize?: number): Promise<TEntity[]> {
    // Check if we cached data for current user
    if (this.cacheToken !== tokenService.get()) {
      this.clearCache();
      this.cacheToken = tokenService.get();
    }

    if (otherSize) {
      this.pageSize = otherSize;
    }
    this.isFetching = true;

    if (!this.cache[this.page] || !useCache) {
      const pagination = this.pageSize
        ? { page: this.page, page_size: this.pageSize }
        : {};

      const ordering = Object.entries(this.sort)
        .sort(([, value1], [, value2]) => {
          if (value1.priority === undefined && value2.priority === undefined) {
            return 0;
          } else if (value1.priority === undefined) {
            return -1;
          } else if (value2.priority === undefined) {
            return 1;
          } else {
            return value1.priority - value2.priority;
          }
        })
        .map(([key, value]) => (value.order === "asc" ? key : `-${key}`));

      const { results, count } = (await this.http.get(`${this.prefix}/`, {
        params: {
          ...pagination,
          ...this.filters,
          ordering,
        },
      })) as ListResponse<TEntity[]>;
      this.cache[this.page] = results;
      this.totalCount = count;
      this.pageCount = Math.ceil(count / this.pageSize);
    }

    this.data = this.cache[this.page];

    this.isFetching = false;
    return this.data;
  }

  setFilters(filters: Filters) {
    this.page = 1;
    this.filters = filters;
    this.cache = {};
    this.fetch();
  }

  setOrder(sortBy: string, options?: SortOptions, clear?: boolean) {
    const entry = this.sort[sortBy];

    if (clear) {
      this.sort = {};
    }

    if (!entry) {
      this.sort[sortBy] = {
        order: options?.order || "desc",
        priority: options?.priority,
      };
    } else if (!options?.order) {
      entry.order = entry.order === "asc" ? "desc" : "asc";
    } else {
      this.sort[sortBy] = options;
    }

    this.page = 1;
    this.cache = {};
    this.fetch();
  }

  clearOrder(sortBy: string, shouldRefetch = true) {
    delete this.sort[sortBy];

    if (shouldRefetch) {
      this.page = 1;
      this.cache = {};
      this.fetch();
    }
  }

  clearAllOrders(shouldRefetch = true) {
    this.sort = {};

    if (shouldRefetch) {
      this.page = 1;
      this.cache = {};
      this.fetch();
    }
  }

  goTo(page: number) {
    if (page < 1 || page > this.pageCount) {
      throw Error("Page is out of boundaries");
    }

    this.page = page;
    this.fetch();
  }

  clearCache() {
    this.cache = {};
    this.pageCount = 1;
    this.page = 1;
  }
}
