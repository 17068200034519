import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useVideoUpload from "hooks/useVideoUpload";
import ControlledFileUpload from "common/components/ControlledFileUpload";
import { BrowseButton } from "common/components/FileUpload";
import Button from "common/UIKit/Button";
import { RecordVideoModal } from "common/components/RecordVideoModal";
import { EditHeader } from "common/components/EditHeader";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { pathPhotosAndVideo } from "../PhotosAndVideos";
import styles from "./VideosEdit.module.scss";

export const VideosEdit = () => {
  const navigate = useNavigate();
  const { goBack } = useDashboardNavigation("Upload or Record a Video", {
    goBack: () => navigate(`./../..${pathPhotosAndVideo}`),
  });
  const {
    existingFilesLogic: { fileUploadProps, handleSubmit },
    recordedVideoLogic,
    modeSwitchingLogic,
  } = useVideoUpload(goBack);

  const RequestRecordingButton = useCallback(
    () => (
      <Button
        className={styles.startRecordingBtn}
        onClick={modeSwitchingLogic.requestRecording}
      >
        Start recording
      </Button>
    ),
    []
  );

  if (modeSwitchingLogic.isRecording) {
    return (
      <RecordVideoModal
        onClose={modeSwitchingLogic.cancelRecording}
        {...recordedVideoLogic}
      />
    );
  }

  return (
    <div className={styles.videosEdit}>
      <EditHeader />
      <form onSubmit={handleSubmit}>
        <ControlledFileUpload
          accept="video/*"
          className={styles.dropContainer}
          actionsContainerClassName={styles.fileUploadActions}
          hideAcceptHint
          caption="Drag files here to start uploading"
          actions={[
            (e) =>
              BrowseButton({
                label: "Upload files",
                startBrowsing: e.startBrowsing,
              }),
            RequestRecordingButton,
          ]}
          {...fileUploadProps}
        />
      </form>
    </div>
  );
};
