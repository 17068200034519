import Button from "common/UIKit/Button";
import macPath from "assets/images/mac.png";
import styles from "./SubmitSection.module.scss";

export const SubmitSection = () => {
  return (
    <section className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.info}>
          <h3 className={styles.title}>
            Submit your application to Therapass today
          </h3>
          <p className={styles.text}>
            We look forward to hearing from you! After receiving your
            application, we'll contact you by email.
          </p>
          <Button className={styles.button} isLink to="/therapist/signup">
            Apply Now
          </Button>
        </div>
        <img
          className={styles.image}
          src={macPath}
          alt="IMac displaying current website"
        />
      </div>
    </section>
  );
};
