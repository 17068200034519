import { stepsFullPath } from "../stepsPath";
import { TherapistFields } from "../util";

export const coveredFields: TherapistFields = [
  "npi_number",
  "malpractice_carrier",
  "membership_id",
  "insurance_companies",
];
export const step7Path = "/7";
export const step7FullPath = `${stepsFullPath}${step7Path}`;
export const step7Caption = "Insurance";
