import { FC } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import cn from "classnames";
import { Tab, TabList, Tabs } from "common/UIKit/Tabs";
import { useResponsive } from "hooks/useResponsive";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";
import { Notifications, notificationsPath } from "./components/Notifications";
import { Payments, paymentsPath } from "./components/Payments";
import { AddNewCard } from "common/pages/AddNewCard";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./Settings.module.scss";

export const settingsPath = "/settings";

const tabs = [notificationsPath, paymentsPath];

const Root: FC = () => {
  useDashboardNavigation("Settings", { goBack: "pop_path" });
  const { isMobile } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();
  useDocumentTitle("TheraPass - Settings");

  const selectedIndex = tabs.findIndex((tab) =>
    tab.includes(location.pathname.split("/").reverse()[0])
  );
  const onTabChange = (index: number) => navigate(`.${tabs[index]}`);

  return (
    <>
      {!isMobile && <h1 className={styles.title}>Settings</h1>}
      <Tabs onSelect={onTabChange} selectedIndex={selectedIndex}>
        <div className={styles.tabs}>
          <TabList className={cn("react-tabs__tab-list", styles.tabList)}>
            <Tab>Notifications</Tab>
            <Tab>Payments</Tab>
          </TabList>
          {!isMobile && selectedIndex === 1 && (
            <div
              className={styles.addNewCard}
              role="button"
              onClick={() => navigate("./add-new-card")}
            >
              <PlusIcon /> <span>Add new credit card</span>
            </div>
          )}
        </div>
      </Tabs>
      <div className={styles.content}>
        <Routes>
          <Route path={notificationsPath} element={<Notifications />} />
          <Route path={paymentsPath} element={<Payments />} />
          <Route path="*" element={<Navigate to={`.${notificationsPath}`} />} />
        </Routes>
      </div>
    </>
  );
};

export const Settings = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.settings}>
      <Routes>
        <Route
          path="/add-new-card"
          element={
            <AddNewCard
              goBack={() => {
                navigate(`.${paymentsPath}`);
              }}
            />
          }
        />
        <Route path="/*" element={<Root />} />
      </Routes>
    </div>
  );
};
