import styles from "./parts.module.scss";
import cn from "classnames";

type BlindFn = () => void;

type RemoveBtnProps = {
  handleRemoveFile: BlindFn;
  disabled?: boolean;
  className?: string;
};

export function RemoveBtn({
  handleRemoveFile,
  disabled,
  className,
}: RemoveBtnProps) {
  return (
    <button
      disabled={disabled}
      onClick={() => handleRemoveFile()}
      type="button"
      className={cn(styles.removeBtn, className, "g-underline")}
    >
      Remove
    </button>
  );
}
