import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { useLocation, useSearchParams } from "react-router-dom";
import { therapistListingService } from "services/therapistListingService";
import { scrollToTop } from "common/utils/async";

export const useTherapistListing = () => {
  const {
    therapists,
    totalCount,
    pageSize,
    currentPage,
    isInitializing,
    isFetching,
    appliedFilters,
    search,
    isError,
  } = useSnapshot(therapistListingService);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCount = Math.ceil(Number(totalCount) / pageSize);
  const state = useLocation().state as null | { [x: string]: any };

  useEffect(() => {
    if (state?.withScroll) {
      scrollToTop();
    }
  }, [state]);

  useEffect(() => {
    therapistListingService.init(searchParams);
  }, []);

  useEffect(() => {
    if (!isInitializing) {
      setSearchParams(
        new URLSearchParams(therapistListingService.getSearchParams())
      );
    }
  }, [isInitializing, currentPage, appliedFilters, setSearchParams]);

  return {
    therapists,
    totalCount,
    isFetching: isInitializing,
    isFetchingList: isFetching,
    pageCount,
    currentPage,
    isError,
    clearFilters: () => therapistListingService.clearFilters(),
  };
};
