import { useEffect, useState } from "react";

import useFileUpload from "./useFileUpload";
import { Nullable } from "common/utils";
import { useStepsLayoutContext } from "pages/Therapists/TherapistSteps/components/TherapistStepsLayout/context";
import { filesService } from "services/filesService";
import { useAuth } from "./useAuth";
import { toast } from "react-toastify";
import { useResponsive } from "./useResponsive";

export const useVideoUpload = (onSuccess?: () => void, onSkip?: () => void) => {
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const existingFilesLogic = useFileUpload(
    "photo_and_video_intro",
    ["photo_and_video_intro", "video"],
    { onSuccess, onSkip, instantRemove: true, instantUpload: true }
  );

  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [recordingResult, setRecordingResult] = useState<Nullable<Blob>>(null);
  const { setProgressDisplayHidden } = useStepsLayoutContext();

  useEffect(() => {
    setProgressDisplayHidden(isRecording);
  }, [isRecording]);

  function uploadRecording() {
    setIsUploading(true);
    user &&
      filesService
        .createFiles(
          user.id,
          [recordingResult as Blob],
          "photo_and_video_intro"
        )
        .then(onSuccess)
        .catch((e) => {
          setError(e);
          toast("Something went wrong. Please try again later.", { type: "error", autoClose: isMobile ? 3000 : 5000, position: isMobile ? "top-center" : "top-right" })
          setIsUploading(false);
        })
        .finally(async () => {
          filesService.isFetched = false;
          await filesService.list(user.id);
        });
  }

  function onRecorded(recording: Blob) {
    if (isRecording) {
      setRecordingResult(recording);
    }
  }

  const recordedVideoLogic = {
    recordingResult,
    onRecorded,
    isUploading,
    uploadRecording,
    error,
  };

  function cancelRecording() {
    setRecordingResult(null);
    setIsRecording(false);
  }

  function requestRecording() {
    setIsRecording(true);
  }

  const modeSwitchingLogic = {
    isRecording,
    requestRecording,
    cancelRecording,
  };

  return {
    existingFilesLogic,
    recordedVideoLogic,
    modeSwitchingLogic,
  };
};

export default useVideoUpload;