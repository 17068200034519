import { useState } from "react";
import { TwilioError } from "twilio-video";
import { VideoProvider } from "providers/VideoProvider";
import { ParticipantProvider } from "providers/ParticipantProvider";
import { VideoWrapper } from "./VideoWrapper";

export const clientVideoPath = "/chat";

export const ClientVideo = () => {
  const [error, setError] = useState<TwilioError | null>(null);

  return (
    <VideoProvider onError={setError}>
      <ParticipantProvider>
        <VideoWrapper />
      </ParticipantProvider>
    </VideoProvider>
  );
};
