import { createContext, Dispatch, SetStateAction } from "react";

export type DashboardNavigationFunction = () => void;

interface IDashboardNavigationContext {
  isMainTab: boolean;
  setIsMainTab: Dispatch<SetStateAction<boolean>>;
  isNavigationHidden: boolean;
  setIsNavigationHidden: Dispatch<SetStateAction<boolean>>;
  tabName: string;
  setTabName: Dispatch<SetStateAction<string>>;
  goBack?: () => void;
  setGoBack: Dispatch<SetStateAction<(() => void) | undefined>>;
  edit?: () => void;
  setEdit: Dispatch<SetStateAction<(() => void) | undefined>>;
}

export const DashboardNavigationContext = createContext<IDashboardNavigationContext>({
  isMainTab: true,
  setIsMainTab: () => {},
  isNavigationHidden: true,
  setIsNavigationHidden: () => {},
  tabName: "",
  setTabName: () => {},
  setGoBack: () => {},
  setEdit: () => {},
});
