import React, { ReactNode, useRef, useState, useEffect } from "react";
import Layout from "common/components/Layout/Layout";
import { TherapistStepsProgress } from "../TherapistStepsProgress";
import { StepsLayoutContext } from "./context";
import styles from "../../TherapistSteps.module.scss";

export const TherapistStepsLayout = ({ children }: { children: ReactNode }) => {
  const [progressDisplayHidden, setProgressDisplayHidden] = useState(false);
  const [height, setHeight] = useState(0);

  const ref = React.createRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [ref]);

  return (
    <Layout
      mainClass={styles.wrapper}
      withoutHeader
      doPadding
      paddingThreshold={900}
    >
      <div className={styles.main} ref={ref}>
        <div className={styles.content}>
          <StepsLayoutContext.Provider
            value={{ progressDisplayHidden, setProgressDisplayHidden }}
          >
            {children}
          </StepsLayoutContext.Provider>
        </div>
      </div>
      {!progressDisplayHidden && (
        <div className={styles.progress}>
          <TherapistStepsProgress contentHeight={height} />
        </div>
      )}
    </Layout>
  );
};
