import { ModalName, modalService } from "services/modalService";
import { useSnapshot } from "valtio";
import { Properties } from "types/modal.types";

export const useModal = (name: ModalName) => {
  const { currentModal, properties } = useSnapshot(modalService);

  const open = () => modalService.open(name);
  const close = () => modalService.close(name);
  const closeAll = () => modalService.closeAll();
  const clearProperties = () => modalService.clearProperties();
  const addProperties = (props: Properties) =>
    modalService.addProperties(props);

  return {
    isOpen: currentModal === name,
    open,
    close,
    addProperties,
    properties,
    clearProperties,
    closeAll,
  };
};
