import React, { useEffect, useRef, useState, MouseEvent as SyntheticMouseEvent } from 'react';
import { UPD_RATE, VideoData } from '../util';
import { useDelayedFn, useMountedState } from 'hooks/misc';
import { Nullable } from 'common/utils';
import styles from './Controls.module.scss';


type Props = {
  totalDuration: Nullable<number>,
  currentTimeData: VideoData<number>
};
type EventTimeData = {
  fullDurationAsPixels: number,
  newTimeAsPixels: number
};

function ProgressBar({ totalDuration, currentTimeData }: Props) {
  const barRef = useRef<HTMLDivElement>(null);
  const throttleControllerRef = useRef<any>(null);
  const { set: setCurrentTime, val: currentTime } = currentTimeData;

  const [tempThumbPosition, setTempThumbPosition] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [throttleHasPassed, setThrottleHasPassed] = useMountedState(true);

  const updateTempThumbPosition = useDelayedFn(setTempThumbPosition, UPD_RATE, 'throttle');

  useEffect(() => {
    const seekerFn = (e: MouseEvent) => {
      const offsetData = getOffset(e);
      updateTempThumbPosition(offsetData.newTimeAsPixels / offsetData.fullDurationAsPixels * 100);
      setCurrentTime(getTime(offsetData));
    }
    const finisherFn = () => setIsSeeking(false);
    if (isSeeking) {
      clearTimeout(throttleControllerRef.current);
      setThrottleHasPassed(false);
      document.addEventListener('mousemove', seekerFn);
      document.addEventListener('mouseup', finisherFn);
    }

    return () => {
      document.removeEventListener('mousemove', seekerFn);
      document.removeEventListener('mouseup', finisherFn);
      throttleControllerRef.current = setTimeout(() => setThrottleHasPassed(true), UPD_RATE);
    };
  }, [isSeeking])

  function getOffset(e: MouseEvent | SyntheticMouseEvent): EventTimeData {
    const barBox = (barRef.current as HTMLDivElement).getBoundingClientRect();
    const fullDurationAsPixels = barBox.width;
    const newTimeAsPixels = e.clientX - barBox.left;
    return { fullDurationAsPixels, newTimeAsPixels };
  }

  function getTime({ newTimeAsPixels, fullDurationAsPixels }: EventTimeData): number {
    const newTimeAsFraction = newTimeAsPixels / fullDurationAsPixels;
    return newTimeAsFraction * (totalDuration as number);
  }

  function getThumbPosition(): string {
    if (totalDuration == null) {
      return '0px';
    }

    return `${isSeeking || !throttleHasPassed ? tempThumbPosition : currentTime / totalDuration * 100}%`;
  }

  return (
    <div
      className={styles.progressBar}
      onClick={e => {
        if (!isSeeking) {
          setCurrentTime(getTime(getOffset(e)));
        }
      }}
      ref={barRef}
    >
      <div className={styles.passedTime} style={{ width: getThumbPosition() }} />
      <div
        className={styles.thumb}
        style={{ left: getThumbPosition() }}
        onMouseDown={() => setIsSeeking(true)}
      />
    </div>
  );
}

export default ProgressBar;