import { StylesConfig } from "react-select";
import { useSnapshot } from "valtio";
import { useRef } from "react";
import { FilterSelect } from "common/UIKit/FilterSelect";
import { therapistListingService } from "services/therapistListingService";
import { useResponsive } from "hooks/useResponsive";
import { useWindowResize } from "hooks/useWindowResize";
import { ListOption } from "constants/options";
import { TherapyList } from "../../components/TherapyList/TherapyList";
import {
  insuranceOptions,
  availabilityOptions,
  priceOptions,
  // milesOptions,
  experienceOptions,
  multiSelectStyles,
  selectStyles,
  selectStylesMobile,
} from "../../helpers/options";
import styles from "../../TherapistsListing.module.scss";
import localStyles from "./Filters.module.scss";
import { InputSmartSearch } from "common/UIKit/InputSmartSearch";
import { Search } from "../Search";

export const DesktopFilters = () => {
  const {
    specialties,
    therapyValue,
    experienceValue,
    priceValue,
    specialtyValue,
    insuranceValue,
    availabilityValue,
  } = useSnapshot(therapistListingService);
  const { isMobile } = useResponsive();
  const selectRow = useRef(null);
  const selectStyle = isMobile ? selectStylesMobile : selectStyles;

  const multiSelectStyle = (rightPos: string, topPos?: string): StylesConfig =>
    isMobile ? selectStylesMobile : multiSelectStyles(rightPos, topPos);

  const onCircleClick = (option: ListOption) => {
    const filterInclude = therapyValue.some(
      (item) => item.value === option.value
    );
    if (filterInclude) {
      therapistListingService.removeFilter("therapyValue", option.value);
    } else {
      therapistListingService.addListFilter("therapyValue", option);
    }
  };

  return (
    <>
      <TherapyList onClick={onCircleClick} />
      {/* {typeof geo === "string" && <p className="error_msg">{geo}</p>} */}

      <Search />

      <form className={styles.listingFilter}>
        <div className={styles.listingFilterRow} ref={selectRow}>
          {/* <FilterSelect
            name="Distance"
            placeholder={isMobile ? "Distance" : "Select distance"}
            label="Distance"
            className={styles.listingFilterSelect}
            value={distanceValue}
            options={milesOptions}
            onChange={(data) => {
              navigator.geolocation.getCurrentPosition(
                (pos) =>
                  therapistListingService.setGeo({
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                  }),
                () => therapistListingService.setGeo("Please, approve geo")
              );
              const option = data as ListOption;
              therapistListingService.setSingleFilter("distanceValue", option);
            }}
            styles={selectStyle}
          /> */}
          <FilterSelect
            name="Specialty"
            placeholder="Specialty"
            placeholderAsLabel
            className={styles.listingFilterSelect}
            options={specialties}
            value={specialtyValue}
            onChange={(data) => {
              therapistListingService.setListFilter(
                "specialtyValue",
                data as ListOption[]
              );
            }}
            isMulti
            menuPortalTarget={!isMobile ? selectRow.current : null}
            styles={multiSelectStyle("0")}
          />
          <FilterSelect
            name="Experience"
            placeholder="Experience"
            placeholderAsLabel
            className={styles.listingFilterSelect}
            options={experienceOptions}
            value={experienceValue}
            onChange={(data) => {
              const option = data as ListOption;
              therapistListingService.setSingleFilter(
                "experienceValue",
                option
              );
            }}
            styles={selectStyle}
          />
          <FilterSelect
            name="Price"
            placeholder="Hourly rate"
            placeholderAsLabel
            options={priceOptions}
            value={priceValue}
            className={styles.listingFilterSelect}
            onChange={(data) => {
              const option = data as ListOption;
              therapistListingService.setSingleFilter("priceValue", option);
            }}
            styles={selectStyle}
          />
          <FilterSelect
            name="Issues"
            isMulti
            placeholder="Issues"
            styles={{
              menu: (base) => ({ ...base, minWidth: "400px" }),
            }}
            placeholderAsLabel
            className={styles.listingFilterSelect}
            options={specialties}
            value={specialtyValue}
            onChange={(data) => {
              therapistListingService.setListFilter(
                "specialtyValue",
                data as ListOption[]
              );
            }}
          />
          {/* <FilterSelect
              menuPortalTarget={selectRow.current}
              styles={multiSelectStyle("50%")}
              isMulti
              placeholder="Cognitive Therapy"
              label="Types of therapy"
              className={styles.listingFilterSelect}
              options={therapies}
              value={null}
              onChange={(data) => {
                const options = data as ListOption[];
                therapistListingService.changeListFilter(
                  "typesValue",
                  options[options.length - 1]
                );
              }}
            /> */}
          <FilterSelect
            name="Insurance"
            placeholder="Insurance"
            placeholderAsLabel
            className={styles.listingFilterSelect}
            options={insuranceOptions}
            value={insuranceValue}
            onChange={(data) => {
              const option = data as ListOption;
              therapistListingService.setSingleFilter("insuranceValue", option);
            }}
            styles={selectStyle}
          />
          <FilterSelect
            name="Modes"
            isMulti
            placeholder="Types of Therapy"
            placeholderAsLabel
            className={styles.listingFilterSelect}
            options={availabilityOptions}
            value={availabilityValue}
            onChange={(data) => {
              therapistListingService.setListFilter(
                "availabilityValue",
                data as ListOption[]
              );
            }}
            styles={selectStyle}
          />
        </div>
      </form>
    </>
  );
};
