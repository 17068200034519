import { EditHeader } from "common/components/EditHeader";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { Controller, SubmitHandler, useForm, Path } from "react-hook-form";
import AppSelect from "common/UIKit/Select/Select";
import { CredentialType, Credential } from "types/credential.types";
import {
  CredentialOption,
  VERIFIED_CREDENTIAL_OPTIONS,
} from "constants/options";
import {
  NUMBER_FIELD,
  NUMBER_LABEL,
  ORGANIZATION_FIELD,
  ORGANIZATION_LABEL,
} from "constants/fields";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { credentialService } from "services/credentialService";
import { goThroughErrors } from "common/utils";
import { fullProfilePath } from "../../Profile";
import { AxiosResponse } from "axios";
import styles from "./AddOtherCredential.module.scss";
import { useResponsive } from "hooks/useResponsive";
import { toast } from "react-toastify";

interface Inputs {
  type: CredentialType;
  year?: number | null;
  membership_organization?: string;
  membership_id?: string;
  license_issuer?: string;
  license_number?: string;
  accrediting_institution?: string;
  certificate_number?: string;
  diploma_school_graduated?: string;
  diploma_degree_type?: string;
}

const defaultValues: Inputs = {
  type: "membership",
  year: null,
  membership_organization: "",
  membership_id: "",
  license_issuer: "",
  license_number: "",
  accrediting_institution: "",
  certificate_number: "",
  diploma_school_graduated: "",
  diploma_degree_type: "",
};

export const AddOtherCredential = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  useDashboardNavigation(`${id ? "Edit" : "Add"} Credential`, {
    goBack: () => navigate("./../", { state: { empty: true } }),
    isNavigationHidden: true,
  });
  const { user } = useAuth();
  const { isMobile } = useResponsive();
  const [credential, setCredential] = useState<Credential | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<Inputs>({ defaultValues });
  const type = watch("type");

  useEffect(() => {
    if (id && user) {
      credentialService
        .getById(user.id, Number(id))
        .then(setCredential)
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id, user]);

  useEffect(() => {
    if (id && credential) {
      reset({
        ...credential,
      });
    }
  }, [id, credential, reset]);

  const submitHandler: SubmitHandler<Inputs> = async (values) => {
    if (!user) return;

    const data = {
      ...values,
      year: values.year ? +values.year : null,
    };

    try {
      credential
        ? await credentialService.patch(user.id, credential.id, data)
        : await credentialService.create(user.id, data);
      navigate(`${fullProfilePath}/other-credentials/edit`);
      toast("Information has been updated", {
        type: "info",
        autoClose: isMobile ? 3000 : 5000,
        position: isMobile ? "top-center" : "top-right",
      });
    } catch (error) {
      const { data } = error as AxiosResponse;
      goThroughErrors(data, (fieldName, errorMessage) =>
        setError(fieldName as Path<Inputs>, {
          message: errorMessage,
          type: "custom",
        })
      );
    }
  };

  const setValues = (v: Partial<Inputs>) => {
    Object.entries(v).forEach(([fName, fVal]) =>
      setValue(fName as keyof Inputs, fVal)
    );
  };

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
        <stepsUi.Grid>
          <stepsUi.Row label="Credential Type">
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <AppSelect
                  placeholder="Select"
                  options={VERIFIED_CREDENTIAL_OPTIONS}
                  value={VERIFIED_CREDENTIAL_OPTIONS.find(
                    (o) => o.value === field.value
                  )}
                  onChange={(nextValue) => {
                    const { value } = nextValue as CredentialOption;
                    setValues({
                      type: value,
                      [ORGANIZATION_FIELD[type]]: "",
                      [NUMBER_FIELD[type]]: "",
                    });
                  }}
                />
              )}
            />
          </stepsUi.Row>
          <stepsUi.Row label={ORGANIZATION_LABEL[type]}>
            <Input
              type="text"
              placeholder=""
              {...register(ORGANIZATION_FIELD[type])}
              error={errors[ORGANIZATION_FIELD[type]]?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.Row label={NUMBER_LABEL[type]}>
            <Input
              type="text"
              placeholder="ID"
              {...register(NUMBER_FIELD[type])}
              optional="OPTIONAL"
            />
          </stepsUi.Row>
          <stepsUi.Row label="Year">
            <Controller
              name="year"
              control={control}
              rules={{
                validate: {
                  nonNegative: (value) =>
                    value === null ||
                    (value && value >= 0) ||
                    "Values must be non-negative",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  placeholder="YYYY"
                  type="text"
                  error={errors.year?.message}
                  maxLength={4}
                  isValid={!error}
                  value={field?.value ? field.value : ""}
                  name={field.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const inputValue = e.target.value;

                    if (!isNaN(+inputValue)) {
                      field.value = inputValue === "" ? 0 : +inputValue;
                      field.onChange(field.value);
                    }
                  }}
                />
              )}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <Button
          className={styles.button}
          type="submit"
        >
          Save
        </Button>
      </form>
    </section>
  );
};
