import { HttpService } from "./httpService";
import { proxy } from "valtio";
import { TestimonialsList } from "types/testimonials.types";
import { ListResponse } from "types/common.types";

class TestimonialsService extends HttpService {
  prefix = "v1/testimonials";
  featuredTestimonials: TestimonialsList = [];

  async getFeaturedTestimonials() {
    try {
      const data = (await this.http.get(
        `${this.prefix}/featured/`
      )) as ListResponse<TestimonialsList>;

      this.featuredTestimonials = data as unknown as TestimonialsList;
      return this.featuredTestimonials;
    } catch (e) {
      return this.featuredTestimonials;
    }
  }
}

export const testimonialsService = proxy(new TestimonialsService());
