import { DashboardLayoutDesktopHeader } from "common/components/DashboardLayout/DesktopHeader";
import { format } from "date-fns";
import { FC, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { useWindowResize } from "hooks/useWindowResize";
import { patientProfileService } from "services/patientProfileService";
import { formatPhone, therapistName } from "common/utils";
import { dictionaryService, DictionaryItem } from "services/dictionaryService";
import { filterDictionaryItems } from "utils/utils";
import {
  AGE_OPTIONS,
  APPOINTMENT_DATE_OPTIONS,
  THERAPY_TYPE_OPTIONS,
} from "constants/options";
import { testAttemptService } from "services/testAttemptService";
import { DashboardLayoutMobileHeader } from "common/components/DashboardLayout/MobileHeader/DashboardLayoutMobileHeader";
import { loginPath } from "../../commonLinks";
import { Avatar } from "common/UIKit/Avatar/Avatar";
import { SessionControls } from "./SessionControls";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./TherapistPatient.module.scss";
import Button from "common/UIKit/Button";
import { clientVideoPath } from "pages/ClientVideo";
import { esaApiEndpoint } from "utils/env";
import { LoadingHOC } from "common/components/LoadingHOC";

export const therapistPatientPath = "/patient";

interface Props {
  showJoinVideoButton?: boolean;
  chatSessionId?: string;
  therapistKey?: string;
  patientId?: string;
}

export const TherapistPatient: FC<Props> = ({
  showJoinVideoButton,
  chatSessionId,
  therapistKey,
  patientId,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { width } = useWindowResize();
  const isSmallWindow = width < 1200;
  const { currentProfile } = useSnapshot(patientProfileService);
  const { data: tests } = useSnapshot(testAttemptService);
  const [issues, setIssues] = useState<DictionaryItem[]>([]);
  const [languages, setLanguages] = useState<DictionaryItem[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [numParticipants, setNumParticipants] = useState(0);

  // showJoinVideoButton = true;
  // chatSessionId = "E1E3415E-622A-409E-B827-B00300D00CF4";
  // therapistKey = "christian-starpoli";
  // patientId = "VqYKXvL";

  useDocumentTitle(
    `${therapistName({
      first_name: currentProfile?.user.first_name,
      last_name: currentProfile?.user.last_name,
    })} Patient Details`
  );

  const ptId = id || patientId;

  useEffect(() => {
    (async () => {
      if (!ptId) return;
      try {
        await patientProfileService.getById(ptId);
        await testAttemptService.list(false, {
          patient_id: ptId,
          page_size: 100,
          status: "finished",
        });
      } catch (error) {
        const { status } = error as AxiosResponse;
        if ([403, 404].includes(status)) {
          navigate("/error");
          return;
        }
        if (status === 401) {
          localStorage.setItem("redirect_to", window.location.pathname);
          navigate({
            pathname: `/therapist${loginPath}`,
            search: `?redirect_to=${window.location.pathname}`,
          });
          return;
        }

        throw error;
      }
    })();
    // eslint-disable-next-line
  }, [ptId]);

  useEffect(() => {
    (async () => {
      const [issues, languages] = await Promise.all([
        dictionaryService.getIssues(),
        dictionaryService.getLanguages(),
      ]);
      setIssues(filterDictionaryItems(issues, currentProfile?.issues));
      setLanguages(
        filterDictionaryItems(languages, currentProfile?.preferred_languages)
      );
    })();
  }, [currentProfile]);

  useEffect(() => {
    axios
      .get(
        `${esaApiEndpoint}/videoSession/GetParticpants/GetParticpants/${chatSessionId}`
      )
      .then((data) => {
        if (data.data.Participants) {
          setNumParticipants(data.data.Participants.length);
        }
      });
  });

  const onJoin = () => {
    navigate(
      {
        pathname: clientVideoPath,
        search: `?SessionID=${chatSessionId}&patientId=${patientId}&therapistKey=${therapistKey}&isTherapist=${true}`,
      },
      {
        replace: true,
      }
    );
  };

  const onApprove = () => {
    const payload = JSON.stringify({
      CallCompleteAction: 0,
      SessionID: chatSessionId,
      Notes: "",
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setIsUpdating(true);
    axios
      .post(`${esaApiEndpoint}/therapist/callcomplete`, payload, meta)
      .then((response) => {
        console.log(response);
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log(error);
      });
  };

  const onDeny = () => {
    const payload = JSON.stringify({
      CallCompleteAction: 1,
      SessionID: chatSessionId,
      Notes: "",
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setIsUpdating(true);
    axios
      .post(`${esaApiEndpoint}/therapist/callcomplete`, payload, meta)
      .then((response) => {
        console.log(response);
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log(error);
      });
  };

  const onReschedule = () => {
    const payload = JSON.stringify({
      CallCompleteAction: 2,
      SessionID: chatSessionId,
      Notes: "",
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setIsUpdating(true);
    axios
      .post(`${esaApiEndpoint}/therapist/callcomplete`, payload, meta)
      .then((response) => {
        console.log(response);
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log(error);
      });
  };

  return (
    <>
      <LoadingHOC isLoading={isUpdating}>
        <div className={styles.root}>
          {!isSmallWindow ? (
            <DashboardLayoutDesktopHeader withLogo className={styles.logo} />
          ) : (
            <DashboardLayoutMobileHeader tabs={[]} />
          )}
          <section className={styles.wrapper}>
            <h1 className={styles.title}>Patient Details</h1>
            <div className={styles.container}>
              <div className={styles.row}>
                <div className={styles.info}>
                  <Avatar
                    className={styles.img}
                    file={
                      currentProfile?.file ? currentProfile.file : undefined
                    }
                    alt={therapistName({
                      first_name: currentProfile?.user.first_name,
                      last_name: currentProfile?.user.last_name,
                    })}
                  />
                  <div className={styles.userInfo}>
                    <h4 className={styles.userTitle}>
                      {therapistName({
                        first_name: currentProfile?.user.first_name,
                        last_name: currentProfile?.user.last_name,
                      })}
                    </h4>
                    <p className={styles.userAge}>
                      {currentProfile?.age &&
                        AGE_OPTIONS.find(
                          (age) => age.value === currentProfile.age
                        )?.label}
                    </p>
                    {showJoinVideoButton && chatSessionId && (
                      <>
                        <div className={styles.buttonrow}>
                          <Button onClick={onJoin}>Join Call</Button>
                          <Button onClick={onApprove}>Approve</Button>
                          <Button onClick={onDeny}>Decline</Button>
                          <Button onClick={onReschedule}>Reschedule</Button>
                        </div>
                        <p className={styles.userAge}>
                          {numParticipants ? numParticipants : 0}{" "}
                          {numParticipants < 1 ? "participant" : "participants"}{" "}
                          in the room
                        </p>
                      </>
                    )}
                  </div>
                  <div className={styles.userInfo}></div>
                </div>
                {width > 992 && <SessionControls />}
              </div>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <h6 className={styles.listTitle}>Email</h6>
                  <p className={styles.listText}>
                    <a href={`mailto:${currentProfile?.user.email}`}>
                      {currentProfile?.user.email}
                    </a>
                  </p>
                </li>
                <li className={styles.listItem}>
                  <h6 className={styles.listTitle}>Phone Number</h6>
                  <p className={styles.listText}>
                    <a href={`tel:${currentProfile?.user.mobile_phone}`}>
                      {formatPhone(currentProfile?.user.mobile_phone)}
                    </a>
                  </p>
                </li>
                <li className={styles.listItem}>
                  <h6 className={styles.listTitle}>
                    When would you like to speak to a therapist?
                  </h6>
                  <p className={styles.listText}>
                    {currentProfile?.appointment_date &&
                      APPOINTMENT_DATE_OPTIONS.find(
                        (date) => date.value === currentProfile.appointment_date
                      )?.label}
                  </p>
                </li>
                <li className={styles.listItem}>
                  <h6 className={styles.listTitle}>Language</h6>
                  <p className={styles.listText}>
                    {languages.map((language) => language.name).join(", ")}
                  </p>
                </li>
                <li className={styles.listItem}>
                  <h6 className={styles.listTitle}>
                    I’d like to do therapy via:
                  </h6>
                  <p className={styles.listText}>
                    {THERAPY_TYPE_OPTIONS.filter((option) =>
                      currentProfile?.therapy_types.includes(option.value)
                    )
                      .map((item) => item.label)
                      .join(", ")}
                  </p>
                </li>
                <li className={styles.listItem}>
                  <h6 className={styles.listTitle}>
                    I would prefer a therapist with experience in
                  </h6>
                  <p className={styles.listText}>
                    {issues.map((issue) => issue.name).join(", ")}
                  </p>
                </li>
              </ul>
            </div>
            {width <= 992 && <SessionControls />}
            <div className={styles.container}>
              <h2 className={styles.lead}>Tests Taken</h2>
              {tests.length ? (
                <ul className={styles.testList}>
                  {tests.map((test) => (
                    <li
                      className={styles.testItem}
                      key={test.id}
                      onClick={() => navigate(`test/${test.id}`)}
                    >
                      <div className={styles.testRow}>
                        <b>Survey:</b> {test.test.name}
                      </div>
                      <div className={styles.testRow}>
                        <b>Score:</b> {test.result}
                      </div>
                      <div className={styles.testRow}>
                        <b>Completed:</b>{" "}
                        {test.finished_at &&
                          format(
                            new Date(test.finished_at),
                            "MMM dd, yyyy h:mma"
                          )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className={styles.noTests}>
                  This patient has no test results on file.
                </p>
              )}
            </div>
          </section>
        </div>
        {/*<Footer />*/}
      </LoadingHOC>
    </>
  );
};
