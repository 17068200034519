import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { parseISO } from "date-fns";
import { CrudService } from "./crudService";
import {
  BuySubscriptionValues,
  Subscription,
  SubscriptionId,
} from "types/subscription.types";
import { CreateCreditCardData, CreditCardId } from "types/creditCards.types";
import { authService } from "./authService";
import { creditCardService } from "./creditCardService";

class SubscriptionService extends CrudService<SubscriptionId, Subscription> {
  constructor(args?: any) {
    super({
      ...args,
      prefix: "/v1/subscriptions",
    });
  }

  async updateSubscriptions() {
    await this.list(false);
    await authService.getCurrentUser();
  }

  async cancel() {
    const res = (await this.http.post(
      `${this.prefix}/cancel/`
    )) as Subscription;
    this.data = this.data.map((subscription) =>
      subscription.id === res.id ? res : subscription
    );
    await this.updateSubscriptions();
    return res;
  }

  async changeCard(values: { bank_card: CreditCardId }) {
    const res = (await this.http.post(
      `${this.prefix}/${this.activeSubscription?.id}/change-card/`,
      values
    )) as Subscription;
    this.data = this.data.map((subscription) =>
      subscription.id === res.id ? res : subscription
    );
    return res;
  }

  async buyWithNewCard(values: CreateCreditCardData & BuySubscriptionValues) {
    const res = (await this.http.post(
      `${this.prefix}/buy/new-card/`,
      values
    )) as Subscription;
    this.data.push(res);
    await creditCardService.list(false);
    await this.updateSubscriptions();
    return res;
  }

  async buyWithSavedCard(
    values: { bank_card: CreditCardId } & BuySubscriptionValues
  ) {
    const res = (await this.http.post(
      `${this.prefix}/buy/saved-card/`,
      values
    )) as Subscription;
    this.data.push(res);
    await this.updateSubscriptions();
    return res;
  }

  // TODO: check if it's save as `currentSubscription`
  public get activeSubscription() {
    return this.data.find(
      (subscription) =>
        subscription.status === "active" && !subscription.cancelled_at
    );
  }

  public get activeSubscriptions() {
    return this.data
      .filter((subscription) => subscription.status === "active")
      .sort(
        (subscription1, subscription2) =>
          parseISO(subscription1.next_payment_at).getTime() -
          parseISO(subscription2.next_payment_at).getTime()
      );
  }

  public get currentSubscription(): Subscription | undefined {
    return this.activeSubscriptions[0];
  }
}

export const subscriptionService = proxy(new SubscriptionService());
subscriptionService.init();

// devtools(subscriptionService, { name: "subscriptionService", enabled: true });
