import { Modal } from "common/components/Modal";
import { useModal } from "hooks/useModal";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import Button from "common/UIKit/Button";
import Radiobutton from "common/UIKit/Radiobutton/Radiobutton";
import {
  UseFormRegister,
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";
import { useEffect } from "react";
import { RateCard } from "types/rateCards.types";
import styles from "./RateChooseType.module.scss";

interface Props {
  errors: FieldErrors;
  register: UseFormRegister<RateCard>;
  setError: UseFormSetError<RateCard>;
  type?: string;
  clearErrors: UseFormClearErrors<RateCard>;
  setValue: UseFormSetValue<RateCard>;
  isDirty: boolean;
}

export const RateChooseType = ({
  errors,
  register,
  setError,
  type,
  clearErrors,
  setValue,
  isDirty,
}: Props) => {
  const { isOpen, close, closeAll } = useModal("RATE_CARD_TYPE");
  const { open: openDetailsModal } = useModal("RATE_CARD_DETAILS");

  useEffect(() => {
    if (type) {
      clearErrors();
    }
  }, [type, clearErrors]);

  const onNextButton = () => {
    if (!type) {
      return setError("type", { message: "Field is required." });
    }

    if (isDirty) {
      setValue("get_free_count", 0);
      setValue("pay_sessions_count", 0);
      setValue("fee", "");
    }

    openDetailsModal();
    close();
  };

  return (
    <Modal className={styles.wrapper} visible={isOpen} onClose={closeAll}>
      <div className={styles.root}>
        <button className={styles.closeButton} onClick={closeAll}>
          <CloseIcon />
        </button>
        <h1 className={styles.title}>Add a rate card</h1>
        <p className={styles.subtitle}>
          Step 1 - Choose the type of a rate card:
        </p>
        {errors.type && <ErrorMessage>{errors.type?.message}</ErrorMessage>}
        <div className={styles.formRow}>
          <Radiobutton
            className={styles.radio}
            text="Special"
            value="special"
            {...register("type")}
          />
          <Radiobutton
            className={styles.radio}
            text="Hourly"
            value="hourly"
            {...register("type")}
          />
          <Radiobutton
            className={styles.radio}
            text="Weekly"
            value="weekly"
            {...register("type")}
          />
          <Radiobutton
            className={styles.radio}
            text="Monthly"
            value="monthly"
            {...register("type")}
          />
        </div>
        <Button className={styles.button} type="button" onClick={onNextButton}>
          Next
        </Button>
      </div>
    </Modal>
  );
};
