import { FC } from "react";
import styles from "./ProgressCircle.module.scss";

interface Props {
  progress: number;
  width?: number;
  height?: number;
  fontSize?: number;
  strokeWidth?: number;
}

export const ProgressCircle: FC<Props> = ({
  progress,
  width = 114,
  height = 114,
  fontSize = 30,
  strokeWidth = 13,
}) => {
  const strokeDasharray = `${Math.PI * progress} ${Math.PI * (100 - progress)}`;

  return (
    <div className={styles.progressCircle}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <circle
          r={Math.round(width / 2.4)}
          cx={Math.round(width / 2)}
          cy={Math.round(width / 2)}
        />
        <circle
          r={Math.round(width / 2.4)}
          cx={Math.round(width / 2)}
          cy={Math.round(width / 2)}
          className={styles.progressAccent}
          style={{
            strokeDasharray,
            strokeWidth,
          }}
        />
      </svg>
      <h1 style={{ fontSize: `${fontSize}px` }}>{progress}%</h1>
    </div>
  );
};
