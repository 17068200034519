import { useEffect } from "react";
import { stepsUi } from "../components";
import Input from "common/UIKit/Input";
import { useTherapistStep7 } from "./useTherapistStep7";
import { npiNumberValidator } from "common/validators/insuranceValidators";
import ControlledMultiSelect from "common/components/ControlledMultiSelect";
import styles from "./TherapistStep7.module.scss";

export function TherapistStep7() {
  const {
    register,
    handleSubmit,
    control,
    errors,
    actionsProps,
    isProcessing,
    generalInsuranceCompanies,
    additionalInsuranceCompanies,
    watch,
    setError,
  } = useTherapistStep7();

  const npiNumber = watch("npi_number");

  useEffect(() => {
    setError("npi_number", {});
  }, [npiNumber]);

  return (
    <>
      <stepsUi.Header>Insurance</stepsUi.Header>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <stepsUi.Grid>
          <stepsUi.Row
            label="Health Insurance"
            subLabel="If you're in-network with an insurance company, select it here"
          >
            <ControlledMultiSelect
              name="general_insurance_companies"
              control={control}
              options={generalInsuranceCompanies}
              isDisabled={isProcessing}
              placeholder="Select"
            />
          </stepsUi.Row>
          <stepsUi.Row
            label="Other In-Network Insurance Companies"
            subLabel="Add insurance company"
          >
            <ControlledMultiSelect
              name="additional_insurance_companies"
              control={control}
              options={additionalInsuranceCompanies}
              isDisabled={isProcessing}
              placeholder="Select"
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <h2 className={styles.editInsuranceTitle}>Professional Insurance</h2>
        <stepsUi.Grid>
          <stepsUi.Row
            label="NPI Number"
            subLabel="Your 10 digit National Provider Identifier"
          >
            <Input
              type="text"
              placeholder=""
              maxLength={10}
              {...register("npi_number", npiNumberValidator)}
              error={errors.npi_number?.message}
            />
          </stepsUi.Row>
          <stepsUi.Row
            label="Malpractice Carrier"
            className={styles.malpractice}
          >
            <Input
              className={styles.inputCarrier}
              type="text"
              placeholder=""
              {...register("malpractice_carrier")}
              error={errors.malpractice_carrier?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Membership ID/Number"
              {...register("membership_id")}
              error={errors.membership_id?.message}
              optional="OPTIONAL"
            />
          </stepsUi.Row>
        </stepsUi.Grid>
      </stepsUi.Form>
    </>
  );
}
