import { ReactNode } from "react";
import cn from "classnames";
import styles from "../../../Therapists/TherapistSteps/TherapistSteps.module.scss";

type Props = {
  children: ReactNode | string;
  className?: string;
  label?: string;
  subLabel?: string;
}

export const PatientStepsRow = ({
  children,
  className,
  label,
  subLabel,
}: Props) => (
  <div className={cn("patient-step__row-layout", className)}>
    {label && <div className="patient-step__row-title">{label}</div>}
    {subLabel && <div className="patient-step__row-subtitle">{subLabel}</div>}
    {children}
  </div>
)

