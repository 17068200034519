import React from "react";
import clsx from "clsx";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import PinIcon from "./PinIcon/PinIcon";
import sytles from "./ParticipantInfo.module.scss";
import { Typography } from "@mui/material";
import AvatarIcon from "icons/AvatarIcon";
import usePublications from "hooks/usePublications";
import useIsTrackSwitchedOff from "hooks/useIsTrackSwitchedOff";
import useTrack from "hooks/useTrack";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";

const borderWidth = 2;

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const videoPublication = publications.find(
    (p) => !p.trackName.includes("screen") && p.kind === "video"
  );

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  return (
    <div
      className={clsx(sytles.container, {
        [sytles.hideParticipant]: hideParticipant,
        [sytles.cursorPointer]: Boolean(onClick),
        [sytles.dominantSpeaker]: isDominantSpeaker,
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      <div className={sytles.infoContainer}>
        <NetworkQualityLevel participant={participant} />
        <div className={sytles.infoRowBottom}>
          <span className={sytles.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Typography
              variant="body1"
              className={sytles.typography}
              component="span"
            >
              {participant.identity}
              {isLocalParticipant && " (You)"}
            </Typography>
          </span>
        </div>
        <div>{isSelected && <PinIcon />}</div>
      </div>
      <div className={sytles.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div className={sytles.avatarContainer}>
            <AvatarIcon />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
