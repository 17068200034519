import { isEmpty, omitBy } from "lodash";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { HttpService } from "./httpService";

type VerifyAnswer = boolean;

class PhoneVerifyService extends HttpService {
  private endpoint = "/v1/phone/";
  currentPhone = "";
  newPhone = "";
  isVerified = false;
  consent = false;

  async resendCode(mobile_phone: string, type: string) {
    await this.http.post(`${this.endpoint}resend/`, {
      mobile_phone,
      type,
    });

    return this;
  }

  async confirmPhone(mobile_phone: any, pin: any, type: any) {
    this.currentPhone = mobile_phone;
    const formData = {
      mobile_phone,
      pin,
      ...(type && {
        type: type,
      }),
    };
    const verifiedFlag = (await this.http.post(
      `${this.endpoint}verify/`,
      formData
    )) as VerifyAnswer;
    this.isVerified = verifiedFlag;
    return this.isVerified;
  }

  async changePhone(
    user_id: string | null,
    mobile_phone: string,
    type: string
  ) {
    await this.http.post(
      `${this.endpoint}change/`,
      omitBy(
        {
          user_id,
          mobile_phone,
          old_mobile_phone: this.currentPhone,
          type,
        },
        isEmpty
      )
    );
    this.currentPhone = mobile_phone;
    return this.currentPhone;
  }

  async getConsent() {
    try {
      await this.http.post(`/v1/consent/confirm/`, {
        phone: this.currentPhone,
      });
      this.consent = true;
      return this.consent;
    } catch (e: any) {
      this.consent = e.errors[0];
      return this.consent;
    }
  }
}

export const phoneVerifyService = proxy(new PhoneVerifyService());
devtools(phoneVerifyService, { name: "phoneVerifyService", enabled: true });
