import { FC, useMemo } from "react";
import { Controller } from "react-hook-form";
import { TestRadio } from "types/testProcess.types";
import Checkbox from "common/UIKit/Checkbox";
import { useTest } from "hooks/useTest";
import styles from "./Radio.module.scss";

interface Props {
  question: TestRadio;
  disabled?: boolean;
  questionsAnswered?: Map<string, boolean>;
  setquestionsAnswered?: React.Dispatch<
    React.SetStateAction<Map<string, boolean>>
  >;
}

export const Radio: FC<Props> = ({
  question,
  disabled,
  questionsAnswered,
  setquestionsAnswered,
}) => {
  const { control, errors } = useTest();

  return (
    <Controller
      control={control}
      name={question.id.toString()}
      rules={{
        required: {
          value: question.required,
          message: "This field is required",
        },
      }}
      render={({ field: { value, onChange, ref } }) => (
        <div className={styles.root}>
          <div className="row gutter-12 justify-content-center">
            {question.options.map((option) => (
              <div className="d-flex" key={option.value}>
                <Checkbox
                  className="mb-10"
                  size="sm"
                  id={`${question.id}-${option.value}`}
                  value={option.value}
                  type="radio"
                  checked={option.value === value}
                  onChange={() => {
                    onChange(option.value);
                    if (setquestionsAnswered) {
                      questionsAnswered?.set(question.key, true);
                      questionsAnswered &&
                        setquestionsAnswered(questionsAnswered);
                    }
                  }}
                  ref={ref}
                  disabled={disabled}
                  styleVersion="bordered-block"
                  label={option.label}
                >
                  {option.label}
                </Checkbox>
              </div>
            ))}
          </div>

          {errors[question.id] && (
            <p className="invalid-feedback">{errors[question.id]?.message}</p>
          )}
        </div>
      )}
    />
  );
};
