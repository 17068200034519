import { ReactNode } from "react";
import { Control, useController } from "react-hook-form";
import { ListOption } from "constants/options";
import IconCheckboxCard from "../../UIKit/IconCheckboxCard/IconCheckboxCard";
import styles from "./ControlledMultiCardCheckbox.module.scss";
import cn from "classnames";
import { omit } from "lodash";
import { ErrorMessage } from "common/UIKit/ErrorMessage";

export type ListOptionWithIcon = ListOption & {
  icon?: ReactNode;
  subLabel?: string;
};

type Props = {
  name: string;
  control: Control<any>;
  label?: string;
  rules?: object;
  options: ListOptionWithIcon[];
  className?: string;
  compact?: boolean;
  ageCards?: boolean;
};

function ControlledMultiCardCheckbox({
  options,
  label,
  name,
  control,
  rules,
  className,
  compact,
  ageCards,
}: Props) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  return (
    <div className={cn(styles.wrapper, className)}>
      {!!label && <label className={styles.groupLabel}>{label}</label>}
      <div className={styles.inner}>
        <div
          className={cn(
            ageCards ? styles.cardsContainerAge : styles.cardsContainer,
            compact && styles.cardsContainer_compact
          )}
        >
          {options.map((option) => {
            const isSelected = value && value.includes(option.value);
            return (
              <IconCheckboxCard
                className={cn(
                  ageCards ? styles.singleCardAge : styles.singleCard
                )}
                key={option.value}
                compact={compact}
                checked={isSelected}
                {...omit(option, "value")}
                onChange={() =>
                  onChange(
                    isSelected
                      ? value.filter(
                          (v: typeof option.value) => v !== option.value
                        )
                      : [...value, option.value]
                  )
                }
              />
            );
          })}
        </div>
      </div>
      <ErrorMessage>{error?.message}</ErrorMessage>
    </div>
  );
}

ControlledMultiCardCheckbox.defaultProps = {
  compact: false,
};

export default ControlledMultiCardCheckbox;
