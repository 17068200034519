import { FC, useContext } from "react";
import cn from "classnames";
import AddButton from "common/UIKit/AddButton";
import { DashboardNavigationContext } from "contexts/DashboardNavigationContext";
import styles from "./EditHeader.module.scss";

interface Props {
  className?: string;
  addButton?: string;
  onAddButton?: () => void;
  withoutBack?: boolean;
}

export const EditHeader: FC<Props> = ({
  className,
  addButton,
  onAddButton,
  withoutBack,
}) => {
  const { tabName, goBack } = useContext(DashboardNavigationContext);

  return (
    <div className={cn(styles.editHeader, className)}>
      {!withoutBack && goBack && (
        <button className={styles.goBackButton} onClick={goBack}>
          <div className={styles.angle} />
        </button>
      )}
      <h1>{tabName}</h1>
      {addButton && (
        <AddButton className={styles.addButton} onClick={onAddButton}>
          {addButton}
        </AddButton>
      )}
    </div>
  );
};
