import { FC } from "react";
import Layout from "common/components/Layout/Layout";
import classNames from "classnames";
import { Logo } from "common/components/Header";
import photoChatImage from "assets/images/peoples/photo-chat.png";
import "./AuthLayout.scss";
import cn from "classnames";

interface Props {
  signTitle?: string;
  signSupTitle?: string;
  signPostTitle?: string;
  signRightTitle?: string;
  isTherapist?: boolean;
  withoutCircle?: boolean;
  className?: string;
  containerClassName?: string;
  withoutLogo?: boolean;
  wrapperClassname?: string;
  logoClassName?: string;
  error?: string;
}

export const AuthLayout: FC<Props> = (props) => {
  const {
    signTitle,
    signSupTitle,
    signPostTitle,
    children,
    signRightTitle,
    isTherapist,
    withoutCircle,
    className,
    containerClassName,
    withoutLogo,
    wrapperClassname,
    logoClassName,
    error,
  } = props;
  const titleClass = classNames(
    "sign-title__caption title",
    withoutCircle && "sign-title__caption--uncircle"
  );
  const mainClass = classNames("sign", className);
  const containerClass = classNames("sign-container", containerClassName);

  return (
    <Layout withoutHeader mainClass={mainClass}>
      <section className={containerClass}>
        {!withoutLogo && (
          <Logo className={cn("sign-logo", logoClassName && logoClassName)} />
        )}
        <div className={wrapperClassname || "sign-wrapper"}>
          {signTitle && (
            <div className="sign-title">
              <h1 className={titleClass}>{signTitle}</h1>
              <span className="sign-title__suptitle">{signSupTitle}</span>
            </div>
          )}
          {error && <h3 className="sign-title__posttitle">{error}</h3>}
          {signPostTitle && (
            <h3 className="sign-title__posttitle">{signPostTitle}</h3>
          )}
          {children}
        </div>
      </section>
      <section className="sign-right-block">
        <h2
          className={`sign-right-block__text title${
            isTherapist ? ` title-therapist` : ""
          }`}
        >
          {signRightTitle}
        </h2>
        <img src={photoChatImage} alt="chat" className="photo-chat" />
      </section>
    </Layout>
  );
};
