import { useEffect, useState } from "react";
import { useModal } from "hooks/useModal";
import { emailService } from "services/emailService";
import { useForm, SubmitHandler } from "react-hook-form";
import { AxiosResponse } from "axios";
import { useTimer } from "hooks/useTimer";
import { goThroughErrors } from "common/utils";

interface Config {
  email: string;
}

export default function useChangeEmail() {
  const { isOpen, close: closeEmailModal } = useModal("CHANGE_EMAIL");
  const { open: openEmailModal } = useModal("CHANGE_EMAIL_VERIFICATION");
  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm<Config>();
  const { seconds, setSeconds, on } = useTimer();

  useEffect(() => {
    (async () => {
      if (isOpen) {
      }
    })();
  }, [isOpen]);

  const onClose = () => closeEmailModal();

  const onSave: SubmitHandler<Config> = async (data) => {
    setEmailErrors([]);
    try {
      await emailService.changeEmail(data);
      emailService.newEmail = data.email;
      closeEmailModal();
      openEmailModal();
    } catch (error) {
      const { data, status } = error as AxiosResponse;

      goThroughErrors(
        data,
        (fieldName, message) =>
          fieldName === "email" && setError("email", { message })
      );
      const errors = data?.errors as string[] | undefined;
      const errorWithTime =
        status === 429 && errors?.find((error) => error.match(/second/g));

      if (errorWithTime) {
        const time = Number(errorWithTime?.replace(/\D/g, ""));
        setEmailErrors([errorWithTime]);
        setSeconds(time);
        on();
      } else {
        setEmailErrors(errors?.length ? errors : [`Error ${status}`]);
      }
    }
  };

  useEffect(() => {
    if (seconds) {
      setError("email", {
        message: emailErrors.join("").replace(/\d{1,2}/g, seconds.toString()),
      });
    } else {
      clearErrors("email");
    }
  }, [seconds]);

  return {
    isOpen,
    onClose,
    onSubmit: handleSubmit(onSave),
    register,
    errors,
  };
}
