import { FC, ElementType } from "react";
import cn from "classnames";
import styles from "./Modality.module.scss";

interface Props {
  label: string;
  className?: string;
  withoutText?: boolean;
  Icon: ElementType;
}

export const Modality: FC<Props> = ({
  label,
  className,
  withoutText,
  Icon,
}) => {
  return (
    <div className={cn(styles.modality, className)}>
      <div className={styles.modalityIcon}>
        <Icon />
      </div>
      <span
        className={cn(
          styles.modalityText,
          withoutText && styles.modalityTextHidden
        )}
      >
        {label}
      </span>
    </div>
  );
};
