import { CrudService } from "./crudService";
import { proxy } from "valtio";
import {
  Therapist,
  TherapistId,
  TherapistList,
  TherapistStats,
} from "types/therapist.types";

export class TherapistService extends CrudService<TherapistId, Therapist> {
  private static statsEndpoint = "/stats";
  therapists: TherapistList = [];
  stats: TherapistStats = {
    email: 0,
    profile_completion: 0,
    sms: 0,
    total_contacts: 0,
  };

  constructor(args?: any) {
    super({
      ...args,
      prefix: "/v1/therapists",
    });
  }

  async getStatsById(therapistId: TherapistId) {
    const data = (await this.http.get(
      `${this.prefix}/${therapistId}${TherapistService.statsEndpoint}/`
    )) as TherapistStats;

    return (this.stats = data);
  }
}

export const therapistService = proxy(new TherapistService());
therapistService.init();
