import { useEffect, useMemo, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { AgeCode, Ethnicity, Faith, TherapyType } from "constants/options";
import {
  AppointmentDate,
  PreferredPrice,
  PreferredSexuality,
  TherapistPreference,
} from "types/patient.types";
import { PatientFields } from "pages/Patients/PatientSteps/util";
import { useProfileForm } from "../useProfileForm";
import { DictionaryItem, dictionaryService } from "services/dictionaryService";

export type Inputs = {
  zip_code: string;
  therapy_types: TherapyType[];
  issues: string[];
  therapist_preferences: TherapistPreference[];
  age: AgeCode;
  preferred_ethnicities: Ethnicity;
  preferred_price: PreferredPrice;
  preferred_sexualities: PreferredSexuality[];
  preferred_languages: string[];
  preferred_faith: Faith;
  appointment_date: AppointmentDate;
};

const coveredFields: PatientFields = [
  "zip_code",
  "therapy_types",
  "issues",
  "therapist_preferences",
  "age",
  "preferred_price",
  "preferred_ethnicities",
  "preferred_sexualities",
  "preferred_languages",
  "preferred_faith",
  "appointment_date",
];

export type EditPatientInformationForm = {
  register: UseFormRegister<Inputs>;
  control: Control<Inputs>;
  errors: FieldErrors<Inputs>;
  languages: DictionaryItem[];
  issues: {
    data: DictionaryItem[];
    searchString: string;
    setSearchString: (s: string) => void;
  };
};

export const useEditPatientInformation = (onSuccess?: () => void) => {
  const [issues, setIssues] = useState<DictionaryItem[]>([]);
  const [languages, setLanguages] = useState<DictionaryItem[]>([]);
  const [searchString, setSearchString] = useState("");

  const foundIssues = useMemo(
    () =>
      issues.filter(
        ({ name }) =>
          !searchString ||
          name.toLocaleLowerCase().includes(searchString.toLowerCase())
      ),
    [issues, searchString]
  );

  useEffect(() => {
    dictionaryService.getIssues().then(setIssues);
    dictionaryService.getLanguages().then(setLanguages);
  }, []);

  const formMethods = useProfileForm<Inputs>(coveredFields, onSuccess);

  return {
    ...formMethods,
    issues: { data: foundIssues, searchString, setSearchString },
    languages,
  };
};
