import { useState } from "react";
import cn from "classnames";
import PhoneInput from "./react-phone-input-2";
import { Control, Controller, ValidationRule, Message } from "react-hook-form";
import "./react-phone-input-2/lib/style.css";

import styles from "./InputPhone.module.scss";

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface Props {
  className?: string;
  label?: string;
  id?: string;
  control: Control<any>;
  name: string;
  rules?: { required: Message | ValidationRule<boolean> };
  errorMessage?: string;
}

export const InputPhone = ({
  className,
  label,
  id,
  control,
  name,
  rules,
  errorMessage,
}: Props) => {
  const [dialCode, setDialCode] = useState("");
  const dialCodeRegexp = dialCode ? new RegExp(`^\\+${dialCode}`) : /^/;

  return (
    <div className={cn(styles.field, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          minLength: { value: 11, message: "Minimum 11 characters" },
          maxLength: { value: 15, message: "Maximum 20 characters" },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <PhoneInput
              containerClass={cn(
                styles.inputContainer,
                error && styles.inputContainerError
              )}
              preferredCountries={["us"]}
              disableCountryCode
              disableCountryGuess
              disableInitialCountryGuess={false}
              inputClass={styles.inputField}
              buttonClass={styles.inputDropdown}
              country={"us"}
              value={value && (value as string).replace(dialCodeRegexp, "")}
              placeholder=""
              onChange={(value: string, country: CountryData) => {
                const { dialCode } = country;
                setDialCode(dialCode);
                onChange(value ? "+" + dialCode + value : value);
              }}
              onMount={(_value: string, country: CountryData) => {
                const { dialCode } = country;

                setDialCode(dialCode);
              }}
            />
            {error && <p className={styles.errorMessage}>{error.message}</p>}
            {errorMessage && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
          </>
        )}
      />
    </div>
  );
};
