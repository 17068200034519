import { Footer } from "../components/Footer/Footer";
import { LandingHeader } from "../components/LandingHeader";
import { Testimonials } from "../components/Testimonials";
import { BenefitsSection } from "./blocks/BenefitsSection/BenefitsSection";
import { ChatSection } from "./blocks/ChatSection/ChatSection";
import { ProvideSection } from "./blocks/ProvideSection/ProvideSection";
import { RequirementsSection } from "./blocks/RequirementsSection/RequirementsSection";
import { SubmitSection } from "./blocks/SubmitSection/SubmitSection";
import styles from "./TherapistLanding.module.scss";
import { useSnapshot } from "valtio";
import { testimonialsService } from "services/testimonials";
import { TestimonialCard } from "../CTA/components/TestimonialCard/TestimonialCard";
import { useEffect } from "react";

export const therapistLandingPath = "/therapist";

export const TherapistLanding = () => {
  const { featuredTestimonials } = useSnapshot(testimonialsService);
  const sliderNodes = featuredTestimonials.map((item) => <TestimonialCard {...item} />);

  useEffect(() => {
      testimonialsService.getFeaturedTestimonials();
  }, []);

  return (
    <div className={styles.root}>
      <LandingHeader />
      <main className={styles.main}>
        <ProvideSection />
        <BenefitsSection />
        <ChatSection />
        {featuredTestimonials.length &&
            <Testimonials
                spaceBetween={24}
                className={styles.slider}
                sliderNodes={sliderNodes}
            />}
        <RequirementsSection />
        <SubmitSection />
      </main>
      <Footer />
    </div>
  );
};
