import { useForm, SubmitHandler } from "react-hook-form";
import { RateChooseType } from "./RateChooseType/RateChooseType";
import { RateDetails } from "./RateDetails/RateDetails";
import { RateDescription } from "./RateDescription/RateDescription";
import { memo, useEffect, useState } from "react";
import { rateCardsService } from "services/rateCardsService";
import { RateCard } from "types/rateCards.types";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";

const defaultValues: RateCard = {
  therapist: "",
  fee: "",
  pay_sessions_count: 0,
  get_free_count: 0,
  description: "",
  id: 0,
};

export const RateCards = memo(() => {
  const { closeAll, properties } = useModal("RATE_CARD_DESCRIPTION");
  const [totalPay, setTotalPay] = useState(0.0);
  const {
    control,
    watch,
    formState: { errors, isDirty },
    setError,
    register,
    clearErrors,
    reset,
    handleSubmit,
    setValue,
  } = useForm<RateCard>({ mode: "onChange" });
  const type = watch("type");
  const fees = watch("fee");
  const sessions = watch("pay_sessions_count");
  const free = watch("get_free_count");
  const { user } = useAuth();

  useEffect(() => {
    setTotalPay(Number(sessions * +fees));
  }, [fees, sessions]);

  useEffect(() => {
    if (user && properties?.id) {
      rateCardsService.getById(user.id, +properties.id).then((result) => {
        result?.type && result.type.match("special")
          ? reset({
              ...result,
              fee: `${Number(+result.fee / result.pay_sessions_count).toFixed(
                2
              )}`,
            })
          : reset(result);
      });
    } else {
      reset({ ...defaultValues });
    }
  }, [user, properties.id, reset]);

  const onSubmitForm: SubmitHandler<RateCard> = async (values) => {
    const data = {
      ...values,
      ...(type === "special" && { fee: String(totalPay.toFixed(2)) }),
    };

    try {
      if (user)
        properties?.id
          ? await rateCardsService.patch(user.id, Number(properties.id), data)
          : await rateCardsService.create(user.id, data);
      closeAll();
      reset(defaultValues);
    } catch (error) {
      const err = error as AxiosResponse;
      toast(err.data.detail, { type: "error" });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <RateChooseType
        errors={errors}
        register={register}
        setError={setError}
        type={type}
        clearErrors={clearErrors}
        isDirty={isDirty}
        setValue={setValue}
      />
      <RateDetails
        control={control}
        type={type}
        watch={watch}
        fees={fees}
        free={free}
        sessions={sessions}
        totalPay={totalPay}
        setError={setError}
        clearErrors={clearErrors}
      />
      <RateDescription control={control} type={type} />
    </form>
  );
});
