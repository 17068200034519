import React, { FC } from "react";
import { Controller } from "react-hook-form";

import { AGE_OPTIONS } from "constants/options";
import { EditPatientInformationForm } from "../useEditPatientInformation";
import styles from "../EditPatientInformation.module.scss";

export const PatientsAge: FC<EditPatientInformationForm> = ({ control }) => {
  return (
    <div className={styles.therapyAges}>
      <Controller
        control={control}
        name="age"
        render={({ field: { value, onChange } }) => (
          <>
            {AGE_OPTIONS.map((age) => (
              <div key={age.value} className={styles.therapyAgesIcons}>
                <input
                  className={styles.therapyAgesInput}
                  type="checkbox"
                  id={`age-${age.value}`}
                  checked={value === age.value}
                  onChange={() => onChange(age.value)}
                />
                <label htmlFor={`age-${age.value}`}>{age.label}</label>
              </div>
            ))}
          </>
        )}
      />
    </div>
  );
};
