import React, { FC } from "react";
import { Controller } from "react-hook-form";

import { EditPatientInformationForm } from "../useEditPatientInformation";
import Checkbox from "common/UIKit/Checkbox";
import { SEXUALITY_OPTIONS } from "constants/options";

export const Sexuality: FC<EditPatientInformationForm> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="preferred_sexualities"
      render={({ field: { value, onChange } }) => (
        <>
          {SEXUALITY_OPTIONS.map((option, index) => (
            <Checkbox
              key={option.label}
              className="patient-step__checkbox"
              id={`sexuality-value-${index}`}
              checked={value.includes(option.value)}
              onChange={(e) => {
                const noPreferenceCode = "none";
                if (
                  option.value === noPreferenceCode &&
                  e.currentTarget.checked
                ) {
                  onChange([noPreferenceCode]);
                } else {
                  const valueWithoutNone = value.filter(
                    (v) => v !== noPreferenceCode
                  );
                  const newValue = e.currentTarget.checked
                    ? [...valueWithoutNone, option.value]
                    : valueWithoutNone.filter((v) => v !== option.value);

                  onChange(newValue);
                }
              }}
            >
              {option.label}
            </Checkbox>
          ))}
        </>
      )}
    />
  );
};
