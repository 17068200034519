import { proxy } from "valtio";
import { Letter, LetterMeta, LetterFile } from "types/letters.types";
import { esaTokenService } from "./esaTokenService";
import axios, { AxiosError } from "axios";
import { esaApiEndpoint } from "../utils/env";
import { generalLogicService } from "./generalLogicService";
import { LETTER_TYPES } from "constants/options";

class EsaLettersService {
  isLoaded: boolean = false;
  isFetchingEsa: boolean = false;
  data: Letter[] = [];

  handleError = (error: AxiosError) => {
    if (error.response) {
      const { status, data } = error.response;
      const errorMessage = data.detail || error.message;

      const serverError = status >= 500;
      if (serverError) {
        generalLogicService.setError({
          status,
          message: errorMessage,
          type: "critical",
        });
      }
    }
  }

  async loadAll(){
    if (!this.isLoaded) {
      this.isFetchingEsa = true;
      const letters = (await axios.get(
        `${esaApiEndpoint}/Client/getletters?token=${esaTokenService.get()}`
      )).data["letters"] as LetterMeta[];
      letters.forEach((val) => {
        axios.get(`${esaApiEndpoint}/Client/getletter?token=${esaTokenService.get()}&letterid=${val.LetterID}&lettertype=${LETTER_TYPES.ESA_LETTER}`)
              .then((response) => {
                const letterFile = response.data as LetterFile;
                const loaded = this.data.find((dataVal) => {
                  return dataVal.LetterID === val.LetterID 
                });
                if(!loaded){
                  this.data.push({...val, LetterFile: letterFile});
                }
              })
      })
      this.isLoaded = true;
      this.isFetchingEsa = false;
    }
  }
}

export const esaLettersService = proxy(new EsaLettersService());
