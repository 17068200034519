import { DependencyList, useEffect, useMemo, useState } from "react";
import {
  SubmitHandler,
  useForm,
  UseFormProps,
  FieldValues,
  Path,
} from "react-hook-form";
import { authService } from "services/authService";
import { FileType, Therapist } from "types/therapist.types";
import { useSnapshot } from "valtio";
import {
  FileDataInputs,
  makeDefaultValues,
  makeDiffToSend,
  TherapistFields,
} from "pages/Therapists/TherapistSteps/util";
import { AxiosResponse } from "axios";
import { DeepPartial, goThroughErrors } from "common/utils";
import { BaseUser } from "types/auth.types";
import { filesService } from "services/filesService";
import useFileUpload from "hooks/useFileUpload";
import { toast } from "react-toastify";
import { useResponsive } from "hooks/useResponsive";

type Options<TInputs extends FieldValues> = {
  toFields?: (
    v: DeepPartial<Therapist> & DeepPartial<BaseUser>
  ) => DeepPartial<TInputs>;
  toApi?: (
    v: DeepPartial<TInputs>
  ) => Promise<DeepPartial<Therapist>> | DeepPartial<Therapist>;
  fileType?: FileType;
  mimeGroup?: string[];
  dependencies?: DependencyList;
  useFormProps?: UseFormProps<TInputs>;
};

export const useProfileForm = <TInputs extends FieldValues>(
  coveredFields: TherapistFields,
  backPath?: string | null,
  options: Options<TInputs> = {}
) => {
  const therapist = useSnapshot(authService).user as Therapist | null;
  const { toFields, toApi, fileType, mimeGroup, dependencies = [] } = options;
  const withFiles = !!(fileType && mimeGroup);
  const { data: files } = useSnapshot(filesService);
  const { isMobile } = useResponsive();

  useEffect(() => {
    therapist && filesService.list(therapist.id);
  }, [therapist]);

  const suitableFiles = useMemo(
    () =>
      withFiles
        ? files.filter(
            (f) =>
              mimeGroup.map((mime) => f.mime.startsWith(mime)) &&
              f.type === fileType
          )
        : [],
    [files, fileType, mimeGroup, withFiles]
  );
  const defaultValues: any = useMemo(() => {
    if (!therapist) {
      return {};
    }
    const df = makeDefaultValues(therapist, coveredFields);
    if (withFiles) {
      (df as FileDataInputs).fileData = {
        currentValue: suitableFiles,
        toDelete: [],
      };
    }
    return toFields?.(df) || df;
    //eslint-disable-next-line
  }, [...dependencies, therapist]);

  type ForFiles = typeof fileType extends FileType ? FileDataInputs : {};
  type Inputs = TInputs & ForFiles;

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    ...formMethods
  } = useForm<Inputs>({ ...options?.useFormProps, defaultValues });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const { handleSubmit: handleSubmitFiles, fileUploadProps } = useFileUpload(
    fileType || "photo_and_video_intro",
    mimeGroup || []
  );

  const submitHandler: SubmitHandler<Inputs> = async (values) => {
    await setIsSubmitting(true);
    const diff = toApi
      ? await toApi(values as DeepPartial<TInputs>)
      : makeDiffToSend(values, coveredFields);

    diff?.user?.email && delete diff.user.email;
    diff?.user?.mobile_phone && delete diff.user.mobile_phone;

    try {
      await authService.patch({ diff });
      if (withFiles) {
        await handleSubmitFiles();
      }
      toast("Information has been updated", {
        type: "info",
        autoClose: isMobile ? 3000 : 5000,
        position: isMobile ? "top-center" : "top-right",
      });
    } catch (e) {
      const { data, status } = e as AxiosResponse;
      if (status === 400) {
        goThroughErrors(data, (fieldName, errorMessage) =>
          setError(fieldName as Path<Inputs>, {
            message: errorMessage,
            type: "custom",
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(submitHandler),
    control,
    errors,
    isSubmitting,
    setValue,
    getValues,
    fileUploadProps,
    watch,
    setError,
    ...formMethods,
  };
};
