import { Link } from "react-router-dom";
import { useModal } from "hooks/useModal";
import { tokenService } from "services/tokenService";
import { history } from "utils/history";
import { LoggedOutContainer } from "../LoggedOutContainer/LoggedOutContainer";
import styles from "./LoggedOutToPatients.module.scss";
import { esaTokenService } from "services/esaTokenService";

export const LoggedOutToPatients = () => {
  const { isOpen, close } = useModal("LOGGED_OUT_PATIENT");

  const onClose = () => {
    close();
    tokenService.remove();
    esaTokenService.remove();
  };

  const handleButtonClick = () => {
    onClose();
    history.push("/patient/login");
  };

  return (
    <LoggedOutContainer
      subTitle={
        <div className={styles.textContainer}>
          <span>
            You need to be logged in to Chat Connect with a therapist.
            Registration is easy and free.
          </span>
          <span>
            To register{" "}
            <Link to="/patient/signup" onClick={onClose}>
              click here.
            </Link>
          </span>
          <span>
            If you are already a member,{" "}
            <Link to="/patient/login" onClick={onClose}>
              log in.
            </Link>
          </span>
        </div>
      }
      className={styles.loggedOutToPatients}
      isOpen={isOpen}
      close={() => close()}
      onButtonClick={handleButtonClick}
    />
  );
};
