import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import MUISnackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import CloseIcon from "icons/CloseIcon";
import InfoIcon from "icons/InfoIcon";
import WarningIcon from "icons/WarningIcon";
import ErrorIcon from "icons/ErrorIcon";
import { Typography, IconButton } from "@mui/material";
import styles from "./Snackbar.module.scss";

interface SnackbarProps {
  headline: string;
  message: string | React.ReactNode;
  variant?: "error" | "warning" | "info";
  open: boolean;
  handleClose?: () => void;
}

export default function Snackbar({
  headline,
  message,
  variant,
  open,
  handleClose,
}: SnackbarProps) {
  const handleOnClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    handleClose?.();
  };

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      onClose={handleOnClose}
      autoHideDuration={10000}
    >
      <div
        className={clsx(styles.container, {
          [styles.error]: variant === "error",
          [styles.warning]: variant === "warning",
          [styles.info]: variant === "info",
        })}
      >
        <div className={styles.contentContainer}>
          <div className={styles.iconContainer}>
            {variant === "warning" && <WarningIcon />}
            {variant === "error" && <ErrorIcon />}
            {variant === "info" && <InfoIcon />}
          </div>
          <div>
            <Typography
              variant="body1"
              className={styles.headline}
              component="span"
            >
              {headline}
            </Typography>
            <Typography variant="body1" component="span">
              {" "}
              {message}
            </Typography>
          </div>
        </div>
        <div>
          {handleClose && (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </div>
    </MUISnackbar>
  );
}
