import { proxy } from "valtio";

import { CrudService } from "./crudService";
import { CreateCreditCardData, CreditCard } from "types/creditCards.types";

export class CreditCardService extends CrudService<number, CreditCard> {
  private static endpoint = "/v1/bank-cards";

  constructor(args?: any) {
    super({
      ...args,
      prefix: CreditCardService.endpoint,
    });
  }

  async create(data: CreateCreditCardData) {
    const response = (await this.http.post(
      `${CreditCardService.endpoint}/`,
      data
    )) as CreditCard;
    this.data.push(response);

    return response;
  }
}

export const creditCardService = proxy(new CreditCardService());
creditCardService.init();
