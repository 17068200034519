import { HTMLProps, ReactNode } from "react";
import cn from "classnames";

import { ReactComponent as PlusIcon } from "./icons/PlusIcon.svg";

import styles from "./AddButton.module.scss";

interface Props extends Omit<HTMLProps<HTMLButtonElement>, "type"> {
  type?: "button" | "submit" | "reset";
  className?: string;
  icon?: ReactNode;
}

// TODO: rename it to InlineButton or IconButton

function AddButton({ children, className, icon, ...restProps }: Props) {
  return (
    <button className={cn(styles.button, className)} {...restProps}>
      <div className={styles.iconWrapper}>{icon || <PlusIcon />}</div>
      <div className={styles.text}>{children}</div>
    </button>
  );
}

export default AddButton;
