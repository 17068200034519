import Button from "common/UIKit/Button/Button";
import { stepsFullPath } from "../stepsPath";
import { useNavigate } from "react-router-dom";
import { esaProfileService } from "services/esaProfileService";
import { useSnapshot } from "valtio";
import { useEffect, useState } from "react";
import { testPsychologyListService } from "services/testPsychologyListService";
import { AvailableTest } from "types/availableTests.types";
import { EsaTestTable } from "../components/EsaTestTable";
import { informedConsentFullPath } from "../InformedConsent";

export const esaStep1Path = "/esa_1";
export const esaStep1FullPath = `${stepsFullPath}${esaStep1Path}`;

export function EsaStep1() {
  const navigate = useNavigate();
  const { pendingTests } = useSnapshot(esaProfileService);
  useEffect(() => {
    esaProfileService.getProfile(true).then(
      (data) => {
        if (!data?.pendingtests.length) {
          navigate(informedConsentFullPath);
        }
        if (data?.pendingtests.length === 1 && data.pendingtests[0].key === "sNtve1") {
          navigate(informedConsentFullPath);
        }
      }
    );
  });
  const pendingTestKeys = pendingTests.map(pendingTest => pendingTest.key);
  const { data, pageCount, page } = useSnapshot(
    testPsychologyListService
  );
  useEffect(() => {
    testPsychologyListService.fetch()
  }, []);
  const tests : AvailableTest[] = JSON.parse(JSON.stringify(data)) as AvailableTest[];
  const requiredTests = tests.filter((test) => pendingTestKeys.includes(test.key));


  return (
    <div className="default-typography">
      <div className="text-center mb-35">
        <h1 className="h2">Intake</h1>
        <div className="text-block text-sm">
          <p>Before you can download your ESA letter, your therapist needs you to complete the intake form.</p>
        </div>
      </div>

      <EsaTestTable rows={requiredTests} />
    </div>
  );
}