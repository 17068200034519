import { ListService } from "./listService";
import { TestAttempt, TestAttemptId } from "../types/testAttempt.types";
import { proxy } from "valtio";

class TestPsychologyListService extends ListService<
  TestAttemptId,
  TestAttempt
> {}

export const testPsychologyListService = proxy(
  new TestPsychologyListService({
    prefix: "/v1/psychology-tests",
    pageSize: 10,
  })
);
