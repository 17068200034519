import React, { ReactNode } from 'react';
import { VideoData } from '../util';
import { Nullable } from 'common/utils';
import styles from './Controls.module.scss';
import ProgressBar from './ProgressBar';
import PlayButton from './PlayButton';


type Props = {
  pausedData: VideoData<boolean>,
  currentTimeData: VideoData<number>,
  totalDuration: Nullable<number>,
  adornment?: ReactNode
}

function Controls({ pausedData, currentTimeData, totalDuration, adornment }: Props) {
  return (
    <div className={styles.bottomPartWrapper}>
      <div className={styles.controlsContainer}>
        <PlayButton pausedData={pausedData} />
        <ProgressBar
          currentTimeData={currentTimeData}
          totalDuration={totalDuration}
        />
      </div>
      {adornment}
    </div>
  );
}

export default Controls;