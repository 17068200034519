import cn from "classnames";

import React, { ChangeEvent, ForwardedRef, forwardRef } from "react";
import Input, { InputProps } from "../Input/Input";
import styles from "./RangeInput.module.scss";

export type Range = [number | null, number | null];

interface RangeInputProps
  extends Omit<InputProps, "value" | "ref" | "onChange" | "type"> {
  value: Range;
  wrapperClassName?: string;
  error?: string;
  onChange: (updRage: Range) => void;
  adornmentVariant?: "gray" | "black";
  withLeftAdornment?: boolean;
  withAdaptiveWidth?: boolean;
  maxLength?: number;
}

function RangeInputComponent(
  {
    onChange,
    value,
    wrapperClassName,
    className,
    error,
    withLeftAdornment,
    withAdaptiveWidth,
    maxLength,
    ...props
  }: RangeInputProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const handleChangeIndex =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      if (!isNaN(+inputValue)) {
        value[index] = inputValue === "" ? null : +inputValue;
        onChange([...value]);
      }
    };

  return (
    <div className={cn(styles.rangeInputWrapper, wrapperClassName)} ref={ref}>
      <div>
        <Input
          value={value[0] ?? ""}
          onChange={handleChangeIndex(0)}
          className={className}
          isValid={!error}
          withLeftAdornment
          {...props}
          inputStyles={
            withAdaptiveWidth
              ? {
                  width: value[0]
                    ? `${value[0]?.toString().length * 10}px`
                    : "10px",
                  maxWidth: "80px",
                }
              : {}
          }
          maxLength={maxLength}
        />
        <p>to</p>
        <Input
          value={value[1] ?? ""}
          onChange={handleChangeIndex(1)}
          className={className}
          isValid={!error}
          withLeftAdornment
          {...props}
          inputStyles={
            withAdaptiveWidth
              ? {
                  width: value[1]
                    ? `${value[1]?.toString().length * 10}px`
                    : "10px",
                  maxWidth: "80px",
                }
              : {}
          }
          maxLength={maxLength}
        />
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}

const RangeInput = forwardRef(RangeInputComponent);

export { RangeInput };
