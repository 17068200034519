import classNames from "classnames";
import { MouseEventHandler, useState } from "react";
import { ReactComponent as Star } from "./images/star.svg";

import styles from "./Ranking.module.scss";

type Props = {
  value?: number;
  onChange?: (value: number) => void;
};

export const Ranking = ({ value, onChange }: Props) => {
  const [hovered, setHovered] = useState<number>(0);

  const handleMouseEnter: MouseEventHandler<HTMLDivElement> = (event) => {
    const hoveredRank = Number(event.currentTarget.getAttribute("data-rank"));
    setHovered(hoveredRank);
  };

  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = (event) => {
    setHovered(0);
  };

  return (
    <div className={styles.root}>
      {[1, 2, 3, 4, 5].map((rank) => (
        <div
          key={rank}
          className={styles.star}
          data-rank={rank}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Star
            className={classNames({
              [styles.hovered]: rank <= hovered,
              [styles.selected]: rank <= (value || 0),
            })}
            onClick={() => onChange && onChange(rank)}
          />
        </div>
      ))}
    </div>
  );
};
