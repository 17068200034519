import React, { useEffect } from "react";
import { useSnapshot } from "valtio";

import { PlanInfoCard } from "./PlanInfoCard";
import { PlanRenewalCard } from "./PlanRenewalCard";
import { subscriptionService } from "services/subscriptionService";
import styles from "./Overview.module.scss";

export const Overview = () => {
  const { activeSubscriptions, currentSubscription } =
    useSnapshot(subscriptionService);

  useEffect(() => {
    subscriptionService.list();
  }, []);

  return (
    <div className={styles.overview}>
      <PlanInfoCard subscription={currentSubscription} />
      <div className={styles.renewalCards}>
        {activeSubscriptions.map((subscription) => (
          <PlanRenewalCard subscription={subscription} key={subscription.id} />
        ))}
      </div>
    </div>
  );
};
