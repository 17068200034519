import { useState } from "react";
import { Props as ReactSelectProps, ActionMeta } from "react-select";
import cn from "classnames";

import AppSelect from "../../UIKit/Select";
import { Control, useController } from "react-hook-form";
import { toCodes } from "../../utils";
import { ListOption } from "constants/options";
import {
  CustomControl,
  CustomDropdownIndicator,
  CustomInput,
  CustomMenu,
  CustomMenuList,
  CustomOption,
} from "./customComponents";

import styles from "./ControlledMultiSelect.module.scss";

type ToOmit =
  | "value"
  | "defaultValue"
  | "onChange"
  | "isMulti"
  | "components"
  | "menuIsOpen"
  | "closeMenuOnSelect"
  | "controlShouldRenderValue"
  | "hideSelectedOptions"
  | "isSearchable"
  | "isClearable";

interface Props extends Omit<ReactSelectProps, ToOmit> {
  name: string;
  control: Control<any>;
  rules?: object;
  options: readonly ListOption<any>[];
  placeholder: string;
  className?: string;
  withColumns?: boolean;
  noPreference?: string;
  disabled?: boolean;
}

function ControlledMultiSelect({
  name,
  rules,
  control,
  options,
  placeholder,
  onMenuClose,
  onMenuOpen,
  defaultMenuIsOpen,
  className,
  withColumns,
  disabled,
  noPreference = "no-preference",
  ...otherProps
}: Props) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });
  const [menuIsOpen, setMenuIsOpen] = useState(defaultMenuIsOpen);

  const selectedOptions = options.filter((opt) => value?.includes(opt.value));

  function closeMenu() {
    setMenuIsOpen(false);
    onMenuClose?.();
  }

  function openMenu() {
    setMenuIsOpen(true);
    onMenuOpen?.();
  }

  return (
    <>
      <AppSelect
        className={cn(
          className && className,
          withColumns && styles.withColumns
        )}
        error={error?.message}
        isMulti
        onChange={(newValue, actionMeta) =>
          onChange(
            toCodes(
              newValue as ListOption[],
              actionMeta as ActionMeta<ListOption>,
              noPreference
            )
          )
        }
        options={options}
        value={selectedOptions}
        placeholder={placeholder}
        hideSelectedOptions={false}
        isSearchable
        menuIsOpen={menuIsOpen}
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        isClearable={false}
        onMenuClose={closeMenu}
        onMenuOpen={openMenu}
        components={{
          Control: CustomControl,
          Input: CustomInput,
          Placeholder: () => null,
          DropdownIndicator: CustomDropdownIndicator,
          Menu: CustomMenu,
          MenuList: CustomMenuList,
          Option: CustomOption,
        }}
        isDisabled={disabled}
        {...otherProps}
      />
    </>
  );
}

export default ControlledMultiSelect;
