import { LandingHeader } from "../Auth/components/LandingHeader/LandingHeader";
import { Footer } from "../Auth/components/Footer/Footer";
import { sections } from "./sections";
import { Accordion } from "common/components/Accordion";
import styles from "./FAQ.module.scss";

export const faqPath = "/faq";

export const FAQ = () => {
  return (
    <main className={styles.root}>
      <LandingHeader />
      <div className={styles.inner}>
        <h1 className={styles.title}>Frequently Asked Questions</h1>
        <section className={styles.questions}>
          {sections.map((section) => (
            <Accordion
              key={section.title}
              className={styles.accordion}
              tabClassName={styles.accordionTab}
              panelClassName={styles.accordionPanel}
              tab={section.title}
              panel={section.content}
            />
          ))}
        </section>
      </div>
      <Footer />
    </main>
  );
};
