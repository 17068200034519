import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { DictionaryItem, dictionaryService } from "services/dictionaryService";
import { step4FullPath } from "../PatientStep4";
import { useSteps } from "hooks/useSteps";
import { useSelectPreferences } from "../useSelectPreferences";
import { sortItems } from "common/utils";

export const usePatientStep3 = () => {
  const [issues, setIssues] = useState<DictionaryItem[]>([]);

  const [search, setSearch] = useState("");
  const { isSubmitting, error, submit } = useSteps();
  const { selected, handleChange } = useSelectPreferences("issues", "none");

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res = await dictionaryService.getIssues();
      setIssues(sortItems(res, "order"));
    })();
  }, [setIssues]);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.currentTarget.value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    await submit({
      diff: { issues: selected.includes("none") ? [] : selected },
      onSuccess: () => navigate(step4FullPath),
      errorMessage: "Select at least one issue",
    });
  };

  return {
    search,
    issues: search
      ? issues.filter((issue) =>
          issue.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )
      : issues,
    selectedIssues: selected as string[],
    isSubmitting,
    error,
    handleSearch,
    handleChange,
    handleSubmit,
  };
};
