import { FC, useState } from "react";
import { TestBlock } from "types/testProcess.types";
import { Question } from "../Question";
import styles from "./Block.module.scss";
import Button from "common/UIKit/Button/Button";

interface Props {
  block: TestBlock;
  disabled?: boolean;
  placeholder?: string;
  questionNum?: number;
  displayAll?: boolean;
  questionsAnswered?: Map<string, boolean>;
  setquestionsAnswered?: React.Dispatch<
    React.SetStateAction<Map<string, boolean>>
  >;
}

export const Block: FC<Props> = ({
  block,
  disabled,
  placeholder,
  questionNum = 0,
  displayAll = false,
  questionsAnswered,
  setquestionsAnswered,
}) => {
  const currentQuestion = block.questions[questionNum];
  return (
    <div className="default-typography mb-30">
      {block.general_question && (
        <h4 className="mb-30">{block.general_question}</h4>
      )}

      <div className={styles.questionsBlock}>
        {!displayAll && (
          <Question
            question={currentQuestion}
            isIntro={block.is_intro}
            key={currentQuestion.id}
            disabled={disabled}
            placeholder={placeholder}
            questionsAnswered={questionsAnswered}
            setquestionsAnswered={setquestionsAnswered}
          />
        )}
        {displayAll &&
          block.questions.map((question) => (
            <Question
              question={question}
              isIntro={block.is_intro}
              key={question.id}
              disabled={disabled}
              placeholder={placeholder}
            />
          ))}
      </div>
    </div>
  );
};
