import { FC } from "react";
import { stepsUi } from "../components";
import ClockImage from "assets/images/clock.png";
import { ReactComponent as TwoPersonIcon } from "assets/images/icons/two-person.svg";
import { ReactComponent as VideoIcon } from "assets/images/icons/video.svg";
import { ReactComponent as PhoneIcon } from "assets/images/icons/mobile.svg";
import { ReactComponent as ChatIcon } from "assets/images/icons/message.svg";
import ToggleCheckbox from "common/UIKit/ToggleCheckbox/ToggleCheckbox";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { Controller } from "react-hook-form";
import AppSelect from "common/UIKit/Select";
import { CHAT_STATUS_OPTIONS, colorStyles } from "./helpers";
import { useTherapistStep11 } from "./useTherapistStep11";
import { step9_2FullPath } from "../TherapistStep9_2";
import { Link } from "react-router-dom";
import { step2FullPath } from "../TherapistStep2/step2Config";

export const TherapistStep11: FC = () => {
  const { control, register, handleSubmit, errors, isMobile, isSubmitting } =
    useTherapistStep11();

  return (
    <div className="therapist-step-11">
      <div className="therapist-step-11__wrapper">
        <div className="therapist-step-11__img">
          <img src={ClockImage} alt="Clock" />
        </div>
        <stepsUi.Header>Availability</stepsUi.Header>
        <stepsUi.Hint>
          Let people know you’re accepting new clients in-person and/or online.
        </stepsUi.Hint>
      </div>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={
          <stepsUi.Actions
            buttons={[
              {
                type: "button",
                color: "green-light",
                children: "Back",
                className: "btn-color-blue",
                isLink: true,
                to: step9_2FullPath,
              },
              {
                type: "submit",
                children: isMobile ? "Continue" : "Next",
                isLoading: isSubmitting,
              },
            ]}
          />
        }
      >
        <div className="therapist-step-11__row">
          <div className="therapist-step-11__column">
            <div className="therapist-step-11__column-icon">
              <TwoPersonIcon />
            </div>
            <h5 className="therapist-step-11__column-title">In-Person</h5>
            <p className="therapist-step-11__column-text">
              Are you accepting new clients for in-person sessions?
            </p>
            <div className="therapist-step-11__column-buttons">
              <label className="therapist-step-11__checkbox">
                <input
                  type="checkbox"
                  {...register("in_person_session_availability")}
                />
                <span data-value="true">Yes</span>
                <span data-value="false">No</span>
              </label>
            </div>
            <ErrorMessage>
              {errors.in_person_session_availability?.message}
            </ErrorMessage>
          </div>
          <div className="therapist-step-11__column">
            <div className="therapist-step-11__column-icon">
              <PhoneIcon className="phone" />
            </div>
            <h5 className="therapist-step-11__column-title">Telephone</h5>
            <p className="therapist-step-11__column-text">
              Are you accepting new clients for telephone sessions?
            </p>
            <div className="therapist-step-11__column-buttons">
              <label className="therapist-step-11__checkbox">
                <input
                  type="checkbox"
                  {...register("telephone_session_availability")}
                />
                <span data-value="true">Yes</span>
                <span data-value="false">No</span>
              </label>
            </div>
            <ErrorMessage>
              {errors.telephone_session_availability?.message}
            </ErrorMessage>
          </div>
          <div className="therapist-step-11__column">
            <div className="therapist-step-11__column-icon">
              <VideoIcon />
            </div>
            <h5 className="therapist-step-11__column-title">Video</h5>
            <p className="therapist-step-11__column-text">
              Are you accepting new clients for video sessions?
            </p>
            <div className="therapist-step-11__column-buttons">
              <label className="therapist-step-11__checkbox">
                <input
                  type="checkbox"
                  {...register("video_session_availability")}
                />
                <span data-value="true">Yes</span>
                <span data-value="false">No</span>
              </label>
            </div>
            <ErrorMessage>
              {errors.video_session_availability?.message}
            </ErrorMessage>
          </div>
          <div className="therapist-step-11__column">
            <div className="therapist-step-11__column-icon">
              <ChatIcon />
            </div>
            <h5 className="therapist-step-11__column-title">Chat Connect</h5>
            <p className="therapist-step-11__column-text">
              Are you accepting new clients for online sessions?
            </p>
            <div className="therapist-step-11__column-buttons">
              <label className="therapist-step-11__checkbox">
                <input
                  type="checkbox"
                  {...register("online_chat_sessions_availability")}
                />
                <span data-value="true">Yes</span>
                <span data-value="false">No</span>
              </label>
            </div>
            <ErrorMessage>
              {errors.online_chat_sessions_availability?.message}
            </ErrorMessage>
          </div>
        </div>
        <Controller
          name="chat_status"
          control={control}
          render={({ field, fieldState }) => (
            <AppSelect
              styles={colorStyles}
              className="therapist-step-11__status"
              placeholder="Select"
              label="Chat status"
              options={CHAT_STATUS_OPTIONS}
              {...field}
              value={CHAT_STATUS_OPTIONS.find((o) => o.value === field.value)}
              onChange={(nextValue) => {
                const { value } = nextValue as { value: string };
                field.onChange(value);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
        <h3 className="therapist-step-11__title">Intro call</h3>
        <sub className="therapist-step-11__subtitle">
          Do you offer a free 15-minute intro call to discuss clients’ pressing
          issues and basic questions? Don’t forget to enter your phone number in{" "}
          <Link to={step2FullPath} className="g-underline">
            Step 2
          </Link>
          .
        </sub>
        <fieldset className="therapist-step-11__toggle">
          <ToggleCheckbox {...register("offer_free_call")} />
          <label>Yes, I offer a free 15-minute intro call.</label>
        </fieldset>
        <ErrorMessage>{errors.offer_free_call?.message}</ErrorMessage>
      </stepsUi.Form>
    </div>
  );
};
