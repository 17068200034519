import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useQuery } from "hooks/useQuery";
import { authService } from "services/authService";
import { passwordResetSuccessPath } from "../PasswordResetSuccess";
import { AxiosResponse } from "axios";

type Values = {
  password: string;
  confirmPassword: string;
};

const defaultValues: Values = {
  password: "",
  confirmPassword: "",
};

export const usePasswordReset = () => {
  const q = useQuery();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<Values>({ defaultValues });
  const navigate = useNavigate();

  const [userType, setUserType] = useState("patient");
  const [globalError, setGlobalError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: SubmitHandler<Values> = async (values) => {
    try {
      await setIsSubmitting(true);
      const token = q.get("token") || "";
      const email = q.get("email") || "";
      const user_type = q.get("user_type");

      if (user_type && user_type !== "null") {
        setUserType(user_type);
      }
      await authService.confirmResetPassword(email, token, values.password);
      navigate({
        pathname: passwordResetSuccessPath,
        search: `&user_type=${user_type}`,
      });
    } catch (error) {
      const { data } = error as AxiosResponse;
      setGlobalError(data.errors);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    register,
    getValues,
    dirtyFields,
    handleSubmit: handleSubmit(onSubmit),
    errors: { ...errors, global: globalError },
    isSubmitting,
    userType,
  };
};
