import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AxiosResponse } from "axios";

import { authService } from "services/authService";
import { tokenService } from "services/tokenService";

type ChangePasswordValues = {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};
const defaultValues: ChangePasswordValues = {
  oldPassword: "",
  newPassword: "",
  repeatNewPassword: "",
};

export const useChangePassword = (onSuccess?: () => void) => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
    getValues,
  } = useForm<ChangePasswordValues>({ defaultValues });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: SubmitHandler<ChangePasswordValues> = async (values) => {
    try {
      await setIsSubmitting(true);
      const { token } = await authService.changePassword(
        values.oldPassword,
        values.newPassword
      );

      tokenService.set(token);
      onSuccess && onSuccess();
    } catch (error) {
      const message = (error as AxiosResponse).data.errors[0];
      setError("oldPassword", { message, type: "custom" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    isSubmitting,
    dirtyFields,
    getValues,
  };
};
