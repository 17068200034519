import ReCAPTCHA from "react-google-recaptcha";
import { googleReCaptchaKey } from "utils/env";
import Input from "../../common/UIKit/Input";
import Textarea from "../../common/UIKit/Textarea/Textarea";
import Button from "../../common/UIKit/Button";
import { LandingHeader } from "../Auth/components/LandingHeader/LandingHeader";
import { Footer } from "../Auth/components/Footer/Footer";
import styles from "./ContactUs.module.scss";
import { contactFormService } from "services/contactFormService";
import { useForm } from "react-hook-form";
import { Contact } from "types/contact.types";

export const contactUsPath = "/contact";

export const ContactUs = () => {
  const { handleSubmit, register, reset } = useForm<Contact>();

  const onSubmit = async (values: Contact) => {
    await contactFormService.submit_contact(values);
    reset();
  };

  return (
    <div className={styles.root}>
      <LandingHeader />
      <div className={styles.content}>
        <h1 className={styles.contactTitle}>Contact Us</h1>
        <p className={styles.contactSubtitle}>
          We look forward to hearing from you! After receiving your message,
          we'll contact you by email.
        </p>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formColumn}>
            <Input
              className={styles.input}
              label="Your Name"
              {...register("name")}
            />
            <Input
              className={styles.input}
              label="Your Email"
              {...register("email")}
            />
            <Input
              className={styles.input}
              label="Phone number"
              {...register("phone")}
            />
          </div>
          <div className={styles.formColumn}>
            <Textarea
              className={styles.textarea}
              {...register("message")}
              label="Message"
              placeholder="Type your message here..."
              withoutLength
            />
            <ReCAPTCHA
              className={styles.captcha}
              sitekey={googleReCaptchaKey}
            />

            <Button type="submit" className={styles.formButton}>
              Send
            </Button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};
