import { ReactNode } from "react";
import { capitalize } from "lodash";

import { CredentialType } from "types/credential.types";
import {
  Group,
  Credential,
  ALL_TITLES,
  Title,
  PrimaryCredentialType,
  Gender,
} from "types/therapist.types";
import {
  AppointmentDate,
  PreferredPrice,
  PreferredSexuality,
  TherapistPreference,
} from "types/patient.types";
import { PaymentPeriod } from "types/plan.types";

import { ReactComponent as InPerson } from "assets/images/icons/sofa.svg";
import { ReactComponent as Telephone } from "assets/images/icons/mobile.svg";
import { ReactComponent as Videochat } from "assets/images/icons/video.svg";
import { ReactComponent as Textchat } from "assets/images/icons/comment-dots.svg";

import { ReactComponent as ToddlerIcon } from "assets/images/icons/toddler.svg";
import { ReactComponent as ChildrenIcon } from "assets/images/icons/children.svg";
import { ReactComponent as TeenIcon } from "assets/images/icons/teen.svg";
import { ReactComponent as AdultIcon } from "assets/images/icons/adult.svg";
import { ReactComponent as ElderIcon } from "assets/images/icons/elder.svg";

// Options are lists of choices that are static, not fetched; we know the codes (values) for them beforehand
export type ListOption<V = string> = {
  value: V;
  label: string;
  icon?: ReactNode;
};

export type AgeCode =
  | "toddler"
  | "child"
  | "preteen"
  | "teen"
  | "adult"
  | "adults"
  | "elder";
export type Ethnicity =
  | "none"
  | "native_american"
  | "hispanic"
  | "black"
  | "asian"
  | "pacific_islander"
  | "other";
export type Faith =
  | "none"
  | "christian"
  | "fundamentalist_christian"
  | "jewish"
  | "buddhist"
  | "church_of_jesus_christ"
  | "hindu"
  | "islam"
  | "sikh"
  | "spiritual"
  | "other";
export type Availability = "yes" | "no" | "waitlist";
export type TherapyType = "inperson" | "telephone" | "videochat" | "textchat";
export type State =
  | "AL"
  | "AK"
  | "AZ"
  | "AR"
  | "CA"
  | "CO"
  | "CT"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "ID"
  | "IL"
  | "IN"
  | "IA"
  | "KS"
  | "KY"
  | "LA"
  | "ME"
  | "MD"
  | "MA"
  | "MI"
  | "MN"
  | "MS"
  | "MO"
  | "MT"
  | "NE"
  | "NV"
  | "NH"
  | "NJ"
  | "NM"
  | "NY"
  | "NC"
  | "ND"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VT"
  | "VA"
  | "WA"
  | "WV"
  | "WI"
  | "WY";

export type FaithOption = ListOption<Faith>;
export type EthnicityOption = ListOption<Ethnicity>;
export type GroupOption = ListOption<Group>;
export type TherapyTypeOption = ListOption<TherapyType>;
export type CredentialOption = ListOption<CredentialType>;
export type AgeOption = ListOption<AgeCode>;
export type PriceOption = ListOption<PreferredPrice>;
export type PreferenceOption = ListOption<TherapistPreference> & {
  isSole?: boolean;
};
export type SexualityOption = ListOption<PreferredSexuality> & {
  isSole?: boolean;
};
export type AppointmentDateOption = ListOption<AppointmentDate>;
export type AvailabilityOption = ListOption<Availability>;
export type StateOption = ListOption<State>;

export const TITLE_OPTIONS: ListOption<Title>[] = ALL_TITLES.map((value) => ({
  value: value,
  label: `${capitalize(value)}.`,
}));

export const CREDENTIALS_OPTIONS: ListOption<Credential>[] = [
  { value: "ma", label: "MA" },
  { value: "phd", label: "Phd" },
  { value: "lcsw", label: "LCSW" },
];

export const FAITH_OPTIONS: FaithOption[] = [
  { value: "none", label: "No preference" },
  { value: "hindu", label: "Hindu" },
  { value: "christian", label: "Christian" },
  { value: "islam", label: "Islam" },
  { value: "fundamentalist_christian", label: "Fundamentalist Christian" },
  { value: "sikh", label: "Sikh" },
  { value: "jewish", label: "Jewish" },
  { value: "spiritual", label: "Spiritual but not religious" },
  { value: "buddhist", label: "Buddhist" },
  { value: "other", label: "Other" },
  {
    value: "church_of_jesus_christ",
    label: "Church of Jesus Christ of Latter Day Saints",
  },
];
export const ETHNICITY_OPTIONS: EthnicityOption[] = [
  { value: "none", label: "No Preference" },
  { value: "black", label: "Black/African American" },
  { value: "native_american", label: "Native American" },
  { value: "hispanic", label: "Hispanic/Latino" },
  { value: "asian", label: "Asian" },
  { value: "pacific_islander", label: "Pacific Islander" },
  { value: "other", label: "Other Racial or Ethnic Background" },
];

export const THERAPY_TYPE_OPTIONS: TherapyTypeOption[] = [
  { value: "inperson", label: "In-Person", icon: <InPerson /> },
  { value: "telephone", label: "Phone", icon: <Telephone /> },
  { value: "videochat", label: "Video", icon: <Videochat /> },
  { value: "textchat", label: "Chat", icon: <Textchat /> },
];

export const VERIFIED_CREDENTIAL_OPTIONS: CredentialOption[] = [
  { value: "diploma", label: "Diploma / Degree" },
  { value: "certificate", label: "Certificate" },
  { value: "license", label: "License" },
  { value: "membership", label: "Membership" },
];

export const AGE_OPTIONS: AgeOption[] = [
  { value: "toddler", label: "Toddler", icon: <ToddlerIcon /> },
  { value: "child", label: "Child (6-10)", icon: <ChildrenIcon /> },
  { value: "preteen", label: "Preteen", icon: <TeenIcon /> },
  { value: "teen", label: "Teen", icon: <TeenIcon /> },
  { value: "adult", label: "Adult (18+)", icon: <AdultIcon /> },
  { value: "elder", label: "Senior (65+)", icon: <ElderIcon /> },
];

export const PRICE_OPTIONS: PriceOption[] = [
  {
    label: "Under $90",
    value: "min",
  },
  {
    label: "$90 - $130",
    value: "avg",
  },
  {
    label: "$130 and above",
    value: "max",
  },
  {
    label: "Flex rate",
    value: "flex",
  },
];

export const PREFERENCE_OPTIONS: PreferenceOption[] = [
  {
    label: "No preference",
    value: "none",
    isSole: true,
  },
  {
    label: "I prefer a male therapist",
    value: "male",
  },
  {
    label: "I prefer a female therapist",
    value: "female",
  },
  {
    label: "I prefer a therapist who provides Christian-based therapy",
    value: "christian",
  },
  {
    label: "I prefer an older therapist (45+)",
    value: "older",
  },
  {
    label: "I prefer a non-religious therapist",
    value: "nonreligious",
  },
  {
    label: "I prefer a therapist who speaks Spanish",
    value: "spanish",
  },
  {
    label: "I prefer a therapist of color",
    value: "color",
  },
];

export const SEXUALITY_OPTIONS: SexualityOption[] = [
  {
    value: "none",
    label: "No preference",
    isSole: true,
  },
  {
    value: "straight",
    label: "Straight",
  },
  {
    value: "lesbian",
    label: "Lesbian",
  },
  {
    value: "bisexual",
    label: "Bisexual",
  },
  {
    value: "lgbtq",
    label: "LGBTQIA+",
  },
  {
    value: "gay",
    label: "Gay",
  },
];

export const APPOINTMENT_DATE_OPTIONS: AppointmentDateOption[] = [
  { value: "immediately", label: "Immediately" },
  { value: "today", label: "Today" },
  { value: "next_couple_days", label: "Within the next few days" },
  { value: "none", label: "Not sure" },
];

export const AVAILABILITY_OPTIONS: AvailabilityOption[] = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "waitlist", label: "Waitlist" },
];
export const STATE_OPTIONS: StateOption[] = [
  {
    value: "AL",
    label: "Alabama",
  },
  {
    value: "AK",
    label: "Alaska",
  },
  {
    value: "AZ",
    label: "Arizona",
  },
  {
    value: "AR",
    label: "Arkansas",
  },
  {
    value: "CA",
    label: "California",
  },
  {
    value: "CO",
    label: "Colorado",
  },
  {
    value: "CT",
    label: "Connecticut",
  },
  {
    value: "DE",
    label: "Delaware",
  },
  {
    value: "FL",
    label: "Florida",
  },
  {
    value: "GA",
    label: "Georgia",
  },
  {
    value: "HI",
    label: "Hawaii",
  },
  {
    value: "ID",
    label: "Idaho",
  },
  {
    value: "IL",
    label: "Illinois",
  },
  {
    value: "IN",
    label: "Indiana",
  },
  {
    value: "IA",
    label: "Iowa",
  },
  {
    value: "KS",
    label: "Kansas",
  },
  {
    value: "KY",
    label: "Kentucky",
  },
  {
    value: "LA",
    label: "Louisiana",
  },
  {
    value: "ME",
    label: "Maine",
  },
  {
    value: "MD",
    label: "Maryland",
  },
  {
    value: "MA",
    label: "Massachusetts",
  },
  {
    value: "MI",
    label: "Michigan",
  },
  {
    value: "MN",
    label: "Minnesota",
  },
  {
    value: "MS",
    label: "Mississippi",
  },
  {
    value: "MO",
    label: "Missouri",
  },
  {
    value: "MT",
    label: "Montana",
  },
  {
    value: "NE",
    label: "Nebraska",
  },
  {
    value: "NV",
    label: "Nevada",
  },
  {
    value: "NH",
    label: "New Hampshire",
  },
  {
    value: "NJ",
    label: "New Jersey",
  },
  {
    value: "NM",
    label: "New Mexico",
  },
  {
    value: "NY",
    label: "New York",
  },
  {
    value: "NC",
    label: "North Carolina",
  },
  {
    value: "ND",
    label: "North Dakota",
  },
  {
    value: "OH",
    label: "Ohio",
  },
  {
    value: "OK",
    label: "Oklahoma",
  },
  {
    value: "OR",
    label: "Oregon",
  },
  {
    value: "PA",
    label: "Pennsylvania",
  },
  {
    value: "RI",
    label: "Rhode Island",
  },
  {
    value: "SC",
    label: "South Carolina",
  },
  {
    value: "SD",
    label: "South Dakota",
  },
  {
    value: "TN",
    label: "Tennessee",
  },
  {
    value: "TX",
    label: "Texas",
  },
  {
    value: "UT",
    label: "Utah",
  },
  {
    value: "VT",
    label: "Vermont",
  },
  {
    value: "VA",
    label: "Virginia",
  },
  {
    value: "WA",
    label: "Washington",
  },
  {
    value: "WV",
    label: "West Virginia",
  },
  {
    value: "WI",
    label: "Wisconsin",
  },
  {
    value: "WY",
    label: "Wyoming",
  },
];

export const primaryCredentialOptions: ListOption<PrimaryCredentialType>[] = [
  { value: "i_am_licensed", label: "I’m licensed" },
  {
    value: "i_am_pre_licensed",
    label: "I’m pre-licensed or under supervision",
  },
  { value: "i_have_no_license", label: "I have no license" },
];

export const availabilityOptions = {
  in_person_session_availability: "In-Person",
  telephone_session_availability: "Telephone",
  video_session_availability: "Video",
  online_chat_sessions_availability: "Chat Connect",
};

export const paymentPeriodOptions: ListOption<PaymentPeriod>[] = [
  {
    label: "Monthly",
    value: "month",
  },
  {
    label: "Annually",
    value: "year",
  },
];

export const mentalHealthRoles = [
  {
    label: "Certified Nurse Practitioner (CNP)",
    value: "Certified Nurse Practitioner (CNP)",
  },
  {
    label: "Clinical Social Worker (CSW)",
    value: "Clinical Social Worker (CSW)",
  },
  {
    label: "Doctor of Psychology (PSYD)",
    value: "Doctor of Psychology (PSYD)",
  },
  {
    label: "Licensed Addiction Counselor (LAC)",
    value: "Licensed Addiction Counselor (LAC)",
  },
  {
    label: "Licensed Clinical Professional Counselor (LCPC)",
    value: "Licensed Clinical Professional Counselor (LCPC)",
  },
  {
    label: "Licensed Clinical Psychologist (LCP)",
    value: "Licensed Clinical Psychologist (LCP)",
  },
  {
    label: "Licensed Clinical Social Worker - Registered (LCSW-R)",
    value: "Licensed Clinical Social Worker - Registered (LCSW-R)",
  },
  {
    label: "Licensed Clinical Social Worker (LCSW)",
    value: "Licensed Clinical Social Worker (LCSW)",
  },
  {
    label: "Licensed Independent Clinical Social Worker (LICSW)",
    value: "Licensed Independent Clinical Social Worker (LICSW)",
  },
  {
    label: "Licensed Marriage and Family Therapist (LMFT)",
    value: "Licensed Marriage and Family Therapist (LMFT)",
  },
  {
    label: "Licensed Master Social Worker (LMSW)",
    value: "Licensed Master Social Worker (LMSW)",
  },
  {
    label: "Licensed Mental Health Counselor (LMHC)",
    value: "Licensed Mental Health Counselor (LMHC)",
  },
  {
    label: "Licensed Nurse Practitioner (LNP)",
    value: "Licensed Nurse Practitioner (LNP)",
  },
  {
    label: "Licensed Professional Counselor - Supervisor (LPC-S)",
    value: "Licensed Professional Counselor - Supervisor (LPC-S)",
  },
  {
    label: "Licensed Professional Counselor (LPC)",
    value: "Licensed Professional Counselor (LPC)",
  },
  {
    label: "Licensed Professional Counselor Candidate (LPCC)",
    value: "Licensed Professional Counselor Candidate (LPCC)",
  },
  {
    label: "Licensed Psychologist (LP)",
    value: "Licensed Psychologist (LP)",
  },
  {
    label: "Limited Licensed Master Social Worker (LLMSW)",
    value: "Limited Licensed Master Social Worker (LLMSW)",
  },
  {
    label: "Limited Licensed Psychologist (LLP)",
    value: "Limited Licensed Psychologist (LLP)",
  },
  {
    label: "Marriage and Family Counselor (MFC)",
    value: "Marriage and Family Counselor (MFC)",
  },
  {
    label: "Medical Doctor (MD)",
    value: "Medical Doctor (MD)",
  },
  {
    label: "National Certified Counselor (NCC)",
    value: "National Certified Counselor (NCC)",
  },
  {
    label: "Professional Counselor",
    value: "Professional Counselor",
  },
  {
    label: "Psychiatric Mental Health Nurse Practitioner (PMHNP)",
    value: "Psychiatric Mental Health Nurse Practitioner (PMHNP)",
  },
  {
    label: "Psychiatrist (PSY-MD)",
    value: "Psychiatrist (PSY-MD)",
  },
  {
    label: "Social Worker",
    value: "Social Worker",
  },
];

export const states = [
  { value: "AL", label: "Alabama" },
  { value: "AR", label: "Arkansas" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "IA", label: "Iowa" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "MA", label: "Massachusetts" },
  { value: "MD", label: "Maryland" },
  { value: "ME", label: "Maine" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VA", label: "Virginia" },
  { value: "VT", label: "Vermont" },
  { value: "WA", label: "Washington" },
  { value: "WI", label: "Wisconsin" },
  { value: "WV", label: "West Virginia" },
  { value: "WY", label: "Wyoming" },
];

export const months = [
  {
    label: "January",
    value: "01",
  },
  {
    label: "February",
    value: "02",
  },
  {
    label: "March",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "June",
    value: "06",
  },
  {
    label: "July",
    value: "07",
  },
  {
    label: "August",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];

export type Prefix = "mr" | "ms" | "mrs" | "miss" | "mx";
export type PrefixOption = ListOption<Prefix>;
export const PREFIX_OPTIONS: PrefixOption[] = [
  { value: "mr", label: "Mr." },
  { value: "ms", label: "Ms." },
  { value: "mrs", label: "Mrs." },
  { value: "miss", label: "Miss" },
  { value: "mx", label: "Mx." },
]

export type GenderOption = ListOption<Gender>;
export const GENDER_OPTIONS: GenderOption[] = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "non_binary", label: "Non binary" },
]

export const animalTypes = [
  { value: 1, label: "Dog" },
  { value: 2, label: "Cat" },
]

export const dogBreeds = [
  {
    value: 1,
    label: "Unknown"
  },
  {
    value: 1105,
    label: "Affenpinscher"
  },
  {
    value: 1106,
    label: "Afghan Hound"
  },
  {
    value: 1107,
    label: "Airedale Terrier"
  },
  {
    value: 1108,
    label: "Akita"
  },
  {
    value: 1109,
    label: "Alaskan Malamute"
  },
  {
    value: 1110,
    label: "American English Coonhound"
  },
  {
    value: 1111,
    label: "American Eskimo Dog"
  },
  {
    value: 1112,
    label: "American Foxhound"
  },
  {
    value: 1113,
    label: "American Hairless Terrier"
  },
  {
    value: 1114,
    label: "American Leopard Hound"
  },
  {
    value: 1116,
    label: "American Water Spaniel"
  },
  {
    value: 1117,
    label: "Anatolian Shepherd Dog"
  },
  {
    value: 1118,
    label: "Appenzeller Sennenhunde"
  },
  {
    value: 1119,
    label: "Australian Cattle Dog"
  },
  {
    value: 1120,
    label: "Australian Shepherd"
  },
  {
    value: 1121,
    label: "Australian Terrier"
  },
  {
    value: 1122,
    label: "Azawakh"
  },
  {
    value: 1123,
    label: "Barbet"
  },
  {
    value: 1124,
    label: "Basenji"
  },
  {
    value: 1125,
    label: "Basset Fauve de Bretagne"
  },
  {
    value: 1126,
    label: "Basset Hound"
  },
  {
    value: 1127,
    label: "Bavarian Mountain Scent Hound"
  },
  {
    value: 1128,
    label: "Beagle"
  },
  {
    value: 1129,
    label: "Bearded Collie"
  },
  {
    value: 1130,
    label: "Beauceron"
  },
  {
    value: 1131,
    label: "Bedlington Terrier"
  },
  {
    value: 1132,
    label: "Belgian Laekenois"
  },
  {
    value: 1133,
    label: "Belgian Malinois"
  },
  {
    value: 1134,
    label: "Belgian Sheepdog"
  },
  {
    value: 1135,
    label: "Belgian Tervuren"
  },
  {
    value: 1136,
    label: "Bergamasco Sheepdog"
  },
  {
    value: 1137,
    label: "Berger Picard"
  },
  {
    value: 1138,
    label: "Bernese Mountain Dog"
  },
  {
    value: 1139,
    label: "Bichon Frise"
  },
  {
    value: 1140,
    label: "Biewer Terrier"
  },
  {
    value: 1141,
    label: "Black and Tan Coonhound"
  },
  {
    value: 1142,
    label: "Black Russian Terrier"
  },
  {
    value: 1143,
    label: "Bloodhound"
  },
  {
    value: 1144,
    label: "Bluetick Coonhound"
  },
  {
    value: 1145,
    label: "Boerboel"
  },
  {
    value: 1146,
    label: "Bolognese"
  },
  {
    value: 1147,
    label: "Border Collie"
  },
  {
    value: 1148,
    label: "Border Terrier"
  },
  {
    value: 1149,
    label: "Borzoi"
  },
  {
    value: 1150,
    label: "Boston Terrier"
  },
  {
    value: 1151,
    label: "Bouvier des Flandres"
  },
  {
    value: 1152,
    label: "Boxer"
  },
  {
    value: 1153,
    label: "Boykin Spaniel"
  },
  {
    value: 1154,
    label: "Bracco Italiano"
  },
  {
    value: 1155,
    label: "Braque du Bourbonnais"
  },
  {
    value: 1156,
    label: "Braque Francais Pyrenean"
  },
  {
    value: 1157,
    label: "Briard"
  },
  {
    value: 1158,
    label: "Brittany"
  },
  {
    value: 1159,
    label: "Broholmer"
  },
  {
    value: 1160,
    label: "Brussels Griffon"
  },
  {
    value: 1161,
    label: "Bull Terrier"
  },
  {
    value: 1162,
    label: "Bulldog"
  },
  {
    value: 1164,
    label: "Cairn Terrier"
  },
  {
    value: 1166,
    label: "Cardigan Welsh Corgi"
  },
  {
    value: 1167,
    label: "Carolina Dog"
  },
  {
    value: 1168,
    label: "Catahoula Leopard Dog"
  },
  {
    value: 1169,
    label: "Caucasian Shepherd Dog"
  },
  {
    value: 1170,
    label: "Cavalier King Charles Spaniel"
  },
  {
    value: 1171,
    label: "Central Asian Shepherd Dog"
  },
  {
    value: 1172,
    label: "Cesky Terrier"
  },
  {
    value: 1173,
    label: "Chesapeake Bay Retriever"
  },
  {
    value: 1174,
    label: "Chihuahua"
  },
  {
    value: 1175,
    label: "Chinese Crested"
  },
  {
    value: 1176,
    label: "Chinese Shar-Pei"
  },
  {
    value: 1177,
    label: "Chinook"
  },
  {
    value: 1178,
    label: "Chow Chow"
  },
  {
    value: 1179,
    label: "Cirneco dell’Etna"
  },
  {
    value: 1180,
    label: "Clumber Spaniel"
  },
  {
    value: 1181,
    label: "Cocker Spaniel"
  },
  {
    value: 1182,
    label: "Collie"
  },
  {
    value: 1183,
    label: "Coton de Tulear"
  },
  {
    value: 1184,
    label: "Curly-Coated Retriever"
  },
  {
    value: 1185,
    label: "Czechoslovakian Vlcak"
  },
  {
    value: 1186,
    label: "English Cocker Spaniel"
  },
  {
    value: 1187,
    label: "English Foxhound"
  },
  {
    value: 1188,
    label: "English Setter"
  },
  {
    value: 1189,
    label: "English Springer Spaniel"
  },
  {
    value: 1190,
    label: "English Toy Spaniel"
  },
  {
    value: 1191,
    label: "Entlebucher Mountain Dog"
  },
  {
    value: 1192,
    label: "Estrela Mountain Dog"
  },
  {
    value: 1193,
    label: "Eurasier"
  },
  {
    value: 1194,
    label: "Field Spaniel"
  },
  {
    value: 1195,
    label: "Finnish Spitz"
  },
  {
    value: 1196,
    label: "Flat-Coated Retriever"
  },
  {
    value: 1197,
    label: "French Bulldog"
  },
  {
    value: 1198,
    label: "French Spaniel"
  },
  {
    value: 1199,
    label: "German Longhaired Pointer"
  },
  {
    value: 1200,
    label: "German Pinscher"
  },
  {
    value: 1201,
    label: "German Shepherd Dog"
  },
  {
    value: 1202,
    label: "German Shorthaired Pointer"
  },
  {
    value: 1203,
    label: "German Spitz"
  },
  {
    value: 1204,
    label: "German Wirehaired Pointer"
  },
  {
    value: 1205,
    label: "Giant Schnauzer"
  },
  {
    value: 1206,
    label: "Glen of Imaal Terrier"
  },
  {
    value: 1207,
    label: "Golden Retriever"
  },
  {
    value: 1208,
    label: "Gordon Setter"
  },
  {
    value: 1209,
    label: "Grand Basset Griffon Vendeen"
  },
  {
    value: 1210,
    label: "Great Dane"
  },
  {
    value: 1211,
    label: "Great Pyrenees"
  },
  {
    value: 1212,
    label: "Greater Swiss Mountain Dog"
  },
  {
    value: 1213,
    label: "Greyhound"
  },
  {
    value: 1214,
    label: "Hamiltonstovare"
  },
  {
    value: 1215,
    label: "Hanoverian Scenthound"
  },
  {
    value: 1216,
    label: "Harrier"
  },
  {
    value: 1217,
    label: "Havanese"
  },
  {
    value: 1218,
    label: "Hokkaido"
  },
  {
    value: 1219,
    label: "Hovawart"
  },
  {
    value: 1220,
    label: "Jack Terrier"
  },
  {
    value: 1221,
    label: "Japanese Chin"
  },
  {
    value: 1222,
    label: "Jindo"
  },
  {
    value: 1223,
    label: "Old English Sheepdog"
  },
  {
    value: 1224,
    label: "Otterhound"
  },
  {
    value: 1225,
    label: "Papillon"
  },
  {
    value: 1226,
    label: "Parson Russell Terrier"
  },
  {
    value: 1227,
    label: "Pekingese"
  },
  {
    value: 1228,
    label: "Pembroke Welsh Corgi"
  },
  {
    value: 1229,
    label: "Perro de Presa Canario"
  },
  {
    value: 1230,
    label: "Peruvian Inca Orchid"
  },
  {
    value: 1231,
    label: "Petit Basset Griffon Vendeen"
  },
  {
    value: 1232,
    label: "Pharaoh Hound"
  },
  {
    value: 1233,
    label: "Plott"
  },
  {
    value: 1234,
    label: "Pointer"
  },
  {
    value: 1235,
    label: "Polish Lowland Sheepdog"
  },
  {
    value: 1236,
    label: "Pomeranian"
  },
  {
    value: 1237,
    label: "Poodle"
  },
  {
    value: 1238,
    label: "Porcelaine"
  },
  {
    value: 1239,
    label: "Portuguese Podengo"
  },
  {
    value: 1240,
    label: "Portuguese Podengo Pequeno"
  },
  {
    value: 1241,
    label: "Portuguese Pointer"
  },
  {
    value: 1242,
    label: "Portuguese Sheepdog"
  },
  {
    value: 1243,
    label: "Portuguese Water Dog"
  },
  {
    value: 1244,
    label: "Pug"
  },
  {
    value: 1245,
    label: "Puli"
  },
  {
    value: 1246,
    label: "Pumi"
  },
  {
    value: 1247,
    label: "Pyrenean Mastiff"
  },
  {
    value: 1248,
    label: "Pyrenean Shepherd"
  },
  {
    value: 1249,
    label: "Rafeiro do Alentejo"
  },
  {
    value: 1250,
    label: "Rat Terrier"
  },
  {
    value: 1251,
    label: "Redbone Coonhound"
  },
  {
    value: 1252,
    label: "Rhodesian Ridgeback"
  },
  {
    value: 1253,
    label: "Rottweiler"
  },
  {
    value: 1254,
    label: "Russell Terrier"
  },
  {
    value: 1255,
    label: "Russian Toy"
  },
  {
    value: 1256,
    label: "Russian Tsvetnaya Bolonka"
  },
  {
    value: 1257,
    label: "Taiwan Dog"
  },
  {
    value: 1258,
    label: "Teddy Roosevelt Terrier"
  },
  {
    value: 1259,
    label: "Thai Ridgeback"
  },
  {
    value: 1260,
    label: "Tibetan Mastiff"
  },
  {
    value: 1261,
    label: "Tibetan Spaniel"
  },
  {
    value: 1262,
    label: "Tibetan Terrier"
  },
  {
    value: 1263,
    label: "Tornjak"
  },
  {
    value: 1264,
    label: "Tosa"
  },
  {
    value: 1265,
    label: "Toy Fox Terrier"
  },
  {
    value: 1266,
    label: "Transylvanian Hound"
  },
  {
    value: 1267,
    label: "Treeing Tennessee Brindle"
  },
  {
    value: 1268,
    label: "Treeing Walker Coonhound"
  },
  {
    value: 1269,
    label: "Xoloitzcuintli"
  },
  {
    value: 1270,
    label: "Yakutian Laika"
  },
  {
    value: 1271,
    label: "Yorkshire Terrier"
  },
  {
    value: 1272,
    label: "Dachshund"
  },
  {
    value: 1273,
    label: "Dalmatian"
  },
  {
    value: 1274,
    label: "Dandie Dinmont Terrier"
  },
  {
    value: 1275,
    label: "Danish-Swedish Farmdog"
  },
  {
    value: 1276,
    label: "Deutscher Wachtelhund"
  },
  {
    value: 1277,
    label: "Doberman Pinscher"
  },
  {
    value: 1279,
    label: "Dogue de Bordeaux"
  },
  {
    value: 1280,
    label: "Drentsche Patrijshond"
  },
  {
    value: 1281,
    label: "Drever"
  },
  {
    value: 1282,
    label: "Dutch Shepherd"
  },
  {
    value: 1283,
    label: "Ibizan Hound"
  },
  {
    value: 1284,
    label: "Icelandic Sheepdog"
  },
  {
    value: 1285,
    label: "Irish Red and White Setter"
  },
  {
    value: 1286,
    label: "Irish Setter"
  },
  {
    value: 1287,
    label: "Irish Terrier"
  },
  {
    value: 1288,
    label: "Irish Water Spaniel"
  },
  {
    value: 1289,
    label: "Irish Wolfhound"
  },
  {
    value: 1290,
    label: "Italian Greyhound"
  },
  {
    value: 1291,
    label: "Kai Ken"
  },
  {
    value: 1292,
    label: "Karelian Bear Dog"
  },
  {
    value: 1293,
    label: "Keeshond"
  },
  {
    value: 1294,
    label: "Kerry Blue Terrier"
  },
  {
    value: 1295,
    label: "Kishu Ken"
  },
  {
    value: 1296,
    label: "Komondor"
  },
  {
    value: 1297,
    label: "Kromfohrlander"
  },
  {
    value: 1298,
    label: "Kuvasz"
  },
  {
    value: 1299,
    label: "Maltese"
  },
  {
    value: 1300,
    label: "Manchester Terrier"
  },
  {
    value: 1301,
    label: "Mastiff"
  },
  {
    value: 1302,
    label: "Miniature American Shepherd"
  },
  {
    value: 1303,
    label: "Miniature Bull Terrier"
  },
  {
    value: 1304,
    label: "Miniature Pinscher"
  },
  {
    value: 1305,
    label: "Miniature Schnauzer"
  },
  {
    value: 1306,
    label: "Mudi"
  },
  {
    value: 1307,
    label: "Saluki"
  },
  {
    value: 1308,
    label: "Samoyed"
  },
  {
    value: 1309,
    label: "Schapendoes"
  },
  {
    value: 1310,
    label: "Schipperke"
  },
  {
    value: 1311,
    label: "Scottish Deerhound"
  },
  {
    value: 1312,
    label: "Scottish Terrier"
  },
  {
    value: 1313,
    label: "Sealyham Terrier"
  },
  {
    value: 1314,
    label: "Shetland Sheepdog"
  },
  {
    value: 1315,
    label: "Shiba Inu"
  },
  {
    value: 1316,
    label: "Shih Tzu"
  },
  {
    value: 1317,
    label: "Shikoku"
  },
  {
    value: 1318,
    label: "Siberian Husky"
  },
  {
    value: 1319,
    label: "Silky Terrier"
  },
  {
    value: 1320,
    label: "Skye Terrier"
  },
  {
    value: 1321,
    label: "Sloughi"
  },
  {
    value: 1322,
    label: "Slovensky Cuvac"
  },
  {
    value: 1323,
    label: "Slovensky Kopov"
  },
  {
    value: 1324,
    label: "Small Munsterlander Pointer"
  },
  {
    value: 1325,
    label: "Smooth Fox Terrier"
  },
  {
    value: 1326,
    label: "Soft Coated Wheaten Terrier"
  },
  {
    value: 1327,
    label: "Spanish Mastiff"
  },
  {
    value: 1328,
    label: "Spanish Water Dog"
  },
  {
    value: 1329,
    label: "Spinone Italiano"
  },
  {
    value: 1330,
    label: "St. Bernard"
  },
  {
    value: 1331,
    label: "Stabyhoun"
  },
  {
    value: 1333,
    label: "Standard Schnauzer"
  },
  {
    value: 1334,
    label: "Sussex Spaniel"
  },
  {
    value: 1335,
    label: "Swedish Lapphund"
  },
  {
    value: 1336,
    label: "Swedish Vallhund"
  },
  {
    value: 1337,
    label: "Weimaraner"
  },
  {
    value: 1338,
    label: "Welsh Springer Spaniel"
  },
  {
    value: 1339,
    label: "Welsh Terrier"
  },
  {
    value: 1340,
    label: "West Highland White Terrier"
  },
  {
    value: 1341,
    label: "Whippet"
  },
  {
    value: 1342,
    label: "Wire Fox Terrier"
  },
  {
    value: 1343,
    label: "Wirehaired Pointing Griffon"
  },
  {
    value: 1344,
    label: "Wirehaired Vizsla"
  },
  {
    value: 1345,
    label: "Working Kelpie"
  },
  {
    value: 1346,
    label: "Other"
  },
  {
    value: 1347,
    label: "Mix"
  },
  {
    value: 1348,
    label: "Labrador Retriever"
  },
  {
    value: 1349,
    label: "Lhasa Apso"
  },
  {
    value: 1350,
    label: "Lagotto Romagnolo"
  },
  {
    value: 1351,
    label: "Mixed"
  }
]

export const catBreeds = [
  {
    value: 2,
    label: "Unknown"
  },
  {
    value: 1002,
    label: "Abyssinian"
  },
  {
    value: 1003,
    label: "Aegean"
  },
  {
    value: 1004,
    label: "American Curl"
  },
  {
    value: 1005,
    label: "American Bobtail"
  },
  {
    value: 1006,
    label: "American Shorthair"
  },
  {
    value: 1007,
    label: "American Wirehair"
  },
  {
    value: 1008,
    label: "Arabian Mau"
  },
  {
    value: 1009,
    label: "Australian Mist"
  },
  {
    value: 1010,
    label: "Asian"
  },
  {
    value: 1011,
    label: "Asian Semi-longhair"
  },
  {
    value: 1012,
    label: "Balinese"
  },
  {
    value: 1013,
    label: "Bambino"
  },
  {
    value: 1014,
    label: "Bengal"
  },
  {
    value: 1015,
    label: "Birman"
  },
  {
    value: 1016,
    label: "Bombay"
  },
  {
    value: 1017,
    label: "Brazilian Shorthair"
  },
  {
    value: 1018,
    label: "British Semi-longhair"
  },
  {
    value: 1019,
    label: "British Shorthair"
  },
  {
    value: 1020,
    label: "British Longhair"
  },
  {
    value: 1021,
    label: "Burmese"
  },
  {
    value: 1022,
    label: "Burmilla"
  },
  {
    value: 1023,
    label: "California Spangled"
  },
  {
    value: 1024,
    label: "Chantilly-Tiffany"
  },
  {
    value: 1025,
    label: "Chartreux"
  },
  {
    value: 1026,
    label: "Chausie"
  },
  {
    value: 1027,
    label: "Cheetoh"
  },
  {
    value: 1028,
    label: "Colorpoint Shorthair"
  },
  {
    value: 1029,
    label: "Cornish Rex"
  },
  {
    value: 1030,
    label: "Cymric,"
  },
  {
    value: 1031,
    label: "or Manx Longhair"
  },
  {
    value: 1032,
    label: "Cyprus"
  },
  {
    value: 1033,
    label: "Devon Rex"
  },
  {
    value: 1034,
    label: "Donskoy,"
  },
  {
    value: 1035,
    label: "or Don Sphynx"
  },
  {
    value: 1036,
    label: "Dragon Li"
  },
  {
    value: 1037,
    label: "Dwarf cat,"
  },
  {
    value: 1038,
    label: "or Dwelf"
  },
  {
    value: 1039,
    label: "Egyptian Mau"
  },
  {
    value: 1040,
    label: "European Shorthair"
  },
  {
    value: 1041,
    label: "Exotic Shorthair"
  },
  {
    value: 1042,
    label: "Foldex[4]"
  },
  {
    value: 1043,
    label: "German Rex"
  },
  {
    value: 1044,
    label: "Havana Brown"
  },
  {
    value: 1045,
    label: "Highlander"
  },
  {
    value: 1046,
    label: "Himalayan,"
  },
  {
    value: 1047,
    label: "or Colorpoint Persian"
  },
  {
    value: 1048,
    label: "Japanese Bobtail"
  },
  {
    value: 1049,
    label: "Javanese"
  },
  {
    value: 1050,
    label: "Karelian Bobtail"
  },
  {
    value: 1051,
    label: "Khao Manee"
  },
  {
    value: 1052,
    label: "Korat"
  },
  {
    value: 1053,
    label: "Korean Bobtail"
  },
  {
    value: 1054,
    label: "Korn Ja"
  },
  {
    value: 1055,
    label: "Kurilian Bobtail,"
  },
  {
    value: 1056,
    label: "or Kuril Islands Bobtail"
  },
  {
    value: 1057,
    label: "LaPerm"
  },
  {
    value: 1058,
    label: "Lykoi"
  },
  {
    value: 1059,
    label: "Maine Coon"
  },
  {
    value: 1060,
    label: "Manx"
  },
  {
    value: 1061,
    label: "Mekong Bobtail"
  },
  {
    value: 1062,
    label: "Minskin"
  },
  {
    value: 1063,
    label: "Munchkin"
  },
  {
    value: 1064,
    label: "Nebelung"
  },
  {
    value: 1065,
    label: "Napoleon"
  },
  {
    value: 1066,
    label: "Norwegian Forest cat"
  },
  {
    value: 1067,
    label: "Ocicat"
  },
  {
    value: 1068,
    label: "Ojos Azules"
  },
  {
    value: 1069,
    label: "Oregon Rex"
  },
  {
    value: 1070,
    label: "Oriental Bicolor"
  },
  {
    value: 1071,
    label: "Oriental Shorthair"
  },
  {
    value: 1072,
    label: "Oriental Longhair"
  },
  {
    value: 1073,
    label: "PerFold (Experimental Breed - WCF)"
  },
  {
    value: 1074,
    label: "Persian (Modern Persian Cat)"
  },
  {
    value: 1075,
    label: "Persian (Traditional Persian Cat)"
  },
  {
    value: 1076,
    label: "Peterbald"
  },
  {
    value: 1077,
    label: "Pixie-bob"
  },
  {
    value: 1078,
    label: "Raas"
  },
  {
    value: 1079,
    label: "Ragamuffin"
  },
  {
    value: 1080,
    label: "Ragdoll"
  },
  {
    value: 1081,
    label: "Russian Blue"
  },
  {
    value: 1082,
    label: "Russian White, Black and Tabby"
  },
  {
    value: 1083,
    label: "Sam Sawet"
  },
  {
    value: 1084,
    label: "Savannah"
  },
  {
    value: 1085,
    label: "Scottish Fold"
  },
  {
    value: 1086,
    label: "Selkirk Rex"
  },
  {
    value: 1087,
    label: "Serengeti"
  },
  {
    value: 1088,
    label: "Serrade petit"
  },
  {
    value: 1089,
    label: "Siamese"
  },
  {
    value: 1090,
    label: "Siberian"
  },
  {
    value: 1091,
    label: "Singapura"
  },
  {
    value: 1092,
    label: "Snowshoe"
  },
  {
    value: 1093,
    label: "Sokoke"
  },
  {
    value: 1094,
    label: "Somali"
  },
  {
    value: 1095,
    label: "Sphynx"
  },
  {
    value: 1096,
    label: "Suphalak"
  },
  {
    value: 1097,
    label: "Thai"
  },
  {
    value: 1098,
    label: "Thai Lilac"
  },
  {
    value: 1099,
    label: "Tonkinese"
  },
  {
    value: 1100,
    label: "Toyger"
  },
  {
    value: 1101,
    label: "Turkish Angora"
  },
  {
    value: 1102,
    label: "Turkish Van"
  },
  {
    value: 1103,
    label: "Ukrainian Levkoy"
  },
  {
    value: 1104,
    label: "York Chocolate"
  }
]

export enum LETTER_TYPES {
  ESA_LETTER = 1,
  LANDLORD_FORM = 2
}