import { stepsUi } from "../components";
import useTherapistStep4_1 from "./useTherapistStep4_1";
import ControlledMultiSelect from "common/components/ControlledMultiSelect";
import { isMobile } from "common/utils";
import styles from "./TherapistStep4_1.module.scss";

export function TherapistStep4_1() {
  const { isProcessing, control, handleSubmit, availableIssues, actionsProps } =
    useTherapistStep4_1();

  return (
    <>
      <stepsUi.Header>Specialties & Treatment Preferences</stepsUi.Header>
      <stepsUi.Hint>Select the areas in which you specialize.</stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <stepsUi.Row label="Specialties" subLabel="Your Specialties">
          <ControlledMultiSelect
            name="specialties"
            control={control}
            options={availableIssues}
            placeholder="Select"
            isDisabled={isProcessing}
            className={styles.multiSelect}
            withColumns={!isMobile()}
          />
        </stepsUi.Row>
      </stepsUi.Form>
    </>
  );
}
