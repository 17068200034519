import { BaseSyntheticEvent, createContext } from "react";
import {
  FieldError,
  RegisterOptions,
  UseFormRegister,
  UseFormRegisterReturn,
} from "react-hook-form";

import { Test, TestInputs, TestQuestion } from "types/testProcess.types";
import { Control } from "react-hook-form/dist/types";
import { TestAttemptId, TestAttemptStatus } from "types/testAttempt.types";

interface ITestContext {
  attemptId?: TestAttemptId;
  test: Test | null;
  register: UseFormRegister<TestInputs>;
  control: Control<TestInputs>;
  errors: Record<string, FieldError | undefined>;
  registerQuestion: (
    question: TestQuestion,
    options?: RegisterOptions
  ) => UseFormRegisterReturn;
  getSubmitFunction: (
    status: TestAttemptStatus,
    onSuccess: (attemptId: number) => void
  ) => (e?: BaseSyntheticEvent) => Promise<void>;
  startNewAttempt: () => Promise<void>;
  isSubmitting: boolean;
}

export const TestContext = createContext<ITestContext | undefined>(undefined);
