import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import cn from "classnames";
import { ToggleCheckbox } from "common/UIKit/ToggleCheckbox";
import { PlanCard } from "./PlanCard";
import { PaymentPeriod } from "types/plan.types";
import { plansService } from "services/plansService";
import { SubscriptionPlansLayout } from "../SubscriptionPlansLayout";
import { ConfirmCancellationModal } from "../ConfirmCancellationModal";
import { useAuth } from "hooks/useAuth";
import { Plan } from "types/plan.types";
import { cloneDeep } from "lodash";
import styles from "./Overview.module.scss";
import { Testimonials } from "pages/Auth/components/Testimonials";
import { testimonialsService } from "services/testimonials";
import { TestimonialCard } from "pages/Auth/CTA/components/TestimonialCard/TestimonialCard";

export const overviewPath = "/";

interface Props {
  headerAction?: "logOut" | "goBack";
}

export const Overview = ({ headerAction = "logOut" }: Props) => {
  const { user } = useAuth();
  const { data, isFetched } = useSnapshot(plansService);
  const { featuredTestimonials } = useSnapshot(testimonialsService);
  const [paymentPeriod, setPaymentPeriod] = useState<PaymentPeriod>("month");

  useEffect(() => {
    !isFetched && plansService.list();
    testimonialsService.getFeaturedTestimonials();
  }, []);

  const sliderNodes = featuredTestimonials.map((item) => (
    <TestimonialCard {...item} />
  ));

  const togglePaymentFrequency = () =>
    setPaymentPeriod((prev) => (prev === "month" ? "year" : "month"));

  // Discount in percents:
  // const discount = data
  //   .find((plan) => plan.id === 2)
  //   ?.discount_for_yearly.split(".")[0];

  const discount = Math.max(
    ...data.map((plan) => Number(plan.cost) * 12 - Number(plan.cost_yearly))
  ).toFixed(0);

  const sortCard = (data: readonly Plan[]) => {
    const newData = cloneDeep(data) as Plan[];

    newData.sort((a, b) => a.position_priority - b.position_priority);

    return newData;
  };

  return (
    <SubscriptionPlansLayout headerAction={headerAction}>
      {user && <ConfirmCancellationModal />}
      <div className={styles.overview}>
        <div className={styles.header}>
          <h1>Our plans for you</h1>
          <p>
            Check what plan is best for you. We care about convenience in using
            application, so you can update your plan up or down any time.
          </p>
        </div>
        <div className={styles.paymentFrequency}>
          <div className={styles.frequencySelect}>
            <p
              className={cn(
                styles.payMonthly,
                paymentPeriod === "month" && styles.selected
              )}
            >
              Pay Monthly
            </p>
            <ToggleCheckbox
              onChange={togglePaymentFrequency}
              checked={paymentPeriod === "year"}
            />
            <p
              className={cn(
                styles.payYearly,
                paymentPeriod === "year" && styles.selected
              )}
            >
              Pay Annually
            </p>
          </div>
          <p className={styles.discount}>
            <p>Save ${discount}</p>
          </p>
        </div>
        <div
          className={styles.cards}
          style={{ gridTemplateColumns: `repeat(${data.length}, 1fr)` }}
        >
          {sortCard(data).map((plan) => (
            <PlanCard plan={plan} key={plan.id} period={paymentPeriod} />
          ))}
        </div>
      </div>
      {featuredTestimonials.length > 0 && (
        <Testimonials
          spaceBetween={24}
          className={styles.testimonials}
          sliderNodes={sliderNodes}
          sliderClassname={styles.overviewSlider}
          titleClassname={styles.sliderTitleClassname}
        />
      )}
    </SubscriptionPlansLayout>
  );
};
