import { EditHeader } from "common/components/EditHeader";
import AppSelect from "common/UIKit/Select/Select";
import { Controller } from "react-hook-form";
import Button from "common/UIKit/Button";
import { ErrorMessage } from "common/UIKit/ErrorMessage/ErrorMessage";
import ToggleCheckbox from "common/UIKit/ToggleCheckbox/ToggleCheckbox";
import { ReactComponent as TwoPersonIcon } from "assets/images/icons/two-person.svg";
import { ReactComponent as VideoIcon } from "assets/images/icons/video.svg";
import { ReactComponent as PhoneIcon } from "assets/images/icons/mobile.svg";
import { ReactComponent as ChatIcon } from "assets/images/icons/message.svg";
import {
  CHAT_STATUS_OPTIONS,
  colorStyles,
} from "pages/Therapists/TherapistSteps/TherapistStep11/helpers";
import { ChatStatus } from "types/therapist.types";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { useProfileForm } from "../../useProfileForm";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { Link } from "react-router-dom";
import styles from "./EditAvailability.module.scss";

type Inputs = {
  in_person_session_availability: boolean;
  online_chat_sessions_availability: boolean;
  telephone_session_availability: boolean;
  video_session_availability: boolean;
  offer_free_call: boolean;
  chat_status: ChatStatus;
};

const coveredFields: TherapistFields = [
  "in_person_session_availability",
  "online_chat_sessions_availability",
  "telephone_session_availability",
  "video_session_availability",
  "chat_status",
  "offer_free_call",
];

export const EditAvailability = () => {
  useDashboardNavigation("Edit Availability", {
    goBack: "pop_path",
  });
  const { control, handleSubmit, register, errors } =
    useProfileForm<Inputs>(coveredFields);

  return (
    <section className={styles.editAvailability}>
      <EditHeader />
      <form className={styles.editAvailabilityForm} onSubmit={handleSubmit}>
        <div className={styles.editAvailabilityRow}>
          <div className={styles.editAvailabilityColumn}>
            <div className={styles.editAvailabilityIcon}>
              <TwoPersonIcon />
            </div>
            <h5 className={styles.editAvailabilityTitle}>In-Person</h5>
            <p className={styles.editAvailabilityText}>
              Are you accepting new clients for in-person sessions?
            </p>
            <div className={styles.editAvailabilityColumnButtons}>
              <label className={styles.editAvailabilityCheckbox}>
                <input
                  type="checkbox"
                  {...register("in_person_session_availability")}
                />
                <span data-value="true">Yes</span>
                <span data-value="false">No</span>
              </label>
            </div>
            <ErrorMessage>
              {errors.in_person_session_availability?.message}
            </ErrorMessage>
          </div>
          <div className={styles.editAvailabilityColumn}>
            <div className={styles.editAvailabilityIcon}>
              <PhoneIcon className="phone" />
            </div>
            <h5 className={styles.editAvailabilityTitle}>Telephone</h5>
            <p className={styles.editAvailabilityColumnText}>
              Are you accepting new clients for telephone sessions?
            </p>
            <div className={styles.editAvailabilityColumnButtons}>
              <label className={styles.editAvailabilityCheckbox}>
                <input
                  type="checkbox"
                  {...register("telephone_session_availability")}
                />
                <span data-value="true">Yes</span>
                <span data-value="false">No</span>
              </label>
            </div>
            <ErrorMessage>
              {errors.telephone_session_availability?.message}
            </ErrorMessage>
          </div>
          <div className={styles.editAvailabilityColumn}>
            <div className={styles.editAvailabilityIcon}>
              <VideoIcon />
            </div>
            <h5 className={styles.editAvailabilityTitle}>Video</h5>
            <p className={styles.editAvailabilityColumnText}>
              Are you accepting new clients for video sessions?
            </p>
            <div className={styles.editAvailabilityColumnButtons}>
              <label className={styles.editAvailabilityCheckbox}>
                <input
                  type="checkbox"
                  {...register("video_session_availability")}
                />
                <span data-value="true">Yes</span>
                <span data-value="false">No</span>
              </label>
            </div>
            <ErrorMessage>
              {errors.video_session_availability?.message}
            </ErrorMessage>
          </div>
          <div className={styles.editAvailabilityColumn}>
            <div className={styles.editAvailabilityIcon}>
              <ChatIcon />
            </div>
            <h5 className={styles.editAvailabilityTitle}>Chat Connect</h5>
            <p className={styles.editAvailabilityColumnText}>
              Are you accepting new clients for online sessions?
            </p>
            <div className={styles.editAvailabilityColumnButtons}>
              <label className={styles.editAvailabilityCheckbox}>
                <input
                  type="checkbox"
                  {...register("online_chat_sessions_availability")}
                />
                <span data-value="true">Yes</span>
                <span data-value="false">No</span>
              </label>
            </div>
            <ErrorMessage>
              {errors.online_chat_sessions_availability?.message}
            </ErrorMessage>
          </div>
        </div>
        <Controller
          name="chat_status"
          control={control}
          render={({ field, fieldState }) => (
            <AppSelect
              styles={colorStyles}
              className={styles.editAvailabilityStatus}
              placeholder="Select"
              label="Chat status"
              options={CHAT_STATUS_OPTIONS}
              {...field}
              value={CHAT_STATUS_OPTIONS.find((o) => o.value === field.value)}
              onChange={(nextValue) => {
                const { value } = nextValue as { value: string };
                field.onChange(value);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
        <h3 className={styles.editAvailabilityIntroTitle}>Intro call</h3>
        <sub className={styles.editAvailabilityIntroSubTitle}>
          Do you offer a free 15 minute intro call to discuss clients'
          presenting issues and basic questions? Please do not forget to enter
          your phone number on{" "}
          <Link
            className="g-underline"
            to={"/therapists/dashboard/profile/personal-information/edit"}
          >
            Personal Information page
          </Link>
          .
        </sub>
        <fieldset className={styles.editAvailabilityToggle}>
          <ToggleCheckbox {...register("offer_free_call")} />
          <label>Yes, I offer a free 15-minute intro call.</label>
        </fieldset>
        <ErrorMessage>{errors.offer_free_call?.message}</ErrorMessage>
        <Button className={styles.editAvailabilityButton} type="submit">
          Save
        </Button>
      </form>
    </section>
  );
};
