import { proxy } from "valtio";
import { ListService } from "./listService";
import {
  ChatCommand,
  ChatCommandId,
  ChatCommandData,
  SystemCommands,
} from "types/chatCommands.types";
import { DeepPartial } from "react-hook-form";

export class ChatCommandsService extends ListService<
  ChatCommandId,
  ChatCommand
> {
  private systemPrefix = "/v1/system-settings";
  constructor(args?: any) {
    super({
      ...args,
      prefix: "/v1/chat-commands",
      pageSize: 10,
    });
  }

  async create(data: DeepPartial<ChatCommandData>) {
    this.isFetching = false;
    const newEntity = (await this.http.post(
      `${this.prefix}/`,
      data
    )) as ChatCommand;
    this.data = this.data.filter((entity) => entity.id);
    this.data.push(newEntity);
    this.isFetching = true;
    return newEntity;
  }

  async patch(id: ChatCommandId, data: DeepPartial<ChatCommandData>) {
    this.isFetching = false;
    const newEntity = (await this.http.patch(
      `${this.prefix}/${id}/`,
      data
    )) as ChatCommand;
    this.data = this.data.map((entity) =>
      entity.id === newEntity.id ? newEntity : entity
    );
    this.isFetching = true;
    return newEntity;
  }

  async delete(id: ChatCommandId) {
    this.isFetching = false;
    await this.http.delete(`${this.prefix}/${id}/`);
    this.data = this.data.filter((entity) => entity.id !== id);
    this.isFetching = true;
  }

  add(data: ChatCommand) {
    this.data.push(data);
  }

  async systemList(): Promise<SystemCommands> {
    const data = (await this.http.get(this.systemPrefix)) as SystemCommands;

    return data;
  }
}

export const chatCommandsService = proxy(new ChatCommandsService());
