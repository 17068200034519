import cn from "classnames";
import { useSnapshot } from "valtio";
import { useLayoutEffect } from "react";
import {
  ListFilter,
  SingleFilter,
  therapistListingService,
} from "services/therapistListingService";
import { ReactComponent as SettingsIcon } from "assets/images/icons/settings.svg";
import { ReactComponent as ChevronBottomIcon } from "assets/images/icons/chevron-bottom.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import Button from "common/UIKit/Button";
import Checkbox from "common/UIKit/Checkbox";
import { ToggleCheckbox } from "common/UIKit/ToggleCheckbox";
import {
  availabilityOptions,
  experienceOptions,
  insuranceOptions,
  priceOptions,
} from "../../helpers/options";
import { ListOption } from "constants/options";
import { MobileListFilter } from "./MobileListFilter";
import { THERAPIES } from "../TherapyList/TherapyList";
import { Search } from "../Search";
import styles from "./Filters.module.scss";
//import { SortByDistance } from "../SortByDistance";

const ONLINE_CONSULTATION = availabilityOptions.find(
  ({ value }) => value === "online_chat_sessions_availability"
) as ListOption;
const IN_PERSON_CONSULTATION = availabilityOptions.find(
  ({ value }) => value === "in_person_session_availability"
) as ListOption;

const handleChangeMulti = (
  listName: ListFilter,
  currentItems: readonly ListOption[],
  item: ListOption,
  checked: boolean
) => {
  if (checked) {
    therapistListingService.setListFilter(listName, [...currentItems, item]);
  } else {
    therapistListingService.setListFilter(
      listName,
      currentItems.filter((v) => v.value !== item.value)
    );
  }
};

const handleChangeSingle = (
  filterName: SingleFilter,
  item: ListOption,
  checked: boolean
) => {
  if (checked) {
    therapistListingService.setSingleFilter(filterName, item);
  }
};

export const MobileFilters = () => {
  const {
    totalCount,
    showFilters,
    specialties,
    professionalSpecialties,
    therapyValue,
    professionalSpecialtyValue,
    experienceValue,
    priceValue,
    specialtyValue,
    insuranceValue,
    availabilityValue,
    appliedFilters: applied_filters,
  } = useSnapshot(therapistListingService);

  useLayoutEffect(() => {
    const body = document.body;
    if (showFilters) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "";
    }
  }, [showFilters]);

  return (
    <>
      <Search />
      <div className={styles.filterRow}>
        <button
          className={cn(
            styles.filterButton,
            applied_filters.length && styles.filterButtonActive
          )}
          onClick={() => therapistListingService.toggleShowFilters()}
        >
          <SettingsIcon />
          <div className={styles.filterButtonText}>Filters</div>

          {applied_filters.length > 0 && (
            <div className={styles.filterButtonCount}>
              {applied_filters.length}
            </div>
          )}
          <ChevronBottomIcon />
        </button>
        {/*<SortByDistance />*/}
      </div>
      {showFilters && (
        <div
          className={cn(styles.mobile_root, {
            [styles.padding_bottom]: Boolean(applied_filters.length),
          })}
        >
          <div className={cn(styles.mobile_container)}>
            <div className={styles.mobile_header}>
              <h1 className={styles.mobile_title}>Filters</h1>
              <button
                className={styles.mobile_close}
                onClick={() => therapistListingService.toggleShowFilters()}
              >
                <CloseIcon />
              </button>
            </div>
            <div className={styles.mobile_filters}>
              <div className={styles.mobile_toggle_filter}>
                <span>Show all therpists who offers online consultation</span>
                <ToggleCheckbox
                  checked={
                    availabilityValue.findIndex(
                      (item) => item.value === ONLINE_CONSULTATION.value
                    ) >= 0
                  }
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      therapistListingService.setListFilter(
                        "availabilityValue",
                        [...availabilityValue, ONLINE_CONSULTATION]
                      );
                    } else {
                      therapistListingService.setListFilter(
                        "availabilityValue",
                        availabilityValue.filter(
                          (item) => item.value !== ONLINE_CONSULTATION.value
                        )
                      );
                    }
                  }}
                />
              </div>

              <div className={styles.mobile_toggle_filter}>
                <span>
                  Show all therpists who offers in-person consultation
                </span>
                <ToggleCheckbox
                  checked={
                    availabilityValue.findIndex(
                      (item) => item.value === IN_PERSON_CONSULTATION.value
                    ) >= 0
                  }
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      therapistListingService.setListFilter(
                        "availabilityValue",
                        [...availabilityValue, IN_PERSON_CONSULTATION]
                      );
                    } else {
                      therapistListingService.setListFilter(
                        "availabilityValue",
                        availabilityValue.filter(
                          (item) => item.value !== IN_PERSON_CONSULTATION.value
                        )
                      );
                    }
                  }}
                />
              </div>
              <MobileListFilter title="Specialty">
                {specialties.map((item) => (
                  <div key={item.value} className={styles.mobile_checkbox}>
                    <Checkbox
                      name={item.value}
                      id={item.value}
                      label={item.label}
                      checked={Boolean(
                        specialtyValue.find(
                          (v) => v.value === item.value
                        )
                      )}
                      onChange={(event) =>
                        handleChangeMulti(
                          "specialtyValue",
                          specialtyValue,
                          item,
                          event.currentTarget.checked
                        )
                      }
                    />
                  </div>
                ))}
              </MobileListFilter>
              {/* <MobileListFilter title="Distance">
                {milesOptions.map((item) => (
                  <div key={item.value} className={styles.mobile_checkbox}>
                    <Checkbox
                      name="distanceValue"
                      id={item.label}
                      label={item.label}
                      checked={Boolean(distanceValue?.value === item.value)}
                      onChange={(event) => {
                        if (!event.currentTarget.checked) return;

                        navigator.geolocation.getCurrentPosition(
                          (pos) =>
                            therapistListingService.setGeo({
                              lat: pos.coords.latitude,
                              lng: pos.coords.longitude,
                            }),
                          () =>
                            therapistListingService.setGeo(
                              "Please, approve geo"
                            )
                        );

                        therapistListingService.setSingleFilter(
                          "distanceValue",
                          item
                        );
                      }}
                    />
                  </div>
                ))}
              </MobileListFilter> */}
              <MobileListFilter title="Experience">
                {experienceOptions.map((item) => (
                  <div key={item.value} className={styles.mobile_checkbox}>
                    <Checkbox
                      name="experienceValue"
                      id={item.label}
                      label={item.label}
                      checked={Boolean(experienceValue?.value === item.value)}
                      onChange={(event) =>
                        handleChangeSingle(
                          "experienceValue",
                          item,
                          event.currentTarget.checked
                        )
                      }
                    />
                  </div>
                ))}
              </MobileListFilter>
              <MobileListFilter title="Price">
                {priceOptions.map((item) => (
                  <div key={item.value} className={styles.mobile_checkbox}>
                    <Checkbox
                      name={item.value}
                      id={item.value}
                      label={item.label}
                      checked={Boolean(priceValue?.value === item.value)}
                      onChange={(event) =>
                        handleChangeSingle(
                          "priceValue",
                          item,
                          event.currentTarget.checked
                        )
                      }
                    />
                  </div>
                ))}
              </MobileListFilter>
              <MobileListFilter title="Issues">
                {specialties.length > 0 &&
                  specialties.map((item) => (
                    <div key={item.value} className={styles.mobile_checkbox}>
                      <Checkbox
                        name={item.value}
                        id={item.value}
                        label={item.label}
                        checked={Boolean(
                          specialtyValue.find((v) => v.value === item.value)
                        )}
                        onChange={(event) =>
                          handleChangeMulti(
                            "specialtyValue",
                            specialtyValue,
                            item,
                            event.currentTarget.checked
                          )
                        }
                      />
                    </div>
                  ))}
              </MobileListFilter>
              {/* <MobileListFilter title="Types of Therapy">
                {therapies.map((item) => (
                  <div key={item.value} className={localStyles.mobile_checkbox}>
                    <Checkbox
                      name={item.value}
                      id={item.value}
                      label={item.label}
                      checked={Boolean(
                        therapyValue.find((v) => v.value === item.value)
                      )}
                      onChange={(event) =>
                        handleChangeMulti(
                          "therapyValue",
                          therapies,
                          item,
                          event.currentTarget.checked
                        )
                      }
                    />
                  </div>
                ))}
              </MobileListFilter> */}
              <MobileListFilter title="Therapies">
                {THERAPIES.map((item) => (
                  <div key={item.value} className={styles.mobile_checkbox}>
                    <Checkbox
                      name={item.value}
                      id={item.value}
                      label={item.label}
                      checked={Boolean(
                        therapyValue.find((v) => v.value === item.value)
                      )}
                      onChange={(event) =>
                        handleChangeMulti(
                          "therapyValue",
                          therapyValue,
                          item,
                          event.currentTarget.checked
                        )
                      }
                    />
                  </div>
                ))}
              </MobileListFilter>
              <div className={styles.mobile_toggle_filter}>
                <span>Insurance Accepted</span>
                <ToggleCheckbox
                  checked={insuranceValue?.value === "true"}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      therapistListingService.setSingleFilter(
                        "insuranceValue",
                        insuranceOptions.find(
                          ({ value }) => value === "true"
                        ) as ListOption
                      );
                    } else {
                      therapistListingService.setSingleFilter(
                        "insuranceValue",
                        null
                      );
                    }
                  }}
                />
              </div>
            </div>

            {Boolean(applied_filters.length) && (
              <div className={styles.mobile_buttom}>
                <Button
                  className={styles.mobile_results_button}
                  onClick={() => therapistListingService.toggleShowFilters()}
                >{`Show ${totalCount} results`}</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
