import { ListService } from "services/listService";
import { TestAttempt, TestAttemptId } from "types/testAttempt.types";
import { proxy } from "valtio";

class TestProgressListService extends ListService<TestAttemptId, TestAttempt> {}

export const testProgressListService = proxy(
  new TestProgressListService({
    prefix: "/v1/test-attempts",
    filters: { status: "progress" },
    pageSize: 10,
    sort: {
      started_at: {
        order: "desc",
      },
    },
  })
);
