import { ReactComponent as IconTime } from "assets/images/icons/time.svg";
import { ReactComponent as IconLead } from "assets/images/icons/lead.svg";
import { ReactComponent as IconMoney } from "assets/images/icons/money-bag.svg";
import styles from "./BenefitsSection.module.scss";

export const BenefitsSection = () => {
  return (
    <section className={styles.root}>
      <h2 className={styles.title}>
        Benefits of Joining Therapass as a Therapist
      </h2>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.columnIcon}>
            <IconTime />
          </div>
          <div className={styles.info}>
            <h6 className={styles.columnTitle}>Flexible Hours</h6>
            <p className={styles.columnText}>Lorem ipsum dolor set</p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.columnIcon}>
            <IconLead />
          </div>
          <div className={styles.info}>
            <h6 className={styles.columnTitle}>Client Leads</h6>
            <p className={styles.columnText}>Lorem ipsum dolor set</p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.columnIcon}>
            <IconMoney />
          </div>
          <div className={styles.info}>
            <h6 className={styles.columnTitle}>Reliable Income</h6>
            <p className={styles.columnText}>Lorem ipsum dolor set</p>
          </div>
        </div>
      </div>
    </section>
  );
};
