import { useEffect, useState } from "react";

export const useTimer = () => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const on = () => {
    setIsActive(true);
  };

  const off = () => {
    setSeconds(0);
    setIsActive(false);
  };

  useEffect(() => {
    let interval: any = null;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      interval = null;
    }

    return () => clearInterval(interval);
  }, [isActive]);

  useEffect(() => {
    if (seconds <= 0) {
      off();
    }
  }, [seconds]);

  return {
    seconds,
    setSeconds,
    on,
    off,
  };
};
