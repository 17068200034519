import { proxy } from "valtio";

import { CrudService } from "./crudService";
import { AvailableTest, TestId } from "types/availableTests.types";

export const isFree = (test: AvailableTest) => test.cost === "0.00";

export class AvailableTestsService extends CrudService<TestId, AvailableTest> {
  constructor(args?: any) {
    super({ ...args, prefix: "/v1/psychology-tests" });
  }
}

export const availableTestsService = proxy(new AvailableTestsService());
availableTestsService.init();
