import cn from "classnames";
import { ReactNode, useState } from "react";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { ReactComponent as RemoveIcon } from "assets/images/icons/remove.svg";
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/icons/arrow-up.svg";
import styles from "./Card.module.scss";

interface Value {
  caption?: string;
  text?: ReactNode;
  class?: string;
  el?: ReactNode;
}

interface Props {
  className?: string;
  iconClassName?: string;
  values: Value[];
  icon: string | ReactNode;
  onEdit?: () => void;
  onRemove?: () => void;
  editIcon?: ReactNode;
  removeIcon?: ReactNode;
  buttonClass?: string;
  withAccordion?: boolean;
  accordionContent?: ReactNode;
  withFlex?: boolean;
}

const Card = ({
  className,
  iconClassName,
  values,
  icon,
  onEdit,
  onRemove,
  editIcon = <EditIcon />,
  removeIcon = <RemoveIcon />,
  buttonClass,
  withAccordion,
  accordionContent,
  withFlex = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const withContent = withAccordion && accordionContent && isOpen;

  return (
    <>
      <div
        className={cn(
          styles.card,
          className,
          withAccordion && styles.accordion
        )}
        onClick={() => withAccordion && setIsOpen((prevState) => !prevState)}
      >
        <div className={cn(styles.icon, iconClassName)}>
          {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
        </div>
        {values.map((v, idx) => (
          <div
            key={v.caption}
            className={cn(styles.cell, v.class)}
            style={{
              ...(withFlex && {
                flex: `0 0 ${Math.floor(90 / (values.length + 1))}%`,
              }),
            }}
          >
            {v?.el ? (
              v.el
            ) : (
              <>
                <div className={styles.caption}>{v.caption}</div>
                <div className={styles.text}>{v.text}</div>
              </>
            )}
          </div>
        ))}
        {onEdit && (
          <button
            type="button"
            className={cn(styles.edit, buttonClass, {
              "card__edit--only-btn": !onRemove,
            })}
            aria-label="Edit"
            onClick={onEdit}
          >
            {editIcon}
          </button>
        )}
        {onRemove && (
          <button
            type="button"
            className={cn(styles.remove, buttonClass)}
            aria-label="Remove"
            onClick={onRemove}
          >
            {removeIcon}
          </button>
        )}
        {withAccordion && (
          <button
            type="button"
            className={cn(styles.arrow, buttonClass)}
            aria-label="Remove"
            onClick={onRemove}
          >
            {withContent ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </button>
        )}
      </div>
      {withContent && accordionContent}
    </>
  );
};

export default Card;
