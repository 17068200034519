import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { VerificationMessage } from "common/components/VerificationMessage";
import { useQuery } from "hooks/useQuery";
import { loginPath } from "../../../commonLinks";

export const passwordResetSuccessPath = "/password-reset-success";

export const PasswordResetSuccess = () => {
  const navigate = useNavigate();
  const q = useQuery();

  useEffect(() => {
    let userType = q.get("user_type");
    if (!userType || userType === "null") {
      userType = "patient";
    }

     setTimeout(() => {
       navigate(`/${userType}${loginPath}`);
     }, 5000);
  }, []);

  return (
    <VerificationMessage
      text="Your password has been changed"
      caption="Thank you!"
    />
  );
};
