import { useNavigate } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import { DeepPartial } from "common/utils";
import { Therapist } from "types/therapist.types";
import { authService } from "services/authService";

const useSkipSteps = () => {
  const navigate = useNavigate();
  const { setIsProfileCompleted } = useAuth();

  return async (diff?: DeepPartial<Therapist>) => {
    await authService.patch({
      diff: { ...diff, user: { status: "completed" } },
    });
    await setIsProfileCompleted(true);
    navigate("/therapists/completed");
  };
};

export default useSkipSteps;
