import { useModal } from "hooks/useModal";
import { LoggedOutContainer } from "../LoggedOutContainer/LoggedOutContainer";
import styles from "./LoggedAsTherapistBuyTest.module.scss";
import { Link } from "react-router-dom";
import { authService } from "services/authService";

export const LoggedAsTherapistBuyTest = () => {
    const { isOpen, close } = useModal("LOGGED_AS_THERAPIST_BUY_TEST");

    const onClose = async () => {
        close();
        localStorage.setItem("redirectForBuyTest", "redirect_to=/patients/dashboard/tests?tab_index=0")
        await authService.logout()
    };

    const handleButtonClick = () => {
        close()
    };

    return (
        <LoggedOutContainer
            subTitle={
                <div className={styles.textContainer}>
                    <span>You are logged in as a Therapist.</span>
                    <span>Tests are available for Clients only.</span>
                    <span>
                        Please {" "}
                        <Link to="/patient/login" onClick={onClose}>
                            log in{" "}
                        </Link>
                        as a Client to take Tests.
                    </span>
                </div>
            }
            className={styles.loggedOut}
            isOpen={isOpen}
            close={() => close()}
            onButtonClick={handleButtonClick}
        />
    );
};
