type Period = "AM" | "PM";

type Time = { hour: number; minute: number; period: Period };
export type ISOString = string;

export function parseTimeString(value: string): Time {
  const formatedValue = value.toUpperCase().replace(/\s/g, "");
  const match = formatedValue.match(/(AM|PM)/);
  const period: Period = match ? (match[0] as Period) : "AM";

  const digits = formatedValue.replace(/(AM|PM)/, "");
  const [hourStr, minuteStr] = digits.split(":");

  const hour = parseInt(hourStr);
  const minute = parseInt(minuteStr);

  return { hour, minute, period };
}

export function timeValueToApi(value: string) {
  const [hours, rest] = value.split(":");
  const [minutes, meridiem] = rest.split(" ");

  const numHours = (meridiem === "AM" ? 0 : 1) * 12 + Number(hours);
  const numMinutes = +minutes;

  return `${numHours}:${numMinutes}`;
}

export function increaseHour({ hour, minute, period }: Time): Time {
  // 12:00 PM (Noon) + 1 hour = 1:00 PM
  // 12:00 AM (Midnight) + 1 hour = 1:00 AM
  if (hour === 12) return { hour: 1, minute, period };

  // 11:00AM + 1 hour = 12:00 PM (Noon)
  // 11:00PM + 1 hour = // 12:00 AM (Midnight)
  if (hour === 11)
    return { hour: 12, minute, period: period === "AM" ? "PM" : "AM" };

  return { hour: hour + 1, minute, period };
}

export function decreaseHour({ hour, minute, period }: Time): Time {
  // 12:00 PM (Noon) - 1 hour = 11:00 AM
  // 12:00 AM (Midnight) - 1 hour = 11:00 PM
  if (hour === 12)
    return { hour: 11, minute, period: period === "AM" ? "PM" : "AM" };

  if (hour === 1) return { hour: 12, minute, period };

  return { hour: hour - 1, minute, period };
}

export function getTimeString({ hour, minute, period }: Time) {
  let result = "";

  if (hour < 10) {
    result += "0" + hour;
  } else {
    result += hour;
  }

  result += ":";

  if (minute === 0) {
    result += "00";
  } else if (minute < 10) {
    result += "0" + minute;
  } else {
    result += minute;
  }

  result += " " + period.toUpperCase();

  return result;
}

export function timeToMilitary(value: string) {
  const { hour, minute, period } = parseTimeString(value);

  if (hour === 12 && period === "AM") return `00:${minute}`;

  if (period === "PM") return `${hour + 12}:${minute}`;

  return `${hour}:${minute}`;
}

export function militaryToTime(value: string) {
  const [hourStr, minuteStr] = value.split(":");
  const hourNumber = parseInt(hourStr);
  const hour = hourNumber > 12 ? hourNumber - 12 : hourNumber;
  const period = hourNumber > 12 ? "PM" : "AM";

  return getTimeString({ hour, minute: parseInt(minuteStr), period });
}

export function isoStringToAmerican(str: ISOString) {
  return new Date(str).toLocaleDateString("en-US");
}

export function isDateNotInPast(value: string) {
  const today = new Date();
  const [month, year] = value.split("/");
  const expireDate = new Date();
  expireDate.setFullYear(+year + 2000, +month - 1, 1);

  return today.getTime() < expireDate.getTime();
}

export const getListYears = (countYears: number = 10) => {
  const currentYear = new Date().getFullYear();
  const array = [];

  for (let key = 0; key <= countYears; key++) {
    array.push({
      label: `${currentYear + key}`,
      value: `${currentYear + key}`,
    });
  }

  return array;
};

export const getDaysInMonth = (year: number, month: number) =>
  new Date(year, month, 0).getDate();
