import { useState, ReactNode } from "react";
import cn from "classnames";
import { ReactComponent as IconArrow } from "assets/images/icons/chevron-bottom.svg";
import styles from "./Accordion.module.scss";
import { Collapse } from 'react-collapse';

interface Props {
  tab: ReactNode;
  panel: ReactNode;
  className?: string;
  tabClassName?: string;
  panelClassName?: string;
  iconClassName?: string;
}

export const Accordion = ({
  panel,
  tab,
  className,
  tabClassName,
  panelClassName,
  iconClassName,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cn(styles.root, className, isOpen && styles.active)}
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <div className={cn(styles.tab, tabClassName)}>
        {tab}
        <IconArrow className={cn(styles.arrow, iconClassName)} />
      </div>
      <Collapse isOpened={isOpen}>
       <div className={cn(styles.panel, panelClassName, isOpen && styles.showPanel)}>{panel}</div>
      </Collapse>
    </div>
  );
};
