import FileUpload, {
  Props as FileUploadProps,
  Src,
} from "common/components/FileUpload";

export type FileDataField<TExistingFile> = {
  currentValue: (File | TExistingFile)[];
  toDelete: TExistingFile[];
};
interface Props
  extends Omit<FileUploadProps, "error" | "setError" | "enableReinitialize"> {
  name: string;
  isSubmitting?: boolean;
  value: Src[];
}

function ControlledFileUpload({
  name,
  disabled,
  isSubmitting,
  ...otherProps
}: Props) {

  return (
    <FileUpload
      disabled={isSubmitting || disabled}
      enableReinitialize
      {...otherProps}
    />
  );
}

export default ControlledFileUpload;
