import Input from "common/UIKit/Input";
import { ChangeEvent } from "react";
import { Controller, Control } from "react-hook-form";
import cn from "classnames";
import { RateCard } from "types/rateCards.types";
import { isNaN } from "lodash";
import styles from "./RateForm.module.scss";

interface InputItem {
  label: string;
  name: "name" | "fee" | "pay_sessions_count" | "get_free_count";
  adornment?: string;
  type?: "text";
  price?: boolean;
}

interface Props {
  title: string;
  control?: Control<RateCard>;
  isName?: boolean;
  inputs?: InputItem[];
}

export const RateForm = ({ control, isName, title, inputs }: Props) => {
  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.section}>
        {inputs?.map((input) => (
          <fieldset
            className={cn(styles.field, isName && styles.name)}
            key={input.name}
          >
            <label className={styles.subtitle}>{input.label}</label>
            <Controller
              control={control}
              rules={
                !isName
                  ? {
                      validate: {
                        nonNegative: (value) =>
                          !value || +value > 0 || "Values must be non-negative",
                      },
                      required: {
                        value: true,
                        message: "This field is required.",
                      },
                    }
                  : {
                      required: {
                        value: true,
                        message: "This field is required.",
                      },
                    }
              }
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  value={
                    isName ? field?.value : field?.value ? field.value : ""
                  }
                  error={error?.message}
                  adornment={input.adornment}
                  adornmentVariant="black"
                  withLeftAdornment
                  className={styles.input}
                  min={0}
                  maxLength={isName ? 64 : input?.price ? 9 : 4}
                  isValid={!error}
                  type={input.type || "text"}
                  onChange={
                    isName
                      ? field.onChange
                      : (e: ChangeEvent<HTMLInputElement>) => {
                          const inputValue = e.target.value;
                          const validNumbersWithDot =
                            /(^\d{0,6}$)|(^\d{0,6}\.$)|(^\d{0,6}\.\d{0,2}$)/g;
                          const condition = input?.price
                            ? validNumbersWithDot.test(inputValue)
                            : !isNaN(+inputValue);

                          if (condition) {
                            field.value = inputValue === "" ? "" : inputValue;
                            field.onChange(field.value);
                          }
                        }
                  }
                />
              )}
              name={input.name}
            />
          </fieldset>
        ))}
      </div>
    </>
  );
};
