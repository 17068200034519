import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "common/components/Layout/Layout";
import { useResponsive } from "hooks/useResponsive";
import { Footer } from "pages/Auth/components/Footer/Footer";
import styles from "./SubscriptionPlansLayout.module.scss";
import { LandingHeader } from "pages/Auth/components/LandingHeader/LandingHeader";

interface Props {
  headerAction: "logOut" | "goBack";
}

export const SubscriptionPlansLayout: FC<Props> = ({
  children,
  headerAction,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  return (
    <Layout
      className={styles.root}
      mainClass={styles.subscriptionPlansLayout}
      header={isMobile ? undefined : <LandingHeader />}
      doPadding={isMobile}
      footer={<Footer className={styles.footer} />}
    >
      {children}
    </Layout>
  );
};
