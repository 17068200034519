import { FC } from "react";
import { format } from "date-fns";

import { ProgressLine } from "common/components/ProgressLine";
import { Subscription } from "types/subscription.types";
import styles from "./PlanRenewalCard.module.scss";

import { ReactComponent as CreditCardIcon } from "assets/images/icons/credit-card.svg";

interface Props {
  subscription: Subscription;
}

export const PlanRenewalCard: FC<Props> = ({ subscription }) => {
  const endDate = new Date(subscription.next_payment_at || "");
  const now = new Date();
  const startDate = new Date(subscription.previous_payment_at || "");
  const progress =
    (100 * (now.getTime() - startDate.getTime())) /
    (endDate.getTime() - startDate.getTime());

  return (
    <div className={styles.backdrop}>
      <div className={styles.top}>
        <div className={styles.circle}>
          <CreditCardIcon />
        </div>
        <div className={styles.description}>
          <h3>
            {subscription.cancelled_at
              ? "Your plan will be cancelled on"
              : "Your plan will renew in"}
          </h3>
          <p>
            {format(endDate, "MMMM d, yyyy")}
            {!subscription.cancelled_at && (
              <>
                {` for`}
                <span className={styles.price}>
                  {" "}
                  $ {Number(subscription?.amount || 0).toFixed(0)}.
                </span>
                <span className={styles.cents}>
                  {String(subscription?.amount || 0).slice(-2)}
                </span>
                {` USD`}
              </>
            )}
          </p>
        </div>
      </div>
      {now >= startDate && (
        <ProgressLine className={styles.progress} progress={progress} />
      )}
    </div>
  );
};
