import { ReactNode, FormEventHandler } from "react";
import cn from "classnames";

type Props = {
  children: ReactNode;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  actions?: ReactNode;
  className?: string;
}
export const TherapistStepsForm = ({children, actions, handleSubmit, className}: Props) => (
  <form
    className="therapist-step__form"
    onSubmit={e => {
      e.preventDefault();
      handleSubmit(e);
    }}
    action='#'
  >
    <div className={cn('therapist-step__row', className)}>
      {children}
    </div>
    {actions}
  </form>
)