import { es } from "date-fns/locale";

const ESA_TOKEN_KEY = "esa_token"

type tokenHandler = (token: string | null) => void;

const subscribers = new Set<tokenHandler>();

const getToken = () => localStorage.getItem(ESA_TOKEN_KEY);
const setToken = (newToken: string) => {
  localStorage.setItem(ESA_TOKEN_KEY, newToken);
  subscribers.forEach((cb) => cb(newToken));
};
const removeToken = () => {
  localStorage.removeItem(ESA_TOKEN_KEY);
  subscribers.forEach((cb) => cb(null));
};

export const esaTokenService = {
  get: getToken,
  set: setToken,
  remove: removeToken,
} as const;
