import { useNavigate } from "react-router-dom";
import { InputSmartSearch } from "common/UIKit/InputSmartSearch";
import { therapistListingService } from "services/therapistListingService";
import { useEffect } from "react";
import peoplesPath from "assets/images/peoples/circle-peoples.png";
import styles from "./FindSection.module.scss";

export const FindSection = () => {
  const navigate = useNavigate();
  const handleSelectPlace = (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
  }) => {
    therapistListingService.setPlaceFilter({
      ...(args?.city && { city: args.city }),
      ...(args?.search && { search: args.search }),
      ...(args?.state && { state: args.state }),
      ...(args?.zip && { zip: args.zip }),
      withoutRequest: true,
    });
  };

  useEffect(() => {
    handleSelectPlace({ search: "" });
  }, []);

  return (
    <section className={styles.root}>
      <h3 className={styles.title}>Match to a therapist now</h3>
      <div className={styles.row}>
        <InputSmartSearch
          className={styles.search}
          placeholder="Enter city or zip code"
          onSelectPlace={handleSelectPlace}
          maxLength={200}
          max={200}
          withButton
          onButtonClick={() =>
            navigate("/listing", { state: { withScroll: true } })
          }
          buttonClass={styles.button}
          buttonLabel="Talk to a therapist"
        />
      </div>
      <div className={styles.desc}>
        <img className={styles.image} src={peoplesPath} alt="therapists" />
        <p className={styles.text}>
          <b>+1,000</b> Therapists are waiting for you
        </p>
      </div>
    </section>
  );
};
