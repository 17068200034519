import { proxy } from "valtio";
import { CrudService } from "./crudService";
import { TestimonialId, Testimonial } from "types/testimonials.types";

class TestimonialService extends CrudService<TestimonialId, Testimonial> {
  constructor(args?: any) {
    super({
      ...args,
      pageSize: 1,
      prefix: "/v1/testimonials",
    });
  }
}

export const testimonialService = proxy(new TestimonialService());
testimonialService.init();
