import { ReactComponent as Client } from "assets/images/icons/user-Regular.svg";
import { ReactComponent as Home } from "assets/images/icons/Home.svg";
import { ReactComponent as Credentials } from "assets/images/icons/credentials.svg";
import { ReactComponent as Work } from "assets/images/icons/Work.svg";
import { ReactComponent as Radar } from "assets/images/icons/radar.svg";
import { ReactComponent as Wallet } from "assets/images/icons/wallet-black.svg";
import { ReactComponent as Shield } from "assets/images/icons/shield-done.svg";
import { ReactComponent as Camera } from "assets/images/icons/camera.svg";
import { ReactComponent as Document } from "assets/images/icons/document.svg";
import {
  pathOtherCredentials,
  pathEducation,
  pathStateLicensing,
  pathInsurance,
  pathPersonalStatement,
  pathPhotosAndVideo,
  pathClientFocus,
  pathAddressContact,
  pathAvailability,
  pathPersonalInformation,
  pathTreatmentPreferences,
} from "../pages";
import { fullProfilePath, ProfileCard } from "../../helpers";

export const profileCardData: ProfileCard[] = [
  {
    id: 0,
    name: "Personal Info",
    description: "Manage your Personal info.",
    Icon: Client,
    link: fullProfilePath(`${pathPersonalInformation}/edit`),
  },
  {
    id: 1,
    name: "Business Info",
    description: "Enter your business, company or practice details.",
    Icon: Home,
    link: fullProfilePath(`${pathAddressContact}/edit`),
  },
  {
    id: 12,
    name: "State Licensing",
    description: "Specify your licensing and upload appropriate documents.",
    Icon: Document,
    link: fullProfilePath(`${pathStateLicensing}/edit`),
    props: "education",
  },
  {
    id: 13,
    name: "Education",
    description: "Fill information about your education and practice years.",
    Icon: Credentials,
    link: fullProfilePath(`${pathEducation}/edit`),
    props: "education",
  },
  {
    id: 14,
    name: "Other Credentials",
    description:
      "Add other professional credentials and upload appropriate documents.",
    Icon: Document,
    link: fullProfilePath(`${pathOtherCredentials}/edit`),
    props: "education",
  },
  {
    id: 3,
    name: "Specialties & Treatment",
    description:
      "Identify your top specialties and the main styles you use in your practice.",
    Icon: Work,
    link: fullProfilePath(`${pathTreatmentPreferences}/edit`),
  },
  {
    id: 4,
    name: "Client Focus",
    description:
      "Do you have special insights to clients of a specific age, gender or ethnicity?",
    Icon: Radar,
    link: fullProfilePath(`${pathClientFocus}/edit`),
  },
  {
    id: 6,
    name: "Insurance",
    description: "Add information about insurance companies you work with.",
    Icon: Shield,
    link: fullProfilePath(`${pathInsurance}/edit`),
  },
  {
    id: 7,
    name: "Photo & Video Intro",
    description: "Upload photo and video to your profile.",
    Icon: Camera,
    link: fullProfilePath(pathPhotosAndVideo),
  },
  {
    id: 8,
    name: "Availability",
    description:
      "Let clients know you are accepting new clients in-person and/or online.",
    Icon: Radar,
    link: fullProfilePath(`${pathAvailability}/edit`),
  },
  {
    id: 9,
    name: "Personal Statement",
    description: "Write a little about yourself and your practice.",
    Icon: Wallet,
    link: fullProfilePath(`${pathPersonalStatement}/edit`),
  },
];
