import { useAuth } from "hooks/useAuth";
import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Ethnicity } from "constants/options";
import { step7FullPath } from "../PatientStep7";
import { useSteps } from "hooks/useSteps";
import { Patient } from "types/patient.types";

export const usePatientStep6 = () => {
  const patient = useAuth().user as Patient;
  const [ethnicity, setEthnicity] = useState<Ethnicity>(patient?.preferred_ethnicities || "none");

  const { isSubmitting, error, submit } = useSteps();
  const navigate = useNavigate();

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const ethnicity = event.currentTarget.getAttribute("id") as Ethnicity;
    if (event.currentTarget.checked) setEthnicity(ethnicity);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();

    await submit({
      diff: { preferred_ethnicities: ethnicity },
      onSuccess: () => navigate(step7FullPath),
    });
  };

  return {
    ethnicity,
    isSubmitting,
    error,
    handleChange,
    handleSubmit,
  };
};
