import { stepsUi } from "../components";
import { step8FullPath } from "../TherapistStep8";
import useTherapistStep9_1 from "./useTherapistStep";
import ControlledFileUpload from "common/components/ControlledFileUpload";
import { useResponsive } from "hooks/useResponsive";
import { isIOS } from "common/utils";

export function TherapistStep9_1() {
  const { isMobile } = useResponsive();
  const { handleSubmit, fileUploadProps, isSubmitting, handleSkip } =
    useTherapistStep9_1();

  isIOS();
  return (
    <>
      <stepsUi.Header>Photo & Video intro</stepsUi.Header>
      <stepsUi.Hint>
        Give potential clients a positive first impression with photos & videos.
      </stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={
          <stepsUi.Actions
            onSkip={handleSkip}
            buttons={[
              {
                type: "button",
                color: "green-light",
                children: "Back",
                className: "btn-color-blue",
                isLink: true,
                to: step8FullPath,
              },
              {
                type: "submit",
                children: isMobile ? "Continue" : "Next",
                isLoading: isSubmitting,
              },
            ]}
          />
        }
      >
        <ControlledFileUpload
          accept="image/jpg,image/gif,image/png,image/tiff,image/jpeg"
          multiple
          caption="Drag photos here to start uploading"
          {...fileUploadProps}
        />
      </stepsUi.Form>
    </>
  );
}
