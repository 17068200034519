import { Controller, UseFormSetValue, FieldValues } from "react-hook-form";
import { states } from "constants/options";
import { Control } from "react-hook-form/dist/types";
import { Inputs } from "../../TherapistStep3_1";
import { ChangeEventHandler, FocusEventHandler, useState } from "react";
import cn from "classnames";
import styles from "./StateSelect.module.scss";

interface Props {
  control: Control<Inputs, FieldValues>;
  setValue: UseFormSetValue<Inputs>;
  disabled?: boolean;
  name: keyof Inputs;
}

export const StateSelect = ({ control, disabled, name, setValue }: Props) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [options, setOptions] = useState([...states]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.currentTarget;

    setOptions(
      states.filter(
        (item) =>
          item.label.toLowerCase().match(value.toLowerCase()) ||
          item.value.toLowerCase().match(value.toLowerCase())
      )
    );
    setValue(name, value);
  };

  const handleFocus: FocusEventHandler = () => {
    setIsOptionsVisible(true);
  };

  const handleBlur: FocusEventHandler = () => {
    setIsOptionsVisible(false);
    document.body.style.height = "auto";
  };

  const handleSelect = (value: string) => {
    setValue(name, value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <div className={cn(styles.root)}>
          <div
            className={cn(
              styles.field,
              isOptionsVisible && styles.active,
              disabled && styles.disabled
            )}
          >
            <input
              className={styles.input}
              type="text"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={String(field.value)}
              disabled={disabled}
              placeholder="Select"
            />
          </div>
          {fieldState.error && (
            <div className="error_msg">{fieldState.error}</div>
          )}
          {options.length > 0 && isOptionsVisible && (
            <div className={styles.options}>
              {options.map((item) => (
                <div
                  className={cn(
                    styles.item,
                    (item.value === field.value ||
                      item.label === field.value) &&
                      styles.active
                  )}
                  key={item.value}
                  onMouseDown={() => handleSelect(item.value)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    />
  );
};
