import { FC, SVGProps } from "react";
import { CreditCardCompany } from "types/creditCards.types";

import { ReactComponent as AmericanExpress } from "assets/images/payment/american-express.svg";
import { ReactComponent as ApplePay } from "assets/images/payment/apple-pay.svg";
import { ReactComponent as Discover } from "assets/images/payment/discover.svg";
import { ReactComponent as Echeck } from "assets/images/payment/echeck.svg";
import { ReactComponent as JCB } from "assets/images/payment/jcb.svg";
import { ReactComponent as MasterCard } from "assets/images/payment/mastercard.svg";
import { ReactComponent as PayPal } from "assets/images/payment/paypal.svg";
import { ReactComponent as Visa } from "assets/images/payment/visa.svg";

import { ReactComponent as FallbackLogo } from "assets/images/payment/fallback-icon.svg";

const icons = {
  ApplePay,
  Echeck,
  PayPal,
  JCB,
  Discover,
  AmericanExpress,
  MasterCard,
  Visa,
};

interface Props extends SVGProps<SVGSVGElement> {
  company?: CreditCardCompany;
}

export const CreditCardCompanyIcon: FC<Props> = ({ company, ...props }) => {
  const Icon = (company && icons[company]) || FallbackLogo;

  return <Icon {...props} />;
};
