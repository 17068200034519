import { FC, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { authService } from "services/authService";
import { Therapist } from "types/therapist.types";
import { therapistName } from "common/utils";
import { FormatProfileField } from "common/components/FormatProfileField";
import { ErrorPage } from "pages/ErrorPage";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./VideoQueue.module.scss";
import { ScheduledCall } from "types/clientVideo.types";
import axios from "axios";
import { apiEndpoint, esaApiEndpoint } from "utils/env";
import { Button } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { therapistPatientPath } from "pages/Therapists/TherapistPatient";
import { therapistsHomePath } from "pages/Therapists/TherapistsHome";
import { therapistVideoLandingPath } from "pages/Therapists/TherapistVideoLanding";

export const videoQueuePath = "/video-queue";

export const VideoQueue: FC = () => {
  useDashboardNavigation("Video Chat Queue", { isMainTab: true });
  const user = useSnapshot(authService).user as Therapist;
  useDocumentTitle("Video Chat Queue");
  const [chats, setChats] = useState<ScheduledCall[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${esaApiEndpoint}/therapist/scheduledcalls?id=${user.id}`)
      .then((response) => {
        setChats(response.data.results as ScheduledCall[]);
      });
    // axios
    //   .get(`${esaApiEndpoint}/therapist/scheduledcalls?id=G4jbMjL`)
    //   .then((response) => {
    //     setChats(response.data.results as ScheduledCall[]);
    //   });
  }, [user.id]);

  const viewPatient = (chat: ScheduledCall) => {
    // chat.clientid = 123456;

    navigate(
      `${therapistsHomePath}${therapistVideoLandingPath}?therapistKey=${user.id}&sessionId=${chat.id}&clientId=${chat.clientid}`
    );
  };

  if (!user) return <ErrorPage />;

  return (
    <div className={styles.overview}>
      <section className={styles.greeting}>
        <h1>
          <FormatProfileField maxLength={30}>
            {`Welcome, ${therapistName({
              title: user.title,
              last_name: user.user.last_name,
            })}`}
          </FormatProfileField>
        </h1>
        <small>Check the patients waiting to chat with you.</small>
      </section>
      <section>
        <div className={styles.chats}>
          <table className={styles.table}>
            <tbody className={styles.tableBody}>
              {chats.length > 0 &&
                chats.map((chat) => (
                  <tr key={chat.id} className={styles.tableLine}>
                    <td
                      className={classNames(styles.tableLeft, styles.tableCell)}
                    >
                      {`${chat.ClientFirstName} ${chat.ClientLastName}`}
                    </td>
                    <td className={styles.tableCell}>
                      {new Date(chat.ScheduledStart).toLocaleDateString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        }
                      )}
                    </td>

                    <td className={classNames(styles.tableCell)}>
                      <Button
                        className={styles.tableButton}
                        onClick={() => viewPatient(chat)}
                      >
                        View Patient
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
