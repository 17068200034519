import { toast } from "react-toastify";

export const onRequestToNewWindow = async (urlPromise: Promise<unknown>) => {
  localStorage.setItem("redirect_to_new_window", "true");
  const newWindow = window.open(`${window.location.origin}/loading`);

  try {
    const url = await urlPromise;
    if (newWindow) {
      newWindow.location.href = String(url);
      localStorage.removeItem("redirect_to_new_window");
    }
  } catch (error) {
    newWindow?.close();
    toast("Error", { type: "error" });
  }
};

export const onRequestBuyRateCard = async (urlPromise: Promise<unknown>) => {
  try {
    const url = await urlPromise;
    localStorage.removeItem("buyRateCardData");
    if (typeof url === "string") {
      localStorage.setItem("buyCardUrl", url);
    }
    return url
  } catch (error) {
    toast("Error", {type: "error"});
  }
};

export const scrollToTop = () => {
  try {
    setTimeout(() => window.scrollTo({ top: 0 }));
  } catch (error) {
    window.scrollBy({ top: 0 });
  }
};
