import { FC } from "react";
import { Controller } from "react-hook-form";
import Checkbox from "common/UIKit/Checkbox";
import { EditPatientInformationForm } from "../useEditPatientInformation";
import { sortItems } from "common/utils";

export const Language: FC<EditPatientInformationForm> = ({
  control,
  languages,
}) => {
  return (
    <Controller
      control={control}
      name="preferred_languages"
      render={({ field: { value, onChange } }) => (
        <>
          {sortItems(languages, "order").map((option) => (
            <Checkbox
              key={option.code}
              className="patient-step__checkbox"
              id={option.code}
              name={option.code}
              checked={value.includes(option.code)}
              onChange={(e) => {
                const noPreferenceCode = "no";
                if (
                  option.code === noPreferenceCode &&
                  e.currentTarget.checked
                ) {
                  onChange([noPreferenceCode]);
                } else {
                  const valueWithoutNone = value.filter(
                    (v) => v !== noPreferenceCode
                  );
                  const newValue = e.currentTarget.checked
                    ? [...valueWithoutNone, option.code]
                    : valueWithoutNone.filter((v) => v !== option.code);

                  onChange(newValue);
                }
              }}
            >
              {option.name}
            </Checkbox>
          ))}
        </>
      )}
    />
  );
};
