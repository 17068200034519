import { useEffect, useState } from "react";
import { dictionaryService } from "services/dictionaryService";
import { step8FullPath } from "../TherapistStep8";
import { ListOption } from "constants/options";
import useAnyTherapistStep from "../useAnyTherapistStep";
import { coveredFields } from "./step7Config";
import { step6FullPath } from "../TherapistStep6";
import { DeepPartial, splitInsuranceCompanies, toOptions } from "common/utils";
import useFetch from "hooks/useFetch";
import { Therapist } from "types/therapist.types";

type Inputs = {
  general_insurance_companies: string[];
  npi_number: string | null;
  malpractice_carrier: string;
  membership_id: string;
  additional_insurance_companies: string[];
};

export const useTherapistStep7 = () => {
  const [generalInsuranceCompanies, setGeneralInsuranceCompanies] = useState<
    ListOption[]
  >([]);

  const additionalInsuranceCompanies = useFetch<ListOption[]>(
    () => dictionaryService.getInsuranceCompanies(),
    {
      transformer: toOptions,
    }
  );

  const generic = useAnyTherapistStep<Inputs>(coveredFields, step8FullPath, {
    useFormProps: {
      reValidateMode: "onSubmit",
    },
    prevStep: step6FullPath,
    toFields: ({ insurance_companies, ...rest }: DeepPartial<Therapist>) => {
      const [general_insurance_companies, additional_insurance_companies] =
        splitInsuranceCompanies(
          insurance_companies,
          generalInsuranceCompanies,
          additionalInsuranceCompanies.data
        );
      return {
        ...rest,
        general_insurance_companies,
        additional_insurance_companies,
      };
    },
    toApi: ({
      general_insurance_companies,
      additional_insurance_companies,
      ...rest
    }) => ({
      ...rest,
      insurance_companies: general_insurance_companies?.concat(
        additional_insurance_companies
      ),
    }),
    dependencies: [
      generalInsuranceCompanies.length,
      additionalInsuranceCompanies.data.length,
    ],
  });

  const [isProcessing, setIsProcessing] = useState(true);
  const [newCompanyName, setNewCompanyName] = useState("");

  useEffect(() => {
    (async () => {
      setIsProcessing(true);
      const companies = await dictionaryService.getHealthInsuranceCompanies();
      setGeneralInsuranceCompanies(toOptions(companies));
      setIsProcessing(false);
    })();
  }, []);

  const createOption = async () => {
    setIsProcessing(true);
    const { code, name } = await dictionaryService.createHealthInsuranceCompany(
      newCompanyName
    );
    const newOption = { value: code, label: name };
    setGeneralInsuranceCompanies((prev) => [...prev, newOption]);
    generic.setValue(
      "general_insurance_companies",
      generic.getValues("general_insurance_companies").concat(code)
    );
    setIsProcessing(false);
    setNewCompanyName("");
    return newOption;
  };

  return {
    ...generic,
    isProcessing,
    generalInsuranceCompanies,
    createOption,
    setNewCompanyName,
    newCompanyName,
    additionalInsuranceCompanies: additionalInsuranceCompanies.data,
  };
};
