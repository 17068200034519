import { useJsApiLoader } from "@react-google-maps/api";
import { addressToString } from "common/utils";
import { mapOptions } from "constants/googleMap";
import { useCallback } from "react";

export type Address = {
  // address_line_1: string;
  street_address?: string;
  city?: string;
  state?: string | null;
  zip_code?: string;
};

export const useGeocoder = () => {
  const { isLoaded } = useJsApiLoader(mapOptions);

  const geocoder = useCallback(
    async (address: Address) => {
      if (!isLoaded) throw Error("Google maps api is not loaded");

      const service = new window.google.maps.Geocoder();

      const response = await service.geocode({
        address: `${[address.street_address].filter(Boolean).join(" ")}, ${
          address.city
        }, ${address.state} ${address.zip_code}`,
      });

      return response.results[0];
    },
    [isLoaded]
  );

  return geocoder;
};
