import { AccessController } from "common/components/Layout/AccessController";
import { PhoneConfirmation } from "common/components/PhoneConfirmation/PhoneConfirmation";
import { MessagePopup } from "common/components/MessagePopup";

export const confirmPhonePath = "/confirmPhone"

export const ConfirmPhone = () => {
    return (
        <AccessController
            unauthorized="allowed"
            patient="verification"
            therapist="verification"
        >
            <PhoneConfirmation />
            {/*<MessagePopup />*/}
        </AccessController>
    )
}
