import { useVerification } from "./useVerification";
import { VerificationMessage } from "common/components/VerificationMessage";

export const verifiedPath = "/verify-email";

export function Verification() {
  const { verificationStatus, error } = useVerification();

  let text, caption;
  switch (verificationStatus) {
    case "verifying":
      text = "We are verifying you email";
      caption = "Please wait";
      break;
    case "verified":
      text = "Your email has been verified";
      caption = "Thank you!";
      break;
    case "error":
      text = "Error occurred";
      caption = "";
  }

  return (
    <VerificationMessage
      status={verificationStatus !== "error" ? "success" : "error"}
      caption={caption}
      text={error || text}
    />
  );
}
