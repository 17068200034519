import { Link } from "react-router-dom";
import Layout from "common/components/Layout/Layout";
import { useSnapshot } from "valtio";
import { generalLogicService } from "services/generalLogicService";
import { ReactComponent as CloseIcon } from "assets/images/tick-circle-red.svg";

function ErrorPage() {
  const { error } = useSnapshot(generalLogicService);

  const text = error
    ? `${error.status}${error.message ? `: ${error.message}` : ""}`
    : "Error 404: Page doesn’t exist";

  return (
    <Layout withoutHeader mainClass="verification">
      <section className="verification-container">
        <div className="verification-wrapper">
          <CloseIcon />
          <h1 className="verification-title__header title error-title">
            {text}
          </h1>
          <Link
            className="verification-title__link g-underline"
            onClick={() => generalLogicService.clearError()}
            to="/"
          >
            Go to home page
          </Link>
        </div>
      </section>
    </Layout>
  );
}

export { ErrorPage };
