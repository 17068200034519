import styles from "./ErrorMessage.module.scss";
import React from "react";
import cn from "classnames";

type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  isText?: boolean;
};
export const ErrorMessage = ({ children, style, className, isText }: Props) =>
  children ? (
    <p
      className={cn(styles.container, className, isText && styles.text)}
      style={style}
    >
      {children}
    </p>
  ) : null;
