import { FC } from "react";
import cn from "classnames";

import { Sort } from "services/listService";
import styles from "./SortArrow.module.scss";

import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";

interface Props {
  name: string | string[];
  sort: Sort;
  inverted?: boolean;
}

export const SortArrow: FC<Props> = ({ name, sort, inverted }) => {
  const sortOption = Array.isArray(name)
    ? Object.entries(sort).find(([key, _]) => name.includes(key))?.[1]
    : sort[name];
  const isPointingDown = inverted
    ? sortOption?.order === "asc"
    : sortOption?.order === "desc";

  return (
    <ArrowDown className={cn(styles.arrow, isPointingDown && styles.down)} />
  );
};
