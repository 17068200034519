import { FC, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { AxiosResponse } from "axios";
import cn from "classnames";
import { CreditCard } from "types/creditCards.types";
import Button from "common/UIKit/Button";
import { CreditCardCompanyIcon } from "common/components/CreditCardCompanyIcon";
import { formatCardExpirationDate } from "common/utils";
import { creditCardService } from "services/creditCardService";
import { subscriptionService } from "services/subscriptionService";
import { ReactComponent as CrossIcon } from "assets/images/icons/close-square.svg";
import { isTherapist } from "services/authService";
import { useAuth } from "hooks/useAuth";
import styles from "./CreditCardInfo.module.scss";

interface Props {
  card: CreditCard;
}

export const CreditCardInfo: FC<Props> = ({ card }) => {
  const { isFetched: subscriptionsFetched, activeSubscription } =
    useSnapshot(subscriptionService);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuth();
  const withSubscriptions = user && isTherapist(user);

  const isDefault = activeSubscription?.bank_card === card.id;

  const handleMakeDefault = async () => {
    try {
      await setIsLoading(true);
      await subscriptionService.changeCard({ bank_card: card.id });
      setError("");
    } catch (error) {
      const { data } = error as AxiosResponse;
      setError(data?.errors?.join(". "));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await creditCardService.delete(card.id);
      setError("");
    } catch (error) {
      const { data } = error as AxiosResponse;
      setError(data.errors.join(". "));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (withSubscriptions && !subscriptionsFetched) subscriptionService.list();
  }, [subscriptionsFetched, withSubscriptions]);

  return (
    <div className={styles.creditCardInfo}>
      <div className={cn(styles.card, error && styles.error)}>
        <div className={styles.leftSide}>
          <div className={styles.header}>
            <CreditCardCompanyIcon company={card.card_type} />
            <h2>
              {card.card_type} ending in {card.last_four} •{" "}
              {formatCardExpirationDate(card.expires_at)}
            </h2>
            {isDefault && (
              <div className={styles.defaultCardMessage}>
                Future payments will use this card.
              </div>
            )}
          </div>

          <div className={styles.infoField}>
            <h3>Expires</h3>
            <p>{formatCardExpirationDate(card.expires_at)}</p>
          </div>
          <div className={styles.infoField}>
            <h3>Cardholder</h3>
            <p>{card.cardholder_name}</p>
          </div>
        </div>
        <div className={styles.buttons}>
          {activeSubscription && !isDefault && (
            <Button
              className={styles.makeDefaultButton}
              onClick={handleMakeDefault}
              isLoading={isLoading}
            >
              Make default
            </Button>
          )}
          <Button
            color="outline-gray"
            className={styles.removeCardButton}
            onClick={handleDelete}
            isLoading={isLoading}
          >
            <CrossIcon /> Remove card
          </Button>
        </div>
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};
