import { proxy } from "valtio";
import { HttpService } from "./httpService";
import { ListResponse } from "types/common.types";
import { toQuery } from "../pages/Patients/TherapistsListing/helpers/params";
import { ArticlesList, ArticlesListItem } from "types/articles.types";

class ArticlesService extends HttpService {
  //private cache: Cache = {};
  prefix = "/v1/articles";
  articles: ArticlesList = [];
  article: ArticlesListItem | undefined;
  featuredArticles: ArticlesList = [];
  totalCount = 0;
  isError = false;
  isFetching = false;
  pageSize = 15;
  currentPage = 1;
  sortType = "";
  isInitialized = false;
  category = "";
  currentCategoryTitle = "";
  async list(params?: URLSearchParams) {
    try {
      this.isInitialized = true;
      this.isFetching = true;
      const data = (await this.http.get(
        `${this.prefix}/?${this.getQuery()}`
      )) as ListResponse<ArticlesList>;
      this.articles = data.results as ArticlesList;

      this.totalCount = data.count;
      return this.articles;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return this.articles;
    } finally {
      this.isInitialized = false;
      this.isFetching = false;
    }
  }

  async listFeatured(params?: URLSearchParams) {
    try {
      this.isInitialized = true;
      this.isFetching = true;
      const data = (await this.http.get(
        `${this.prefix}/featured/`
      )) as ListResponse<ArticlesList>;
      this.featuredArticles = data.results as ArticlesList;

      return this.featuredArticles;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return this.featuredArticles;
    } finally {
      this.isInitialized = false;
      this.isFetching = false;
    }
  }

  async getArticleById(id: string) {
    try {
      this.isFetching = true;
      const data = (await this.http.get(
        `${this.prefix}/${id}`
      )) as ListResponse<ArticlesListItem>;
      this.article = data as unknown as ArticlesListItem;
      return this.article;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return this.article;
    } finally {
      this.isFetching = false;
    }
  }

  getQuery() {
    const newParams = {
      page_size: this.pageSize,
      page: this.currentPage,
      ...(this.sortType && {
        ordering: this.sortType,
      }),
      ...(this.category && {
        category: this.category,
      }),
    };

    return new URLSearchParams(toQuery(newParams)).toString();
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.list();
  }

  getSearchParams() {
    let params: Record<string, string> = {};
    this.isError = false;
    params.currentPage = String(this.currentPage);

    if (this.sortType) {
      params.ordering = this.sortType;
    }
    if (this.category) {
      params.category = this.category;
    }

    return new URLSearchParams(params);
  }

  setOrdering(sortType: string) {
    if (sortType) {
      this.currentPage = 1;
      this.sortType = sortType;
    }
    this.list();
  }

  setCurrentCategory(category: string) {
    if (category) {
      this.currentPage = 1;
      this.category = category;
    }
    this.list();
  }

  setCurrentCategoryTitle(title: string) {
    this.currentCategoryTitle = title;
  }

  clearFilters() {
    this.currentPage = 1;
    this.category = "";
    this.sortType = "-published_at";
    this.currentCategoryTitle = "";
    this.list();
  }
}
export const articlesService = proxy(new ArticlesService());
