import { useModal } from "hooks/useModal";
import { esaTokenService } from "services/esaTokenService";
import { tokenService } from "services/tokenService";
import { history } from "utils/history";
import { LoggedOutContainer } from "../LoggedOutContainer/LoggedOutContainer";

export const LoggedOutToCTA = () => {
  const { isOpen, close } = useModal("LOGGED_OUT");

  const handleClose = () => {
    close();
    tokenService.remove();
    esaTokenService.remove();
    history.push("/");
  };

  return (
    <LoggedOutContainer
      isOpen={isOpen}
      close={handleClose}
      onButtonClick={handleClose}
    />
  );
};
