import { Link } from "react-router-dom";
import { ElementType, FC } from "react";
import cn from "classnames";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { isStripeAccess } from "services/authService";
import styles from "./InfoCard.module.scss";

interface Info {
  id: number;
  name: string;
  description: string;
  link: string;
  props?: string;
}

interface Props {
  info: Info;
  Icon: ElementType;
}

export const InfoCard: FC<Props> = ({ info, Icon }) => {
  const user = useAuth().user as Therapist;

  if (info.name.match("Stripe") && !isStripeAccess(user)) return null;
  if (info.name.match("Rate Cards") && !isStripeAccess(user)) return null;

  return (
    <div className={styles.card}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Icon className={cn(styles.img, info.props && styles[info.props])} />
          <div className={styles.editIcon}>{info.name}</div>
        </div>
        <div className={styles.description}>{info.description}</div>
      </div>
      <div className={styles.footer}>
        <Link to={info.link} className={styles.link}>
          Edit information
        </Link>
      </div>
    </div>
  );
};
