import { stepsFullPath } from "../stepsPath";
import { TherapistFields } from "../util";

export const coveredFields: TherapistFields = [
  "user.first_name",
  "user.last_name",
  "user.mobile_phone",
  "middle_name",
  "title",
  "credentials_choice",
  "gender",
  "state",
  "zip_code",
  "street_address",
  "city",
  "longitude",
  "latitude",
];
export const step1Path = "/1";
export const step1FullPath = `${stepsFullPath}${step1Path}`;
export const step1Caption = "Personal Info";
