import { FC, useLayoutEffect, useRef, useState } from "react";
import cn from "classnames";
import "./ShowMore.scss";
import { ReactComponent as Arrow } from "assets/images/icons/arrow.svg";

type Props = {
  className?: string;
  maxHeight: number;
  showMoreText?: string;
  showLessText?: string;
  isTwoColumns?: boolean;
};

export const ShowMore: FC<Props> = (props) => {
  const { children, className, maxHeight, showMoreText, showLessText, isTwoColumns } = props;
  const [isActive, setActive] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLSpanElement>(null);

  const handleToggle = () => {
    setActive(!isActive);
  };

  useLayoutEffect(() => {
    if (containerRef.current && btnRef.current) {
      if (maxHeight > containerRef.current.offsetHeight) {
        btnRef.current.style.visibility = "hidden";
      } else {
        btnRef.current.style.visibility = "visible";
      }
    }
  });

  return (
    <div className={cn("showmore", className, { expanded: !isActive })}>
      <div
        className={cn("showmore__container", isTwoColumns && "two-columns")}
        style={isActive ? { maxHeight } : undefined}
        ref={containerRef}
      >
        {children}
      </div>
      <span ref={btnRef} className="showmore__btn" onClick={handleToggle}>
        {isActive ? (
          <>
            {showMoreText}
            <Arrow />
          </>
        ) : (
          <>
            {showLessText}
            <Arrow />
          </>
        )}
      </span>
    </div>
  );
};

ShowMore.defaultProps = {
  className: "",
  maxHeight: 300,
  showMoreText: "Show More",
  showLessText: "Show Less",
};
