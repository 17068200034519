import { ReactNode, useRef } from "react";
import Controls from "./Controls/Controls";
import { useCurrentTime, usePaused, useTotalDuration } from "./hooks";
import { calcSeekedTime } from "./util";
import styles from "./VideoPlayer.module.scss";
import playIconLarge from "assets/images/icons/play-large.svg";
import useVideoEffect from "../videoCommon";
import { blockEvent } from "common/utils";
import classNames from "classnames";
import { useResponsive } from "hooks/useResponsive";

type Props = {
  src: string;
  adornment?: ReactNode;
  isFullscreen?: boolean;
  className?: string;
  showControls?: boolean
};

function VideoPlayer({ src, adornment, isFullscreen, className, showControls }: Props) {
  const { isMobile } = useResponsive();
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const totalDuration = useTotalDuration(videoRef);
  const currentTimeData = useCurrentTime(videoRef);
  const pausedData = usePaused(videoRef);

  useVideoEffect(
    videoRef,
    () => {
      const keyboardControlsListener = (e: KeyboardEvent) => {
        if ((e.target as HTMLElement | null)?.tagName !== "VIDEO") {
          return;
        }

        switch (e.code) {
          case "Space":
            pausedData.set((curr) => !curr);
            break;
          case "ArrowRight":
            currentTimeData.set((curr) =>
              calcSeekedTime(totalDuration, curr, true)
            );
            break;
          case "ArrowLeft":
            currentTimeData.set((curr) =>
              calcSeekedTime(totalDuration, curr, false)
            );
            break;
          default:
            return;
        }
      };

      document.addEventListener("keyup", keyboardControlsListener);

      return () =>
        document.removeEventListener("keyup", keyboardControlsListener);
    },
    [totalDuration]
  );

  return (
    <div
      ref={containerRef}
      className={classNames(
        styles.playerContainer,
        className,
        isFullscreen && styles.fullScreen
      )}
      onClick={blockEvent}
    >
      <video
        width="100%"
        height="100%"
        ref={videoRef}
        autoPlay={false}
        preload="metadata"
        src={`${src}#t=0.001`}
        onClick={() => pausedData.set((curr) => !curr)}
      />
      {pausedData.val && (
        <img
          src={playIconLarge}
          className={styles.playIconOverlay}
          alt="play"
        />
      )}
      {!!videoRef.current && (
        <Controls
          currentTimeData={currentTimeData}
          pausedData={pausedData}
          totalDuration={totalDuration}
          adornment={adornment}
        />
      )}
    </div>
  );
}

export default VideoPlayer;
