import { useAuth } from "hooks/useAuth";
import { Navigate } from "react-router-dom";
import { therapistDashboardFullPath } from "../TherapistsDashboard";
import { therapistsCompetedFullPath } from "../TherapistsCompleted/TherapistsCompleted";

export function TherapistsHome() {
  const { isProfileCompleted } = useAuth();
  const path = isProfileCompleted
    ? therapistsCompetedFullPath
    : therapistDashboardFullPath;

  return <Navigate to={path} />;
}

export const therapistsHomePath = "/therapists";
