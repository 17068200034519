import { dictionaryService } from "services/dictionaryService";
import useFetch from "hooks/useFetch";
import { step4_2FullPath } from "../TherapistStep4_2";
import { ListOption } from "constants/options";
import { toOptions } from "common/utils";
import useAnyTherapistStep from "../useAnyTherapistStep";
import { coveredFields } from "./step4_1Config";
import { step3_3FullPath } from "../TherapistStep3_3";

type Inputs = {
  specialties: string[];
};

function useTherapistStep4_1() {
  const generic = useAnyTherapistStep<Inputs>(coveredFields, step4_2FullPath, {
    prevStep: step3_3FullPath,
  });

  const issues = useFetch<ListOption[]>(() => dictionaryService.getIssues(), {
    transformer: toOptions,
  });

  return {
    isProcessing: issues.isFetching,
    availableIssues: issues.data,
    ...generic,
  };
}

export default useTherapistStep4_1;
