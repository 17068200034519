import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useSnapshot } from "valtio";

import { AccessController } from "common/components/Layout/AccessController";
import { EmailSent } from "common/components/EmailSent/EmailSent";

import { authService } from "services/authService";

import { useQuery } from "hooks/useQuery";
import isEmail from "validator/lib/isEmail";

import styles from "./VerificationSent.module.scss";

export const verificationPath = "/verification";

function VerificationSent() {
  const q = useQuery();
  const [isResending, setIsResending] = useState(false);
  const { prevSessionUserType } = useSnapshot(authService);

  const email = q.get("email") || "";
  // if (!email || !isEmail(email)) {
  //   return <Navigate to={`/${prevSessionUserType}/login`} />;
  // }
  const handleResend = async () => {
    setIsResending(true);
    await authService.resendEmail(email);
    setIsResending(false);
  };

  return (
    <AccessController
      unauthorized="allowed"
      patient="verification"
      therapist="verification"
    >
      <EmailSent
        email={email}
        type="verification"
        isResending={isResending}
        onResend={handleResend}
      >
        <p className={styles.sign_in}>
          Already have an account?{" "}
          <Link className="g-underline" to={`/${prevSessionUserType}/login`}>
            Log in
          </Link>
        </p>
      </EmailSent>
    </AccessController>
  );
}

export { VerificationSent };
