import { FC } from "react";
import cn from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import { useSnapshot } from "valtio";
import { Controller } from "react-hook-form";
import Button from "common/UIKit/Button";
import Textarea from "common/UIKit/Textarea/Textarea";
import closeSquare from "assets/images/icons/close-square.svg";
import infoCircle from "assets/images/icons/info-circle.svg";
import { Modal } from "common/components/Modal";
import useMessageTherapist from "./useMessageTherapist";
import { useModalDrag } from "hooks/useModalDrag";
import { googleReCaptchaKey } from "utils/env";
import { communicationService } from "services/communicationService";
import { getAvatar, getGenderPronoun, therapistName } from "common/utils";
import { FormatProfileField } from "common/components/FormatProfileField";
import styles from "./MessageTherapistPopup.module.scss";

import avatar from "assets/images/avatar.png";

export const MessageTherapistPopup: FC = () => {
  const { receiver: therapist, senderPhone } =
    useSnapshot(communicationService);

  const {
    isOpen,
    close,
    openChangePhone,
    handleSubmit,
    control,
    isSubmitting,
    currentTab,
    setCurrentTab,
    canSendSms,
  } = useMessageTherapist();

  const therapistDisplayName = therapist && therapistName({ ...therapist });

  const { bind, percentageToClose, transformStyle } = useModalDrag({
    moveDistanceToClose: 300,
    onClose: close,
  });

  return (
    <Modal visible={isOpen} onClose={close} fadePercentage={percentageToClose}>
      <div
        className={cn(styles.messageTherapist, {
          [styles.offline]: currentTab === "email",
        })}
        style={transformStyle}
        {...bind()}
      >
        <button className={styles.popupCloseButton} onClick={close}>
          <img src={closeSquare} alt="close" />
        </button>
        <section className={styles.leftSide}>
          {canSendSms && (
            <div className={styles.tabs}>
              <ul>
                <li
                  className={cn(currentTab === "email" && styles.selectedTab)}
                  onClick={() => setCurrentTab("email")}
                >
                  Email
                </li>
                <li
                  className={cn(currentTab === "sms" && styles.selectedTab)}
                  onClick={() => setCurrentTab("sms")}
                >
                  Chat Connect
                </li>
              </ul>
            </div>
          )}
          <div className={styles.messageTo}>
            <img
              src={
                (therapist?.photos && getAvatar(therapist.photos)?.file) ||
                avatar
              }
              alt="avatar"
            />
            <div className={styles.name}>
              <h4>{currentTab === "sms" ? "Chat to:" : "Email to:"}</h4>
              <p>
                <FormatProfileField maxLength={25}>
                  {therapistDisplayName}
                </FormatProfileField>
              </p>
            </div>
            {canSendSms && (
              <Button
                className={styles.switchButton}
                text={`Switch to ${currentTab === "email" ? "chat" : "email"}`}
                onClick={() =>
                  setCurrentTab((prev) => (prev === "email" ? "sms" : "email"))
                }
              />
            )}
          </div>
          <div className={styles.description}>
            <h1>Initial {currentTab === "sms" ? "chat" : "email"}</h1>
            {currentTab === "sms" ? (
              <p>
                This is your space to write a brief initial message to
                therapist.
              </p>
            ) : (
              <p>
                This is your space to write a brief initial email. You may want
                to provide a little background information about you.
                <br />
                <br />
                Answers to many common questions can be found on the therapist's
                profile page.
              </p>
            )}
          </div>
        </section>
        <section
          className={cn(styles.rightSide, {
            [styles.offline]: currentTab === "email",
          })}
        >
          {currentTab === "email" && therapist?.chat_status === "offline" && (
            <div className={styles.offlineWarning}>
              <img src={infoCircle} alt="info" />
              <p>
                <FormatProfileField maxLength={150}>
                  {`${therapistDisplayName} is currently unavailable for SMS
                messages, please send ${getGenderPronoun(
                  therapist?.gender,
                  "object"
                )} an email.`}
                </FormatProfileField>
              </p>
            </div>
          )}
          <form id="messageTherapist" onSubmit={handleSubmit}>
            <Controller
              control={control}
              rules={{
                required: "Required field",
                maxLength: { value: 160, message: "Message is too long" },
              }}
              name="message"
              render={({ field, fieldState }) => (
                <Textarea
                  id="message"
                  type="textarea"
                  className={`${styles.message} ${styles.input}`}
                  placeholder="Type your message here..."
                  label="Message"
                  currentLength={field.value.length}
                  maxLength={160}
                  {...field}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="captcha"
              rules={{
                required: "Please verify that you are not a robot",
              }}
              render={({ fieldState: { error }, field: { onChange } }) => (
                <div className={styles.captchaContainer}>
                  <ReCAPTCHA
                    sitekey={googleReCaptchaKey}
                    onChange={onChange}
                    className={cn(styles.captcha, error && styles.error)}
                  />
                  {error && (
                    <p className={styles.errorMessage}>{error.message}</p>
                  )}
                </div>
              )}
            />
            <Button
              type="submit"
              text={currentTab === "email" ? "Send email" : "Submit"}
              isLoading={isSubmitting}
            />
          </form>
        </section>
      </div>
    </Modal>
  );
};
