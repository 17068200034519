import { FC } from "react";
import cn from "classnames";
import { useModal } from "hooks/useModal";
import { useResponsive } from "hooks/useResponsive";
import { Modal } from "common/components/Modal";
import Radiobutton from "common/UIKit/Radiobutton";
import Button from "common/UIKit/Button";
import { CreditCardCompanyIcon } from "common/components/CreditCardCompanyIcon";
import { formatCardExpirationDate } from "common/utils";
import { CreditCard } from "types/creditCards.types";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { ReactComponent as PlusBigIcon } from "assets/images/icons/plus-big.svg";
import styles from "./ChooseCreditCard.module.scss";
import AddButton from "../../UIKit/AddButton/AddButton";

interface Props {
  options: readonly CreditCard[];
  value?: CreditCard;
  onChange: (selected: CreditCard) => void;
}

export const ChooseCreditCard: FC<Props> = ({ options, value, onChange }) => {
  const { isOpen, close } = useModal("CHOOSE_CREDIT_CARD");
  const { open } = useModal("ADD_CREDIT_CARD");
  const { isMobile } = useResponsive();

  return (
    <Modal className={styles.wrapper} visible={isOpen} onClose={close}>
      <div className={styles.chooseCreditCard}>
        {isMobile && <div className={styles.mobileLine} />}
        <button className={styles.closeButton} onClick={close}>
          <CloseIcon />
        </button>
        <h1>Choose credit card</h1>
        <div className={styles.cardSelect}>
          {options.map((card) => (
            <div
              key={card.id}
              className={cn(
                styles.card,
                value?.id === card.id && styles.selected
              )}
              onClick={() => onChange(card)}
              role="radio"
              aria-checked={value?.id === card.id}
            >
              <Radiobutton
                checked={value?.id === card.id}
                onChange={() => onChange(card)}
              />
              <div className={styles.cardInfo}>
                <CreditCardCompanyIcon company={card.card_type} />
                <div className={styles.text}>
                  <h2>{`${card.card_type} ending in ${card.last_four}`}</h2>
                  <p>
                    {`Expires on ${formatCardExpirationDate(
                      card.expires_at,
                      true
                    )}`}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <AddButton className={styles.addAnotherCard} onClick={open}>
          Add another credit card
        </AddButton>
        {isMobile && (
          <Button className={styles.selectThisCard} onClick={close}>
            Select this card
          </Button>
        )}
      </div>
    </Modal>
  );
};
