import React, { FC } from "react";
import { Controller } from "react-hook-form";

import { EditPatientInformationForm } from "../useEditPatientInformation";
import { THERAPY_TYPE_OPTIONS } from "constants/options";
import styles from "../EditPatientInformation.module.scss";

export const TypesOfTherapy: FC<EditPatientInformationForm> = ({ control }) => {
  return (
    <div className={styles.therapyTypes}>
      <Controller
        control={control}
        name="therapy_types"
        render={({ field: { value, onChange } }) => (
          <>
            {THERAPY_TYPE_OPTIONS.map((option) => (
              <div key={option.value} className={styles.therapyTypesImage}>
                <input
                  className={styles.therapyTypesInput}
                  type="checkbox"
                  id={option.value}
                  name={option.value}
                  checked={value.includes(option.value)}
                  onChange={(e) =>
                    onChange(
                      e.currentTarget.checked
                        ? [...value, option.value]
                        : value.filter((v) => v !== option.value)
                    )
                  }
                />
                <label htmlFor={option.value}>{option.label}</label>
              </div>
            ))}
          </>
        )}
      />
    </div>
  );
};
