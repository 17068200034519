import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";
import Button from "common/UIKit/Button";
import { testProcessService } from "services/testProcessService";
import { Block } from "../components/Block";
import { testsStartPath } from "../TestStart";
import { EditHeader } from "common/components/EditHeader";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useTest } from "hooks/useTest";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import styles from "./TestPrepare.module.scss";
import { esaStep1FullPath } from "pages/Patients/PatientSteps/EsaStep1";
import { useState } from "react";
import { TestBlock } from "types/testProcess.types";

export const testsPreparePath = "/prepare";

interface Props {
  isIntake: boolean;
}

export const TestPrepare = ({ isIntake }: Props) => {
  const { test } = useSnapshot(testProcessService);
  const navigate = useNavigate();
  useDashboardNavigation(test?.name || "Unknown Test", {
    isNavigationHidden: true,
    goBack: () => navigate("./../.."),
  });
  const { getSubmitFunction, isSubmitting, errors } = useTest();

  const introBlocks = test?.blocks.filter((block) => block.is_intro);

  const handleContinue = getSubmitFunction("progress", (attemptId: number) => {
    if (isIntake) {
      navigate(`${esaStep1FullPath}/test/${attemptId}${testsStartPath}`);
    } else {
      navigate(`../../${attemptId}${testsStartPath}`);
    }
  });

  const [questionNum, setQuestionNum] = useState(0);
  const [questionsAnswered, setquestionsAnswered] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>());

  const onNext = (block: TestBlock) => {
    if (introBlocks?.length) {
      if (questionNum < block.questions.length - 1) {
        setQuestionNum(questionNum + 1);
      }
    }
  };

  const onBack = (block: TestBlock) => {
    if (introBlocks?.length) {
      if (questionNum > 0) {
        setQuestionNum(questionNum - 1);
      }
    }
  };

  const currentBlock = introBlocks ? introBlocks[0] : null;

  return (
    <form onSubmit={handleContinue} className="default-typography">
      {/* <EditHeader withoutBack /> */}

      {errors.global && <ErrorMessage>{errors.global.message}</ErrorMessage>}
      {currentBlock && (
        <Block
          block={currentBlock}
          key={currentBlock.id}
          questionNum={questionNum}
          questionsAnswered={questionsAnswered}
          setquestionsAnswered={setquestionsAnswered}
        />
      )}
      {currentBlock && questionNum + 1 < currentBlock.questions.length && (
        <div className={styles.rowButtons}>
          <Button
            color="green-light"
            onClick={() => {
              onBack(currentBlock);
            }}
            disabled={questionNum === 0}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              onNext(currentBlock);
            }}
            disabled={
              !questionsAnswered.get(currentBlock.questions[questionNum].key)
            }
          >
            Next
          </Button>
        </div>
      )}

      <div className="text-center mb-30">
        <h1 className="mb-10">{test?.name}</h1>

        <ul className="list-with-line-divider">
          <li className="list-with-line-divider__item">{`${test?.time_to_complete}\u00A0minutes`}</li>
        </ul>
      </div>

      <div className={styles.surveyInstruction}>
        <h5 className="mb-25">Instructions</h5>
        <div className="text-block mb-30">
          <p>{test?.description}</p>
        </div>
        <div className="text-center">
          {(!currentBlock ||
            questionNum + 1 === currentBlock?.questions.length) && (
            <Button
              type="submit"
              size="sm"
              text="Start testing"
              isLoading={isSubmitting}
            />
          )}
        </div>
      </div>
    </form>
  );
};
