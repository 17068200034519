import { Src } from "../helpers";
import { Preview } from "./Preview";
import { RemoveBtn } from "./RemoveBtn";
import cn from "classnames";
import styles from "./parts.module.scss";

type TargetedFn = (index: number, id?: number) => void;

export type MultiPreviewProps = {
  preview: Src[];
  removeFileByIndex: TargetedFn;
  openRemovalOverlay: TargetedFn;
  disabled?: boolean;
};

export function MultiPreview({
  preview,
  removeFileByIndex,
  openRemovalOverlay,
  disabled,
}: MultiPreviewProps) {
  return (
    <div className={styles.multiPreviewContainer}>
      {preview?.map((src, index) => (
        <div
          key={index}
          className={cn(src.type === "image" && styles.type_image)}
        >
          <Preview
            file={src}
            openRemovalOverlay={() => openRemovalOverlay(index)}
          />
          <RemoveBtn
            disabled={disabled}
            handleRemoveFile={() => removeFileByIndex(index, src.id)}
          />
        </div>
      ))}
    </div>
  );
}
