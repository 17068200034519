import useAnyTherapistStep from "../useAnyTherapistStep";
import { coveredFields } from "./step6Config";
import { step7FullPath } from "../TherapistStep7";
import { step5FullPath } from "../TherapistStep5";
import { FormOfPayment } from "types/therapist.types";

export type Inputs = {
  inPerson: number | null;
  onlineIndividual: number | null;
  form_of_payment: readonly FormOfPayment[];
};

function useTherapistStep6() {
  return useAnyTherapistStep<Inputs>(coveredFields, step7FullPath, {
    prevStep: step5FullPath,
    toFields: (v) => ({
      ...v,
      inPerson: v.in_person_session_cost_min,
      onlineIndividual: v.online_individual_session_cost_min,
    }),
    toApi: ({ form_of_payment, onlineIndividual, inPerson }) => ({
      form_of_payment,
      in_person_session_cost_min: (inPerson && inPerson) || null,
      in_person_session_cost_max: inPerson ? inPerson : null,
      online_individual_session_cost_min:
        (onlineIndividual && onlineIndividual) || null,
      online_individual_session_cost_max: onlineIndividual
        ? onlineIndividual
        : null,
    }),
  });
}

export default useTherapistStep6;
