import { useCallback } from "react";
import { stepsUi } from "../components";
import { step9_1FullPath } from "../TherapistStep9_1";
import ControlledFileUpload from "common/components/ControlledFileUpload";
import useTherapistStep9_2 from "./useTherapistStep9_2";
import { BrowseButton } from "common/components/FileUpload";
import Button from "common/UIKit/Button";
import VideoRecorder from "common/components/Video/Recorder";
import VideoPlayer from "common/components/Video/Player";
import arrowRight from "assets/images/icons/arrow-right.svg";
import removeIcon from "assets/images/icons/remove.svg";
import { useResponsive } from "hooks/useResponsive";
import { ReactComponent as ComputerIcon } from "assets/images/computer-icon.svg";
import styles from "./TherapistStep9_2.module.scss";

export function MoveOnButton(props: {
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <button className={styles.moveOnBtn} type="submit" {...props}>
      <img src={arrowRight} alt="arrow-right" />
    </button>
  );
}

export function TherapistStep9_2() {
  const { isMobile } = useResponsive();
  const { existingFilesLogic, recordedVideoLogic, modeSwitchingLogic } =
    useTherapistStep9_2();

  const RequestRecordingButton = useCallback(
    () => (
      <Button
        className={styles.startRecordingBtn}
        onClick={modeSwitchingLogic.requestRecording}
      >
        Start recording
      </Button>
    ),
    []
  );

  if (modeSwitchingLogic.isRecording) {
    const { recordingResult, onRecorded, isUploading, uploadRecording } =
      recordedVideoLogic;
    return (
      <>
        <stepsUi.Header>Recording Video</stepsUi.Header>
        <div className={styles.container}>
          <button
            type="button"
            className={styles.cancelButton}
            onClick={modeSwitchingLogic.cancelRecording}
          >
            <img src={removeIcon} alt="remove" />
          </button>

          {recordingResult ? (
            <VideoPlayer
              src={URL.createObjectURL(recordingResult)}
              adornment={
                <MoveOnButton
                  onClick={uploadRecording}
                  disabled={isUploading}
                />
              }
            />
          ) : (
            <VideoRecorder onRecorded={onRecorded} />
          )}
        </div>
      </>
    );
  }

  const { handleSubmit, isSubmitting, fileUploadProps, handleSkip } =
    existingFilesLogic;

  return (
    <>
      <stepsUi.Header>Photo & Video intro</stepsUi.Header>
      <stepsUi.Hint>
        Give potential clients a positive first impression with photos & videos.
      </stepsUi.Hint>
      {fileUploadProps.value.length === 0 && (
        <div className={styles.description}>
          <div className={styles.icon}>
            <ComputerIcon />
          </div>
          <h2>Video increases your impact</h2>
          <p>
            Introduce yourself with a 30 to 60-second video and increase your
            range up to 50%.
          </p>
        </div>
      )}
      <stepsUi.Form
        handleSubmit={handleSubmit}
        className={styles.form}
        actions={
          <stepsUi.Actions
            onSkip={handleSkip}
            buttons={[
              {
                type: "button",
                color: "green-light",
                children: "Back",
                className: "btn-color-blue",
                isLink: true,
                to: step9_1FullPath,
              },
              {
                type: "submit",
                children: isMobile ? "Continue" : "Next",
                isLoading: isSubmitting,
              },
            ]}
          />
        }
      >
        <ControlledFileUpload
          accept="video/*"
          hideAcceptHint
          caption="Drag files here to start uploading"
          actions={[BrowseButton, RequestRecordingButton]}
          isFileUploading={isSubmitting}
          {...fileUploadProps}
        />
      </stepsUi.Form>
    </>
  );
}
