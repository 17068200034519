import { Routes, Route } from "react-router-dom";
import {
  EditTreatmentAndSpecialties,
  pathTreatmentPreferences,
  pathClientFocus,
  EditAvailability,
  pathAvailability,
  EditClientFocus,
  pathPersonalInformation,
  EditPersonalInformation,
  pathAddressContact,
  EditAddressContact,
  pathPhotosAndVideo,
  PhotosEdit,
  VideosEdit,
  pathPersonalStatement,
  EditPersonalStatement,
  pathInsurance,
  EditInsurance,
  EditEducation,
  pathEducation,
  PhotosAndVideos,
  AddStateLicensing,
  EditStateLicensing,
  pathStateLicensing,
  AddEducation,
  AddOtherCredential,
  EditOtherCredentials,
  pathOtherCredentials,
} from "./pages";
import { editPath } from "../helpers";
import { ColumnPage } from "../components/ColumnPage/ColumnPage";
import { profileCardData } from "./helpers";

export const profilePath = "/profile";
export const fullProfilePath = "/therapists/dashboard/profile";

export const Profile = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={<ColumnPage data={profileCardData} title="Profile" />}
      />
      <Route path={editPath(pathInsurance)} element={<EditInsurance />} />
      <Route
        path={editPath(pathPersonalStatement)}
        element={<EditPersonalStatement />}
      />
      <Route
        path={editPath(pathStateLicensing)}
        element={<EditStateLicensing />}
      />
      <Route path={editPath(pathEducation)} element={<EditEducation />} />
      <Route
        path={editPath(pathOtherCredentials)}
        element={<EditOtherCredentials />}
      />
      <Route
        path={`${editPath(pathEducation)}/add`}
        element={<AddEducation />}
      />
      <Route
        path={`${editPath(pathEducation)}/:id`}
        element={<AddEducation />}
      />
      <Route
        path={`${editPath(pathStateLicensing)}/add`}
        element={<AddStateLicensing />}
      />
      <Route
        path={`${editPath(pathStateLicensing)}/:id`}
        element={<AddStateLicensing />}
      />
      <Route
        path={`${editPath(pathOtherCredentials)}/add`}
        element={<AddOtherCredential />}
      />
      <Route
        path={`${editPath(pathOtherCredentials)}/:id`}
        element={<AddOtherCredential />}
      />
      <Route path={pathPhotosAndVideo} element={<PhotosAndVideos />} />
      <Route path={editPath("photo")} element={<PhotosEdit />} />
      <Route path={editPath("video")} element={<VideosEdit />} />
      <Route
        path={editPath(pathPersonalInformation)}
        element={<EditPersonalInformation />}
      />
      <Route path={editPath(pathAvailability)} element={<EditAvailability />} />
      <Route
        path={editPath(pathAddressContact)}
        element={<EditAddressContact />}
      />
      <Route
        path={editPath(pathTreatmentPreferences)}
        element={<EditTreatmentAndSpecialties />}
      />
      <Route path={editPath(pathClientFocus)} element={<EditClientFocus />} />
    </Routes>
  );
};
