import { proxy } from "valtio";
import axios, { AxiosError } from "axios";
import { esaApiEndpoint } from "../utils/env";
import { generalLogicService } from "./generalLogicService";

class EsaTestService {
  handleError = (error: AxiosError) => {
    if (error.response) {
      const { status, data } = error.response;
      const errorMessage = data.detail || error.message;

      const serverError = status >= 500;
      if (serverError) {
        generalLogicService.setError({
          status,
          message: errorMessage,
          type: "critical",
        });
      }
    }
  }

  async submitTest(value: any){
    const payload = JSON.stringify(value);
    const meta = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await axios.post(
      `${esaApiEndpoint}/Client/submittest`,
      payload,
      meta
    ).catch((error)=>{
      this.handleError(error);
    });
  }
}

export const esaTestService = proxy(new EsaTestService());
