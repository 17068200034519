import { AxiosResponse } from "axios";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import { authService } from "services/authService";
import { SignUpArgs } from "types/auth.types";
import { confirmPhonePath } from "../../ConfirmPhone/ConfirmPhone";

type Values = SignUpArgs & { terms: boolean };

type UserErrorMessages = Partial<
  Record<keyof SignUpArgs | "non_field_errors", string[]>
>;

type ErrorResponse = {
  user: UserErrorMessages;
};

export default function useSignUp() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, dirtyFields },
    control,
  } = useForm<Values>();

  const [globalError, setGlobalError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { userType } = useParams();

  const onSubmit: SubmitHandler<Values> = async (values) => {
    try {
      setIsLoading(true);
      const { user } = await authService.signUp({...values});
      navigate({
        pathname: confirmPhonePath,
        search: `?phone=${values.mobile_phone}&user_id=${user.id}&userType=${userType}`,
      });
    } catch (error) {
      const {
        data: { user },
      } = error as AxiosResponse<ErrorResponse>;

      (Object.keys(user) as (keyof UserErrorMessages)[]).map((key) => {
        const errors = user[key];
        const message = errors && errors[0];

        if (key !== "non_field_errors" && message) {
          return setError(key, { message });
        }

        return null;
      });

      user.non_field_errors && setGlobalError(user.non_field_errors[0]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors: { ...errors, global: globalError },
    setGlobalError,
    isLoading,
    dirtyFields,
    control,
  };
}
