import React from "react";
import styles from "./LocalVideoPreview.module.scss";
import { Typography } from "@mui/material";
import useVideoContext from "hooks/useVideoContext";
import AvatarIcon from "icons/AvatarIcon";
import { LocalVideoTrack } from "twilio-video";
import VideoTrack from "pages/ClientVideo/VideoTrack/VideoTrack";
import LocalAudioLevelIndicator from "./LocalAudioLevelIndicator";

export default function LocalVideoPreview({ identity }: { identity: string }) {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    (track) => !track.name.includes("screen") && track.kind === "video"
  ) as LocalVideoTrack;

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <div className={styles.avatarContainer}>
            <AvatarIcon />
          </div>
        )}
      </div>

      <div className={styles.identityContainer}>
        <span className={styles.identity}>
          <LocalAudioLevelIndicator />
          <Typography variant="body1" color="inherit" component="span">
            {identity}
          </Typography>
        </span>
      </div>
    </div>
  );
}
