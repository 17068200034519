import cn from "classnames";
import isEmail from "validator/es/lib/isEmail";

import { AuthLayout } from "../../components";
import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import { Link } from "react-router-dom";
import { loginPath } from "../../../commonLinks";
import { useForgot } from "./useForgot";
import styles from "./Forgot.module.scss";

export const Forgot = () => {
  const { register, handleSubmit, errors, isLoading } = useForgot();

  return (
    <AuthLayout
      signTitle="Forgotten password?"
      signPostTitle="Enter your email to reset your password"
      signRightTitle={"Find the best match \nfor your therapy"}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          type="email"
          label="Email"
          placeholder=""
          className={styles.input}
          {...register("email", {
            required: "Required field",
            validate: (value) => isEmail(value) || "Invalid email",
          })}
          error={errors.email?.message}
        />
        <Button type="submit" className="w-100" text="Submit" isLoading={isLoading} />
      </form>
      <p className={cn("sign-help", styles.helpMessage)}>
        Already have an account?{" "}
        <Link to={`/patient${loginPath}`} className="g-underline">
          Log in
        </Link>
      </p>
    </AuthLayout>
  );
};
