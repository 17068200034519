import { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Logo } from "common/components/Header/Logo/Logo";
import Button from "common/UIKit/Button";
import { ReactComponent as LogInIcon } from "assets/images/icons/login.svg";
import { useModal } from "hooks/useModal";
import { useResponsive } from "hooks/useResponsive";
import { UserProfile } from "common/components/UserProfile/UserProfile";
import { useAuth } from "hooks/useAuth";
import styles from "./LandingHeader.module.scss";

const actions = [
  {
    label: "Join",
    value: "signup",
  },
  {
    label: "Login",
    value: "login",
  },
] as const;

type Tooltip = "signup" | "login" | null;

interface Props {
  className?: string;
}

export const LandingHeader: FC<Props> = ({ className }) => {
  const { user } = useAuth();
  const { isMobile } = useResponsive();
  const { open } = useModal("CTA_POPUP");
  const [tooltip, setTooltip] = useState<Tooltip>(null);
  const hideTooltipTimeout = useRef<NodeJS.Timeout | null>(null);

  const handlePopup = () => {
    open();
  };

  const onButtonMouseEnter = (value: Tooltip) => {
    if (hideTooltipTimeout.current) {
      clearTimeout(hideTooltipTimeout.current);
      hideTooltipTimeout.current = null;
    }

    setTooltip(value);
  };

  const onButtonMouseLeave = () => {
    if (hideTooltipTimeout.current) {
      clearTimeout(hideTooltipTimeout.current);
      hideTooltipTimeout.current = null;
    }

    hideTooltipTimeout.current = setTimeout(() => {
      setTooltip(null);
    }, 250);
  };

  const onTooltipMouseEnter = () => {
    if (!hideTooltipTimeout.current) {
      return;
    }

    clearTimeout(hideTooltipTimeout.current);
    hideTooltipTimeout.current = null;
  };

  return (
    <header className={cn(styles.root, className)}>
      <Logo className={styles.logo} />
      {isMobile && !user && (
        <button className={styles.login} onClick={handlePopup}>
          <div className={styles.loginIcon}>
            <span>Login</span>
          </div>
        </button>
      )}
      {user ? (
        <UserProfile />
      ) : (
        <div className={styles.headerButtons}>
          {actions.map(({ label, value }) => (
            <div className={styles.tooltipContainer} key={value}>
              <Button
                onMouseEnter={() => onButtonMouseEnter(value)}
                onMouseLeave={onButtonMouseLeave}
              >
                {label}
              </Button>
              <div
                className={cn(styles.tooltip, tooltip === value && styles.open)}
                onMouseEnter={onTooltipMouseEnter}
                onMouseLeave={() => setTooltip(null)}
              >
                <Link to={`/therapist/${value}`}>{label} as therapist</Link>
                <Link to={`/patient/${value}`}>{label} as client</Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </header>
  );
};
