import { ReactNode } from "react";
import { Header } from "../Header";
import { useWindowResize } from "hooks/useWindowResize";

interface Props {
  children: ReactNode;
  withoutHeader?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  doPadding?: boolean;
  paddingThreshold?: number;
  mainClass?: string;
  className?: string;
}

export const Layout = (props: Props) => {
  const { width } = useWindowResize();
  const { children, withoutHeader, mainClass, header, footer, className } =
    props;
  const doPadding =
    props.doPadding === undefined ? !withoutHeader : props.doPadding;
  const paddingThreshold = props.paddingThreshold || 1024;

  return (
    <div className={className}>
      {!withoutHeader && (header || <Header />)}
      <main
        className={mainClass}
        style={{ paddingTop: doPadding && width <= paddingThreshold ? 75 : 0 }}
      >
        {children}
      </main>
      {footer}
    </div>
  );
};

Layout.defaultProps = {
  children: <></>,
};

export default Layout;
