import { Props as ReactSelectProps } from "react-select";
import cn from "classnames";

import AppSelect from "../../UIKit/Select";
import { FieldError } from "react-hook-form";

import { ListOption } from "constants/options";
import { CustomMenu, CustomMenuList, CustomOption } from "./customComponents";

import styles from "./MultiSelect.module.scss";

type ToOmit =
  | "isMulti"
  | "menuIsOpen"
  | "closeMenuOnSelect"
  | "controlShouldRenderValue"
  | "hideSelectedOptions"
  | "isSearchable"
  | "isClearable";

export interface Props extends Omit<ReactSelectProps, ToOmit> {
  name: string;
  options: readonly ListOption[];
  placeholder: string;
  className?: string;
  withColumns?: boolean;
  noPreference?: string;
  value: readonly ListOption[];
  error?: FieldError;
  placeholderAsLabel?: boolean
}

export function MultiSelect({
  name,
  options,
  placeholder,
  defaultMenuIsOpen,
  className,
  value,
  withColumns,
  noPreference = "no-preference",
  error,
  components,
  ...otherProps
}: Props) {

  return (
    <AppSelect
      className={cn(className && className, withColumns && styles.withColumns)}
      error={error?.message}
      isMulti
      options={options}
      value={value}
      placeholder={placeholder}
      hideSelectedOptions={false}
      isSearchable
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      isClearable={false}
      components={{
        ValueContainer: components?.ValueContainer,
        Menu: CustomMenu,
        MenuList: CustomMenuList,
        Option: CustomOption,
      }}
      {...otherProps}
    />
  );
}
