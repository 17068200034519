import { AcceptJsHookConfig } from "react-acceptjs";
import {
  authorizeNetApiLogin,
  authorizeNetPublicKey,
  authorizeNetSandbox,
} from "../utils/env";

export const acceptJsConfig: AcceptJsHookConfig = {
  authData: {
    apiLoginID: authorizeNetApiLogin,
    clientKey: authorizeNetPublicKey,
  },
  environment: authorizeNetSandbox === "true" ? "SANDBOX" : "PRODUCTION",
};
