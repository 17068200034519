import { useEffect } from "react";

import Layout from "common/components/Layout/Layout";
import { MessagePopup } from "common/components/MessagePopup";
import { AccessController } from "common/components/Layout/AccessController";
import { LoadingHOC } from "common/components/LoadingHOC";
import { ListingHeader } from "common/components/ListingHeader";

import { Pagination } from "common/UIKit/Pagination";

import { Footer } from "pages/Auth/components/Footer/Footer";

import { isTherapist } from "services/authService";
import { therapistListingService } from "services/therapistListingService";
import { useAuth } from "hooks/useAuth";
import { useDocumentTitle } from "hooks/useDocumentTitle";

import { Filters } from "./components/Filters";
import { FilterTags } from "./components/FilterTags";
import ListItem from "./components/ListItem";

import { useTherapistListing } from "./useTherapistListing";

import styles from "./TherapistsListing.module.scss";

export const therapistsListingPath = "/listing";

const formatTherapistsCount = (count: number | null) => {
  if (count === null || count < 1000) {
    return count;
  }

  return count > 1000 ? "1,000+" : "1,000";
};

export const TherapistsListing = () => {
  const {
    therapists,
    totalCount,
    isFetchingList,
    pageCount,
    currentPage,
    isError,
  } = useTherapistListing();
  const { user, authStatus, setIsProfileCompleted, isProfileCompleted } =
    useAuth();

  useDocumentTitle("TheraPass - Listing");

  useEffect(() => {
    if (isProfileCompleted) {
      setIsProfileCompleted(false);
    }
  }, [isProfileCompleted, setIsProfileCompleted]);

  return (
    <AccessController
      unauthorized="allowed"
      therapist="allowed"
      patient="allowed"
    >
      <MessagePopup />
      <Layout
        mainClass={styles.listing}
        header={<ListingHeader />}
        footer={<Footer />}
        className={styles.wrapper}
      >
        <h1 className={styles.listingHeader}>Best therapists for you</h1>
        <p className={styles.listingResults}>
          {formatTherapistsCount(totalCount)} results
        </p>
        <Filters />
        <FilterTags />
        <LoadingHOC
          isLoading={isFetchingList}
          className={styles.listingCatalog}
          text="Searching for therapists..."
        >
          {isError && !isFetchingList ? (
            <div className={styles.noAvailable}>This page does not exist</div>
          ) : (
            <>
              {therapists.length === 0 && !isFetchingList && (
                <div className={styles.noAvailable}>
                  No available therapists
                </div>
              )}
              {therapists.map((therapist) => (
                <ListItem
                  key={therapist.id}
                  therapist={therapist}
                  isTherapist={user && isTherapist(user)}
                  currentUserStatus={authStatus}
                />
              ))}
              <Pagination
                pageCount={pageCount}
                currentPage={currentPage}
                goTo={(page) =>
                  therapistListingService.setCurrentPage(
                    page > pageCount ? pageCount : page
                  )
                }
              />
            </>
          )}
        </LoadingHOC>
      </Layout>
    </AccessController>
  );
};
