import { FormEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DictionaryItem, dictionaryService } from "services/dictionaryService";
import { step9FullPath } from "../PatientStep9";
import { useSteps } from "hooks/useSteps";
import { useSelectPreferences } from "../useSelectPreferences";
import { sortItems } from "common/utils/index";

export const usePatientStep8 = () => {
  const [languageOptions, setLanguageOptions] = useState<DictionaryItem[]>([]);

  const { isSubmitting, error, submit } = useSteps();
  const { selected, handleChange } = useSelectPreferences(
    "preferred_languages",
    "no"
  );

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const languages = await dictionaryService.getLanguages();
      setLanguageOptions(sortItems(languages, "order"));
    })();
  }, [setLanguageOptions]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    await submit({
      diff: { preferred_languages: selected },
      onSuccess: () => navigate(step9FullPath),
    });
  };

  return {
    languageOptions,
    isSubmitting,
    error,
    selected: selected as string[],
    handleChange,
    handleSubmit,
  };
};
