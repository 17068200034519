import { AxiosResponse } from "axios";
import { useModal } from "hooks/useModal";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import { SignInArgs, UserType } from "types/auth.types";
import { useQuery } from "hooks/useQuery";

interface ErrorResponse {
  email?: string[];
  password?: string[];
  non_field_errors?: string[];
}

export const useLogin = (userType: UserType) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInArgs>();
  const [globalError, setGlobalError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { open, close } = useModal("WRONG_ROLE");

  const q = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    close();
  }, [location]);

  const submit: SubmitHandler<SignInArgs> = async (values) => {
    try {
      setIsLoading(true);
      await authService.signIn({ ...values, userType });
      const redirectLink = q.get("redirect_to");
      redirectLink && navigate(redirectLink);
    } catch (error) {
      const { data, status } = error as AxiosResponse<ErrorResponse>;

      if (status === 406) {
        open();
      } else {
        setError("email", { message: data.email && data.email[0] });
        setError("password", { message: data.password && data.password[0] });

        setGlobalError(data.non_field_errors && data.non_field_errors[0]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(submit),

    errors: { ...errors, global: globalError },
    setGlobalError,
    isLoading,
  };
};
