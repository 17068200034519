import { useEffect, useLayoutEffect, useRef } from "react";
import {
  components,
  MenuListProps,
  MenuProps,
  OptionProps,
} from "react-select";
import cn from "classnames";
import styles from "./MultiSelect.module.scss";
import Button from "../../UIKit/Button";
import Checkbox from "../../UIKit/Checkbox";

const SPACING = 48;

function setH(val: string) {
  document.body.style.setProperty("height", val);
}

function getH(): string {
  return document.body.style.getPropertyValue("height");
}

function CustomMenu({ children, ...props }: MenuProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  const initialHeightRef = useRef(getH());

  const {
    options,
    getValue,
    selectProps: { menuIsOpen, onMenuClose },
  } = props;
  const currentValue = getValue();

  useLayoutEffect(() => {
    initialHeightRef.current = getH();

    return () => setH(initialHeightRef.current);
  }, []);

  const deps = JSON.stringify({ options, currentValue });

  useLayoutEffect(() => {
    if (!menuRef.current) {
      return;
    }
    const bodyRect = document.body.getBoundingClientRect();
    const menuRect = menuRef.current.getBoundingClientRect();
    const requiredHeight = menuRect.bottom - bodyRect.top + SPACING;
    if (requiredHeight > bodyRect.height) {
      setH(`${requiredHeight}px`);
    }
  }, [deps]);

  useEffect(() => {
    const outsideClickHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null;
      if (
        menuIsOpen &&
        menuRef.current &&
        target
          &&
        !menuRef.current.contains(target)
      ) {
        onMenuClose();
      }
    };
    document.addEventListener("mousedown", outsideClickHandler, {
      capture: true,
    });

    return () =>
      document.removeEventListener("mousedown", outsideClickHandler, {
        capture: true,
      });
  }, []);

  return (
    <components.Menu className={styles.menuHolder} {...props}>
      <div ref={menuRef}>{children}</div>
    </components.Menu>
  );
}

function CustomMenuList({
  selectProps: { onMenuClose },
  children,
  innerRef,
  innerProps,
}: MenuListProps) {
  return (
    <div className={styles.menuList}>
      <div className={styles.menuListContent} ref={innerRef} {...innerProps}>
        {children}
      </div>

      <div className={styles.menuListActions}>
        <Button onClick={onMenuClose}>
          Done
        </Button>
      </div>
    </div>
  );
}

function CustomOption({
  label,
  isSelected,
  data,
  selectOption,
  isFocused,
  innerRef,
  innerProps,
}: OptionProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      onClick={() => inputRef.current?.click()}
      className={cn(
        styles.optionContainer,
        isFocused && styles.optionContainer_focused
      )}
      ref={innerRef}
      {...innerProps}
    >
      <Checkbox
        checked={isSelected}
        ref={inputRef}
        onChange={() => selectOption(data)}
      >
        {label}
      </Checkbox>
    </div>
  );
}

export { CustomMenuList, CustomOption, CustomMenu };
