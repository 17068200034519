import { Controller } from "react-hook-form";
import { startCase } from "lodash";
import { EditHeader } from "common/components/EditHeader";
import { CardSection } from "pages/Therapists/TherapistSteps/TherapistStep6";
import { CardRadio } from "common/UIKit/CardRadio";
import { FormOfPayment, FormOfPaymentEnum } from "types/therapist.types";
import { PAYMENT_ICONS } from "constants/icons";
import Button from "common/UIKit/Button";
import { useProfileForm } from "../../../Profile/useProfileForm";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./EditFees.module.scss";

export const pathFees = "/fees";

const coveredFields: TherapistFields = [
  "form_of_payment",
  "in_person_session_cost_min",
  "in_person_session_cost_max",
  "online_individual_session_cost_min",
  "online_individual_session_cost_max",
];

export type Inputs = {
  inPerson: number | null;
  onlineIndividual: number | null;
  form_of_payment: readonly FormOfPayment[];
};

export const EditFees = () => {
  useDashboardNavigation("Edit Fees", {
    goBack: "pop_path",
  });
  const { control, isSubmitting, handleSubmit } = useProfileForm<Inputs>(
    coveredFields,
    null,
    {
      toFields: ({
        form_of_payment,
        in_person_session_cost_min,
        online_individual_session_cost_min,
      }) => ({
        form_of_payment,
        onlineIndividual: online_individual_session_cost_min,
        inPerson: in_person_session_cost_min,
      }),
      toApi: ({ form_of_payment, onlineIndividual, inPerson }) => ({
        form_of_payment,
        in_person_session_cost_min: (inPerson && inPerson) || null,
        online_individual_session_cost_min:
          (onlineIndividual && onlineIndividual) || null,
      }),
    }
  );

  return (
    <section className={styles.root}>
      <EditHeader />
      <form onSubmit={handleSubmit}>
        <div className={styles.body}>
          <CardSection
            title="In-Person Session Cost"
            subtitle="In-Person Session Cost"
            control={control}
            name="inPerson"
          />
          <CardSection
            title="Teletherapy Fees"
            subtitle="Online Individual Session Cost"
            description="Enter your fee for a 45-60 minute online therapy session"
            control={control}
            name="onlineIndividual"
          />
        </div>
        <h4 className={styles.typeLabel}>Forms of payment you accept:</h4>
        <Controller
          control={control}
          render={({ field }) => (
            <CardRadio
              className={styles.type}
              {...field}
              options={Object.entries(FormOfPaymentEnum).map(
                ([text, value]) => ({
                  text: startCase(text),
                  value,
                  icon: PAYMENT_ICONS[value],
                })
              )}
              onChange={(event) => {
                const { checked, value } = event.currentTarget;
                if (checked) {
                  field.onChange([...field.value, value]);
                } else {
                  field.onChange(field.value.filter((v) => v !== value));
                }
              }}
            />
          )}
          name="form_of_payment"
        />
        <Button
          type="submit"
          text="Save"
          className={styles.button}
          isLoading={isSubmitting}
        />
      </form>
    </section>
  );
};
