import { FC } from "react";

import { EditPatientInformationForm } from "./useEditPatientInformation";
import { ZipCode } from "./FormGroups/ZipCode";
import { TypesOfTherapy } from "./FormGroups/TypesOfTherapy";
import { TherapistsExperience } from "./FormGroups/TherapistsExperience";
import { TherapistsPreference } from "./FormGroups/TherapistsPreference";
import { PatientsAge } from "./FormGroups/PatientsAge";
import { Price } from "./FormGroups/Price";
import { Ethnicity } from "./FormGroups/Ethnicity";
import { Sexuality } from "./FormGroups/Sexuality";
import { Language } from "./FormGroups/Language";
import { Faith } from "./FormGroups/Faith";
import { YourAvailability } from "./FormGroups/YourAvailability";

type Tab = {
  name: string;
  label: string;
  subLabel?: string;
  Component: FC<EditPatientInformationForm>;
};

const tabs: Tab[] = [
  {
    name: "Zip",
    label: "Please enter your Zip code",
    Component: ZipCode,
  },
  {
    name: "Types of therapy",
    label: "I’d like to do therapy via:",
    subLabel: "Check all that apply",
    Component: TypesOfTherapy,
  },
  {
    name: "Therapist’s experience",
    label: "I’d prefer a therapist with experience in:",
    Component: TherapistsExperience,
  },
  {
    name: "Therapist’s preference",
    label: "Mark all that apply",
    subLabel: "Select one",
    Component: TherapistsPreference,
  },
  {
    name: "Patient’s Age",
    label: "Patient’s age",
    subLabel: "Select one",
    Component: PatientsAge,
  },
  {
    name: "Price",
    label: "My budget per session",
    subLabel: "Select one",
    Component: Price,
  },
  {
    name: "Ethnicity",
    label: "Do you prefer a therapist who works with a specific ethnicity?",
    subLabel: "Check all that apply",
    Component: Ethnicity,
  },
  {
    name: "Sexuality",
    label:
      "Do you prefer a therapist who works with patients of a certain sexuality?",
    subLabel: "Check all that apply",
    Component: Sexuality,
  },
  {
    name: "Language",
    label:
      "Do you prefer a therapist who speaks a language other than English? ",
    subLabel: "Check all that apply",
    Component: Language,
  },
  {
    name: "Faith",
    label: "Faith",
    subLabel: "Select one",
    Component: Faith,
  },
  {
    name: "Your availability",
    label: "When would you like to talk to a therapist?",
    subLabel: "Select one",
    Component: YourAvailability,
  },
];

export default tabs;
