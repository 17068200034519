import { FC } from "react";

import Button from "common/UIKit/Button";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { FormatProfileField } from "common/components/FormatProfileField";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./PersonalStatement.module.scss";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";

export const pathPersonalStatement = "/personal-statement";

export const PersonalStatement: FC = () => {
  const { tabName, edit } = useDashboardNavigation("Personal Statement", {
    edit: "append_edit",
    goBack: "pop_path",
  });
  const auth = useAuth();
  const user = auth.user as Therapist | null;

  return (
    <section className={styles.personalStatement}>
      <h1 className={styles.personalStatementTitle}>{tabName}</h1>
      <div className={styles.personalStatementInner}>
        <div className={styles.personalStatementInfo}>
          <div className={styles.personalStatementBlock}>
            <p className={styles.personalStatementBlockTitle}>
              Short description
            </p>
            <p className={styles.personalStatementBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {user?.personal_statement_short_description}
              </FormatProfileField>
            </p>
          </div>
        </div>
        <Button className={styles.personalStatementButton} onClick={edit}>
          <EditIcon />
          Edit information
        </Button>
      </div>
    </section>
  );
};
