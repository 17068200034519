import { useSnapshot } from "valtio";
import {
    ComponentType,
    Dispatch,
    ForwardedRef,
    forwardRef,
    SetStateAction,
    useRef,
    useState,
    DragEvent,
    useEffect,
    MouseEvent,
} from "react";
import cn from "classnames";
import fileUploadImage from "assets/images/file-upload.svg";
import styles from "../FileUpload/FileUpload.module.scss";
import {
    ActionProps,
    BrowseButton,
    MultiPreview,
    Preview,
    RemoveBtn,
    ProgressBar,
} from "../FileUpload/components";
import { Nullable, blockEvent } from "common/utils";
import {
    fileFits,
    parseAccept,
    Src,
    getOverflow,
    setOverflow,
} from "../FileUpload/helpers";
import useOptionalExternalState from "hooks/useOptionalExternalState";
import { filesService } from "services/filesService";

type ErrorMessage = Nullable<string>;
export type Props = {
    className?: string;
    actionsContainerClassName?: string;
    style?: object;
    multiple?: boolean;
    accept: string;
    actions?: ComponentType<ActionProps>[];
    caption?: string;
    onAddFiles: (files: File[]) => void;
    onRemoveFile: (index: number) => void;
    onChangeIsMain: (id?: number) => void;
    error?: string;
    hideAcceptHint?: boolean;
    disabled?: boolean;
    setError?: Dispatch<SetStateAction<ErrorMessage>>;
    enableReinitialize?: boolean;
    value: Src[];
    isFileUploading?: boolean;
    onAbortUploading?: () => void;
};

function FileUploadProfileComponent(
    {
        className,
        actionsContainerClassName,
        style,
        multiple,
        accept,
        actions,
        caption,
        error: externalError,
        setError: setExternalError,
        onAddFiles,
        onRemoveFile,
        hideAcceptHint,
        disabled,
        value,
        isFileUploading,
        onAbortUploading,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { uploadProgress } = useSnapshot(filesService);
    const inputRef = useRef<HTMLInputElement>(null);

    const [error, setError] = useOptionalExternalState<ErrorMessage>(
        null,
        externalError,
        setExternalError
    );
    const [removedFileIndex, setRemovedFileIndex] =
        useState<Nullable<number>>(null);

    function updateErr(newMessage: string) {
        setError((curr) => `${curr ? `${curr}; ` : ""}${newMessage}`);
    }

    function removeFileByIndex(index: number) {
        onRemoveFile(index);
    }

    // Via onDrop (denied when disabled) or input.onChange (disabled passed as prop)
    function handleAddFiles(files?: Nullable<FileList | File[]>) {
        if (!files) {
            return;
        }
        if (!multiple && files.length > 1) {
            updateErr("Please select one file");
            return;
        }

        const acceptedFiles = Array.from(files).filter((f) => fileFits(accept, f));

        onAddFiles(acceptedFiles);
    }

    // Called as is, need to handle disabled state
    function onDrop(e: DragEvent<HTMLDivElement>) {
        blockEvent(e);
        if (!disabled) {
            const transferItems = e.dataTransfer.items;
            if (!transferItems) {
                return;
            }
            handleAddFiles(
                Array.from(transferItems).map((i) => i.getAsFile()) as File[]
            );
        }
    }

    // Called as is, need to handle disabled state
    function openRemovalOverlay(fileIndex?: number) {
        !disabled && setRemovedFileIndex(fileIndex == null ? -1 : fileIndex);
    }

    // Might need to force this when entering disabled state
    function closeRemovalOverlay() {
        setRemovedFileIndex(null);
    }

    // Called as is, need to handle disabled state
    function startBrowsing(e: MouseEvent) {
        blockEvent(e);
        !disabled && inputRef.current?.click();
    }

    useEffect(() => {
        let originalOverflow = "";
        if (removedFileIndex != null) {
            originalOverflow = getOverflow();
            setOverflow("hidden");
        }

        return () => {
            setOverflow(originalOverflow);
        };
    }, [removedFileIndex]);

    useEffect(() => {
        if (disabled) {
            closeRemovalOverlay();
        }
    }, [disabled]);

    useEffect(() => {
        setError(null);
    }, [value]);

    useEffect(() => {
        document.addEventListener("dragover", blockEvent);
        document.addEventListener("drop", blockEvent);

        return () => {
            document.removeEventListener("dragover", blockEvent);
            document.addEventListener("drop", blockEvent);
        };
    }, []);

    const singlePreview = value[0];
    const singlePreviewVisible =
        !multiple && !!value && singlePreview?.type === "video";

    return (
        <div className={className} style={style} ref={ref}>
            <div onDrop={onDrop}>
                <input
                    className={styles.hiddenFileInput}
                    ref={inputRef}
                    type="file"
                    multiple={multiple}
                    accept={accept}
                    disabled={disabled}
                    onChange={(e) => handleAddFiles(e.target.files)}
                />

                {singlePreviewVisible ? (
                    <>
                        <Preview
                            file={value[0]}
                            openRemovalOverlay={openRemovalOverlay}
                            large
                        />
                        {uploadProgress === null && (
                            <RemoveBtn
                                className={styles.removeBtn}
                                handleRemoveFile={() => removeFileByIndex(0)}
                                disabled={disabled}
                            />
                        )}
                        <ProgressBar onRemove={onAbortUploading} />
                    </>
                ) : (
                    <div className={styles.dropFileArea}>
                        <img
                            className={styles.fileUploadImage}
                            src={fileUploadImage}
                            alt="fileUploadImage"
                        />
                        <p className={styles.captionText}>{caption}</p>
                        {caption ? <p className={styles.captionText}>or</p> : null}
                        <div
                            className={cn(styles.actionsContainer, actionsContainerClassName)}
                        >
                            {actions?.map((Action, idx) => (
                                <Action
                                    disabled={disabled}
                                    startBrowsing={startBrowsing}
                                    key={idx}
                                />
                            ))}
                        </div>
                        {!hideAcceptHint && (
                            <p className={styles.acceptHint}>{parseAccept(accept)}</p>
                        )}
                    </div>
                )}
            </div>
            {error && <p className="error_msg">{error}</p>}
        </div>
    );
}

const FileUploadProfile = forwardRef(FileUploadProfileComponent);

FileUploadProfile.defaultProps = {
    multiple: false,
    actions: [BrowseButton],
};

export default FileUploadProfile;
