import { FC } from "react";

import { AvailableTest } from "types/availableTests.types";
import { TestInfoMobile } from "./TestInfoMobile";
import { TestAttempt } from "types/testAttempt.types";
import styles from "./TestsTableMobile.module.scss";

interface Props {
  rows: readonly AvailableTest[] | readonly TestAttempt[];
}

export const TestsTableMobile: FC<Props> = ({ rows }) => {
  return (
    <div className={styles.testsTableMobile}>
      {rows.map((test) => (
        <TestInfoMobile key={test.id} data={test} />
      ))}
    </div>
  );
};
