import { proxy } from "valtio";
import { DeepPartial } from "common/utils";
import { HttpService } from "./httpService";
import { tokenService } from "./tokenService";
import { esaTokenService } from "./esaTokenService";
import { TherapistService, therapistService } from "./therapistService";
import { PatientService, patientService } from "./patientService";
import {
  BaseUser,
  MapUserType,
  SignInArgs,
  SignUpArgs,
  UserAuthStatus,
  UserType,
} from "types/auth.types";
import { Patient } from "types/patient.types";
import {
  BaseTherapistItem,
  StripeStatus,
  Therapist,
} from "types/therapist.types";
import { AxiosResponse } from "axios";
import { modalService } from "./modalService";

export function isUserType(str?: string): str is UserType {
  return !!str && ["therapist", "patient"].includes(str);
}

export type User = Patient | Therapist;

export function isPatient(user: User): user is Patient {
  return Boolean(user.user.patient);
}

export function isTherapist(user: User): user is Therapist {
  return !isPatient(user);
}

export function isChatAccess(user: Therapist | BaseTherapistItem): boolean {
  return user.subscription_plan.chat_access;
}

export function isStripeAccess(user: Therapist | BaseTherapistItem): boolean {
  return user.subscription_plan.stripe_access;
}

export function stripeStatus(user: Therapist, status: StripeStatus): boolean {
  return user.stripe_status === status;
}

export function getUserType(user: User): UserType {
  return isTherapist(user) ? "therapist" : "patient";
}

export function getUserTypeByBaseUser(baseUser: BaseUser): UserType {
  return baseUser.therapist ? "therapist" : "patient";
}

const getServiceByType = (type: UserType) => {
  const map: MapUserType<TherapistService | PatientService> = {
    patient: patientService,
    therapist: therapistService,
  };
  return map[type];
};

class AuthService extends HttpService {
  authStatus: UserAuthStatus = "unknown";
  baseUser: BaseUser | null = null;
  user: User | null = null;
  temporaryData: any;
  // prevSessionUserType: UserType = "patient";

  // todo: try to put in constructor
  // the trick is that `this` in constructor isn't wrapped with proxy yet
  init() {
    tokenService.sync((token) => {
      if (!token && this.authStatus === "logoutToLogin") {
        this.user = null;
        this.baseUser = null;
        return;
      }
      if (token) {
        this.getCurrentUser();
      } else {
        this.authStatus = "loggedOut";
        this.user = null;
        this.baseUser = null;
      }
    });
  }

  changeAuthStatus(authStatus: UserAuthStatus) {
    this.authStatus = authStatus;

    return this.authStatus;
  }

  async loginWithGoogleAccessToken({
    accessToken,
    userType,
  }: {
    accessToken: string;
    userType: UserType;
  }) {
    try {
      const { token } = await this.http.post<
        { access_token: string },
        { token: string }
      >("/v1/rest-auth/google/", {
        access_token: accessToken,
        user_type: userType,
      });

      tokenService.set(token);
      await this.getCurrentUser();
    } catch (error) {
      const { status } = error as AxiosResponse;

      if (status === 406) {
        modalService.open("WRONG_ROLE");
      } else {
        throw error;
      }
    }
  }

  async getCurrentUser() {
    if (!this.user) {
      this.authStatus = "processing";
    }
    try {
      const baseUser: BaseUser = await this.http.get("/v1/users/me/");
      this.baseUser = baseUser;
      const userType = getUserTypeByBaseUser(baseUser);
      this.changeAuthStatus("unknown");
      if (baseUser.is_email_confirmed && baseUser.is_phone_confirmed) {
        const crudService = getServiceByType(userType);
        this.prevSessionUserType = userType;
        localStorage.setItem("prevSessionUserType", userType);

        this.user = await crudService.getById(
          baseUser.patient || baseUser.therapist!
        );
        this.authStatus = "loggedIn";
      } else if (!baseUser.is_phone_confirmed) {
        this.authStatus = "phoneVerification";
      } else if (!baseUser.is_email_confirmed) {
        this.authStatus = "verification";
      }
    } catch (e) {
      this.authStatus = "loggedOut";
    }
  }

  async signIn({ userType, ...args }: SignInArgs) {
    const { token, esa_token } = await this.http.post<SignInArgs, { token: string, esa_token: string }>(
      `/v1/login/`,
      { ...args, user_type: userType }
    );
    tokenService.set(token);
    esaTokenService.set(esa_token);

    await this.getCurrentUser();
  }

  async signUp({
    firstName,
    lastName,
    email,
    mobile_phone,
    password,
    type,
    consent_accepted,
  }: SignUpArgs) {
    const crudService = getServiceByType(type);

    const data = await crudService.create({
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        mobile_phone,
        password,
      },
      consent_accepted: consent_accepted,
    });

    this.temporaryData = data;
    return this.temporaryData;
  }

  async logout() {
    try {
      await this.http.post("/v1/logout/");
    } catch (error) {
      console.log("Unhandled error", error);
    } finally {
      tokenService.remove();
      esaTokenService.remove();
      this.user = null;
      this.authStatus = "loggedOut";
    }
  }

  async verifyEmail(token: string, email: string) {
    const { token: newToken } = await this.http.post<any, { token: string }>(
      "/v1/email/verify/",
      {
        token,
        email,
      }
    );
    tokenService.set(newToken);
    await this.getCurrentUser();
    if (this.user) {
      this.user.user.is_email_confirmed = true;
    }
  }

  resendEmail(email: string) {
    return this.http.post("/v1/email/resend/", { email });
  }

  async sendResetPassword(email: string) {
    return this.http.post("v1/send-reset-password/", { email });
  }

  async confirmResetPassword(email: string, token: string, password: string) {
    return this.http.post("v1/confirm-reset-password/", {
      email,
      token,
      password,
    });
  }

  async changePassword(
    old_password: string,
    password: string
  ): Promise<{ token: string }> {
    return this.http.post("v1/change-password/", {
      old_password,
      password,
    });
  }

  async patch<T = User>({ diff }: { diff: DeepPartial<T> }) {
    if (!this.user) {
      throw new Error("Can't patch unknown user");
    }
    const userType = getUserType(this.user);
    const crudService = getServiceByType(userType);
    this.user = await crudService.patch(this.user.id, diff);

    // mergeDeep(this.user, diff);
  }

  async verify() {
    return this.http.get("/v1/verify-login/");
  }

  async verifyLogin() {
    try {
      this.verify();
    } catch (error) {
      localStorage.setItem("redirect_to", window.location.pathname);
      localStorage.setItem("isMessageModalOpen", `true`);
      modalService.open("LOGGED_OUT_PATIENT");
      throw new Error("Error");
    }
  }

  public get prevSessionUserType(): UserType {
    return (
      (localStorage.getItem("prevSessionUserType") as UserType) || "patient"
    );
  }

  public set prevSessionUserType(userType: UserType) {
    localStorage.setItem("prevSessionUserType", userType);
  }
}

export const authService = proxy(new AuthService());
authService.logout = authService.logout.bind(authService);
authService.init();
