import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";

import { creditCardService } from "services/creditCardService";

import { useModal } from "hooks/useModal";

import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";
import { useResponsive } from "hooks/useResponsive";

import Button from "common/UIKit/Button";
import { CardInfo } from "common/components/CardInfo";
import { ChooseCreditCard } from "common/components/ChooseCreditCard";
import { AddCreditCard } from "common/components/AddCreditCard";
import { CardCodeModal } from "common/components/CardCodeModal";

import styles from "./BuyTest.module.scss";
import { availableTestsService } from "services/availableTestsService";
import { Preloader } from "common/UIKit/Preloader";
import { SubscriptionPlansLayout } from "pages/Therapists/SubscriptionPlans/SubscriptionPlansLayout";
import { testAttemptService } from "services/testAttemptService";
import { TestAttemptId } from "types/testAttempt.types";
import { testsPreparePath } from "../TakeTest/TestPrepare";
import {toast} from "react-toastify";

export const buyTestPath = "/:id/buy";

export const BuyTest = () => {
  const { entity } = useSnapshot(availableTestsService);
  const { id } = useParams<{ id: string }>();
  const testId = Number(id);
  const navigate = useNavigate();

  const { isMobile } = useResponsive();
  const { data: creditCards, isFetched: isCreditCardsFetched } =
    useSnapshot(creditCardService);

  const { open: showAddCardModal } = useModal("ADD_CREDIT_CARD");
  const { open: showCardCodeModal } = useModal("CARD_CODE");
  const [selectedCard, setSelectedCard] = useState(creditCards[0]);

  useEffect(() => {
    setSelectedCard(creditCards[0]);
  }, [creditCards]);

  useEffect(() => {
    !isCreditCardsFetched && creditCardService.list();
  }, []);

  useEffect(() => {
    availableTestsService.getById(Number(id));
  }, [id]);

  const handleAfterBuy = async (attemptId: TestAttemptId) => {
    // const { id } = await testAttemptService.start(testId);
    console.log("navigate", attemptId);

    navigate(`./../../${attemptId}${testsPreparePath}`);
    toast("Test was purchased", {className: styles.toastMessage});
  };

  if (!entity) return <Preloader />;

  return (
    <>
      <ChooseCreditCard
        options={creditCards}
        value={selectedCard}
        onChange={setSelectedCard}
      />
      <AddCreditCard
        onPayOnce={async (args) => {
          const response = await testAttemptService.buyWithNewCard({
            ...args,
            psychology_test: testId,
            save_card: false,
          });
          await handleAfterBuy(response.id);
        }}
        onAddCard={async (args) => {
          const response = await testAttemptService.buyWithNewCard({
            ...args,
            psychology_test: testId,
            save_card: true,
          });
          await handleAfterBuy(response.id);
        }}
      />
      {selectedCard && (
        <CardCodeModal
          selectedCard={selectedCard}
          onBuy={async (args) => {
            const response = await testAttemptService.buyWithSavedCard({
              ...args,
              psychology_test: Number(id),
            });
            await handleAfterBuy(response.id);
          }}
        />
      )}
      <SubscriptionPlansLayout headerAction="goBack">
        <div className={styles.container}>
          <div>
            <div className={styles.billingContainer}>
              <div className={styles.title}>
                <h2 className={styles.h2}>Buy {entity.name}</h2>
              </div>
              <div className={styles.description}>
                <p className={styles.h2}>
                  <strong>Description</strong>
                </p>
                <p className={styles.p}>{entity.description}</p>
              </div>
            </div>
            <div className={styles.totalContainer}>
              <h2 className={styles.h2}>Total</h2>
              <div className={styles.totalPrice}>
                <h2 className={styles.h2}>$ {entity.cost}</h2>
                <p className={styles.p}>Incl. VAT</p>
              </div>
            </div>

            {selectedCard ? (
              <CardInfo card={selectedCard} />
            ) : (
              <div className={styles.dontHaveCards}>
                <p>You don't have any attached credit cards</p>
                {isMobile ? (
                  <Button
                    className="w-100"
                    text="Add a credit card"
                    onClick={showAddCardModal}
                    color="outline-gray"
                  />
                ) : (
                  <div
                    className={styles.addNewCard}
                    role="button"
                    onClick={showAddCardModal}
                  >
                    <PlusIcon /> <span>Add new credit card</span>
                  </div>
                )}
              </div>
            )}

            <Button
              text="Proceed to Payment"
              className={cn(styles.proceed, "w-100")}
              onClick={selectedCard ? showCardCodeModal : showAddCardModal}
            />
          </div>
        </div>
      </SubscriptionPlansLayout>
    </>
  );
};
