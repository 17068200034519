import { stepsFullPath } from "../stepsPath";
import { TherapistFields } from "../util";

export const step5Path = "/5";
export const step5FullPath = `${stepsFullPath}${step5Path}`;
export const step5Caption = "Client focus";
export const coveredFields: TherapistFields = [
  "ages",
  "preferred_faith",
  "preferred_languages",
  "allied_groups",
  "preferred_ethnicities",
];
