import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CardData, useAcceptJs } from "react-acceptjs";
import { DispatchDataResponse } from "react-acceptjs/dist/types";
import { AxiosResponse } from "axios";
import { goThroughErrors } from "common/utils";
import { acceptJsConfig } from "constants/acceptJs";
import { AddCardHandler } from "./AddCreditCard";

type Inputs = {
  cardHolder: string;
  cardNumber: string;
  expiry: string;
  CVV: string;
  isAccepted: boolean;
};

const defaultValues: Inputs = {
  cardHolder: "",
  cardNumber: "",
  expiry: "",
  CVV: "",
  isAccepted: false,
};

export const usePaymentWithNewCard = (
  onAddCard: AddCardHandler,
  onPayOnce?: AddCardHandler
) => {
  const { dispatchData } = useAcceptJs(acceptJsConfig);
  const {
    control,
    register,
    formState: { errors },
    setValue,
    setError,
    handleSubmit,
  } = useForm<Inputs>({ defaultValues, reValidateMode: "onChange" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingPayOnce, setIsSubmittingPayOnce] = useState(false);
  const [globalError, setGlobalError] = useState<string>();

  const submitBuilder = (
    cardHandler: AddCardHandler,
    setSubmittingStatus: (isSubmitting: boolean) => void
  ) => {
    const handler: SubmitHandler<Inputs> = async (values) => {
      try {
        await setSubmittingStatus(true);
        const [month, year] = values.expiry.split("/");
        const cardData: CardData = {
          cardCode: values.CVV,
          fullName: values.cardHolder,
          cardNumber: values.cardNumber.replace(/[\s_]/g, ""),
          month,
          year,
        };

        const response = await dispatchData({
          cardData,
        });

        await cardHandler({
          card_token: response.opaqueData.dataValue,
          descriptor: response.opaqueData.dataDescriptor,
          cardholder_name: values.cardHolder,
          card_code: values.CVV,
        });
      } catch (error: any) {
        if (error.messages) {
          const { messages } = error as DispatchDataResponse;
          setGlobalError(
            messages.message
              .map(({ code, text }) => `${code}: ${text}`)
              .join(", ")
          );
        } else if (error.data) {
          const { data } = error as AxiosResponse;
          goThroughErrors(data, (fieldName, message) => {
            fieldName === "cardholder_name" &&
              setError("cardHolder", { message });
            fieldName === "errors" && setGlobalError(message);
          });
        }
      } finally {
        setSubmittingStatus(false);
      }
    };

    return handler;
  };

  const onSubmit = submitBuilder(onAddCard, setIsSubmitting);
  const onUseOnlyOnce =
    onPayOnce && submitBuilder(onPayOnce, setIsSubmittingPayOnce);

  return {
    control,
    register,
    errors: { ...errors, global: globalError },
    handleSubmit: handleSubmit(onSubmit),
    handleUseOnlyOnce: onUseOnlyOnce && handleSubmit(onUseOnlyOnce),
    isSubmitting,
    isSubmittingPayOnce,
    setValue,
  };
};
