import { DashboardTab } from "common/components/DashboardLayout/useDashboardTabs";
import { Overview } from "./Overview";
import { Profile, profilePath } from "./Profile";
import { Settings, settingsPath } from "./Settings";
import { Subscription, subscriptionPath } from "./Subscription";
import { MacrosMenu, pathMacrosMenu } from "./MacrosMenu/MacrosMenu";
import { Payments, paymentsPath } from "./Payments/Payments";
import { ReactComponent as Category } from "assets/images/icons/category.svg";
import { ReactComponent as SinglePerson } from "assets/images/icons/single-person.svg";
import { ReactComponent as Paper } from "assets/images/icons/paper.svg";
import { ReactComponent as Gear } from "assets/images/icons/settings-gear.svg";
import { ReactComponent as ChatMacros } from "assets/images/icons/chat-macros.svg";
import { ReactComponent as DoubleWallet } from "assets/images/icons/double-wallet.svg";
import { ReactComponent as Video } from "assets/images/icons/video.svg";
import { videoQueuePath, VideoQueue } from "./VideoQueue";

export const tabs: DashboardTab[] = [
  {
    icon: Category,
    label: "Overview",
    value: "",
    component: Overview,
  },
  {
    icon: SinglePerson,
    label: "Profile",
    value: profilePath,
    component: Profile,
  },
  {
    icon: Paper,
    label: "Subscription",
    value: subscriptionPath,
    component: Subscription,
  },
  {
    icon: Gear,
    label: "Settings",
    value: settingsPath,
    component: Settings,
  },
  {
    icon: ChatMacros,
    label: "Chat Connect Macros",
    value: pathMacrosMenu,
    component: MacrosMenu,
  },
  {
    icon: DoubleWallet,
    label: "Payments",
    value: paymentsPath,
    component: Payments,
  },
  {
    icon: Video,
    label: "Video Chat",
    value: videoQueuePath,
    component: VideoQueue,
  },
];
