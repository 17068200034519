import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import { useCreditCardForm } from "hooks/useCreditCardForm";
import isCreditCard from "validator/es/lib/isCreditCard";
import { isDateNotInPast } from "common/utils";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { EditHeader } from "common/components/EditHeader";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./AddNewCard.module.scss";

type Props = {
  goBack?: () => void;
};

export const AddNewCard = ({ goBack: goBackAction }: Props) => {
  const { goBack } = useDashboardNavigation("Add New Card", {
    goBack: goBackAction || "pop_path",
  });
  const { register, handleSubmit, errors, isSubmitting, control } =
    useCreditCardForm({
      onSuccess: goBack,
    });

  return (
    <div className={styles.addNewCard}>
      <EditHeader />
      <form onSubmit={handleSubmit}>
        {errors.global && <ErrorMessage>{errors.global}</ErrorMessage>}
        <div className={styles.row}>
          <Input
            label="Cardholder"
            placeholder=""
            {...register("cardHolder", {
              required: "This field is required.",
            })}
            error={errors.cardHolder?.message}
          />
        </div>
        <div className={styles.row}>
          <Controller
            name="cardNumber"
            control={control}
            rules={{
              required: "This field is required.",
              validate: (value) =>
                isCreditCard(value.replace(/[\s_]/g, "")) ||
                "Incorrect card number.",
            }}
            render={({ field: { value, onChange } }) => (
              <InputMask
                mask="9999 9999 9999 9999"
                value={value}
                onChange={onChange}
                placeholder="1234 1234 1234 1234"
              >
                {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    label="Card number"
                    error={errors.cardNumber?.message}
                  />
                )}
              </InputMask>
            )}
          />
        </div>
        <div className={styles.twoItemsRow}>
          <Controller
            name="expiry"
            control={control}
            rules={{
              required: "This field is required.",
              validate: {
                isValid: (value) =>
                  /^(0[1-9]|1[0-2])\/?(\d{2})$/.test(value) ||
                  "Incorrect expiry date",
                isNotExpired: (value) =>
                  isDateNotInPast(value) || "This card is expired.",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <InputMask
                mask="99/99"
                value={value}
                onChange={onChange}
                placeholder="MM/YY"
              >
                {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    label="Expiry"
                    error={errors.expiry?.message}
                  />
                )}
              </InputMask>
            )}
          />
          <Input
            type="password"
            label="CVV"
            description="3 or 4 digits"
            maxLength={4}
            {...register("CVV", {
              required: "This field is required.",
              validate: (value) =>
                /^(\d{3,4})$/.test(value) || "Incorrect CVV.",
            })}
            error={errors.CVV?.message}
          />
        </div>

        <Button
          type="submit"
          text="Add card"
          className="w-100"
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};
