import { useEffect } from "react";

export const useDocumentTitle = (title?: string) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    } else {
      document.title = "TheraPass";
    }

    return () => {
      document.title = "TheraPass";
    };
  }, [title]);
};
