import { Footer } from "pages/Auth/components/Footer/Footer";
import { LandingHeader } from "pages/Auth/components/LandingHeader/LandingHeader";
import { useEffect } from "react";
import styles from "./PrivacyPolicy.module.scss";

export const privacyPolicyPath = "/privacy";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollBy({ top: 0 });
  }, []);

  return (
    <div className={styles.root}>
      <LandingHeader />
      <main className={styles.main}>
        <h1 className={styles.title}>Privacy Policy</h1>
        <section className={styles.section}>
          <p>Last modified: September 12, 2022</p>
          <h2>1. Introduction</h2>
          <div>
            <p>
              TheraPass, Inc. (“TheraPass” or “we”) respects your privacy and is
              committed to protecting it through our compliance with this
              policy. This policy describes the types of information we may
              collect from you or that you may provide when you visit
              therapass.com (the “Platform”) and our practices for collecting,
              using, maintaining, protecting, and disclosing that information.
            </p>
            <p>This policy applies to information we collect:</p>
            <ul>
              <li>On this Platform.</li>
              <li>
                In email, text, and other electronic messages between you and
                this Platform.
              </li>
            </ul>
            <p>It does not apply to information collected by:</p>
            <ul>
              <li>
                Any third party (including therapists in the TheraPass therapist
                network), including through any application or content
                (including advertising) that may link to or be accessible from
                or through the Platform.
              </li>
            </ul>
            <p>
              Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. If
              you do not agree with our policies and practices, your choice is
              not to use our Platform. By accessing or using this Platform, you
              agree to this privacy policy. This policy may change from time to
              time. Your continued use of this Platform after we make changes is
              deemed to be acceptance of those changes, so please check the
              policy periodically for updates.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>2. Children Under the Age of 13</h2>
          <div>
            <p>
              Our Platform is not intended for children under 13 years of age.
              No one under age 13 may provide any information to the Platform.
              We do not knowingly collect personal information from children
              under 13. If you are under 13, do not use or provide any
              information on this Platform. If we learn we have collected or
              received personal information from a child under 13, we will
              delete that information. If you believe we might have any
              information from or about a child under 13, please contact us at
              Therapass, 5237 Summerlin Commons #367 Fort Myers, FL 33907
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>3. Information We Collect About You and How We Collect It</h2>
          <div>
            <p>
              We collect several types of information from and about users of
              our Platform, including information:
            </p>
            <ul>
              <li>
                By which you may be personally identified, such as name, postal
                address, email address, telephone number, or any other
                identifier by which you may be contacted online (“personal
                information”); and/or
              </li>
              <li>
                About your internet connection, the equipment you use to access
                our Platform, and usage details.
              </li>
            </ul>
            <p>We collect this information:</p>
            <ul>
              <li>Directly from you when you provide it to us.</li>
              <li>
                Automatically as you navigate through the site. Information
                collected automatically may include usage details, IP addresses,
                and information collected through cookies, web beacons, and
                other tracking technologies.
              </li>
            </ul>
            <p>
              <i>Information You Provide to Us</i>
            </p>
            <p>
              The information we collect on or through our Platform may include:
            </p>
            <ul>
              <li>
                Information that you provide by filling in forms on our
                Platform. This includes information provided at the time of
                registering to use our Platform, subscribing to our service, or
                requesting further services. We may also ask you for information
                when you report a problem with our Platform.
              </li>
              <li>
                Records and copies of your correspondence (including email
                addresses), if you contact us.
              </li>
              <li>
                Your responses to surveys that you may elect to take on the
                Platform.
              </li>
              <li>Your search queries on the Platform.</li>
            </ul>
            <p>
              <i>
                Information We Collect Through Automatic Data Collection
                Technologies
              </i>
            </p>
            <p>
              As you navigate through and interact with our Platform, we may use
              automatic data collection technologies to collect certain
              information about your equipment, browsing actions, and patterns,
              including:
            </p>
            <ul>
              <li>
                Details of your visits to our Platform, including traffic data
                and other communication data and the resources that you access
                and use on the Platform.
              </li>
              <li>
                Information about your computer and internet connection,
                including your IP address, operating system, and browser type.
              </li>
            </ul>
            <p>
              The information we collect automatically is only statistical data
              and does not include personal information, but we may maintain it
              or associate it with personal information we collect in other
              ways. It helps us to improve our Platform and to deliver a better
              and more personalized service, including by enabling us to:
            </p>
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Store information about your preferences, allowing us to
                customize our Platform according to your individual interests.
              </li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Platform.</li>
            </ul>
            <p>
              The technologies we use for this automatic data collection may
              include:
            </p>
            <ul>
              <li>
                <b>Cookies (or browser cookies).</b> A cookie is a small file
                placed on the hard drive of your computer. You may refuse to
                accept browser cookies by activating the appropriate setting on
                your browser. However, if you select this setting you may be
                unable to access certain parts of our Platform. Unless you have
                adjusted your browser setting so that it will refuse cookies,
                our system will issue cookies when you direct your browser to
                our Platform.
              </li>
              <li>
                <b>Web Beacons.</b> Pages of our Platform may contain small
                electronic files known as web beacons (also referred to as clear
                gifs, pixel tags, and single-pixel gifs) that permit TheraPass,
                for example, to count users who have visited those pages and for
                other related website statistics (for example, recording the
                popularity of certain website content and verifying system and
                server integrity).
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.section}>
          <h2>4. How We Use Your Information</h2>
          <div>
            <p>
              We use information that we collect about you or that you provide
              to us, including any personal information:
            </p>
            <ul>
              <li>To present our Platform and its contents to you.</li>
              <li>
                To provide you with information or services that you request
                from us.
              </li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>To provide you with notices about your account.</li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.
              </li>
              <li>
                To notify you about changes to our Platform or services we offer
                or provide though it.
              </li>
              <li>
                To allow you to participate in interactive features on our
                Platform.
              </li>
              <li>
                In any other way we may describe when you provide the
                information.
              </li>
              <li>For any other purpose with your consent.</li>
            </ul>
          </div>
        </section>
        <section className={styles.section}>
          <h2>5. Disclosure of Your Information</h2>
          <div>
            <p>
              Except to the extent prohibited by applicable law, we may disclose
              aggregated information about our users, and information that does
              not identify any individual, without restriction.
            </p>
            <p>
              We may disclose personal information that we collect or you
              provide as described in this privacy policy:
            </p>
            <ul>
              <li>To our subsidiaries and affiliates.</li>
              <li>
                To contractors, service providers, and other third parties we
                use to support our business and who are bound by contractual
                obligations to keep personal information confidential and use it
                only for the purposes for which we disclose it to them.
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of our assets.
              </li>
              <li>To fulfill the purpose for which you provide it. </li>
              <li>
                For any other purpose disclosed by us when you provide the
                information.
              </li>
              <li>With your consent.</li>
            </ul>
            <p>We may also disclose your personal information:</p>
            <ul>
              <li>
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request.
              </li>
              <li>
                To enforce or apply our Terms of Use{" "}
                <a href="https://therapass.com/privacy">
                  https://therapass.com/privacy
                </a>
                .
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of TheraPass, our customers, or
                others.
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.section}>
          <h2>6. Choices About How We Use and Disclose Your Information</h2>
          <div>
            <p>
              We strive to provide you with choices regarding the personal
              information you provide to us. We have created mechanisms to
              provide you with the following control over your information:
            </p>
            <ul>
              <li>
                <b>Tracking Technologies and Advertising.</b> You can set your
                browser to refuse all or some browser cookies, or to alert you
                when cookies are being sent. If you disable or refuse cookies,
                please note that some parts of this site may then be
                inaccessible or not function properly.
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.section}>
          <h2>7. Accessing and Correcting Your Information</h2>
          <div>
            <p>
              You can review and change your personal information by logging
              into the Platform and visiting your account profile page.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>8. Data Security</h2>
          <div>
            <p>
              We have implemented measures designed to secure your personal
              information from accidental loss and from unauthorized access,
              use, alteration, and disclosure. However, the safety and security
              of your information also depends on you. Where we have given you
              (or where you have chosen) a password for access to certain parts
              of our Platform, you are responsible for keeping this password
              confidential. We ask you not to share your password with anyone.
            </p>
            <p>
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although we do our best to protect your
              personal information, we cannot guarantee the security of your
              personal information transmitted to our Platform. We are not
              responsible for circumvention of any privacy settings or security
              measures contained on the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>9. Changes to Our Privacy Policy</h2>
          <div>
            <p>
              It is our policy to post any changes we make to our privacy policy
              on this page. If we make material changes to how we treat our
              users’ personal information, we will notify you through a notice
              on the Platform home page. The date the privacy policy was last
              revised is identified at the top of the page. You are responsible
              for ensuring we have an up-to-date active and deliverable email
              address for you, and for periodically visiting our Platform and
              this privacy policy to check for any changes.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>10. Contact Information</h2>
          <div>
            <p>
              To ask questions or comment about this privacy policy and our
              privacy practices, contact us at:{" "}
              <a href="mailto:notice@therapass.com">notice@therapass.com</a>
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};
