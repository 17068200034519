import { FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { useAuth } from "hooks/useAuth";
import { stepsFullPath } from "../stepsPath";
import { stepsUi } from "../components";
import { step4FullPath } from "../PatientStep4";
import { step6FullPath } from "../PatientStep6";
import { useSteps } from "hooks/useSteps";
import Radiobutton from "common/UIKit/Radiobutton/Radiobutton";
import { Patient, PreferredPrice } from "types/patient.types";
import { useResponsive } from "hooks/useResponsive";
import { PRICE_OPTIONS } from "constants/options";
import styles from "./PatientStep5.module.scss";

export const step5Path = "/5";
export const step5FullPath = [stepsFullPath, step5Path].join("");

export const PatientStep5 = () => {
  const patient = useAuth().user as Patient;
  const { isMobile } = useResponsive();

  const [selectedPrice, setSelectedPrice] = useState<PreferredPrice>(
    patient?.preferred_price || "avg"
  );

  const { isSubmitting, error, submit } = useSteps();
  const navigate = useNavigate();
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    await submit({
      diff: { preferred_price: selectedPrice },
      onSuccess: () => navigate(step6FullPath),
      errorMessage: "Select a price",
    });
  };

  return (
    <>
      <stepsUi.Header>My budget per session</stepsUi.Header>
      <stepsUi.Hint>Select one</stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        maxWidth="304px"
        actions={
          <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <stepsUi.Actions
              buttons={[
                {
                  isLink: true,
                  children: "Back",
                  className: "btn-color-blue",
                  to: step4FullPath,
                },
                {
                  type: "submit",
                  children: isMobile ? "Continue" : "Next",
                  isLoading: isSubmitting,
                },
              ]}
            />
          </>
        }
      >
        <stepsUi.List>
          {PRICE_OPTIONS.map(({ label, value }) => (
            <Radiobutton
              isOutlined
              key={value}
              id={`value-${value}`}
              checked={value === selectedPrice}
              onChange={() => setSelectedPrice(value)}
            >
              {label}
            </Radiobutton>
          ))}
        </stepsUi.List>
      </stepsUi.Form>
    </>
  );
};
