import cn from "classnames";
import { useSnapshot } from "valtio";

import { filesService } from "services/filesService";

import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";

import styles from "./ProgressBar.module.scss";

type Props = {
  onRemove?: () => void;
};

export const ProgressBar = ({ onRemove }: Props) => {
  const { uploadProgress, uploadStartTime } = useSnapshot(filesService);

  const diff = (Date.now() - uploadStartTime) / 1000;
  const inProgress = uploadProgress !== null;

  if (!inProgress) {
    return null;
  }

  return (
    <div className={styles.root}>
      {inProgress && <p className={styles.text}>Uploading</p>}
      <button className={styles.remove} type="button" onClick={onRemove}>
        <CloseIcon />
      </button>
      {inProgress && (
        <>
          <p className={cn(styles.text, styles.progress_text)}>
            {uploadProgress}% <span className={styles.dot} />{" "}
            {diff && uploadProgress
              ? Math.round((100 / uploadProgress - 1) * diff)
              : "-"}{" "}
            sec remaining
          </p>
          <div className={styles.bar_container}>
            <div
              className={styles.bar}
              style={{ width: `${uploadProgress}%` }}
            />
          </div>
        </>
      )}
    </div>
  );
};
