import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { DispatchDataResponse } from "react-acceptjs/dist/types";
import { AxiosResponse } from "axios";
import { goThroughErrors } from "common/utils";
import { CreditCardId } from "types/creditCards.types";
import { BuyHandler } from "./CardCodeModal";

type Inputs = {
  CVV: string;
};

const defaultValues: Inputs = {
  CVV: "",
};

export const usePaymentWithExistingCard = (
  cardId: CreditCardId,
  onBuy: BuyHandler
) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<Inputs>({ defaultValues, reValidateMode: "onChange" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [globalError, setGlobalError] = useState<string>();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    try {
      await setIsSubmitting(true);
      await onBuy({
        bank_card: cardId,
        card_code: values.CVV,
      });
    } catch (error: any) {
      if (error.messages) {
        const { messages } = error as DispatchDataResponse;
        setGlobalError(
          messages.message
            .map(({ code, text }) => `${code}: ${text}`)
            .join(", ")
        );
      } else if (error.data) {
        const { data } = error as AxiosResponse;
        goThroughErrors(data, (fieldName, message) => {
          fieldName === "errors" && setGlobalError(message);
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    control,
    register,
    errors: { ...errors, global: globalError },
    handleSubmit: handleSubmit(onSubmit),
    isSubmitting,
    setValue,
  };
};
