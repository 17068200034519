import { Link } from "react-router-dom";
import { ReactComponent as LogoIcon } from "assets/images/logo.svg";
import cn from "classnames";
import "./Logo.scss";

interface Props {
  className?: string;
}

export const Logo = ({ className }: Props) => {
  return (
    <Link to="/" className={cn("logo", className)}>
      <LogoIcon />
    </Link>
  );
};
