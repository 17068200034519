import { FC, useEffect } from "react";
import isEmail from "validator/lib/isEmail";
import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import AppSelect from "common/UIKit/Select";
import { useProfileForm } from "../useProfileForm";
import { PatientFields } from "../../../PatientSteps/util";
import { personalInformationPath } from "../PersonalInformation";
import { useAuth } from "hooks/useAuth";
import { ReactComponent as EditIcon } from "assets/images/icons/edit-white.svg";
import { useModal } from "hooks/useModal";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./EditPersonalInformation.module.scss";
import { MessagePopup } from "common/components/MessagePopup";
import { Patient } from "types/patient.types";
import { GenderOption, GENDER_OPTIONS, ListOption, Prefix, PrefixOption, PREFIX_OPTIONS } from "constants/options";
import { Controller } from "react-hook-form";
import { Gender } from "types/therapist.types";

export const editPersonalInformationPath = `${personalInformationPath}/edit`;

const coveredFields: PatientFields = [
  "user.first_name",
  "user.last_name",
  "user.mobile_phone",
  "user.email",
  "user.dob",
  "user.gender",
  "user.prefix",
];
type Inputs = {
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
  dob: string;
  gender: string;
  prefix: Prefix;
};

export const EditPersonalInformation: FC = () => {
  const { goBack } = useDashboardNavigation("Edit Personal Information", {
    goBack: "pop_path",
  });
  const { register, control, handleSubmit, errors, isSubmitting, setValue } =
    useProfileForm<Inputs>(coveredFields, goBack, "client");
  const { baseUser } = useAuth();
  const user = useAuth().user as Patient | null;
  const { open } = useModal("CHANGE_EMAIL");
  const phoneChangeModal = useModal("CHANGE_PHONE_NUMBER_IN_PROFILE");

  useEffect(() => {
    user && setValue('mobile_phone', user?.user.mobile_phone);
  }, [user?.user.mobile_phone])

  const handleChangeEmail = () => open();

  return (
    <div className={styles.editPersonalInformation}>
      <MessagePopup />
      <form className={styles.mainForm} onSubmit={handleSubmit}>
        <Controller
          control={control}
          name="prefix"
          render={({ field, fieldState }) => (
            <AppSelect
              label="Prefix"
              placeholder="Select"
              {...field}
              options={PREFIX_OPTIONS}
              value={PREFIX_OPTIONS.find((o) => o.value === field.value)}
              onChange={(newValue) => {
                const { value } = newValue as PrefixOption;
                field.onChange(value);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
        <Input
          label="First Name"
          placeholder=""
          {...register("first_name", {
            required: "This field is required.",
          })}
          error={errors.first_name?.message}
        />
        <Input
          label="Last Name"
          placeholder=""
          {...register("last_name", {
            required: "This field is required.",
          })}
          error={errors.last_name?.message}
        />
        <div className={styles.row}>
          <Input
              className={styles.phone}
              label="Mobile Phone"
              placeholder=""
              value={user?.user.mobile_phone}

              disabled
          />
          {!baseUser?.is_google_user && (
              <button
                  className={styles.changeEmail}
                  onClick={() => phoneChangeModal.open()}
                  type="button"
              >
                <EditIcon className={styles.changeEmailIcon} />
                <div className={styles.changeEmailText}>Change mobile phone</div>
              </button>
          )}
        </div>
        <div className={styles.row}>
          <Input
            type="email"
            label="Email"
            placeholder=""
            {...register("email", {
              required: "This field is required.",
              validate: (value) =>
                !value || isEmail(value) || "This email is incorrect.",
            })}
            error={errors.email?.message}
            disabled
          />
          {!baseUser?.is_google_user && (
            <button
              className={styles.changeEmail}
              onClick={handleChangeEmail}
              type="button"
            >
              <EditIcon className={styles.changeEmailIcon} />
              <div className={styles.changeEmailText}>Change email</div>
            </button>
          )}
        </div>
        <Input
          label="Menthal Healh Role"
          placeholder=""
          value="Patient"
          disabled
        />
        <Controller
          control={control}
          name="gender"
          render={({ field, fieldState }) => (
            <AppSelect
              label="Gender"
              placeholder="Select"
              {...field}
              options={GENDER_OPTIONS}
              value={GENDER_OPTIONS.find((o) => o.value === field.value)}
              onChange={(newValue) => {
                const { value } = newValue as GenderOption;
                field.onChange(value);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
        <Input
          label="Date of Birth"
          placeholder=""
          {...register("dob")}
          className={styles.editPersonalInformation__fullWidth}
        />
        <Button
          className="w-100"
          type="submit"
          text="Save"
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};
