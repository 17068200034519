import { Route, Routes } from "react-router-dom";
import { DashboardLayout } from "common/components/DashboardLayout";
import { AccessController } from "common/components/Layout/AccessController";
import { tabs } from "./tabs";
import { profilePath } from "./Profile";
import { settingsPath } from "./Settings";
import { subscriptionPath } from "./Subscription";
import { paymentsPath } from "./Payments/Payments";
import { RateCards } from "./Payments/pages/RateCards/RateCards";
import { StripeCancellation } from "./Payments/pages/EditStripe/StripeCancellation/StripeCancellation";
import { ConfirmCancellationModal } from "../SubscriptionPlans/ConfirmCancellationModal";
import { ChangePlanSuccessModal } from "../SubscriptionPlans/ChangePlanSuccessModal";

export const therapistDashboardPath = "/dashboard";
export const therapistDashboardFullPath = `/therapists${therapistDashboardPath}`;
const nestedRoutes = [
  profilePath,
  settingsPath,
  subscriptionPath,
  paymentsPath,
];

export const TherapistsDashboard = () => {
  return (
    <AccessController therapist="allowed">
      <DashboardLayout tabs={tabs}>
        <Routes>
          {tabs.map(({ value, component: Element }) => {
            return (
              <Route
                path={nestedRoutes.includes(value) ? `${value}/*` : value}
                element={<Element />}
                key={value}
              />
            );
          })}
        </Routes>
      </DashboardLayout>
      <StripeCancellation />
      <RateCards />
      <ConfirmCancellationModal />
      <ChangePlanSuccessModal />
    </AccessController>
  );
};
