import {
  APPOINTMENT_DATE_OPTIONS,
  ETHNICITY_OPTIONS,
  FAITH_OPTIONS,
  ListOption,
  PREFERENCE_OPTIONS,
  PRICE_OPTIONS,
  SEXUALITY_OPTIONS,
  THERAPY_TYPE_OPTIONS,
} from "constants/options";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { AGE_OPTIONS } from "common/UIKit/AgeCardCheckbox/AgeCardCheckbox";
import { Patient } from "types/patient.types";

const findOptionsForMultiple = <T>(field: T[], options: ListOption<T>[]) =>
  field.map((pref) => options.find((option) => option.value === pref));

const findOptionsForSingular = <T>(field: T, options: ListOption<T>[]) =>
  options.find((option) => option.value === field);

export const usePatientsInformation = (user: Patient) => {
  const experience = useDictionaryItemNames("Issues", user.issues);
  const language = useDictionaryItemNames(
    "Languages",
    user.preferred_languages
  );

  const age = AGE_OPTIONS.find((option) => option.value === user.age);
  const price = findOptionsForSingular(user.preferred_price, PRICE_OPTIONS);
  const ethnicity = findOptionsForSingular(
    user.preferred_ethnicities,
    ETHNICITY_OPTIONS
  );
  const faith = findOptionsForSingular(user.preferred_faith, FAITH_OPTIONS);
  const appointmentDate = findOptionsForSingular(
    user.appointment_date,
    APPOINTMENT_DATE_OPTIONS
  );

  const preference = findOptionsForMultiple(
    user.therapist_preferences,
    PREFERENCE_OPTIONS
  );
  const sexuality = findOptionsForMultiple(
    user.preferred_sexualities,
    SEXUALITY_OPTIONS
  );
  const therapyType = findOptionsForMultiple(
    user.therapy_types,
    THERAPY_TYPE_OPTIONS
  );

  return {
    therapyType,
    experience,
    preference,
    age,
    price,
    ethnicity,
    sexuality,
    language,
    faith,
    appointmentDate,
  };
};
