import { Range } from "react-range";

import "./Range.scss";

type Props = {
  value: number;
  minValue?: number;
  maxValue?: number;
  onChange: (nextValue: number) => void;
};

function RangePicker({ value, minValue = 0, maxValue = 100, onChange }: Props) {
  return (
    <Range
      step={1}
      min={minValue}
      max={maxValue}
      values={[value]}
      onChange={(values) => onChange(values[0])}
      renderTrack={({ props, children }) => (
        <div {...props} className="range__track">
          {children}
          <div className="range__row">
            <div className="range__min">{minValue}</div>
            <div className="range__max">{maxValue}</div>
          </div>
        </div>
      )}
      renderThumb={({ props }) => <div {...props} className="range__thumb" />}
    />
  );
}

export default RangePicker;
