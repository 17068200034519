import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import { useSteps } from "hooks/useSteps";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";
import { Faith } from "constants/options";
import { step10FullPath } from "../PatientStep10";

type Inputs = {
  preferred_faith: Faith;
};

export const usePatientStep9 = () => {
  const { isSubmitting, error, submit } = useSteps();
  const patient = useAuth().user as Patient;
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: { preferred_faith: patient.preferred_faith || "none" },
  });

  const onSubmit: SubmitHandler<Inputs> = async (values) =>
    submit({
      diff: values,
      onSuccess: () => navigate(step10FullPath),
    });

  return {
    register,
    isSubmitting,
    error,
    handleSubmit: handleSubmit(onSubmit),
  };
};
