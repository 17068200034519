import useFetch from "hooks/useFetch";
import { ListOption } from "constants/options";
import { dictionaryService } from "services/dictionaryService";
import { toOptions } from "common/utils";
import { step5FullPath } from "../TherapistStep5";
import useAnyTherapistStep from "../useAnyTherapistStep";
import { coveredFields } from "./step4_2Config";
import { step4_1FullPath } from "../TherapistStep4_1";
import { Modality } from "types/therapist.types";

type Inputs = {
  therapies: string[];
  modalities: Modality[];
};

function useTherapistStep4_2() {
  const generic = useAnyTherapistStep<Inputs>(coveredFields, step5FullPath, {
    prevStep: step4_1FullPath,
  });

  const therapyTypes = useFetch<ListOption[]>(() => dictionaryService.getTherapies(), {
    transformer: toOptions,
  });

  return {
    isProcessing: therapyTypes.isFetching,
    availableTherapyTypes: therapyTypes.data,
    ...generic,
  };
}

export default useTherapistStep4_2;
