import Input from "common/UIKit/Input";
import styles from "./MacrosSystemItem.module.scss";

interface Props {
  position: string;
  value: string;
  text: string;
}

export const MacrosSystemItem = ({ position, text, value }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.position}>{position}</div>
      <div className={styles.row}>
        <Input className={styles.input} value={value} disabled hashtag={true} withLeftAdornment adornment="#"/>
        <div className={styles.label}>{text}</div>
      </div>
    </div>
  );
};
