import { Route, Routes, Navigate } from "react-router-dom";

import { TestPrepare, testsPreparePath } from "./TestPrepare";
import { TestStart, testsStartPath } from "./TestStart";
import { TestResult, testsResultPath } from "./TestResult";
import { TestProvider } from "providers/TestProvider";

export const takeTestPath = "/:id";

interface Props {
  isIntake: boolean;
}

export const TakeTest = ({ isIntake } : Props) => {
  return (
    <TestProvider>
      <Routes>
        <Route path={testsPreparePath} element={<TestPrepare isIntake={isIntake} />} />
        <Route path={testsStartPath} element={<TestStart />} />
        <Route path={testsResultPath} element={<TestResult isIntake={isIntake} />} />
        <Route path="*" element={<Navigate to={`.${testsPreparePath}`} />} />
      </Routes>
    </TestProvider>
  );
};
