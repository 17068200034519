import { FC } from "react";
import { Controller, FieldError } from "react-hook-form";
import { TestTextArea } from "types/testProcess.types";
import Textarea from "common/UIKit/Textarea/Textarea";
import { useTest } from "hooks/useTest";
import styles from "./TextArea.module.scss";

interface Props {
  question: TestTextArea;
  disabled?: boolean;
  placeholder?: string;
}

export const TextArea: FC<Props> = ({ question, disabled, placeholder }) => {
  const { control } = useTest();

  return (
    <Controller
      name={question.id.toString()}
      control={control}
      rules={{
        required: {
          value: question.required,
          message: "This field is required",
        },
      }}
      render={({ field: { value, onChange, ref }, formState: { errors } }) => (
        <Textarea
          placeholder={placeholder || question.placeholder}
          maxLength={question.max_length || undefined}
          currentLength={(value as string | undefined)?.length || 0}
          className={styles.textarea}
          error={(errors[question.id] as FieldError | undefined)?.message}
          value={(value as string | undefined) || ""}
          onChange={(event) =>
            onChange((event.target as HTMLTextAreaElement).value || "")
          }
          ref={ref}
          disabled={disabled}
          withoutLength={disabled}
        />
      )}
    />
  );
};
