import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CardData, useAcceptJs } from "react-acceptjs";
import { DispatchDataResponse } from "react-acceptjs/dist/types";
import { AxiosResponse } from "axios";

import { acceptJsConfig } from "constants/acceptJs";
import { creditCardService } from "services/creditCardService";
import { CreditCard } from "types/creditCards.types";
import { goThroughErrors } from "common/utils";

type Inputs = {
  cardHolder: string;
  cardNumber: string;
  expiry: string;
  CVV: string;
  isAccepted: boolean;
};

type Options = {
  onSuccess?: (newCard: CreditCard) => void;
  onError?: (error: unknown) => void;
};

const defaultValues: Inputs = {
  cardHolder: "",
  cardNumber: "",
  expiry: "",
  CVV: "",
  isAccepted: false,
};

export const useCreditCardForm = (options?: Options) => {
  const { dispatchData } = useAcceptJs(acceptJsConfig);

  const {
    control,
    register,
    formState: { errors },
    setValue,
    setError,
    handleSubmit,
  } = useForm<Inputs>({ defaultValues, reValidateMode: "onChange" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [globalError, setGlobalError] = useState<string>();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    try {
      await setIsSubmitting(true);
      const [month, year] = values.expiry.split("/");
      const cardData: CardData = {
        cardCode: values.CVV,
        fullName: values.cardHolder,
        cardNumber: values.cardNumber.replace(/[\s_]/g, ""),
        month,
        year,
      };

      const response = await dispatchData({
        cardData,
      });
      const newCard = await creditCardService.create({
        card_token: response.opaqueData.dataValue,
        descriptor: response.opaqueData.dataDescriptor,
        cardholder_name: values.cardHolder,
      });

      options?.onSuccess && options?.onSuccess(newCard);
    } catch (error: any) {
      if (error.messages) {
        const { messages } = error as DispatchDataResponse;
        setGlobalError(
          messages.message
            .map(({ code, text }) => `${code}: ${text}`)
            .join(", ")
        );
      } else if (error.data) {
        const { data } = error as AxiosResponse;
        goThroughErrors(data, (fieldName, message) => {
          fieldName === "cardholder_name" &&
            setError("cardHolder", { message });
          fieldName === "errors" && setGlobalError(message);
        });
      }
      options?.onError && options.onError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    control,
    register,
    errors: { ...errors, global: globalError },
    handleSubmit: handleSubmit(onSubmit),
    isSubmitting,
    setValue,
  };
};
