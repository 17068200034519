import { Routes, Route } from "react-router-dom";
import { AccessController } from "common/components/Layout/AccessController";
import { ChangePlanSuccessModal } from "./ChangePlanSuccessModal";
import { Overview, overviewPath } from "./Overview";
import { Payment, paymentPath } from "./Payment";

export const subscriptionPlansPath = "/subscription-plans";

export const SubscriptionPlans = () => {
  return (
    <AccessController therapist="allowed">
      <ChangePlanSuccessModal />
      <Routes>
        <Route path={overviewPath} element={<Overview />} />
        <Route path={paymentPath} element={<Payment />} />
      </Routes>
    </AccessController>
  );
};
