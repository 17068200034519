import { FC, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import cn from "classnames";
import { useResponsive } from "hooks/useResponsive";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { Tab, TabList, TabPanel, Tabs } from "common/UIKit/Tabs";
import { Payments } from "common/pages/Payments";
import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";
import { Overview } from "./Overview";
import { AddNewCard } from "common/pages/AddNewCard";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./Subscription.module.scss";

export const subscriptionPath = "/subscription";

const RootElement = () => {
  useDashboardNavigation("Subscription", {
    goBack: "pop_path",
  });
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const { isMobile } = useResponsive();
  const [tabIndex, setTabIndex] = useState(0);
  useDocumentTitle("TheraPass - Subscription");

  useEffect(() => {
    if (query.get("tab")) {
      setTabIndex(1);
    }
  }, [query]);

  const onSelected = (index: number) => {
    if (index === 1) {
      setQuery({ tab: "payments" });
    } else {
      setQuery("");
    }
    setTabIndex(index);
  };
  const showAddCard = () => navigate("./add-new-card");

  return (
    <>
      {!isMobile && <h1 className={styles.title}>Subscription</h1>}
      <Tabs selectedIndex={tabIndex} onSelect={onSelected}>
        <div className={styles.tabs}>
          <TabList className={cn("react-tabs__tab-list", styles.tabList)}>
            <Tab>Overview</Tab>
            <Tab>Payments</Tab>
          </TabList>
          {!isMobile && tabIndex === 1 && (
            <div
              className={styles.addNewCard}
              role="button"
              onClick={showAddCard}
            >
              <PlusIcon /> <span>Add new credit card</span>
            </div>
          )}
        </div>

        <TabPanel>
          <Overview />
        </TabPanel>
        <TabPanel>
          <Payments onAddCard={showAddCard} />
        </TabPanel>
      </Tabs>
    </>
  );
};

export const Subscription: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.subscription}>
      <Routes>
        <Route path="/" element={<RootElement />} />
        <Route
          path="/add-new-card"
          element={
            <AddNewCard
              goBack={() =>
                navigate("/therapists/dashboard/subscription?tab=payments")
              }
            />
          }
        />
        <Route path="*" element={<Navigate to="./" />} />
      </Routes>
    </div>
  );
};
