import { useNavigate } from "react-router-dom";
import { step9_2FullPath } from "../TherapistStep9_2";
import useFileUpload from "hooks/useFileUpload";
import useSkipSteps from "../useSkipSteps";

function useTherapistStep9_1() {
  const navigate = useNavigate();
  const skipSteps = useSkipSteps();

  const fUpload = useFileUpload("photo_and_video_intro", ["image"], {
    onSuccess: () => navigate(step9_2FullPath),
    onSkip: () => skipSteps(),
  });
  return fUpload;
}

export default useTherapistStep9_1;
