import { FC } from "react";
import { Controller } from "react-hook-form";
import { TestCheckbox, TestOptionValue } from "types/testProcess.types";
import Checkbox from "common/UIKit/Checkbox";
import { useTest } from "hooks/useTest";
import styles from "./Multi.module.scss";

interface Props {
  question: TestCheckbox;
  disabled?: boolean;
}

export const Multi: FC<Props> = ({ question, disabled }) => {
  const { control, errors } = useTest();

  return (
    <Controller
      control={control}
      name={question.id.toString()}
      rules={{
        required: {
          value: question.required,
          message: "This field is required",
        },
      }}
      render={({ field }) => {
        const { onChange, ref } = field;
        const value = (field.value || []) as (string | number)[];

        return (
          <div className={styles.multi}>
            <div className={styles.row}>
              {question.options.map((option) => (
                <div className={styles.item} key={option.value}>
                  <div className={styles.label}>{option.label}</div>
                  <Checkbox
                    className={styles.checkbox}
                    id={`${question.id}-${option.value}`}
                    value={option.value}
                    type="checkbox"
                    checked={value.includes(option.value) || false}
                    onChange={(e) =>
                      onChange(
                        e.currentTarget.checked
                          ? [...value, option.value]
                          : value?.filter(
                              (v: TestOptionValue) => v !== option.value
                            )
                      )
                    }
                    ref={ref}
                    disabled={disabled}
                  >
                    {option.value}
                  </Checkbox>
                </div>
              ))}
            </div>
            {errors[question.id] && (
              <p className={styles.error}>{errors[question.id]?.message}</p>
            )}
          </div>
        );
      }}
    />
  );
};
