import { FormProvider } from "react-hook-form";
import { TooltipDesktop } from "./TooltipDesktop";
import { StatusSwitch } from "./StatusSwitch";
import { useChangeAvailability } from "./useChangeAvailability";
import { AvailabilitySwitches } from "./AvailabilitySwitches";
import { Therapist } from "types/therapist.types";
import { useSnapshot } from "valtio";
import { authService, isChatAccess } from "services/authService";
import styles from "./Availability.module.scss";
import cn from "classnames";

export const availabilityPath = "/availability";

export const Availability = () => {
  const methods = useChangeAvailability();
  const therapist = useSnapshot(authService).user as Therapist;

  return (
    <div className={styles.availability}>
      <FormProvider {...methods}>
        {isChatAccess(therapist) && (
          <div className={cn(styles.chatStatus, !therapist.online_chat_sessions_availability && styles.disabled)}>
            <div className={styles.description}>
              <div className={styles.name}>
                <p className={styles.chatStatusText}>Chat Connect Status</p>
                <TooltipDesktop />
              </div>
              <small>Please set up your Chat Connect status</small>
            </div>
            <StatusSwitch />
          </div>
        )}
        <AvailabilitySwitches isChatAccess={isChatAccess(therapist)} />
      </FormProvider>
    </div>
  );
};
