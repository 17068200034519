import { HTMLProps, MutableRefObject, ReactElement, useState } from "react";
import cn from "classnames";
import { ReactComponent as Search } from "assets/images/icons/search.svg";
import "./InputSearch.scss";

export interface Props extends Omit<HTMLProps<HTMLInputElement>, "size"> {
  size?: "full" | "auto";
  selectedCount?: number;
  inputRef?: MutableRefObject<HTMLInputElement>;
  searchIcon?: ReactElement;
  placeholder?: string;
}

function InputSearch({
  size = "full",
  className,
  selectedCount,
  inputRef,
  searchIcon = <Search />,
  placeholder = "Search",
  ...restProps
}: Props) {
  const [isActive, setIsActive] = useState(false);
  const SearchIcon = () => searchIcon;

  return (
    <div className={cn("field", className)}>
      <div className={cn("search", isActive && "active")}>
        <span className="search__icon">
          <SearchIcon />
        </span>
        <input
          className={`input-size-${size} search__input`}
          type="text"
          placeholder={placeholder}
          ref={inputRef}
          {...restProps}
          onFocus={(e) => {
            setIsActive(true);
            if (restProps?.onFocus) {
              restProps.onFocus(e);
            }
          }}
          onBlur={(e) => {
            setIsActive(false);
            if (restProps?.onBlur) {
              restProps.onBlur(e);
            }
          }}
        />
        {selectedCount ? (
          <div className="search__selected">{selectedCount} selected</div>
        ) : null}
      </div>
    </div>
  );
}

export default InputSearch;
