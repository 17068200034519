import { useSearchParams } from "react-router-dom";
import { Tabs, Tab, TabList, TabPanel } from "common/UIKit/Tabs";
import { TestsTaken } from "./TestsTaken";
import { AvailableTests } from "./AvailableTests";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import testImage from "assets/images/test.png";
import { InProgress } from "./InProgress/InProgress";
import { FilterTestsPopup } from "common/components/FilterPopup/FilterTestsPopup";
import styles from "./Overview.module.scss";

export const testsMainPath = "/";

export const Overview = () => {
  useDashboardNavigation("Tests", {
    goBack: "pop_path",
  });

  const [search, navigate] = useSearchParams();
  const tabIndex = Number(search.get("tab_index"));

  return (
    <>
      <div className={styles.tests}>
        <h1 className={styles.title}>Tests</h1>
        <div className={styles.card}>
          <div className={styles.text}>
            <p className={styles.description}>
              Gain additional personal insights by taking any of our wide
              selection of both free and paid psychological tests.
            </p>
          </div>
          <img src={testImage} alt="test" className={styles.testImage} />
        </div>
        <Tabs
          className={styles.tabs}
          selectedIndex={tabIndex}
          onSelect={(tabIndex) => {
            navigate({ tab_index: String(tabIndex) });
          }}
        >
          <TabList>
            <Tab>Available Tests</Tab>
            <Tab>Tests Taken</Tab>
            <Tab>Test in Progress</Tab>
          </TabList>
          <TabPanel>
            <AvailableTests />
          </TabPanel>
          <TabPanel>
            <TestsTaken />
          </TabPanel>
          <TabPanel>
            <InProgress />
          </TabPanel>
        </Tabs>
      </div>
      <FilterTestsPopup />
    </>
  );
};
