import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Input from "common/UIKit/Input";
import { zipCodeValidator } from "common/validators/addressValidators";
import { stepsFullPath } from "../stepsPath";
import { stepsUi } from "../components";
import { step2FullPath } from "../PatientStep2";
import { useSteps } from "hooks/useSteps";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";
import { useResponsive } from "hooks/useResponsive";
import styles from "./PatientStep1.module.scss";

export const step1Path = "/1";
export const step1FullPath = `${stepsFullPath}${step1Path}`;

type Inputs = { zip_code: string };

export function PatientStep1() {
  const patient = useAuth().user as Patient;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { zip_code: patient?.zip_code },
  });
  const { isSubmitting, error, submit } = useSteps();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    await submit({ diff: values, onSuccess: () => navigate(step2FullPath) });
  };

  return (
    <>
      <stepsUi.Header>Enter your Zip code</stepsUi.Header>
      <stepsUi.Hint>Get best Recommended Therapists</stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit(onSubmit)}
        maxWidth="387px"
        actions={
          <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <stepsUi.Actions
              className={styles.actions}
              buttons={[
                {
                  className: "full-width",
                  type: "submit",
                  children: isMobile ? "Continue" : "Next",
                  isLoading: isSubmitting,
                },
              ]}
            />
          </>
        }
      >
        <Input
          type="text"
          className={styles.input}
          id="zip"
          placeholder=""
          label="Zip code"
          {...register("zip_code", zipCodeValidator)}
          error={errors.zip_code?.message}
        />
      </stepsUi.Form>
    </>
  );
}
