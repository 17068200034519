import { parseISO, format } from "date-fns";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";

import { Modal } from "common/components/Modal";

import { useModal } from "hooks/useModal";
import { useAuth } from "hooks/useAuth";

import { therapistDashboardFullPath } from "pages/Therapists/TherapistsDashboard";
import { subscriptionPath } from "pages/Therapists/TherapistsDashboard/Subscription";

import { subscriptionService } from "services/subscriptionService";

import tickCircleGreen from "assets/images/tick-circle-green.png";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";

import { Therapist } from "types/therapist.types";

import styles from "./ChangePlanSuccessModal.module.scss";

// `CANCEL` - cancelation date is assigned
// `DOWNGRADE` - instante cancel or downgrading plan

type PlanChangeEvent = "CANCEL" | "UPGRADE" | "DOWNGRADE";

const HEADER_TEXT: Record<PlanChangeEvent, ReactNode> = {
  CANCEL: "Done!",
  UPGRADE: "Congratulations!",
  DOWNGRADE: "Go back to Premium!",
};

export const ChangePlanSuccessModal = () => {
  const navigate = useNavigate();
  const { currentSubscription } = useSnapshot(subscriptionService);
  const user = useAuth().user as Therapist;
  const { isOpen, close } = useModal("CHANGE_SUBSCRIPTION_SUCCESS");

  const event: PlanChangeEvent = currentSubscription?.cancelled_at
    ? "CANCEL"
    : currentSubscription
    ? "UPGRADE"
    : "DOWNGRADE";

  const cancelDate =
    currentSubscription?.cancelled_at &&
    format(parseISO(currentSubscription.cancelled_at), "d MMMM yyyy");

  const bodyText =
    event === "UPGRADE" ? (
      <span>Now you’re a {user.subscription_plan.name} user</span>
    ) : event === "DOWNGRADE" ? (
      <span>You're a {user.subscription_plan.name} user now</span>
    ) : (
      <>
        <span>Your subscription will be automatically cancelled on</span>
        <span>{cancelDate}</span>
      </>
    );

  const onClose = () => {
    close();
    navigate(`${therapistDashboardFullPath}${subscriptionPath}`);
  };

  return (
    <Modal visible={isOpen} className={styles.wrapper} onClose={onClose}>
      <div className={styles.changePlanSuccessModal}>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
        <img src={tickCircleGreen} alt="circle-green" />
        <h1>{HEADER_TEXT[event]}</h1>
        <p>{bodyText}</p>
        <p className={styles.goToDashboard} onClick={onClose}>
          Go to dashboard
        </p>
      </div>
    </Modal>
  );
};
