import { Group, GroupId, ViewMode } from "types/group.types";
import { proxy } from "valtio";
import { CrudService } from "./crudService";

export class GroupService extends CrudService<GroupId, Group> {
  viewMode: ViewMode = "list";
  selectedGroupId?: GroupId;

  constructor(args?: any) {
    super({
      ...args,
      prefix: "/v1/therapists/groups",
    });
  }
}

export const groupService = proxy(new GroupService());
groupService.init();
