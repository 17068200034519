import isNumeric from "validator/lib/isNumeric";

export const npiNumberValidator = {
  validate: {
    isNumber: (value: string | null) =>
      value === null ||
      value === "" ||
      isNumeric(value) ||
      "Should be a number",
    length: (value: string | null) =>
      value === null ||
      value === "" ||
      value.length === 10 ||
      "Enter 10 digits",
  },
};

export const npiNumberValidatorForRemove = {
  required: "Required field",
  validate: {
    isNumber: (value: string | null) =>
        value === null ||
        value === "" ||
        isNumeric(value) ||
        "Should be a number",
    length: (value: string | null) =>
        value === null ||
        value === "" ||
        value.length === 10 ||
        "Enter 10 digits",
  },
};

