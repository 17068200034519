import { useWindowResize } from "hooks/useWindowResize";
import { DesktopFilters } from "./DesktopFilters";
import { MobileFilters } from "./MobileFilters";

export const Filters = () => {
  const { width } = useWindowResize();
  const isMobile = width <= 992;

  return isMobile ? <MobileFilters /> : <DesktopFilters />;
};
