import { useEffect, useState } from "react";
import cn from "classnames";
import { useSearchParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { PaymentRadio } from "common/UIKit/PaymentRadio";
import Button from "common/UIKit/Button";
import { CardInfo } from "common/components/CardInfo";
import { CardCodeModal } from "common/components/CardCodeModal";
import { ChooseCreditCard } from "common/components/ChooseCreditCard";
import { AddCreditCard } from "common/components/AddCreditCard";
import { plansService } from "services/plansService";
import { creditCardService } from "services/creditCardService";
import { subscriptionService } from "services/subscriptionService";
import { paymentPeriodOptions } from "constants/options";
import { PaymentPeriod, Plan } from "types/plan.types";
import { useModal } from "hooks/useModal";
import { useResponsive } from "hooks/useResponsive";
import { SubscriptionPlansLayout } from "../SubscriptionPlansLayout";
import { ReactComponent as IconInfo } from "assets/images/icons/info-circle.svg";
import styles from "./Payment.module.scss";

import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";

export const paymentPath = "/payment";

export const Payment = () => {
  const location = window.location.href;
  const [searchParams, setSearchParams] = useSearchParams();
  const planId = searchParams.get("planId");
  const period = searchParams.get("period");
  const { isMobile } = useResponsive();
  const { data: creditCards, isFetched: isCreditCardsFetched } =
    useSnapshot(creditCardService);
  const {
    data: plans,
    isFetched: isPlansFetched,
    planInfo,
  } = useSnapshot(plansService);
  const { activeSubscription, isFetched: isSubscriptionsFetched } =
    useSnapshot(subscriptionService);
  const { open: showAddCardModal } = useModal("ADD_CREDIT_CARD");
  const { open: showCardCodeModal } = useModal("CARD_CODE");
  const { open: showSubscriptionSuccessModal } = useModal(
    "CHANGE_SUBSCRIPTION_SUCCESS"
  );
  const [plan, setPlan] = useState<Plan>();
  const [selectedCard, setSelectedCard] = useState(creditCards[0]);

  useEffect(() => {
    if (location) {
      plansService.clearPlanInfo();
    }
  }, [location]);

  useEffect(() => {
    const planId = Number(searchParams.get("planId")) || 2;
    setPlan(plans.find(({ id }) => id === planId));
    // eslint-disable-next-line
  }, [plans]);

  useEffect(() => {
    setSelectedCard(
      creditCards.find((card) => card.id === activeSubscription?.bank_card) ||
        creditCards[0]
    );
  }, [creditCards, activeSubscription]);

  useEffect(() => {
    !isCreditCardsFetched && creditCardService.list();
    !isPlansFetched && plansService.list();
    !isSubscriptionsFetched && subscriptionService.list();
    // eslint-disable-next-line
  }, []);

  const paymentPeriod: PaymentPeriod =
    searchParams.get("period") === "year" ? "year" : "month";
  const setPaymentPeriod = (newPeriod: PaymentPeriod) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("period", newPeriod);
    setSearchParams(newParams);
  };
  const price = `$ ${
    paymentPeriod === "month" ? plan?.cost : plan?.cost_yearly
  } / ${paymentPeriod}`;

  const onPurchaseClick = async () => {
    if (!planInfo) {
      await plansService.changePlanInfo({
        ...(planId && { new_plan: planId }),
        ...(period && { new_interval: period === "month" ? "1" : "12" }),
      });
    } else {
      return selectedCard ? showCardCodeModal() : showAddCardModal();
    }
  };

  return (
    <SubscriptionPlansLayout headerAction="goBack">
      <ChooseCreditCard
        options={creditCards}
        value={selectedCard}
        onChange={setSelectedCard}
      />
      <AddCreditCard
        onAddCard={async (args) => {
          await subscriptionService.buyWithNewCard({
            ...args,
            subscription_plan: plan?.id || 0,
            interval: paymentPeriod === "year" ? 12 : 1,
          });
          showSubscriptionSuccessModal();
        }}
      />
      {selectedCard && (
        <CardCodeModal
          selectedCard={selectedCard}
          onBuy={async (args) => {
            await subscriptionService.buyWithSavedCard({
              ...args,
              subscription_plan: plan?.id || 0,
              interval: paymentPeriod === "year" ? 12 : 1,
            });
            showSubscriptionSuccessModal();
          }}
        />
      )}
      <div className={styles.payment}>
        <h1 className={styles.header}>
          <span className={styles.get}>Get Therapass</span>
          <span className={styles.premium}>{plan?.name}</span> plan
        </h1>
        <div className={styles.billingContainer}>
          <div className={styles.price}>
            <h2>Billing</h2>
            <p>{price}</p>
          </div>
          <PaymentRadio
            options={paymentPeriodOptions}
            value={paymentPeriod}
            onChange={setPaymentPeriod}
            className={styles.paymentRadio}
          />
        </div>
        <div className={styles.totalContainer}>
          <h2>Total</h2>
          <div className={styles.totalPrice}>
            <h2>{price}</h2>
            <p>Incl. VAT</p>
          </div>
        </div>
        {planInfo && (
          <div className={styles.warning}>
            <div className={styles.warningIcon}>
              <IconInfo />
            </div>
            <p className={styles.warningInfo}>
              {planInfo.text.replace(
                "{effective_date}",
                new Date(planInfo?.effective_date).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              )}
            </p>
          </div>
        )}
        {selectedCard ? (
          <CardInfo card={selectedCard} />
        ) : (
          <div className={styles.dontHaveCards}>
            <p>You don't have any attached credit cards</p>
            {isMobile ? (
              <Button
                className="w-100"
                text="Add a credit card"
                onClick={showAddCardModal}
                color="outline-gray"
              />
            ) : (
              <div
                className={styles.addNewCard}
                role="button"
                onClick={showAddCardModal}
              >
                <PlusIcon /> <span>Add new credit card</span>
              </div>
            )}
          </div>
        )}
        <Button
          text="Proceed to Payment"
          className={cn(styles.proceed, "w-100")}
          onClick={onPurchaseClick}
        />
      </div>
    </SubscriptionPlansLayout>
  );
};
