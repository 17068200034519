import { FC } from "react";

import { ChangePasswordForm } from "common/components/ChangePasswordForm";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./ChangePassword.module.scss";

export const changePasswordPath = "/change-password";

export const ChangePassword: FC = () => {

  return (
    <div className={styles.changePassword}>
      {/*<EditHeader />*/}
      <ChangePasswordForm />
    </div>
  );
};
