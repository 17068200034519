import { useSnapshot } from "valtio";
import cn from "classnames";
import { Availability } from "common/components/Availability";
import { LikeButton } from "common/UIKit/LikeButton";
import Button from "common/UIKit/Button";
import { OPTIONS_ICONS } from "constants/icons";
import { ReactComponent as MobileIcon } from "assets/images/icons/mobile-phone.svg";
import { ReactComponent as LandlineIcon } from "assets/images/icons/landline-phone.svg";
import { ReactComponent as ChatIcon } from "assets/images/icons/comment-dots.svg";
import { ReactComponent as ShareIcon } from "assets/images/icons/share.svg";
import { ReactComponent as BrowserIcon } from "assets/images/icons/browser.svg";
import { VerifiedCircle } from "../../blocks/VerifiedCircle/VerifiedCircle";
import { therapistProfileService } from "services/therapistProfileService";
import { favoriteTherapistsService } from "services/favoriteTherapistsService";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { useCallback } from "react";
import { communicationService } from "services/communicationService";
import { Avatar } from "common/UIKit/Avatar";
import { formatPhone, getWebsiteAddress, therapistName } from "common/utils";
import { FormatProfileField } from "common/components/FormatProfileField";
import { UserAuthStatus } from "types/auth.types";
import { availabilityOptions } from "constants/options";
import { isChatAccess } from "services/authService";
import { stateLicenseService } from "services/stateLicenseService";
import styles from "./PatientsTherapistHeaderMobile.module.scss";
import { modalService } from "services/modalService";
import { StateLicenseListItem } from "common/components/StateLicenseListItem";

interface Props {
  isTherapist: boolean | null;
  currentUserStatus: UserAuthStatus;
}

export const PatientsTherapistHeaderMobile = ({
  isTherapist,
  currentUserStatus,
}: Props) => {
  const shareData = {
    title: "TheraPass",
    text: "Therapist profile.",
    url: window.location.href,
  };

  const onShareButton = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(err);
    }
  };
  const openMessage = () => {
    currentProfile && communicationService.openPopup(currentProfile);
  };

  const { currentProfile } = useSnapshot(therapistProfileService);
  const { verifiedStateLicenses } = useSnapshot(stateLicenseService);

  const mobilePhone =
    currentProfile?.user.mobile_phone || currentProfile?.phone;
  const showMobilePhone =
    currentProfile &&
    mobilePhone &&
    (isChatAccess(currentProfile)
      ? !currentProfile?.user.hide_mobile_phone_number
      : true);

  const companyPhone = currentProfile?.business_info?.phone;
  const showCompanyPhone =
    currentProfile &&
    companyPhone &&
    !currentProfile?.business_info?.hide_company_phone_number;

  const withStatus =
    currentProfile &&
    isChatAccess(currentProfile) &&
    currentProfile?.online_chat_sessions_availability;

  const specialties = useDictionaryItemNames(
    "Issues",
    currentProfile?.specialties
  );

  const professionalSpecialtyNames = useDictionaryItemNames(
    "ProfessionalSpecialties",
    currentProfile?.professional_specialties
  );

  const onLike = useCallback(async () => {
    await therapistProfileService.like();
    favoriteTherapistsService.list(false);
  }, []);

  if (currentProfile === null) return null;

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Avatar
          className={styles.img}
          alt={therapistName({
            first_name: currentProfile.user.first_name,
            last_name: currentProfile.user.last_name,
          })}
          file={currentProfile?.avatar}
          chat_status={withStatus ? currentProfile.chat_status : undefined}
          withZoom
          id={currentProfile.id}
        />
        <div className={styles.info}>
          <div className={styles.claimBlock}>
            {isChatAccess(currentProfile) && (
              <Availability
                isAvailable={currentProfile?.is_available}
                className={styles.available}
              />
            )}
            {currentProfile.source.includes("imported") && (
              <div
                onClick={() => modalService.open("CLAIM_PROFILE")}
                className={styles.claim}
              >
                Claim this Profile
              </div>
            )}
          </div>

          <p className={styles.name}>
            <FormatProfileField maxLength={40}>
              {therapistName({
                first_name: currentProfile.user.first_name,
                last_name: currentProfile.user.last_name,
              })}
            </FormatProfileField>
            <VerifiedCircle withoutText size="sm" className={styles.nameIcon} />
          </p>
          {currentProfile.company_name &&
            currentProfile.business_info?.use_company_name_also &&
            (currentProfile.website ? (
              <a
                className={styles.company}
                href={getWebsiteAddress(currentProfile.website)}
              >
                {currentProfile.company_name}
              </a>
            ) : (
              <p className={styles.company}>{currentProfile.company_name}</p>
            ))}
          <p className={styles.subtitle}>
            {currentProfile.credentials_choice && (
              <em>
                <FormatProfileField shouldLimitLength={false}>
                  {currentProfile.credentials_choice}
                </FormatProfileField>
              </em>
            )}
            {currentProfile?.source.includes("imported") &&
              professionalSpecialtyNames.length > 0 && (
                <FormatProfileField
                  fallbackText="No Educational Specialties specified"
                  shouldLimitLength={false}
                >
                  {professionalSpecialtyNames.filter((item) => item).join(", ")}
                </FormatProfileField>
              )}
          </p>
        </div>
        {!isTherapist && currentUserStatus !== "loggedOut" ? (
          <LikeButton
            className={styles.like}
            isLiked={currentProfile?.is_my_favourite}
            onClick={onLike}
          />
        ) : null}
      </div>
      {verifiedStateLicenses.map((license) => (
        <StateLicenseListItem
          key={`${license.state}-${license.number}`}
          license={license}
        />
      ))}
      <div className={styles.options}>
        <p className={styles.optionsText}>Therapy Options:</p>
        <ul className={styles.optionsContainer}>
          <FormatProfileField fallbackText="No options">
            {OPTIONS_ICONS.filter(
              (option) => currentProfile && currentProfile[option.name]
            ).map(({ name, component: Component }) => (
              <li
                key={name}
                className={styles.optionsItem}
                aria-label={availabilityOptions[name]}
              >
                <Component />
              </li>
            ))}
          </FormatProfileField>
        </ul>
      </div>
      <div className={styles.contacts}>
        <div className={styles.linksContainer}>
          {showMobilePhone && (
            <a href={`tel:${mobilePhone}`} className={styles.phone}>
              <span className={styles.phoneIcon}>
                <MobileIcon />
              </span>
              <span className={styles.phoneText}>
                <span>{formatPhone(mobilePhone)}</span>
              </span>
            </a>
          )}
          {showCompanyPhone && (
            <a href={`tel:${companyPhone}`} className={styles.phone}>
              <span className={styles.phoneIcon}>
                {currentProfile?.user.mobile_phone &&
                currentProfile.business_info.phone &&
                !currentProfile.business_info.hide_company_phone_number &&
                !currentProfile.user.hide_mobile_phone_number ? (
                  <LandlineIcon />
                ) : (
                  <MobileIcon />
                )}
              </span>
              <span className={styles.phoneText}>
                <span>{formatPhone(companyPhone)}</span>
              </span>
            </a>
          )}
          {!showMobilePhone && !showCompanyPhone && (
            <p className={cn(styles.phone, styles.disabled)}>
              <span className={styles.phoneIcon}>
                <MobileIcon />
              </span>
              <span className={styles.phoneText}>
                <span>{formatPhone(mobilePhone)}</span>
              </span>
            </p>
          )}
        </div>
        <div className={styles.iconButtonsContainer}>
          <Button className={styles.contactsButton} onClick={onShareButton}>
            <ShareIcon />
          </Button>
          {currentProfile?.website && (
            <a
              className={cn(styles.contactsButton)}
              href={getWebsiteAddress(currentProfile.website)}
              target="_blank"
              rel="noreferrer"
            >
              <BrowserIcon />
            </a>
          )}
        </div>
      </div>
      {!isTherapist && currentProfile?.subscription_plan.chat_access && (
        <Button
          onClick={openMessage}
          className={cn(
            styles.button,
            isChatAccess(currentProfile) && styles.premium,
            withStatus && styles[`status_${currentProfile.chat_status}`]
          )}
        >
          <ChatIcon />
          <FormatProfileField maxLength={25}>
            {`${
              withStatus && !currentProfile.chat_status.match("offline")
                ? "Message"
                : "Email"
            }  ${therapistName({
              title: currentProfile.title,
              last_name: currentProfile.user.last_name,
            })}`}
          </FormatProfileField>
        </Button>
      )}
      {currentProfile?.source.includes("imported") &&
        professionalSpecialtyNames.length > 0 && (
          <ul className={styles.list}>
            <FormatProfileField fallbackText="No Educational Specialties specified">
              {specialties.length > 0 &&
                specialties.map((specialty) => (
                  <li className={styles.listItem} key={specialty}>
                    {specialty}
                  </li>
                ))}
            </FormatProfileField>
          </ul>
        )}
    </div>
  );
};
