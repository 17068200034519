import React, { ChangeEventHandler, ForwardedRef, forwardRef, ReactNode, useRef } from 'react';
import styles from './IconCheckboxCard.module.scss';
import cn from 'classnames';

type IconCheckboxCardProps = {
  checked: boolean,
  label: string,
  subLabel?: string,
  icon?: ReactNode,
  onChange: ChangeEventHandler<HTMLInputElement>,
  className?: string,
  compact?: boolean
};

function IconCheckboxCardComponent(
  { checked, label, icon, onChange, className, subLabel, compact }: IconCheckboxCardProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      ref={ref}
      className={className}
      onClick={() => inputRef.current?.click()}
    >
      <input
        ref={inputRef}
        className={cn(styles.IconCheckboxCardInput, compact && styles.IconCheckboxCardInput_compact)}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <label>
        {!!icon && <div data-div-role='icon-wrapper' className={styles.IconWrapper}>{icon}</div>}
        <div>
          <span className={styles.label}>{label}</span>
          {!!subLabel && <span className={styles.subLabel}>{subLabel}</span>}
        </div>
      </label>
    </div>
  );
}

const IconCheckboxCard = forwardRef(IconCheckboxCardComponent);

IconCheckboxCard.defaultProps = {
  compact: false
};

export default IconCheckboxCard;
