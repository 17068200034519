import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { useWindowResize } from "hooks/useWindowResize";
import { Pagination } from "common/UIKit/Pagination";
import { TestsTableMobile } from "../TestsTableMobile";
import { TestsTable } from "../TestsTable";
import { testProgressListService } from "services/testProgressListService";
import styles from "./InProgress.module.scss";

export const InProgress = () => {
  const { width } = useWindowResize();
  const { data, pageCount, page, sort, isFetching } = useSnapshot(
    testProgressListService
  );

  useEffect(() => {
    testProgressListService.fetch(false);
  }, []);

  const onSortChange = (
    sortBy: "name" | "finished_at" | "time_to_complete" | "started_at"
  ) => {
    if (sortBy === "name") {
      testProgressListService.setOrder(sortBy, { priority: 2 });
    } else {
      testProgressListService.clearOrder("name", false);
      testProgressListService.setOrder("started_at", { priority: 1 });
    }
  };

  if (data.length === 0 && !isFetching)
    return (
      <p className={styles.empty_message}>
        You don't have any Tests In Progress
      </p>
    );

  return (
    <>
      {width <= 1300 ? (
        <TestsTableMobile rows={data} />
      ) : (
        <TestsTable onSortChange={onSortChange} rows={data} sort={sort} />
      )}
      <Pagination
        pageCount={pageCount}
        currentPage={page}
        goTo={(page) => testProgressListService.goTo(page)}
      />
    </>
  );
};
