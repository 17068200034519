import { pick } from "lodash";
import { DeepPartial } from "common/utils";
import { FileDataField } from "common/components/ControlledFileUpload";
import { BaseUser } from "types/auth.types";
import {Patient, PatientFile} from "types/patient.types";

type PurePatientField = keyof Patient;
export type PatientFields = (PurePatientField | `user.${keyof BaseUser}`)[];
export type FileDataInputs = {
  fileData: FileDataField<PatientFile>;
};

function distinguishPaths(paths: PatientFields): {
  nestedFields: PurePatientField[];
  shallowFields: PurePatientField[];
} {
  const nestedFields: PurePatientField[] = [];
  const shallowFields: PurePatientField[] = [];
  paths.forEach((p) =>
    (p.startsWith("user") ? nestedFields : shallowFields).push(
      p.replace("user.", "") as PurePatientField
    )
  );
  return { nestedFields, shallowFields };
}

function makeDefaultValues(
  therapist: Patient,
  paths: PatientFields
): DeepPartial<Patient> {
  const { nestedFields, shallowFields } = distinguishPaths(paths);
  return {
    ...pick(therapist, shallowFields),
    ...pick(therapist.user, nestedFields),
  };
}

function makeDiffToSend(
  values: object,
  paths: PatientFields
): DeepPartial<Patient> {
  const { nestedFields, shallowFields } = distinguishPaths(paths);
  const res = pick(values, shallowFields);
  if (nestedFields.length) {
    (res as DeepPartial<Patient>).user = pick(values, nestedFields);
  }
  return res;
}

export { makeDefaultValues, makeDiffToSend };
