import {
  LoggedOutToPatients,
  LoggedOutToCTA,
  LoggedAsTherapist,
  LoggedOutAndBuyRateCard,
  LoggedAsTherapistBuyRateCard,
  LoggedAsTherapistBuyTest
} from "./components";

export const LoggedOutPopup = () => {
  return (
    <>
      <LoggedOutToCTA />
      <LoggedOutToPatients />
      <LoggedAsTherapist />
      <LoggedOutAndBuyRateCard />
      <LoggedAsTherapistBuyRateCard />
      <LoggedAsTherapistBuyTest />
    </>
  );
};
