import { forwardRef, ReactNode, ForwardedRef } from "react";
import cn from "classnames";
import Select, { components } from "react-select";
import { pluralize } from "common/utils";
import "./Select.scss";
import {
  MultiSelect,
  Props as MultiSelectProps,
} from "common/components/MultiSelect/MultiSelect";
import { ListOption } from "constants/options";

interface Props extends Omit<MultiSelectProps, "value"> {
  value: ListOption | readonly ListOption[] | null;
  label?: ReactNode;
  isMulti?: boolean;
  placeholderAsLabel?: boolean;
}

const ValueContainer = ({ children, ...props }: any) => {
  const { getValue, selectProps, isMulti } = props;

  let valuesLength = getValue().length;
  if (valuesLength > 0 && isMulti) {
    return (
      <components.ValueContainer {...props}>
        <div onClick={selectProps.onMenuOpen}>{`${valuesLength} ${pluralize(
          valuesLength,
          "item",
          "items"
        )} selected`}{children[1]}</div>
      </components.ValueContainer>
    );
  }

  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
};

function FilterSelectComponent(
  {
    error,
    label,
    className,
    isMulti,
    value,
    placeholderAsLabel,
    ...restProps
  }: Props,
  ref: ForwardedRef<HTMLDivElement>
) {

  return (
    <div className={cn("react-select", className)} ref={ref}>
      {label && <label className="label">{label}</label>}
      {isMulti ? (
        <MultiSelect
          {...restProps}
          value={value as ListOption[]}
          className="react-select__container"
          classNamePrefix="react-select"
          placeholderAsLabel={placeholderAsLabel}
          components={{ ValueContainer }}
        />
      ) : (
        <Select
          {...restProps}
          className={cn(
            "react-select__container",
            placeholderAsLabel && "placeholder-as-label"
          )}
          classNamePrefix="react-select"
          isClearable={false}
          value={value}
          menuIsOpen={isMulti && false}
        />
      )}
      {error && <p className="error_msg">{error}</p>}
    </div>
  );
}

export const FilterSelect = forwardRef(FilterSelectComponent);
