import { forwardRef, ReactNode } from "react";
import styles from "./IconCheckbox.module.scss";

interface Props {
  icon?: ReactNode;
  name: string;
  value?: string;
  label: string;
  id?: string;
  checked?: boolean;
  onChange?: (value: string) => void;
}

// TODO: merge with `IconCheckboxCard`

export const IconCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ icon, name, value, label, id, checked, onChange }, ref) => {
    return (
      <div className={styles.root}>
        <input
          ref={ref}
          className={styles.input}
          type="checkbox"
          id={id}
          checked={checked}
          onChange={() => {
            console.log(value, onChange);

            value && onChange && onChange(value);
          }}
        />
        <label className={styles.label} htmlFor={name}>
          <div className={styles.icon}>{icon}</div>

          <span>{label}</span>
        </label>
      </div>
    );
  }
);
