import { useNavigate } from "react-router-dom";
import { useModal } from "hooks/useModal";
import { tokenService } from "services/tokenService";
import { LoggedOutContainer } from "../LoggedOutContainer/LoggedOutContainer";
import { authService } from "services/authService";
import styles from "./LoggedAsTherapistBuyRateCard.module.scss";

export const LoggedAsTherapistBuyRateCard = () => {
    const navigate = useNavigate();
    const { isOpen, close } = useModal("LOGGED_AS_THERAPIST_BUY_RATE_CARD");

    const onClose = async () => {
        close();
        await authService.logout();
        navigate("/patient/login");
    };

    const handleButtonClick = () => {
        onClose();
    };

    return (
        <LoggedOutContainer
            subTitle={
                <div className={styles.textContainer}>
                    <span>You are logged in as a Therapist.</span>
                    <span>
                        Please{" "}
                        <button className={styles.button} type="button" onClick={onClose}>
                        login as
                        </button>
                            {" "}
                            a Client
                    </span>
                    <span>to buy Rate Card.</span>
                </div>
            }
            className={styles.loggedOutToPatients}
            isOpen={isOpen}
            close={() => close()}
            onButtonClick={handleButtonClick}
        />
    );
};
