import { useNavigate } from "react-router-dom";

import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useAuth } from "hooks/useAuth";

import { EditHeader } from "common/components/EditHeader";
import { OrdersTable } from "common/components/OrdersTable";

import { Therapist } from "types/therapist.types";
import { ErrorPage } from "pages/ErrorPage";

import { fullPaymentsPath } from "../../Payments";

import styles from "./PurchaseHistory.module.scss";

export const pathPurchaseHistory = "/purchase-history";

export const PurchaseHistory = () => {
  const navigate = useNavigate();
  useDashboardNavigation("Purchase History", {
    goBack: () => navigate(fullPaymentsPath),
  });
  const user = useAuth().user as Therapist;

  if (!user?.id) return <ErrorPage />;

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <OrdersTable userRole="therapist" />
    </section>
  );
};
