import { Pagination } from "types";
import { proxy } from "valtio";
import { HttpService } from "./httpService";

// Dictionaries are lists of choices that are to be FETCHED. We DO NOT KNOW the available codes, we CAN NOT hardcode them

export type DictionaryItem<C = string> = {
  code: C;
  name: string;
  order?: number;
};

type CacheEntry = {
  data: DictionaryItem[] | null;
  request: Promise<DictionaryItem[]> | null;
  error: string | null;
};
type Cache = Record<string, CacheEntry>;

class DictionaryService extends HttpService {
  private cache: Cache = {};
  private static prefix = "/v1";
  private static cacheExpirationTime = 1000 * 60 * 5; // 5 minutes

  clearCache(key?: string) {
    if (key) {
      this.cache[key].data = null;
    } else {
      this.cache = {};
    }
  }

  async fetchDictionary(endpoint: string): Promise<DictionaryItem[]> {
    if (!(endpoint in this.cache)) {
      this.cache[endpoint] = {
        data: null,
        request: null,
        error: null,
      };
    }
    const cacheEntry = this.cache[endpoint];

    if (cacheEntry.data) {
      return cacheEntry.data;
    }

    if (!cacheEntry.request) {
      cacheEntry.request = this.http
        .get<DictionaryItem, Pagination<DictionaryItem>>(
          `${DictionaryService.prefix}${endpoint}/`
        )
        .then((res) => {
          setTimeout(
            () => this.clearCache(endpoint),
            DictionaryService.cacheExpirationTime
          );
          return res.results;
        })
        .catch((error) => {
          cacheEntry.data = null;
          cacheEntry.error = (error as Error).message;
          throw error;
        })
        .finally(() => {
          cacheEntry.request = null;
        });
    }

    return await cacheEntry.request;
  }

  async getIssues(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/issues");
  }

  async getSpecialties(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/issues"); // they are the same, but two diffrent functions are for "encapsulation"
  }

  async getLanguages(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/languages");
  }

  async getTherapies(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/therapies");
  }

  async getAlliedGroups(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/allied-groups");
  }

  async getHealthInsuranceCompanies(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/health-insurance-companies");
  }

  async getInsuranceCompanies(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/insurance-companies");
  }

  async getProfessionalSpecialties(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/professional-specialties");
  }

  async createHealthInsuranceCompany(name: string) {
    const response: DictionaryItem = await this.http.post(
      "/v1/health-insurance-companies/",
      {
        code: name.toLocaleLowerCase().split(" ").join("-"),
        name,
      }
    );

    return response;
  }
}

export const dictionaryService = proxy(new DictionaryService());
