import { proxy } from "valtio";
import { TherapistCollectionService } from "./therapistCollectionService";
import { Id } from "types";
import { RateCard } from "../types/rateCards.types";

class RateCardsService extends TherapistCollectionService<Id, RateCard> {
  buyIsFetched = false;
  constructor(props?: any) {
    super({ ...props, collectionString: "/rate-cards" });
  }

  async buyRate(therapistId: string, itemId: Id) {
    this.buyIsFetched = true;
    try {
      const data = await this.http.get(
        `${this.prefix}/${therapistId}/rate-cards/${itemId}/pay/`
      );
      this.buyIsFetched = false;
      return data;
    } catch (error) {
      this.buyIsFetched = false;
    }
  }
}

export const rateCardsService = proxy(new RateCardsService());
