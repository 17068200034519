import React, { forwardRef, HTMLProps, ReactNode } from "react";
import "./Radiobutton.scss";
import cn from "classnames";

interface Props extends HTMLProps<HTMLInputElement> {
  text?: string;
  children?: ReactNode;
  isOutlined?: boolean;
  insideText?: string;
  isButton?: boolean;

  /** @deprecated */
  withBorder?: boolean;
}

const Radiobutton = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    text,
    children,
    className,
    isOutlined,
    checked,
    insideText,
    isButton,
    withBorder,
    ...restProps
  } = props;

  return (
    <label
      className={cn(
        "radiobutton",
        className,
        { "radiobutton--outlined": isOutlined },
        { checked: checked },
        { "is-button": isButton }
      )}
    >
      <input
        ref={ref}
        className="radiobutton__input"
        type="radio"
        checked={checked}
        {...restProps}
      />
      <div className="radiobutton__inner">
        <div className="radiobutton__button">{insideText}</div>
        <div className="radiobutton__text">{text || children}</div>
      </div>
    </label>
  );
});

export default Radiobutton;
