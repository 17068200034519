import { FC, useEffect, useRef } from "react";

import styles from "./FullScreenContainer.module.scss";
import { isElementFullScreen } from "../../utils";
import cn from "classnames";

interface Props {
  isFullscreen?: boolean;
  onEnter?: () => void;
  className?: string;
}

export const FullScreenContainer: FC<Props> = ({
  isFullscreen,
  children,
  onEnter,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    (async () => {
      if (container && isFullscreen) {
        try {
          await container.requestFullscreen();
        } catch (error) {
          console.log(error);
        }
      }
      onEnter && onEnter();
    })();

    return () => {
      if (isElementFullScreen(container)) {
        document.exitFullscreen();
      }
    };
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className={cn(className, styles.fullScreenContainer, {
        [styles.fullScreen]: isFullscreen,
      })}
    >
      <>{children}</>
    </div>
  );
};
