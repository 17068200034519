import isNumeric from "validator/lib/isNumeric";

export const zipCodeValidator = {
  minLength: { value: 5, message: "Must have 5 digits" },
  maxLength: { value: 5, message: "Must have 5 digits" },
  validate: (value: string) =>
    value === "" || isNumeric(value) || "Should be a number",
};

export const streetAddressValidator = {
  maxLength: { value: 255, message: "Maximum 255 characters" },
};

export const cityValidator = {
  maxLength: { value: 64, message: "Maximum 64 characters" },
};
