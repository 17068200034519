import { useEffect, useState } from "react";
import { Awaited, Nullable } from "common/utils";

type AnyArr = any[];
type NullableErr = Nullable<Error>;
type Options<TRawData = AnyArr, TData = TRawData> = {
  initialData?: TRawData;
  deps?: AnyArr;
  condition?: boolean;
  transformer?: (v: TRawData) => TData;
};
type Fetcher = (...args: AnyArr) => Promise<any>;
type FetchState<TData = AnyArr> = {
  data: TData;
  isFetching: boolean;
  error: NullableErr;
};

function useFetch<TData = AnyArr>(
  fetcher: Fetcher,
  {
    initialData = [],
    deps = [],
    condition = true,
    transformer = (v) => v,
  }: Options<Awaited<ReturnType<typeof fetcher>>, TData> = {}
): FetchState<TData> {
  const [data, setData] = useState<TData>(initialData);
  const [isFetching, setIsFetching] = useState<boolean>(condition);
  const [error, setError] = useState<NullableErr>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        const freshData = await fetcher(...deps);
        setData(transformer ? transformer(freshData) : freshData);
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsFetching(false);
      }
    };

    if (condition) {
      fetchData();
    }
  }, deps);

  return { data, isFetching, error };
}

export default useFetch;
