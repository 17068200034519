import { chatCommandsService } from "services/chatCommandsService";
import { TabList, Tab, TabPanel, Tabs } from "common/UIKit/Tabs";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useEffect, useState } from "react";
import { MacrosSystemItem } from "./blocks/MacrosSystemItem/MacrosSystemItem";
import { MacrosCustomItem } from "./blocks/MacrosCustomItem/MacrosCustomItem";
import AddButton from "common/UIKit/AddButton";
import { useSnapshot } from "valtio";
import { SYSTEM_TEXTS } from "constants/fields";
import { Entries } from "types";
import { SystemCommands } from "types/chatCommands.types";
import { ToggleCheckbox } from "common/UIKit/ToggleCheckbox";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { authService } from "services/authService";
import { getShallowDiff } from "common/utils";
import { isEmpty } from "lodash";
import styles from "./MacrosMenu.module.scss";
import { useResponsive } from "hooks/useResponsive";

export const pathMacrosMenu = "/macros-menu";

interface Inputs {
  send_system_macros_notification: boolean;
  send_custom_macros_notification: boolean;
}

export const MacrosMenu = () => {
  useDashboardNavigation("Chat-Connect Macros", {
    goBack: "pop_path",
  });
  useDocumentTitle("TheraPass - Chat-Connect Macros");
  const user = useAuth().user as Therapist;
  const { isMobile } = useResponsive();
  const defaultValues = {
    send_system_macros_notification: user.send_system_macros_notification,
    send_custom_macros_notification: user.send_custom_macros_notification,
  };
  const { register, watch, handleSubmit } = useForm<Inputs>({ defaultValues });
  const { data } = useSnapshot(chatCommandsService);
  const [systemCommand, setSystemCommand] = useState<Entries<SystemCommands>>(
    []
  );

  useEffect(() => {
    try {
      chatCommandsService.fetch(false);
      chatCommandsService.systemList().then((res) => {
        const systemData = Object.entries(res) as Entries<SystemCommands>;
        setSystemCommand(systemData);
      });
    } catch (error) {}
  }, []);

  const onClickAdd = () => {
    chatCommandsService.add({ name: "", text: "", id: 0 });
  };

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    try {
      const changedValues = getShallowDiff(values, defaultValues);
      if (!isEmpty(changedValues)) {
        await authService.patch({ diff: changedValues });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  });

  return (
    <div className={styles.root}>
      {!isMobile && <h1 className={styles.header}>Chat Connect Macros</h1>}
      <Tabs>
        <TabList className={styles.tabList}>
          <Tab className={styles.tab}>System</Tab>
          <Tab className={styles.tab}>Custom</Tab>
        </TabList>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.field}>
            <ToggleCheckbox {...register("send_system_macros_notification")} />
            <p className={styles.label}>
              Send copy of outbound text message to my phone
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.title}>Name</p>
            <p className={styles.title}>Action</p>
          </div>
          {systemCommand.length > 0 &&
            systemCommand.map((item, index) => (
              <MacrosSystemItem
                key={item[0]}
                position={`${index + 1}`}
                text={SYSTEM_TEXTS[item[0]]}
                value={item[1].replace("#", "")}
              />
            ))}
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <div className={styles.field}>
            <ToggleCheckbox {...register("send_custom_macros_notification")} />
            <p className={styles.label}>
              Send copy of outbound text message to my phone
            </p>
          </div>
          {data.length > 0 && (
            <>
              <div className={styles.row}>
                <p className={styles.anotherTitle}>Macros Name</p>
                <p className={styles.anotherTitle}>Message</p>
                <AddButton
                  onClick={onClickAdd}
                  className={styles.mobileAddButton}
                  disabled={data.some((el) => !el.id)}
                >
                  Add another macros
                </AddButton>
              </div>
              <div className={styles.mobileContent}>
                {data.map((command, index) => (
                  <MacrosCustomItem
                    key={command.id || index}
                    id={command.id}
                    nameValue={command.name.replace("#", "")}
                    textValue={command.text}
                  />
                ))}
              </div>
            </>
          )}
          <AddButton
            onClick={onClickAdd}
            className={styles.addButton}
            disabled={data.some((el) => !el.id)}
          >
            Add another macros
          </AddButton>
        </TabPanel>
      </Tabs>
    </div>
  );
};
