import { ReactNode, FormEventHandler } from "react";
import cn from "classnames";

import { useResponsive } from "hooks/useResponsive";

type Props = {
  children: ReactNode;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  actions: ReactNode;
  className?: string;
  isTwoColumns?: boolean;
  maxWidth?: string;
};
export const PatientStepsForm = ({
  children,
  actions,
  handleSubmit,
  className,
  isTwoColumns,
  maxWidth,
}: Props) => {
  const { isMobile } = useResponsive();

  return (
    <form className="patient-step__form" onSubmit={handleSubmit}>
      <div
        className={cn("patient-step__row", className, {
          "patient-step__two-columns": isTwoColumns,
        })}
        style={isMobile ? undefined : { maxWidth }}
      >
        {children}
      </div>
      {actions}
    </form>
  );
};
