import { stepsUi } from "../components";
import { stepsFullPath } from "../stepsPath";
import { step7FullPath } from "../PatientStep7";
import { usePatientStep8 } from "./usePatientStep8";
import Checkbox from "common/UIKit/Checkbox/Checkbox";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { useResponsive } from "hooks/useResponsive";
import styles from "./PatientStep8.module.scss";

export const step8Path = "/8";
export const step8FullPath = [stepsFullPath, step8Path].join("");

export function PatientStep8() {
  const { isMobile } = useResponsive();
  const {
    languageOptions,
    isSubmitting,
    error,
    selected,
    handleChange,
    handleSubmit,
  } = usePatientStep8();
  return (
    <>
      <stepsUi.Header>
        Do you prefer a therapist who speaks a language other than English?
      </stepsUi.Header>
      <stepsUi.Hint>Check all that apply</stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        maxWidth="400px"
        className={styles.form}
        isTwoColumns={!isMobile}
        actions={
          <stepsUi.Actions
            buttons={[
              {
                type: "button",
                children: "Back",
                className: "btn-color-blue",
                isLink: true,
                to: step7FullPath,
              },
              {
                type: "submit",
                children: isMobile ? "Continue" : "Next",
                isLoading: isSubmitting,
              },
            ]}
          />
        }
      >
        {languageOptions.map((option) => (
          <Checkbox
            key={option.code}
            className="patient-step__checkbox"
            id={option.code}
            name={option.code}
            checked={selected.includes(option.code)}
            onChange={handleChange}
          >
            {option.name}
          </Checkbox>
        ))}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </stepsUi.Form>
    </>
  );
}
