import React, { FC } from "react";

import { useResponsive } from "hooks/useResponsive";
import cn from "classnames";
import { Tab, TabList, TabPanel, Tabs } from "common/UIKit/Tabs";
import { stepsUi } from "pages/Patients/PatientSteps/components";
import Button from "common/UIKit/Button";
import tabs from "./tabs";
import { useEditPatientInformation } from "./useEditPatientInformation";
import { patientInformationPath } from "../PatientsInformation";
import { EditHeader } from "common/components/EditHeader";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./EditPatientInformation.module.scss";

export const editPatientInformationPath = `${patientInformationPath}/edit`;

export const EditPatientInformation: FC = () => {
  const { goBack } = useDashboardNavigation("Edit Patient's Information", {
    goBack: "pop_path",
  });
  const { isMobile } = useResponsive();
  const { handleSubmit, isSubmitting, ...rest } =
    useEditPatientInformation(goBack);

  return (
    <div className={styles.editPatientInformation}>
      <EditHeader />
      <form className={styles.form} onSubmit={handleSubmit}>
        {isMobile && (
          <Button
            className={styles.button}
            type="submit"
            text="Save"
            isLoading={isSubmitting}
          />
        )}
        {isMobile ? (
          <Tabs className={styles.tabs}>
            <TabList className={styles.tabList}>
              {tabs.map(({ name }) => (
                <Tab key={name}>{name}</Tab>
              ))}
            </TabList>
            {tabs.map(({ Component, label, subLabel, name }) => (
              <TabPanel key={name}>
                <stepsUi.Row label={label} subLabel={subLabel}>
                  <Component {...rest} />
                </stepsUi.Row>
              </TabPanel>
            ))}
          </Tabs>
        ) : (
          tabs.map(({ Component, label, subLabel, name }) => (
            <stepsUi.Row label={label} subLabel={subLabel} key={name}>
              <Component {...rest} />
            </stepsUi.Row>
          ))
        )}
        {!isMobile && (
          <Button
            type="submit"
            text="Save"
            className={cn("w-100", isMobile ? "" : "max-width-380")}
            isLoading={isSubmitting}
          />
        )}
      </form>
    </div>
  );
};
