import { FC } from "react";
import cn from "classnames";

import styles from "./ProgressLine.module.scss";

interface Props {
  progress: number;
  className?: string;
}

export const ProgressLine: FC<Props> = ({ progress, className }) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.accent} style={{ width: `${progress}%` }} />
  </div>
);
