import styles from "./Steps.module.scss";
import React, { useLayoutEffect, useRef } from "react";
import cn from "classnames";
import { Step, StepProps } from "./components/Step";

interface StepsProps {
  activeIndex: number;
  steps: Omit<StepProps, "isActive">[];
  className?: string;
  height: number;
}

export function Steps({ steps, activeIndex, className, height }: StepsProps) {
  const stepsRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useLayoutEffect(() => {
    const activeStepElem = stepsRef.current.childNodes[
      activeIndex
    ] as HTMLDivElement;
    if (!activeStepElem) return;
    stepsRef.current.style.transform = `translateY(${
      -activeStepElem.offsetTop + 120
    }px)`;
  }, [activeIndex]);

  return (
    <div
      className={cn(styles.stepsWrapper, className)}
      style={{ height: height - 364 }}
    >
      {activeIndex > 0 && <div className={styles.overlay} />}
      <div className={styles.stepsList} ref={stepsRef}>
        {steps.map((config, index) => (
          <Step key={index} {...config} isActive={index === activeIndex} />
        ))}
      </div>
    </div>
  );
}

Steps.Step = Step;
