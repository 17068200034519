import React from 'react';
import PropTypes from 'prop-types';
import './Scale.scss'
import cn from "classnames";

export function Scale({
   scaleValue,
   className
 }) {
  return (
    <div className={cn("scale", className)}>
      <div className="scale__line">
        <div className="scale__depth" style={{width: `${scaleValue}%`}}/>
      </div>
    </div>
  )
}

Scale.defaultProps = {
  scaleValue: '0',
  className: ''
};

Scale.propTypes = {
  scaleValue: PropTypes.number,
  className: PropTypes.string
};
