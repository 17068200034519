import { useModal } from "hooks/useModal";
import { emailService } from "services/emailService";
import { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSnapshot } from "valtio";
import { useTimer } from "hooks/useTimer";

interface Config {
  code: string;
}

export default function useVerificationSent() {
  const { isOpen, close: closeEmailModal } = useModal(
    "CHANGE_EMAIL_VERIFICATION"
  );
  const { open: openChangeEmailSuccess } = useModal("CHANGE_EMAIL_SUCCESS");
  const [resendErrors, setResendErrors] = useState<string[]>([]);
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm<Config>();
  const { newEmail } = useSnapshot(emailService);
  const { seconds, setSeconds, on } = useTimer();

  const onClose = () => closeEmailModal();

  const onSave: SubmitHandler<Config> = async (data) => {
    try {
      await emailService.confirmChange(data);
      closeEmailModal();
      openChangeEmailSuccess();
    } catch (error) {
      const { data, status } = error as AxiosResponse;

      setError("code", {
        message: data?.errors ? data?.errors.join(", ") : `Error ${status}`,
      });
    }
  };

  const onResend = async () => {
    setResendErrors([]);

    try {
      await emailService.resendEmail();
    } catch (error) {
      const { data, status } = error as AxiosResponse;
      const errors = data?.errors as string[];
      const errorWithTime =
        status === 429 && errors.find((error) => error.match(/second/g));

      if (errorWithTime) {
        const time = Number(errorWithTime?.replace(/\D/g, ""));
        setResendErrors([errorWithTime]);
        setSeconds(time);
        on();
      } else {
        setResendErrors(errors.length ? errors : [`Error ${status}`]);
      }
    }
  };

  useEffect(() => {
    if (seconds) {
      setResendErrors([
        resendErrors.join("").replace(/\d{1,2}/g, seconds.toString()),
      ]);
    } else {
      setResendErrors([]);
    }
  }, [seconds]);

  return {
    isOpen,
    onSubmit: handleSubmit(onSave),
    onClose,
    onResend,
    resendErrors,
    register,
    errors,
    newEmail,
  };
}
