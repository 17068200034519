import React, { FC } from "react";
import { Controller } from "react-hook-form";

import { EditPatientInformationForm } from "../useEditPatientInformation";
import Radiobutton from "common/UIKit/Radiobutton";
import { APPOINTMENT_DATE_OPTIONS } from "constants/options";
import styles from "../EditPatientInformation.module.scss";

export const YourAvailability: FC<EditPatientInformationForm> = ({
  control,
}) => {
  return (
    <Controller
      control={control}
      name="appointment_date"
      render={({ field: { value, onChange } }) => (
        <>
          {APPOINTMENT_DATE_OPTIONS.map((option) => (
            <Radiobutton
              key={option.value}
              className={`patient-step__radio ${styles.time}`}
              name="appointment_date"
              isOutlined
              checked={option.value === value}
              onChange={() => onChange(option.value)}
            >
              {option.label}
            </Radiobutton>
          ))}
        </>
      )}
    />
  );
};
