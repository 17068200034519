import { Route, Routes, useNavigate } from "react-router-dom";
import {
  EditPersonalInformation,
  editPersonalInformationPath,
} from "./EditPersonalInformation";
import {
  EditPersonalBilling,
  editPersonalBillingPath,
} from "./EditPersonalBilling";
import {
  EditPersonalShipping,
  editPersonalShippingPath,
} from "./EditPersonalShipping";
import {
  editPatientInformationPath,
  EditPatientInformation,
} from "./EditPatientInformation";
import { ChangePassword, changePasswordPath } from "./ChangePassword";
import {
  PersonalInformation,
  personalInformationPath,
} from "./PersonalInformation";
import {
  patientInformationPath,
  PatientsInformation,
} from "./PatientsInformation";
import { OverviewMobile } from "./OverviewMobile";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { esaProfileService } from "services/esaProfileService";
import { useEffect, useState } from "react";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";

export const myAccountPath = "/my-account";

export const MyAccount = () => {
  useDashboardNavigation("My Account", { isMainTab: true });
  const navigate = useNavigate();
  useDocumentTitle("TheraPass - My Account");
  const user = useAuth().user as Patient;
  const [isSynced, setIsSynced] = useState(false);
  useEffect(() => {
    if (user?.user.is_esa_user && 
      (!user?.user.gender || !user.user.billing_address_1)) {
      esaProfileService.syncProfile(user);
      setIsSynced(true);
    }
  }, [isSynced]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <OverviewMobile
            onChangePassword={() => navigate(`.${changePasswordPath}`)}
            onPersonalInformation={() =>
              navigate(`.${personalInformationPath}`)
            }
            onPatientInformation={() => navigate(`.${patientInformationPath}`)}
          />
        }
      />
      <Route
        path={personalInformationPath}
        element={
          <PersonalInformation
            onChangePassword={() => navigate(`.${changePasswordPath}`)}
            onEdit={() => navigate(`.${editPersonalInformationPath}`)}
            onEditBilling={() => navigate(`.${editPersonalBillingPath}`)}
            onEditShipping={() => navigate(`.${editPersonalShippingPath}`)}
          />
        }
      />
      <Route path={patientInformationPath} element={<PatientsInformation />} />

      <Route
        path={editPersonalInformationPath}
        element={<EditPersonalInformation />}
      />
      <Route
        path={editPersonalBillingPath}
        element={<EditPersonalBilling />}
      />
      <Route
        path={editPersonalShippingPath}
        element={<EditPersonalShipping />}
      />

      <Route path={changePasswordPath} element={<ChangePassword />} />
      <Route
        path={editPatientInformationPath}
        element={<EditPatientInformation />}
      />
    </Routes>
  );
};
