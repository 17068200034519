import { proxy } from "valtio";
import { HttpService } from "./httpService";

class InformedConsentService extends HttpService {
  private endpoint = "/v1/informedconsent/";

  async completeConsent(esaToken: string, clientName: string, imageData: string) {
    const payload = {
      esaToken: esaToken,
      clientName: clientName,
      signatureImage: imageData
    };

    const response = await this.http.post(this.endpoint, payload);
    return response;
  }

}

export const informedConsentService = proxy(new InformedConsentService());