import FileUploadProfile, {
    Props as FileUploadProfileProps,
    Src,
} from "common/components/FileUploadProfile";

export type FileDataField<TExistingFile> = {
    currentValue: (File | TExistingFile)[];
    toDelete: TExistingFile[];
};
interface Props
    extends Omit<FileUploadProfileProps, "error" | "setError" | "enableReinitialize"> {
    name: string;
    isSubmitting?: boolean;
    value: Src[];
}

function ControlledFileUploadProfile({ name, disabled, isSubmitting, ...otherProps }: Props) {
    return (
        <FileUploadProfile
            disabled={isSubmitting || disabled}
            enableReinitialize
            {...otherProps}
        />
    );
}

export default ControlledFileUploadProfile;
