import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect } from "react";
import { ToggleCheckbox } from "common/UIKit/ToggleCheckbox";
import { useSnapshot } from "valtio";
import { authService } from "services/authService";
import { Therapist } from "types/therapist.types";
import { getShallowDiff } from "common/utils";
import { isEmpty } from "common/utils/index";
import styles from "./Notifications.module.scss";

export const notificationsPath = "/notifications";

type Inputs = {
  site_notifications: boolean;
  email_notifications: boolean;
};

export const Notifications = () => {
  const therapist = useSnapshot(authService).user as Therapist;
  const defaultValues = {
    email_notifications: therapist.user?.email_notifications,
    site_notifications: therapist.user?.site_notifications,
  };
  const { register, watch, handleSubmit } = useForm<Inputs>({
    defaultValues,
  });

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    try {
      const changedValues = getShallowDiff(values, defaultValues);
      if (!isEmpty(changedValues)) {
        await authService.patch({ diff: { user: changedValues } });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  });

  return (
    <div className={styles.notifications}>
      <div className={styles.backdrop}>
        <div className={styles.field}>
          <div className={styles.label}>
            <p>Site notifications</p>
          </div>
          <ToggleCheckbox {...register("site_notifications")} />
        </div>
        <div className={styles.field}>
          <div className={styles.label}>
            <p>Email notifications</p>
          </div>
          <ToggleCheckbox {...register("email_notifications")} />
        </div>
      </div>
    </div>
  );
};
