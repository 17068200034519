import cn from "classnames";

import { ChatStatus } from "types/therapist.types";
import { useWindowResize } from "hooks/useWindowResize";
import { ReactComponent as ChatIcon } from "assets/images/icons/comment-dots.svg";

import styles from "./ChatStatusBadge.module.scss";

type Props = {
  chatStatus: ChatStatus;
  className?: string;
  onClick?: () => void;
};

export const ChatStatusBadge = ({ chatStatus, className, onClick }: Props) => {
  const { width } = useWindowResize();
  const withText = onClick && width >= 1025;

  return (
    <div
      className={cn(
        styles.root,
        styles[chatStatus],
        withText && chatStatus === "available" && styles.withText,
        className
      )}
      onClick={onClick}
    >
      <ChatIcon />
      {withText && chatStatus === "available" && <p className={styles.availableStatus}>Chat Connect</p>}
    </div>
  );
};
