import { FC } from "react";
import { Modal } from "common/components/Modal";
import Button from "common/UIKit/Button";
import tickCircle from "assets/images/tick-circle-green.png";
import styles from "./EmailSuccessPopup.module.scss";

import { useModal } from "hooks/useModal";
import { useModalDrag } from "hooks/useModalDrag";

export const EmailSuccessPopup: FC = () => {
  const { isOpen, close } = useModal("EMAIL_SUCCESS");

  const { bind, percentageToClose, transformStyle } = useModalDrag({
    moveDistanceToClose: 200,
    onClose: close,
  });

  return (
    <Modal visible={isOpen} onClose={close} fadePercentage={percentageToClose}>
      <div className={styles.emailSuccess} style={transformStyle} {...bind()}>
        <img src={tickCircle} alt="success" />
        <h1>Email has been sent</h1>
        <small>Your email has been sent to therapist.</small>
        <Button text="Close" onClick={close} />
      </div>
    </Modal>
  );
};
