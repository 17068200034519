import styles from "./InformedConsent.module.scss";

import Button from "common/UIKit/Button/Button";
import { useAuth } from "hooks/useAuth";
import { useState } from "react";
import { Patient } from "types/patient.types";
import SignatureCanvas from 'react-signature-canvas';
import { esaTokenService } from "services/esaTokenService";
import { informedConsentService } from "services/informedConsentService";
import { stepsFullPath } from "../stepsPath";
import { authService } from "services/authService";

export const informedConsentPath = "/informed-consent";
export const informedConsentFullPath = `${stepsFullPath}${informedConsentPath}`;

export const InformedConsent = () => {
  const user= useAuth().user as Patient;
  const [trimmedDataUrl, setTrimmedDataUrl] = useState("");
  let sigPad: any = {};

  const trim = () => {
    setTrimmedDataUrl(sigPad.getTrimmedCanvas().toDataURL('image/png'));
  };

  const signConsent = () => {
    if (trimmedDataUrl) {
      const esaToken = esaTokenService.get();
      const clientName = `${user.user.first_name} ${user.user.last_name}`;
      if (esaToken){
        informedConsentService.completeConsent(esaToken, clientName, trimmedDataUrl.split(",")[1]);
      };
      const response = authService.patch({
        diff: { 
          user: { 
            status: "completed"
          }
        }
      })
    }
  };

  return (
    <div className="default-typography">
      <h1 className="h3">Informed Consent</h1>

      <div className="text-block">
        <p>Before continuing we need your consent to provide services.</p>
        <p>Your Emotional Support Animal (ESA) Letter is signed by a licensed therapist and is valid for housing and air travel in all 50 states.</p>
        <p>By utilizing these electronic therapeutic services, I agree that I am soliciting the services of a licensed mental health professional using the telephone or internet as a “point-of-service” modality to engage with, and visit the therapist, and further agree to abide by the ethical and legal guidelines prescribed by the applicable state of licensure.</p>
        <p>I give my consent to communicate with the therapist by text message. Text messaging is conducted on a wireless carrier's (Verizon, AT&T, Sprint, TMO) network that lacks encryption and where there exists risk of unauthorized disclosure.</p>
        <p>I hereby agree to, and affirm everything is accurate and true to the best of my knowledge.</p>
        <br/><br/><br/><br/>
      </div>
      <p>Signature:</p>
      <div>
        <SignatureCanvas 
          ref={(ref: any) => { sigPad = ref }}
          canvasProps={{className: styles.sigPad}}
          onEnd={trim}
        />
      </div>
      <p>Printed Name:&nbsp;&nbsp;&nbsp;&nbsp;<u>{user.user.first_name} {user.user.last_name}</u></p>

      <Button onClick={signConsent}>Continue</Button>
    </div>
  );
};