import { stepsFullPath } from "../stepsPath";
import { TherapistFields } from "../util";

export const coveredFields: TherapistFields = [
  "in_person_session_cost_min",
  "online_individual_session_cost_min",
  "form_of_payment",
];
export const step6Path = "/6";
export const step6FullPath = `${stepsFullPath}${step6Path}`;
export const step6Caption = "Fees";
