import { Fragment } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { ChangeEmailPopup } from "./components/ChangeEmailPopup/ChangeEmailPopup";
import { EmailSuccessPopup } from "./components/EmailSuccessPopup/EmailSuccessPopup";
import { VerificationSentPopup } from "./components/VerificationSentPopup/VerificationSentPopup";

import { ContactTherapistValues } from "services/communicationService";

const defaultValues: ContactTherapistValues = {
  message: "",
  captcha: null,
};

export const EmailPopup = () => {
  const messageFormPack = useForm<ContactTherapistValues>({
    defaultValues,
  });
  return (
    <Fragment>
      <FormProvider {...messageFormPack}>
        <VerificationSentPopup />
        <EmailSuccessPopup />
        <ChangeEmailPopup />
      </FormProvider>
    </Fragment>
  );
};
