import { FC } from "react";
import { TestDropdown } from "types/testProcess.types";
import { useTest } from "hooks/useTest";
import ControlledMultiSelect from "common/components/ControlledMultiSelect";
import styles from "./Dropdown.module.scss";

interface Props {
  question: TestDropdown;
  disabled?: boolean;
}

export const Dropdown: FC<Props> = ({ question, disabled }) => {
  const { control } = useTest();

  return (
    <div className={styles.dropdown}>
      <ControlledMultiSelect
        control={control}
        name={question.id.toString()}
        rules={{
          required: {
            value: question.required,
            message: "This field is required",
          },
        }}
        options={question.options}
        placeholder=""
        disabled={disabled}
      />
    </div>
  );
};
