import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import Button from "common/UIKit/Button";
import { ReactComponent as ShareIcon } from "assets/images/icons/share.svg";
import { ReactComponent as BrowserIcon } from "assets/images/icons/browser.svg";
import { DictionaryItem, dictionaryService } from "services/dictionaryService";
import { therapistProfileService } from "services/therapistProfileService";
import { communicationService } from "services/communicationService";
import { useModal } from "hooks/useModal";
import { Avatar } from "common/UIKit/Avatar";
import { getWebsiteAddress, therapistName } from "common/utils";
import { isChatAccess, isTherapist } from "services/authService";
import { useAuth } from "hooks/useAuth";
import "./TherapistLeftSide.scss";

export const TherapistLeftSide = () => {
  const [issues, setIssues] = useState<DictionaryItem[]>([]);
  const { user } = useAuth();
  const { open } = useModal("SHARE");
  const { open: openLogged } = useModal("LOGGED_AS_THERAPIST");
  const { currentProfile } = useSnapshot(therapistProfileService);
  const withStatus =
    currentProfile &&
    isChatAccess(currentProfile) &&
    currentProfile?.online_chat_sessions_availability;

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        url: window.location.href,
        title: `Therapist ${therapistName({
          title: currentProfile?.title,
          first_name: currentProfile?.user.first_name,
          last_name: currentProfile?.user.last_name,
        })}`,
      });
    } else {
      open();
    }
  };

  const openMessage = () =>
    user && isTherapist(user)
      ? openLogged()
      : currentProfile && communicationService.openPopup(currentProfile);

  useEffect(() => {
    (async () => {
      const issues = await dictionaryService.getIssues();
      setIssues(
        issues.filter((issue) =>
          currentProfile?.specialties.includes(issue.code)
        )
      );
    })();
  }, [setIssues, currentProfile?.specialties]);

  if (currentProfile === null) return null;

  return (
    <aside className="therapist-left-side">
      <Avatar
        className="therapist-left-side__img"
        id={currentProfile.id}
        file={currentProfile.avatar}
        alt={therapistName({
          first_name: currentProfile.user.first_name,
          last_name: currentProfile.user.last_name,
        })}
        onClick={
          withStatus && currentProfile.chat_status === "available"
            ? openMessage
            : undefined
        }
        chat_status={withStatus ? currentProfile.chat_status : undefined}
      />

      <Button
        className="therapist-left-side__button"
        color="outline-gray"
        size="sm"
        onClick={handleShare}
      >
        <ShareIcon />
        Share
      </Button>
      {currentProfile?.website && (
        <Button
          className="therapist-left-side__button"
          isLink
          href={getWebsiteAddress(currentProfile?.website)}
          target="_blank"
        >
          <BrowserIcon />
          Website
        </Button>
      )}
    </aside>
  );
};
