import { ReactComponent as HeartIcon } from "assets/images/icons/heart.svg";
import cn from "classnames";
import "./LikeButton.scss";

interface Props {
  className?: string;
  onClick?: () => void;
  isLiked: boolean;
  disabled?: boolean;
}

export const LikeButton = ({
  className,
  onClick,
  isLiked,
  disabled,
}: Props) => {
  return (
    <button
      className={cn("like", className, { liked: isLiked })}
      onClick={onClick}
      disabled={disabled}
    >
      <HeartIcon />
    </button>
  );
};
