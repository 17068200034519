import { getDaysInMonth, getListYears } from "common/utils";
import { months } from "constants/options";
import { Controller, FieldValues } from "react-hook-form";
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form/dist/types";
import { Inputs } from "../../TherapistStep3_1";
import AppSelect from "common/UIKit/Select/Select";
import { stepsUi } from "../../../components";
import { useState, useEffect } from "react";
import { ErrorMessage } from "common/UIKit/ErrorMessage/ErrorMessage";
import styles from "./DateSelect.module.scss";

interface Props {
  control: Control<Inputs, FieldValues>;
  disabled?: boolean;
  name: keyof Inputs;
  watch: UseFormWatch<Inputs>;
  setValue: UseFormSetValue<Inputs>;
}
export const DateSelect = ({
  control,
  disabled,
  name,
  watch,
  setValue,
}: Props) => {
  const [isTouched, setIsTouched] = useState(false);
  const watcher = watch(name);

  const withValidDays = (currentValue: string) => {
    const dateArray = currentValue.toString().split("-");
    const year = Number(dateArray[0]);
    const month = Number(dateArray[1]);

    return currentValue.replace(
      /-\d\d$/,
      String(`-${getDaysInMonth(year, month)}`)
    );
  };

  useEffect(() => {
    if (watcher?.toString().match(/\d{4}-\d{2}-\d{2}/)) {
      setValue(name, withValidDays(String(watcher)));
      setIsTouched(true);
    }
  }, [watcher, name, setValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field,
        fieldState: { error, isDirty },
        formState: { isSubmitted },
      }) => (
        <stepsUi.Grid>
          <AppSelect
            className={styles.dateSelect}
            error={error?.message}
            placeholder="MM"
            options={months}
            isDisabled={disabled}
            value={
              field.value &&
              months.find((o) => o.value === String(field.value).split("-")[1])
            }
            onChange={(nextValue) => {
              const { value } = nextValue as {
                label: string;
                value: string;
              };
              const currentValue = field.value;

              if (currentValue) {
                field.onChange(
                  String(currentValue).replace(/-[\w\d]{1,2}-/g, `-${value}-`)
                );
              } else {
                field.onChange(`YYYY-${value}-28`);
              }
            }}
          />
          <AppSelect
            className={styles.dateSelect}
            placeholder="YYYY"
            options={getListYears()}
            isDisabled={disabled}
            value={
              field.value &&
              getListYears().find(
                (o) => o.value === String(field.value).split("-")[0]
              )
            }
            onChange={(nextValue) => {
              const { value } = nextValue as {
                label: string;
                value: string;
              };
              const currentValue = field.value;
              if (currentValue) {
                field.onChange(
                  String(currentValue).replace(/^[\w\d]{1,4}-/g, `${value}-`)
                );
              } else {
                field.onChange(`${value}-MM-28`);
              }
            }}
          />
          {isDirty && !isTouched && isSubmitted && (
            <ErrorMessage isText>Required both fields</ErrorMessage>
          )}
        </stepsUi.Grid>
      )}
    />
  );
};
