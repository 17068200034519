import { FC, useState } from "react";
import { useSnapshot } from "valtio";
import { AuthContext } from "contexts/AuthContext";
import { authService, User } from "services/authService";
import { BaseUser } from "types/auth.types";
import { LoggedOutPopup } from "common/components/LoggedOutPopup/LoggedOutPopup";

export const AuthProvider: FC = ({ children }) => {
  const snap = useSnapshot(authService);
  const baseUser = snap.baseUser as BaseUser | null;
  const user = snap.user as User | null;
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const { authStatus, prevSessionUserType } = snap;

  return (
    <AuthContext.Provider
      value={{
        baseUser,
        user,
        authStatus,
        prevSessionUserType,
        isProfileCompleted,
        setIsProfileCompleted,
        isSendingMessage,
        setIsSendingMessage,
      }}
    >
      <LoggedOutPopup />
      {children}
    </AuthContext.Provider>
  );
};
