import { EditHeader } from "common/components/EditHeader";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { fullPaymentsPath } from "../../Payments";
import { Link, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { rateCardsService } from "services/rateCardsService";
import { isStripeAccess, stripeStatus } from "services/authService";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { therapistRatePath } from "pages/Auth/TherapistRate/TherapistRate";
import AddButton from "common/UIKit/AddButton";
import Card from "common/UIKit/Card";
import { ReactComponent as DocumentIcon } from "assets/images/icons/document.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/pencil.svg";
import { useModal } from "hooks/useModal";
import { useEffect } from "react";
import { fullPaymentsPath as paymentsPath } from "pages/Therapists/TherapistsDashboard/helpers";
import { upperFirst } from "lodash";
import { pathPurchaseHistory } from "../PurchaseHistory/PurchaseHistory";
import styles from "./EditRateCards.module.scss";

export const pathRateCards = "/rate-cards";

export const EditRateCards = () => {
  const navigate = useNavigate();
  useDashboardNavigation("Edit Rate Cards", {
    goBack: () => navigate(fullPaymentsPath),
    isNavigationHidden: true,
  });
  const { open, addProperties } = useModal("RATE_CARD_TYPE");
  const { data } = useSnapshot(rateCardsService);
  const user = useAuth().user as Therapist;
  const { online_individual_session_cost_min } = user;

  useEffect(() => {
    user && rateCardsService.list(user.id);
  }, [user]);

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      {isStripeAccess(user) && (
        <>
          <div className={styles.row}>
            {data.length > 0 && (
              <Link
                className="g-underline"
                to={`${therapistRatePath}/${user?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Preview Rate Cards
              </Link>
            )}
          </div>
          {!stripeStatus(user, "completed") && (
            <div className={styles.warning}>
              You must first be connected to Stripe. Click{" "}
              <Link to={paymentsPath("/stripe/edit")} className={styles.link}>
                here
              </Link>{" "}
              to Connect.
            </div>
          )}
          {!online_individual_session_cost_min && (
            <div className={styles.warning}>
              Please enter your rates{" "}
              <Link to={paymentsPath("/fees/edit")} className={styles.link}>
                here
              </Link>
              {"."}
            </div>
          )}
          <div className={styles.row}>
            <AddButton
              type="button"
              onClick={open}
              className={styles.addButton}
            >
              Add a rate card
            </AddButton>
            <Link
              className="g-underline"
              to={`${fullPaymentsPath}${pathPurchaseHistory}`}
            >
              Purchase History
            </Link>
          </div>
          {data.length > 0 && user
            ? data.map((item) => (
                <Card
                  withFlex={false}
                  values={[
                    {
                      caption: "CARD NAME",
                      text: item.public_name,
                      class: styles.startCell,
                    },
                    {
                      caption: "CARD TYPE",
                      text: upperFirst(item.type),
                      class: styles.centerCell,
                    },
                    {
                      caption: "FEE",
                      text: `$${item.fee}`,
                      class: styles.endCell,
                    },
                  ]}
                  key={item.id}
                  icon={<DocumentIcon />}
                  editIcon={<EditIcon />}
                  onEdit={() => {
                    open();
                    addProperties({ id: item.id });
                  }}
                  onRemove={() => rateCardsService.delete(user.id, item.id)}
                />
              ))
            : null}
        </>
      )}
    </section>
  );
};
