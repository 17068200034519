import { useCallback, useEffect, useState } from "react";
import useLocalTracks from "providers/VideoProvider/useLocalTracks";
import useVideoContext from "./useVideoContext";

type RoomStateType = "disconnected" | "connected" | "reconnecting";

export default function useRoomState(onError: any) {
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      console.log(`ERROR: ${error.message}`, error);
    },
    [onError]
  );
  const { localTracks } = useLocalTracks();
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>("disconnected");

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state as RoomStateType);
      setRoomState();
      room
        .on("disconnected", setRoomState)
        .on("reconnected", setRoomState)
        .on("reconnecting", setRoomState);
      return () => {
        room
          .off("disconnected", setRoomState)
          .off("reconnected", setRoomState)
          .off("reconnecting", setRoomState);
      };
    }
  }, [room]);

  return state;
}
