import { FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { useSteps } from "hooks/useSteps";
import { useAuth } from "hooks/useAuth";
import { AGE_OPTIONS, AgeCode } from "constants/options";
import { Patient } from "types/patient.types";
import { useResponsive } from "hooks/useResponsive";
import { IconCheckbox } from "common/components/IconCheckbox";

import { stepsUi } from "../components";
import { step3FullPath } from "../PatientStep3";
import { step5FullPath } from "../PatientStep5";
import { stepsFullPath } from "../stepsPath";

export const step4Path = "/4";
export const step4FullPath = [stepsFullPath, step4Path].join("");

export function PatientStep4() {
  const { isMobile } = useResponsive();
  const patient = useAuth().user as Patient;
  const [selectedAge, setSelectedAge] = useState<AgeCode>(
    patient?.age || "adult"
  );
  const { isSubmitting, error, submit } = useSteps();
  const navigate = useNavigate();

  const handleChange = (age: string) => {
    setSelectedAge(age as AgeCode);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    await submit({
      diff: { age: selectedAge as AgeCode },
      onSuccess: () => navigate(step5FullPath),
      errorMessage: "Need to select age of patient",
    });
  };

  return (
    <>
      <stepsUi.Header>Patient’s age</stepsUi.Header>
      <stepsUi.Hint>Select one</stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        maxWidth="644px"
        actions={
          <stepsUi.Actions
            buttons={[
              {
                isLink: true,
                children: "Back",
                className: "btn-color-blue",
                to: step3FullPath,
              },
              {
                type: "submit",
                children: isMobile ? "Continue" : "Next",
                isLoading: isSubmitting,
              },
            ]}
          />
        }
      >
        {AGE_OPTIONS.map((age) => (
          <IconCheckbox
            key={age.value}
            id={age.value}
            label={age.label}
            icon={age.icon}
            name={age.value}
            value={age.value}
            checked={selectedAge === age.value}
            onChange={handleChange}
          />
        ))}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </stepsUi.Form>
    </>
  );
}
