import { proxy } from "valtio";
import { CrudService } from "./crudService";
import { ChangePlanInfo, ChangePlanQuery, Plan } from "../types/plan.types";
import { serializeQuery } from "utils/utils";

class PlansService extends CrudService<number, Plan> {
  private planInfoPrefix = "/v1/plan-change-info";
  planInfo: ChangePlanInfo | null = null;

  constructor(args?: any) {
    super({
      ...args,
      prefix: "/v1/subscription-plans",
    });
  }

  async changePlanInfo(query: ChangePlanQuery) {
    const data = (await this.http.get(
      `${this.planInfoPrefix}/?${serializeQuery(query)}`
    )) as ChangePlanInfo;
    this.planInfo = data;

    return this.planInfo;
  }

  clearPlanInfo() {
    this.planInfo = null;

    return this.planInfo;
  }
}

export const plansService = proxy(new PlansService());
plansService.init();
