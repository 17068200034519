import { FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import Checkbox from "common/UIKit/Checkbox/Checkbox";
import { stepsUi } from "../components";
import { stepsFullPath } from "../stepsPath";
import { step6FullPath } from "../PatientStep6";
import { step8FullPath } from "../PatientStep8";
import { useSteps } from "hooks/useSteps";
import { useAuth } from "hooks/useAuth";
import { useResponsive } from "hooks/useResponsive";
import {
  ALL_PREFERRED_SEXUALITIES,
  Patient,
  PreferredSexuality,
} from "types/patient.types";
import { SEXUALITY_OPTIONS } from "constants/options";
import styles from "./PatientStep7.module.scss";

export const step7Path = "/7";
export const step7FullPath = [stepsFullPath, step7Path].join("");

type FormValue = {
  [key in PreferredSexuality]: boolean;
};
const noPreferenceValue = ALL_PREFERRED_SEXUALITIES.reduce((acc, pref) => {
  acc[pref] = pref === "none";

  return acc;
}, {} as FormValue);

export const PatientStep7 = () => {
  const { isMobile } = useResponsive();
  const { isSubmitting, submit } = useSteps();
  const navigate = useNavigate();
  const patient = useAuth().user as Patient;
  const [formValue, setFormValue] = useState<FormValue>(() => {
    const value = { ...noPreferenceValue };
    if (!patient) return value;
    for (const pref of patient.preferred_sexualities) {
      value.none = false;
      value[pref] = true;
    }
    return value;
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    const preferred_sexualities = ALL_PREFERRED_SEXUALITIES.filter(
      (pref) => formValue[pref]
    );
    await submit({
      diff: { preferred_sexualities },
      onSuccess: () => navigate(step8FullPath),
    });
  };

  return (
    <>
      <stepsUi.Header>
        Do you prefer a therapist who works with patients of a certain
        sexuality?
      </stepsUi.Header>
      <stepsUi.Hint>Check all that apply</stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        maxWidth="160px"
        className={styles.form}
        actions={
          <>
            <stepsUi.Actions
              buttons={[
                {
                  type: "button",
                  children: "Back",
                  className: "btn-color-blue",
                  isLink: true,
                  to: step6FullPath,
                },
                {
                  type: "submit",
                  children: isMobile ? "Continue" : "Next",
                  isLoading: isSubmitting,
                },
              ]}
            />
          </>
        }
      >
        {SEXUALITY_OPTIONS.map(({ label, value, isSole }, index) => (
          <Checkbox
            key={label}
            className="patient-step__checkbox"
            id={`sexuality-value-${index}`}
            checked={formValue[value]}
            onChange={(e) => {
              if (isSole) {
                setFormValue(noPreferenceValue);
                return;
              }
              const checkbox = e.target as HTMLInputElement;
              const newFormValue = { ...formValue };
              newFormValue[value] = checkbox.checked;
              newFormValue.none = false;
              if (Object.values(newFormValue).filter(Boolean).length === 0) {
                newFormValue.none = true;
              }

              setFormValue(newFormValue);
            }}
          >
            {label}
          </Checkbox>
        ))}
      </stepsUi.Form>
    </>
  );
};
