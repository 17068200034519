import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { Info } from "./Info";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useDocumentTitle } from "hooks/useDocumentTitle";

export const settingsPath = "/settings";

export const Settings: FC = () => {
  useDashboardNavigation("Settings", {
    goBack: "pop_path",
  });
  useDocumentTitle("TheraPass - Settings");

  return (
    <Routes>
      <Route path="*" element={<Info />} />
    </Routes>
  );
};
