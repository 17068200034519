import { EditHeader } from "common/components/EditHeader/EditHeader";
import { useNavigate } from "react-router-dom";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import AddButton from "common/UIKit/AddButton";
import { fullProfilePath } from "../../Profile";
import Card from "common/UIKit/Card";
import { ReactComponent as DocumentIcon } from "assets/images/icons/document.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/pencil.svg";
import { credentialService } from "services/credentialService";
import { useAuth } from "hooks/useAuth";
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { ORGANIZATION_LABEL } from "constants/fields";
import {
  ORGANIZATION_FIELD,
  NUMBER_LABEL,
  NUMBER_FIELD,
} from "constants/fields";
import { FormatProfileField } from "common/components/FormatProfileField";
import styles from "./EditOtherCredentials.module.scss";

export const pathOtherCredentials = "/other-credentials";

export const EditOtherCredentials = () => {
  const navigate = useNavigate();
  useDashboardNavigation("Edit Other Credentials", {
    goBack: () => navigate(fullProfilePath),
    isNavigationHidden: true,
  });
  const { user } = useAuth();
  const { data } = useSnapshot(credentialService);

  useEffect(() => {
    user && credentialService.list(user.id);
  }, [user]);

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <div className={styles.mobileRow}>
        {data.length && user
          ? data.map((credential) => (
              <Card
                key={credential.id}
                className={styles.item}
                values={[
                  {
                    caption: "CREDENTIAL TYPE",
                    text: (
                      <FormatProfileField>
                        {ORGANIZATION_LABEL[credential.type]}
                      </FormatProfileField>
                    ),
                  },
                  {
                    caption: ORGANIZATION_LABEL[credential.type],
                    text: (
                      <FormatProfileField>
                        {credential[ORGANIZATION_FIELD[credential.type]]}
                      </FormatProfileField>
                    ),
                  },
                  {
                    caption: NUMBER_LABEL[credential.type],
                    text: (
                      <FormatProfileField>
                        {credential[NUMBER_FIELD[credential.type]]}
                      </FormatProfileField>
                    ),
                  },
                  {
                    caption: "YEAR",
                    text: (
                      <FormatProfileField>{credential.year}</FormatProfileField>
                    ),
                  },
                ]}
                icon={<DocumentIcon />}
                onEdit={() => navigate(String(credential.id))}
                onRemove={() =>
                  credentialService.delete(user.id, credential.id)
                }
                editIcon={<EditIcon />}
                buttonClass={styles.cardButton}
              />
            ))
          : null}
        <AddButton className={styles.addButton} onClick={() => navigate("add")}>
          Add another credential
        </AddButton>
      </div>
    </section>
  );
};
