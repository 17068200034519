import classNames from "classnames";
import { ChatStatusBadge } from "common/components/ChatStatusBadge";
import { ChatStatus, TherapistFile } from "types/therapist.types";
import { ReactComponent as IconZoom } from "assets/images/icons/zoom.svg";
import avatar from "assets/images/avatar.png";
import { useModal } from "hooks/useModal";
import { VerifiedCircle } from "pages/Patients/PatientsTherapist/blocks/VerifiedCircle/VerifiedCircle";
import styles from "./Avatar.module.scss";
import { patientsTherapistPremiumFullPath } from "pages/Patients/PatientsTherapist";
import { Link, useLocation } from "react-router-dom";

type Props = {
  file?: TherapistFile;
  alt?: string;
  className?: string;
  size?: "lg" | "md" | "sm";
  chat_status?: ChatStatus;
  onClick?: () => void;
  withZoom?: boolean;
  isVerified?: boolean;
  id?: string;
  planName?: string;
  withoutLink?: boolean;
};

export const Avatar = ({
  file,
  size = "md",
  className,
  chat_status,
  alt,
  onClick,
  withZoom,
  isVerified,
  id,
  planName,
  withoutLink
}: Props) => {
  const { open, addProperties } = useModal("ZOOM_AVATAR");
  const { pathname } = useLocation();
  const onZoomAvatar = () => {
    addProperties({ file });
    open();
  };

  return (
    <div className={classNames(styles.root, styles[size], className)}>
      {isVerified && <VerifiedCircle className={styles.verified} withoutText />}
      <Link
        to={withoutLink ? "#" : patientsTherapistPremiumFullPath.replace(":id", String(id))}
        state={{ prevPage: pathname }}
      >
        <img
          className={styles.image}
          alt={alt}
          src={(!file?.mime.match("video") && file?.file) || avatar}
        />
      </Link>
      {withZoom && file && (
          <button className={styles.icon} type="button" onClick={onZoomAvatar}>
            <IconZoom />
          </button>
      )}
      {chat_status && planName === "Premium" && (
        <ChatStatusBadge
          className={styles.badge}
          chatStatus={chat_status}
          onClick={onClick}
        />
      )}
    </div>
  );
};
