import { FC } from "react";

import { Modal } from "common/components/Modal";

interface Props {
  visible: boolean;
  onClose: () => void;
}

export const MobileTooltip: FC<Props> = (props) => <Modal {...props} />;
