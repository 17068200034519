import { AvailableTest } from "types/availableTests.types";
import styles from "./styles.module.scss";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import Button from "common/UIKit/Button";

interface Props {
  rows: AvailableTest[];
}

export const EsaTestTable = ({ rows } : Props) => {
  const navigate = useNavigate();

  const openTest = async (test: AvailableTest) => {
    navigate(`./test/new/prepare?test_id=${test.id}`);
  };

  return (
    <ul className={styles.testsList}>
      {rows.map((test) => {
        return (
          <li key={test.id} className={styles.testsList__item}>
            <div className="row align-items-center">
              <div className="col">
                <div className="mb-20 mb-md-0">
                  <h5 className="mb-5">{test.name}</h5>
                  <ul className="list-with-line-divider">
                    <li className="list-with-line-divider__item">{`${test.time_to_complete}\u00A0minutes`}</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-auto">
                <Button
                  text="Take the Test Now"
                  size="sm"
                  onClick={() => openTest(test)}
                  className="w-100 w-md-auto"
                />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
