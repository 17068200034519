import "./TherapistSteps.scss";
import { Route, Routes } from "react-router-dom";

import { TherapistStep1, step1Path } from "./TherapistStep1";
import { TherapistStep2, step2Path } from "./TherapistStep2";
import { TherapistStep3_1, step3_1Path } from "./TherapistStep3_1";
import { TherapistStep3_2, step3_2Path } from "./TherapistStep3_2";
import { TherapistStep3_3, step3_3Path } from "./TherapistStep3_3";
import { TherapistStep4_1, step4_1Path } from "./TherapistStep4_1";
import { TherapistStep4_2, step4_2Path } from "./TherapistStep4_2";
import { TherapistStep5, step5Path } from "./TherapistStep5";
import { TherapistStep6, step6Path } from "./TherapistStep6";
import { TherapistStep7, step7Path } from "./TherapistStep7";
import { TherapistStep8, step8Path } from "./TherapistStep8";
import { TherapistStep9_1, step9_1Path } from "./TherapistStep9_1";
import { TherapistStep9_2, step9_2Path } from "./TherapistStep9_2";
import { step10Path } from "./TherapistStep10";
import { TherapistStep11 } from "./TherapistStep11";
//import { TherapistStep12 } from "./TherapistStep12";
import { ErrorPage } from "../../ErrorPage";
import { TherapistStepsLayout } from "./components/TherapistStepsLayout";
import { AccessController } from "common/components/Layout/AccessController";
import { TherapistAddStateLicensing } from "./TherapistAddStateLicensing/TherapistAddStateLicensing";
import { TherapistAddEducation } from "./TherapistAddEducation/TherapistAddEducation";
import { TherapistAddOtherCredential } from "./TherapistAddOtherCredential/TherapistAddOtherCredential";

export * from "./stepsPath";

export const TherapistSteps = () => {
  return (
    <AccessController therapist="registration">
      <TherapistStepsLayout>
        <Routes>
          <Route path={step1Path} element={<TherapistStep1 />} />
          <Route path={step2Path} element={<TherapistStep2 />} />
          <Route path={step3_1Path} element={<TherapistStep3_1 />} />
          <Route
            path={`${step3_1Path}/add`}
            element={<TherapistAddStateLicensing />}
          />
          <Route
            path={`${step3_1Path}/add/:id`}
            element={<TherapistAddStateLicensing />}
          />
          <Route path={step3_2Path} element={<TherapistStep3_2 />} />
          <Route
            path={`${step3_2Path}/add`}
            element={<TherapistAddEducation />}
          />
          <Route
            path={`${step3_2Path}/add/:id`}
            element={<TherapistAddEducation />}
          />
          <Route path={step3_3Path} element={<TherapistStep3_3 />} />
          <Route
            path={`${step3_3Path}/add`}
            element={<TherapistAddOtherCredential />}
          />
          <Route
            path={`${step3_3Path}/add/:id`}
            element={<TherapistAddOtherCredential />}
          />
          <Route path={step4_1Path} element={<TherapistStep4_1 />} />
          <Route path={step4_2Path} element={<TherapistStep4_2 />} />
          <Route path={step5Path} element={<TherapistStep5 />} />
          <Route path={step6Path} element={<TherapistStep6 />} />
          <Route path={step7Path} element={<TherapistStep7 />} />
          <Route path={step8Path} element={<TherapistStep8 />} />
          <Route path={step9_1Path} element={<TherapistStep9_1 />} />
          <Route path={step9_2Path} element={<TherapistStep9_2 />} />
          {/*<Route path={step10Path} element={<TherapistStep10 />} />*/}
          <Route path={step10Path} element={<TherapistStep11 />} />
          {/*<Route path={step11Path} element={<TherapistStep12 />} />*/}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </TherapistStepsLayout>
    </AccessController>
  );
};
