import useHeight from "hooks/useHeight";
import useRoomState from "hooks/useRoomState";
import PreJoinScreens from "./PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from "./ReconnectingNotification/ReconnectingNotification";
import MobileTopMenuBar from "./MobileTopMenuBar/MobileTopMenuBar";
import Room from "./Room/Room";
import MenuBar from "./MenuBar/MenuBar";
import { useEffect, useState } from "react";
import { TwilioError } from "twilio-video";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "utils/env";

export const VideoWrapper = () => {
  const [error, setError] = useState<TwilioError | null>(null);
  const roomState = useRoomState(setError);

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const [params] = useSearchParams();
  const [patientId, setPatientId] = useState("");
  const clientId = params.get("clientId");
  useEffect(() => {
    if (params) {
      axios
        .get(`${apiEndpoint}v1/esauserid?esaClientId=${clientId}`)
        .then((response) => {
          setPatientId(response.data.id);
        });
    }
  }, [clientId]);

  return roomState === "disconnected" ? (
    <PreJoinScreens ptId={patientId} />
  ) : (
    <>
      <ReconnectingNotification />
      <MobileTopMenuBar />
      <Room />
      <MenuBar />
    </>
  );
};
