import MembershipBadge from "common/components/MembershipBadge/MembershipBadge";
import { authService, isTherapist } from "services/authService";
import { UserProfile } from "common/components/UserProfile/UserProfile";
import { useSnapshot } from "valtio";
import { Therapist } from "types/therapist.types";
import { Logo } from "common/components/Header";
import cn from "classnames";
import { Notification } from "common/components/Notification/Notification";
import styles from "./DashboardLayoutDesktopHeader.module.scss";
import ChatStatusButton from "../MobileHeader/components/ChatStatusButton";
import { useAuth } from "hooks/useAuth";

interface Props {
  withLogo?: boolean;
  className?: string;
}

export const DashboardLayoutDesktopHeader = ({
  withLogo,
  className,
}: Props) => {
  // const today = new Date().toLocaleDateString("en-US", {
  //   weekday: "long",
  //   day: "numeric",
  //   month: "long",
  //   year: "numeric",
  // });
  const { user } = useAuth();
  const therapist = useSnapshot(authService).user as Therapist | null;
  const notificationsIsVisible = therapist?.user.site_notifications;

  return (
    <header className={cn(styles.desktopHeader, className)}>
      {withLogo && <Logo />}
      {user && isTherapist(user) && <ChatStatusButton/>}
      {notificationsIsVisible && <Notification />}
      {therapist && isTherapist(therapist) && (
        <MembershipBadge membership={therapist.subscription_plan} />
      )}
      <UserProfile />
    </header>
  );
};
