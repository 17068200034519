import { LeadSection } from "./blocks/LeadSection/LeadSection";
import { HowSection } from "./blocks/HowSection/HowSection";
import { CompareSection } from "./blocks/CompareSection/CompareSection";
import { FeaturedSection } from "./blocks/FeaturedSection/FeaturedSection";
import { Testimonials } from "../components/Testimonials";
import { FindSection } from "./blocks/FindSection/FindSection";
import { Footer } from "../components/Footer/Footer";
import { LandingHeader } from "../components/LandingHeader";
import { TestimonialCard } from "./components/TestimonialCard/TestimonialCard";
import { WhereSection } from "./blocks/WhereSection/WhereSection";
import { TestingSection } from "./blocks/TestingSection/TestingSection";
import styles from "./CTA.module.scss";
import { ArticlesSection } from "./blocks/ArticlesSection/ArticlesSection";
import { useSnapshot } from "valtio";
import { articlesService } from "services/articlesService";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { therapistListingService } from "services/therapistListingService";
import { testimonialsService } from "services/testimonials";

export const CTA = () => {
  const { featuredTestimonials } = useSnapshot(testimonialsService);
  const sliderNodes = featuredTestimonials.map((item) => (
    <TestimonialCard {...item} />
  ));
  const { featuredArticles } = useSnapshot(articlesService);
  const { featuredTherapists } = useSnapshot(therapistListingService);
  const { pathname } = useLocation();

  useEffect(() => {
    articlesService.listFeatured();
    therapistListingService.getFeaturedTherapists();
    testimonialsService.getFeaturedTestimonials();
  }, []);

  return (
    <div className={styles.cta}>
      <LandingHeader />
      <main className={styles.main}>
        <LeadSection />
        <HowSection />
        <WhereSection />
        <div className={styles.why}>
          <CompareSection />
          {Boolean(featuredTestimonials.length) && (
            <Testimonials spaceBetween={24} sliderNodes={sliderNodes} />
          )}
        </div>
        <TestingSection />
        {featuredTherapists.length && (
          <FeaturedSection featuredTherapists={featuredTherapists} />
        )}
        {featuredArticles.length && !pathname.includes("backup") && (
          <ArticlesSection />
        )}
        <FindSection />
      </main>
      <Footer />
    </div>
  );
};
