import { TherapistFields } from "../util";
import { stepsFullPath } from "../stepsPath";

export const coveredFields: TherapistFields = [
  "in_person_session_availability",
  "online_chat_sessions_availability",
  "telephone_session_availability",
  "video_session_availability",
  "chat_status",
  "offer_free_call",
];
export const step11Path = "/11";
export const step11FullPath = `${stepsFullPath}${step11Path}`;
export const step11Caption = "Availability";
