import { Controller } from "react-hook-form";
import { EditHeader } from "common/components/EditHeader";
import Button from "common/UIKit/Button";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import Textarea from "common/UIKit/Textarea/Textarea";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { useProfileForm } from "../../useProfileForm";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./EditPersonalStatement.module.scss";

const coveredFields: TherapistFields = [
  "personal_statement_short_description",
  "personal_statement_how_can_help",
];

type Inputs = {
  personal_statement_short_description: string;
  personal_statement_how_can_help: string;
};

export const EditPersonalStatement = () => {
  useDashboardNavigation("Edit Personal Statement", {
    goBack: "pop_path",
  });

  const { control, handleSubmit } = useProfileForm<Inputs>(coveredFields);

  return (
    <section className={styles.editPersonalStatement}>
      <EditHeader />
      <form
        className={styles.editPersonalStatementForm}
        onSubmit={handleSubmit}
      >
        <stepsUi.Row
          label="Short description"
          subLabel="How can you help? Talk about your specialities and what you offer."
        >
          <Controller
            control={control}
            name="personal_statement_short_description"
            render={({ field, fieldState }) => (
              <Textarea
                className={styles.editPersonalStatementTextarea}
                placeholder="Type something"
                {...field}
                currentLength={field.value.length}
                error={fieldState.error?.message}
                maxLength={500}
              />
            )}
          />
        </stepsUi.Row>

        {/* Decision from customer: remove this field
        <stepsUi.Row label="How can you help?">
          <Controller
            control={control}
            name="personal_statement_how_can_help"
            render={({ field, fieldState }) => (
              <Textarea
                className={styles.editPersonalStatementTextarea}
                placeholder="Start typing"
                {...field}
                currentLength={field.value.length}
                error={fieldState.error?.message}
              />
            )}
          />
        </stepsUi.Row> */}
        <Button type="submit" className={styles.editPersonalStatementButton}>
          Save
        </Button>
      </form>
    </section>
  );
};
