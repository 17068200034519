import cn from "classnames";
import { FC, useState } from "react";

import { ReactComponent as Arrow } from "assets/images/icons/chevron-bottom.svg";

import styles from "./Filters.module.scss";

type Props = {
  title: string;
};

export const MobileListFilter: FC<Props> = ({ title, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className={styles.mobile_list_filter}>
      <div
        className={cn(styles.mobile_list_filter_title, {
          [styles.collapsed]: collapsed,
        })}
        onClick={() => setCollapsed((prevState) => !prevState)}
      >
        {title}
        <Arrow />
      </div>
      <div
        className={cn(styles.mobile_list_filter_content, {
          [styles.collapsed]: collapsed,
        })}
      >
        {children}
      </div>
    </div>
  );
};
