import Layout from "common/components/Layout/Layout";
import { LandingHeader } from "../../Auth/components/LandingHeader";
import { Footer } from "../../Auth/components/Footer/Footer";
import { useResponsive } from "hooks/useResponsive";
import { FC } from "react";
import styles from "./ArticlesLayout.module.scss"

export const ArticlesLayout: FC = ({ children }) => {
    const { isMobile } = useResponsive();

    return (
        <Layout
            className={styles.root}
            mainClass={styles.articlesLayout}
            header={isMobile ? undefined : <LandingHeader />}
            doPadding={isMobile}
            footer={<Footer className={styles.footer} />}
        >
            {children}
        </Layout>
    );
};