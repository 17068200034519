import { ReactComponent as VerifiedIcon } from "assets/images/icons/verified.svg";
import handPhonePath from "assets/images/phone-hand.png";
import styles from "./RequirementsSection.module.scss";

export const RequirementsSection = () => {
  return (
    <section className={styles.root}>
      <div className={styles.info}>
        <h3 className={styles.title}>Requirements</h3>
        <p className={styles.text}>
          To participate in the Therapass clinical network, all therapists are
          required to possess the following:
        </p>
        <ul className={styles.list}>
          <li className={styles.item}>
            <VerifiedIcon />
            <span>A LCSW, LMFT, LPCC, or PhD in Clinical Psychology</span>
          </li>
          <li className={styles.item}>
            <VerifiedIcon />
            <span>
              Individual professional malpractice liability insurance policy
            </span>
          </li>
          <li className={styles.item}>
            <VerifiedIcon />
            <span>
              Submission of a fully completed, signed CAQH application
            </span>
          </li>
          <li className={styles.item}>
            <VerifiedIcon />
            <span>Individual NPI number</span>
          </li>
          <li className={styles.item}>
            <VerifiedIcon />
            <span>Reliable internet connection</span>
          </li>
        </ul>
      </div>
      <img className={styles.image} src={handPhonePath} alt="Hand with phone" />
    </section>
  );
};
