import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { Availability } from "common/components/Availability";
import { LikeButton } from "common/UIKit/LikeButton";
import { OPTIONS_ICONS } from "constants/icons";
import { VerifiedCircle } from "../../blocks/VerifiedCircle/VerifiedCircle";
import { therapistProfileService } from "services/therapistProfileService";
import { favoriteTherapistsService } from "services/favoriteTherapistsService";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { FormatProfileField } from "common/components/FormatProfileField";
import { UserAuthStatus } from "types/auth.types";
import { availabilityOptions } from "constants/options";
import { getWebsiteAddress, therapistName } from "common/utils";
import { isChatAccess } from "services/authService";
import { stateLicenseService } from "services/stateLicenseService";
import { modalService } from "services/modalService";
import { StateLicenseListItem } from "common/components/StateLicenseListItem";
import "./PatientsTherapistHeader.scss";

interface Props {
  isTherapist?: boolean | null;
  currentUserStatus: UserAuthStatus;
}

export const PatientsTherapistHeader = ({
  isTherapist,
  currentUserStatus,
}: Props) => {
  const { currentProfile } = useSnapshot(therapistProfileService);
  const { verifiedStateLicenses } = useSnapshot(stateLicenseService);
  const { id } = useParams();

  const onLike = useCallback(async () => {
    try {
      await therapistProfileService.like();
      favoriteTherapistsService.list(false);
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  const professionalSpecialtyNames = useDictionaryItemNames(
    "ProfessionalSpecialties",
    currentProfile?.professional_specialties
  );

  if (currentProfile === null) return null;

  return (
    <div className="patients-therapist-header">
      <div className="patients-therapist-header__claimBlock">
        {isChatAccess(currentProfile) && (
          <Availability
            className="patients-therapist-header__available"
            isAvailable={currentProfile?.is_available}
          />
        )}
        {currentProfile.source.includes("imported") && (
          <div
            onClick={() => modalService.open("CLAIM_PROFILE")}
            className="patients-therapist-header__claim"
          >
            Claim this Profile
          </div>
        )}
      </div>
      <div className="patients-therapist-header__title">
        <FormatProfileField shouldLimitLength={false}>
          {therapistName({
            first_name: currentProfile.user.first_name,
            last_name: currentProfile.user.last_name,
          })}
        </FormatProfileField>
        {currentProfile?.primary_credential_is_verified && (
          <VerifiedCircle className="patients-therapist-header__verified" />
        )}
        {!isTherapist && currentUserStatus !== "loggedOut" ? (
          <LikeButton
            isLiked={currentProfile.is_my_favourite}
            onClick={onLike}
          />
        ) : null}
      </div>
      <div className="patients-therapist-header__company">
        {currentProfile.company_name &&
          currentProfile.business_info?.use_company_name_also &&
          (currentProfile.website ? (
            <a href={getWebsiteAddress(currentProfile.website)}>
              {currentProfile.company_name}
            </a>
          ) : (
            <p>{currentProfile.company_name}</p>
          ))}
      </div>
      <p className="patients-therapist-header__subtitle">
        {currentProfile.credentials_choice && (
          <em>
            <FormatProfileField shouldLimitLength={false}>
              {currentProfile.credentials_choice}
            </FormatProfileField>
          </em>
        )}
        {currentProfile?.source.includes("imported") &&
          professionalSpecialtyNames.length > 0 && (
            <b>
              <FormatProfileField
                fallbackText="No Educational Specialties specified"
                shouldLimitLength={false}
              >
                {professionalSpecialtyNames.filter((item) => item).join(", ")}
              </FormatProfileField>
            </b>
          )}
        {verifiedStateLicenses.map((license) => (
          <StateLicenseListItem
            key={`${license.state}-${license.number}`}
            license={license}
          />
        ))}
      </p>
      <div className="patients-therapist-header__options">
        <p className="patients-therapist-header__options-text">
          Therapy Options:
        </p>
        <ul className="patients-therapist-header__options-container">
          <FormatProfileField fallbackText="No options">
            {OPTIONS_ICONS.filter(
              (option) => currentProfile && currentProfile[option.name]
            ).map(({ name, component: Component }) => (
              <li
                key={name}
                className="patients-therapist-header__options-item"
                aria-label={availabilityOptions[name]}
              >
                <Component />
              </li>
            ))}
          </FormatProfileField>
        </ul>
      </div>
    </div>
  );
};
