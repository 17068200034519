import { FieldError } from "react-hook-form";
import { dictionaryService } from "services/dictionaryService";
import { toOptions } from "common/utils";
import { AgeCode, Ethnicity, Faith, ListOption } from "constants/options";
import useAnyTherapistStep from "../useAnyTherapistStep";
import { coveredFields } from "./step5Config";
import { step6FullPath } from "../TherapistStep6";
import { step4_2FullPath } from "../TherapistStep4_2";
import useFetch from "hooks/useFetch";
import { Group } from "types/therapist.types";

type Inputs = {
  ages: AgeCode[];
  preferred_faith: Faith;
  preferred_languages: string[];
  allied_groups: Group[];
  preferred_ethnicities: Ethnicity[];
};

type Errors = {
  ages?: FieldError;
  preferred_faith?: FieldError;
  preferred_languages?: FieldError;
  allied_groups?: FieldError;
  preferred_ethnicities?: FieldError;
};

export const useTherapistStep5 = () => {
  const { errors, ...generic } = useAnyTherapistStep<Inputs>(coveredFields, step6FullPath, {
    prevStep: step4_2FullPath,
  });

  const alliedGroups = useFetch<ListOption[]>(() => dictionaryService.getAlliedGroups(), {
    transformer: toOptions,
  });

  const languages = useFetch<ListOption[]>(() => dictionaryService.getLanguages(), {
    transformer: data => toOptions(data).filter(l => l.value !== "no" && l.value !== "en"),
  });

  return {
    ...generic,
    isProcessing: languages.isFetching,
    languages: languages.data,
    alliedGroups: alliedGroups.data,
    // @ts-ignore
    errors: errors as Errors,
  };
};
