import { Link } from "react-router-dom";
import cn from "classnames";

import Default from "assets/images/articles/default.jpg";

import { formatArticleDate } from "common/utils";
import { FormatProfileField } from "common/components/FormatProfileField";
import { ReactComponent as LogInIcon } from "assets/images/icons/login.svg";
import { Category } from "types/articles.types";
import { articlesService } from "services/articlesService";

import { articlePath } from "../../Article";
import styles from "./ArticleCard.module.scss";

interface Props {
  src: string;
  title: string;
  date: string;
  id: string;
  className?: string;
  categories: any;
  author_name: string;
  setCategory?: any;
}

export const ArticleCard = ({
  src,
  title,
  date,
  id,
  className,
  categories,
  author_name,
  setCategory,
}: Props) => {
  return (
    <Link to={articlePath.replace(":id", String(id))}>
      <div className={cn(styles.card, className && className)}>
        <div className={styles.columnImage}>
          <img src={src || Default} alt={title} />
        </div>
        <div className={styles.textBlock}>
          <p className={styles.columnTitle}>
            <FormatProfileField maxLength={70}>{title}</FormatProfileField>
          </p>
          <div>
            <div className={styles.captions}>
              <p className={styles.date}>{formatArticleDate(date)}</p>
              <div className={styles.author}>
                <LogInIcon />
                <p>{author_name}</p>
              </div>
            </div>
            <div>
              <div className={styles.categoryLinks}>
                {categories.map((el: Category, i: any) => {
                  if (i === categories.length - 1) {
                    return (
                      <Link
                        to="/articles"
                        className={styles.categoryLink}
                        onClick={() => {
                          setCategory(el.code);
                          articlesService.setCurrentCategoryTitle(el.title);
                        }}
                      >
                        <p>{el.title}</p>
                      </Link>
                    );
                  } else {
                    return (
                      <Link
                        to="/articles"
                        className={styles.categoryLink}
                        onClick={() => {
                          setCategory(el.code);
                          articlesService.setCurrentCategoryTitle(el.title);
                        }}
                      >
                        <p>{el.title},</p>
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          {/*<p className={styles.date}>{formatArticleDate(date)}</p>*/}
        </div>
      </div>
    </Link>
  );
};
