import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSnapshot } from "valtio";

import Button from "common/UIKit/Button";
import { Pagination } from "common/UIKit/Pagination";
import { SortArrow } from "common/components/SortArrow";
import { Spinner } from "common/components/Spinner/Spinner";

import { ratePurchaseService } from "services/ratePurchaseService";

import { onRequestToNewWindow } from "common/utils/async";

import { stringToDate, getFullName } from "./helpers";

import styles from "./OrdersTable.module.scss";
import { UserType } from "types/auth.types";
import { esaProfileService } from "services/esaProfileService";
import { RatePurchase } from "types/ratePurchase.types";

interface Props {
  userRole: UserType;
  emptyMessage?: string;
}

export const OrdersTable = ({ userRole, emptyMessage }: Props) => {
  const { data, isFetching, page, pageCount, isInvoiceFetched, sort } =
    useSnapshot(ratePurchaseService);

  const { isFetchingEsa, orders, client } = useSnapshot(esaProfileService);
  const mergedData = [...data];
  const [sortOption, setSortOption] = useState({field: "paid_at", order: "desc"});

  useEffect(() => {
    ratePurchaseService.fetch(false);
    if (userRole === 'patient') {
      esaProfileService.getProfile();
    }
  }, []);

  const onSortChange = (field: string) => {
    if (sortOption.field === field) {
      const order = sortOption.order === "asc" ? "desc" : "asc";
      setSortOption({field: field, order: order});
    } else {
      setSortOption({field: field, order: "desc"});
    }
  }

  if (isFetching || isFetchingEsa)
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  
  if (userRole === 'patient') {
    orders.forEach((order) => {
      const therapist = {
        id: order.TherapistKey,
        email: "test",
        first_name: "LT",
        last_name: "Chen",
        file: "",
      }
      order.orderlines.forEach((orderline) => {
        const orderData = {
          id: orderline.orderid,
          name: orderline.cartline1,
          fee: (orderline.price * orderline.quantity + orderline.shipping).toFixed(2),
          rate_card: 0,
          paid_at: order.paid_at,
          therapist: therapist,
          invoice: false,
          patient: {
            id: "",
            first_name: order.firstname,
            last_name: order.lastname,
            email: client?.emailaddress,
            file: client?.profileimage
          }
        } as RatePurchase;
        mergedData.push(orderData);
      })
    })
  }

  if (!mergedData.length)
    return (
      <p className={styles.empty}>
        {emptyMessage || "Purchase history is empty"}
      </p>
    );

  mergedData.sort((a, b) => {
    if (sortOption.field === "paid_at") {
      const dateA = Date.parse(a.paid_at);
      const dateB = Date.parse(b.paid_at);
      if (dateA < dateB){
        return sortOption.order === "asc" ? -1 : 1;
      } else if (dateA > dateB){
        return sortOption.order === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    } else {
      const feeA = Number.parseFloat(a.fee);
      const feeB = Number.parseFloat(b.fee);
      if (feeA < feeB){
        return sortOption.order === "asc" ? -1 : 1;
      } else if (feeA > feeB){
        return sortOption.order === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    }
  });

  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr className={styles.tableLine}>
            <th
              className={classNames(
                styles.tableHead,
                styles.tableLeft,
                styles.sortable
              )}
              onClick={() => onSortChange("paid_at")}
            >
              <span>DATE</span>
              <SortArrow name="paid_at" sort={sort} />
            </th>
            <th
              className={classNames(
                styles.tableHead,
                styles.tableLeft,
                styles.sortable
              )}
              onClick={() => onSortChange("fee")}
            >
              <span>AMOUNT</span>
              <SortArrow name="fee" sort={sort} inverted />
            </th>
            <th className={classNames(styles.tableHead, styles.tableLeft)}>
              DESCRIPTION
            </th>
            <th className={styles.tableHead}>
              {/* if we view table as therapist show client column */}
              {userRole === "therapist" ? "CLIENT" : "THERAPIST"}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {mergedData.map((item) => (
            <tr key={`${item.id.toString()}_${item.name}`} className={styles.tableLine}>
              <td className={classNames(styles.tableLeft, styles.tableCell)}>
                {stringToDate(item.paid_at)}
              </td>
              <td
                className={classNames(styles.tableCell, styles.tableCellCost)}
              >
                ${Number(item.fee).toFixed(2)}
              </td>
              <td className={styles.tableCell}>{item.name}</td>
              <td
                className={classNames(styles.tableCell, styles.tableCellImage)}
              >
                {/* if we view table as therapist show client name
                otherwise show therapist name */}
                {userRole === "therapist" ? (
                  item.patient ? (
                    <>
                      {item.patient.file && (
                        <img
                          className={styles.tableImage}
                          src={item.patient.file}
                          alt={getFullName(item.patient)}
                        />
                      )}
                      <Link to={`/therapists/patient/${item.patient.id}`}>
                        {getFullName(item.patient)}
                      </Link>
                    </>
                  ) : (
                    <p className={styles.deletedUser}>Deleted Patient</p>
                  )
                ) : item.therapist ? (
                  <>
                    {item.therapist.file && (
                      <img
                        className={styles.tableImage}
                        src={item.therapist.file}
                        alt={getFullName(item.therapist)}
                      />
                    )}
                    <Link to={`/therapist/${item.therapist.id}`}>
                      {getFullName(item.therapist)}
                    </Link>
                  </>
                ) : (
                  <p className={styles.deletedUser}>Deleted Therapist</p>
                )}
              </td>
              <td className={classNames(styles.tableCell)}>
                <Button
                  className={styles.tableButton}
                  text="View invoice"
                  onClick={() =>
                    onRequestToNewWindow(
                      ratePurchaseService.getInvoice(item.id)
                    )
                  }
                  isLoading={!isInvoiceFetched}
                  disabled={!item.invoice}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={page}
        goTo={(a) => ratePurchaseService.goTo(a)}
        pageCount={pageCount}
      />
    </>
  );
};
