import React, { ChangeEventHandler, ForwardedRef, forwardRef } from "react";
import styles from "./CardRadio.module.scss";
import cn from "classnames";

type Value = string | number;

type Option = {
  icon?: string;
  text?: string;
  value: Value;
};

type Props = {
  options: Option[];
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  value: readonly Value[];
  className?: string;
  style?: object;
  name: string;
};

function CardRadioComponent(
  { options, onChange, value, className, name, disabled, style }: Props,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      className={cn(styles.cardRadioWrapper, className)}
      ref={ref}
      style={style}
    >
      {options.map((option) => (
        <React.Fragment key={option.value}>
          <input
            name={name}
            className={styles.cardRadioOption}
            id={`${option.value}`}
            type="checkbox"
            value={option.value}
            checked={value.includes(option.value)}
            disabled={disabled}
            onChange={onChange}
          />
          <label htmlFor={`${option.value}`}>
            {!!option.icon && (
              <img src={option.icon} alt={`${option.value}-icon`} />
            )}
            {option.text}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
}

const CardRadio = forwardRef(CardRadioComponent);

export {CardRadio};
