import { Nullable } from "common/utils";
import { cloneDeep, last } from "lodash";

export type Src = { type: string; src: string } & {
  id?: number;
  isMain?: boolean;
};
export type SrcSet = Src | Src[];

function parseAccept(acceptString: string): string {
  const tokens = acceptString.split(",");
  const words = tokens.map((token) => {
    const [generic, extension] = token.split("/");
    return extension === "*" ? generic : `.${extension}`;
  });

  if (words.length > 1) {
    return `${words.slice(0, -1).join(", ")} or ${last(words)} files`;
  } else {
    return `${words} files`;
  }
}

function fileFits(acceptString: string, file: File): boolean {
  const tokens = acceptString.split(",");
  return tokens.some((token) => file.type.startsWith(token.replace("*", "")));
}

function getInitialPreview(
  providedInitialValue?: SrcSet,
  multiple?: boolean
): Nullable<SrcSet> {
  if (providedInitialValue) {
    const isArray = Array.isArray(providedInitialValue);
    if (isArray && !multiple) {
      throw new Error(
        "An array passed as initial value for a single file input"
      );
    } else if (!isArray && multiple) {
      throw new Error(
        "A single value passed as initial value for a multiple file input"
      );
    }
    return cloneDeep(providedInitialValue);
  }
  return multiple ? [] : null;
}

function getOverflow(): string {
  return document.body.style.getPropertyValue("overflow");
}

function setOverflow(val: string) {
  document.body.style.setProperty("overflow", val);
}

export { parseAccept, getInitialPreview, fileFits, getOverflow, setOverflow };
