import { FC } from "react";
import { Controller } from "react-hook-form";

import { FAITH_OPTIONS } from "constants/options";
import Radiobutton from "common/UIKit/Radiobutton";
import { EditPatientInformationForm } from "../useEditPatientInformation";

export const Faith: FC<EditPatientInformationForm> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="preferred_faith"
      render={({ field: { value, onChange } }) => (
        <>
          {FAITH_OPTIONS.map((option) => (
            <Radiobutton
              key={option.value}
              className="patient-step__radio"
              name="preferred_faith"
              isOutlined={false}
              checked={option.value === value}
              onChange={() => onChange(option.value)}
            >
              {option.label}
            </Radiobutton>
          ))}
        </>
      )}
    />
  );
};
