import Button from "common/UIKit/Button";
import Lottie from "lottie-react";
import animation from "assets/images/TheraPass-phone-lottie.json";
import styles from "./ChatSection.module.scss";

export const ChatSection = () => {
  return (
    <section className={styles.root}>
      <div className={styles.info}>
        <h2 className={styles.title}>Chat Connect</h2>
        <p className={styles.text}>
          When new clients seek help online, Chat Connect guards your privacy
          while connecting you in real time with users viewing your profile and
          seeking to engage your services.
        </p>
        <Button className={styles.button} isLink to="/therapist/signup">
          Apply Now
        </Button>
      </div>
      <Lottie
        animationData={animation}
        loop={true}
        className={styles.image}
        alt="Phone with chat"
      />
    </section>
  );
};
