import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import AppSelect from "common/UIKit/Select";
import { personalInformationPath } from "../PersonalInformation";
import styles from "./EditPersonalBilling.module.scss";
import { MessagePopup } from "common/components/MessagePopup";
import { PatientFields } from "pages/Patients/PatientSteps/util";
import { useAuth } from "hooks/useAuth";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { FC } from "react";
import { Patient } from "types/patient.types";
import { useProfileForm } from "../useProfileForm";
import { Controller } from "react-hook-form";
import { StateOption, STATE_OPTIONS } from "constants/options";

export const editPersonalBillingPath = `${personalInformationPath}/edit-billing`;

const coveredFields: PatientFields = [
  "user.billing_address_1",
  "user.billing_address_2",
  "user.billing_city",
  "user.billing_state",
  "user.billing_zip",
];
type Inputs = {
  billing_address_1: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_zip: string;
};

export const EditPersonalBilling: FC = () => {
  const { goBack } = useDashboardNavigation("Edit Billing Information", {
    goBack: "pop_path",
  });
  const { register, control, handleSubmit, errors, isSubmitting, setValue } =
    useProfileForm<Inputs>(coveredFields, goBack, "billing address");
  const { baseUser } = useAuth();
  const user = useAuth().user as Patient | null;

  return (
    <div className={styles.editPersonalBilling}>
      <MessagePopup />
      <form className={styles.mainForm} onSubmit={handleSubmit}>
        <Input
          label="Street Address 1"
          {...register("billing_address_1")}
          error={errors.billing_address_1?.message}
        />
        <Input
          label="Street Address 2"
          {...register("billing_address_2")}
          error={errors.billing_address_2?.message}
        />
        <Input
          label="City"
          {...register("billing_city")}
          error={errors.billing_city?.message}
        />
        <Controller
          control={control}
          name="billing_state"
          render={({ field, fieldState }) => (
            <AppSelect
              label="State"
              placeholder="Select"
              {...field}
              options={STATE_OPTIONS}
              value={STATE_OPTIONS.find((o) => o.value === field.value)}
              onChange={(newValue) => {
                const { value } = newValue as StateOption
                field.onChange(value);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
        <Input
          label="Zip"
          {...register("billing_zip")}
          error={errors.billing_zip?.message}
          className={styles.editPersonalBilling__fullWidth}
        />
        <Button
          className="w-100"
          type="submit"
          text="Save"
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};
