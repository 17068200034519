import "./PatientSteps.scss";
import { Route, Routes } from "react-router-dom";
import { PatientStep1, step1Path } from "./PatientStep1";
import { PatientStep2, step2Path } from "./PatientStep2";
import { PatientStep3, step3Path } from "./PatientStep3";
import { PatientStep4, step4Path } from "./PatientStep4";
import { PatientStep5, step5Path } from "./PatientStep5";
import { PatientStep6, step6Path } from "./PatientStep6";
import { PatientStep7, step7Path } from "./PatientStep7";
import { PatientStep8, step8Path } from "./PatientStep8";
import { PatientStep9, step9Path } from "./PatientStep9";
import { PatientStep10, step10Path } from "./PatientStep10";
import { ErrorPage } from "../../ErrorPage";
import { PatientStepsLayout } from "./components/PatientStepsLayout";
import { AccessController } from "common/components/Layout/AccessController";
import { EsaStep1, esaStep1Path } from "./EsaStep1";
import { takeTestPath, TakeTest } from "../PatientsDashboard/Tests/TakeTest";
import { InformedConsent, informedConsentPath } from "./InformedConsent";

export * from "./stepsPath";

export const PatientSteps = () => {
  return (
    <AccessController patient="registration">
      <PatientStepsLayout>
        <Routes>
          <Route path={step1Path} element={<PatientStep1 />} />
          <Route path={step2Path} element={<PatientStep2 />} />
          <Route path={step3Path} element={<PatientStep3 />} />
          <Route path={step4Path} element={<PatientStep4 />} />
          <Route path={step5Path} element={<PatientStep5 />} />
          <Route path={step6Path} element={<PatientStep6 />} />
          <Route path={step7Path} element={<PatientStep7 />} />
          <Route path={step8Path} element={<PatientStep8 />} />
          <Route path={step9Path} element={<PatientStep9 />} />
          <Route path={step10Path} element={<PatientStep10 />} />
          <Route path={esaStep1Path} element={<EsaStep1 />} />
          <Route path={informedConsentPath} element={<InformedConsent />} />
          <Route path={`${esaStep1Path}/test${takeTestPath}/*`} element={<TakeTest isIntake={true} />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </PatientStepsLayout>
    </AccessController>
  );
};
