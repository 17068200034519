import { CredentialType } from "types/credential.types";
import { PrimaryCredentialType } from "types/therapist.types";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { SystemCommands } from "types/chatCommands.types";

export const ORGANIZATION_LABEL: Record<CredentialType, string> = {
  membership: "Membership Organization",
  certificate: "Accrediting Institution",
  diploma: "Diploma School Graduated",
  license: "License Issuer",
};

export const ORGANIZATION_ERRORS: Record<CredentialType, string> = {
  membership: "Fill Membership Organization",
  certificate: "Fill Accrediting Institution",
  diploma: "Fill Diploma School Graduated",
  license: "Fill License Issuer",
};

export const NUMBER_LABEL: Record<CredentialType, string> = {
  membership: "Membership ID/Number",
  certificate: "Certificate Number",
  diploma: "Diploma Degree Type",
  license: "License Number",
};

export const ORGANIZATION_FIELD: Record<
  CredentialType,
  | "membership_organization"
  | "license_issuer"
  | "accrediting_institution"
  | "diploma_school_graduated"
> = {
  membership: "membership_organization",
  license: "license_issuer",
  certificate: "accrediting_institution",
  diploma: "diploma_school_graduated",
};

export const NUMBER_FIELD: Record<
  CredentialType,
  | "membership_id"
  | "license_number"
  | "certificate_number"
  | "diploma_degree_type"
> = {
  membership: "membership_id",
  license: "license_number",
  certificate: "certificate_number",
  diploma: "diploma_degree_type",
};

export const NOTIFICATIONS = {
  success_purchase: "Successful Purchase",
  failed_purchase: "Failed Purchase",
  failed_renewal: "Failed Renewal",
  subscription_cancel: "Subscription Canceled",
  custom: "",
  esa: "",
};

export const CREDENTIAL_FIELDS_BY_TYPE: Record<
  PrimaryCredentialType,
  TherapistFields
> = {
  i_am_licensed: ["license_number", "license_state", "license_expiration"],
  i_am_pre_licensed: [
    "supervisor_name",
    "supervisor_license_number",
    "supervisor_license_state",
    "supervisor_license_expiration",
    "license_number",
    "license_state",
    "license_expiration",
  ],
  i_have_no_license: ["main_other_credential_for_no_license"],
};

export const COMMON_CREDENTIAL_FIELDS: TherapistFields = [
  "primary_credential_type",
  "mental_health_role",
];

export const STATE_LICENSE_LABEL: Record<PrimaryCredentialType, string> = {
  i_am_licensed: "Licensed",
  i_am_pre_licensed: "Pre-licensed",
  i_have_no_license: "No license",
};

export const NO_LICENSE_LABEL: Record<CredentialType, string> = {
  diploma: "Diploma / Degree",
  certificate: "Certificate",
  license: "License",
  membership: "Membership",
};

export const NO_LICENSE_YEAR: Record<CredentialType, string> = {
  diploma: "Year",
  certificate: "Year Issued",
  license: "Year Expired",
  membership: "Year Expired",
};

export const NO_LICENSE_ORGANIZATION_LABEL: Record<CredentialType, string> = {
  diploma: "DIPLOMA SCHOOL",
  certificate: "ACCREDITING INSTITUTION",
  license: "LICENSE ISSUER",
  membership: "MEMBERSHIP ORGANIZATION",
};

export const NO_LICENSE_ORGANIZATION_NUMBER: Record<CredentialType, string> = {
  diploma: "DEGREE TYPE",
  certificate: "CERTIFICATE NUMBER",
  license: "CERTIFICATE NUMBER",
  membership: "MEMBERSHIP ID/NUMBER",
};

export const SYSTEM_TEXTS: Record<keyof SystemCommands, string> = {
  close_session_command: "Close session",
  open_session_command: "Open session",
  pause_session_command: "Pause session",
  ratecard_command: "Send the link to the Rate Card page",
};
