import { Dispatch, SetStateAction, useState } from "react";
import { DeepPartial, getErrorMessage } from "common/utils";
import { authService } from "services/authService";
import { Patient } from "types/patient.types";
import { AxiosResponse } from "axios";

export const useSteps = <Type = Patient>() => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const submit = async ({
    diff,
    onSuccess,
  }: {
    diff: DeepPartial<Type>;
    onSuccess: (setIsSubmitting: Dispatch<SetStateAction<boolean>>) => void;
    errorMessage?: string;
  }) => {
    try {
      setIsSubmitting(true);
      await authService.patch({ diff });
      onSuccess(setIsSubmitting);
    } catch (error) {
      const { status } = error as AxiosResponse;

      if (status !== 401) {
        const { text } = getErrorMessage({ error });
        setError(text);
      }

      setIsSubmitting(false);
    }
  };
  return { isSubmitting, error, submit };
};
