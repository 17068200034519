import { useSnapshot } from "valtio";
import FilterTag from "common/UIKit/FilterTag";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-icon.svg";
import { therapistListingService } from "services/therapistListingService";
import { useWindowResize } from "hooks/useWindowResize";
import styles from "../../TherapistsListing.module.scss";
import { ClearFilters } from "../ClearFilters";

export const FilterTags = () => {
  const { width } = useWindowResize();
  const isMobile = width < 992;
  const { appliedFilters: applied_filters } = useSnapshot(
    therapistListingService
  );

  // if (applied_filters.length === 0) return null;

  return (
    <div className={`${styles.listingFilterRow} ${styles.bottom}`}>
      {applied_filters.length > 0 && (
        <>
          {isMobile && <ClearFilters />}
          {applied_filters.map((filter, index) => (
            <FilterTag
              className={styles.listingFilterTag}
              key={filter.label}
              buttonIcon={<CloseIcon />}
              onButtonClick={() =>
                therapistListingService.removeFilter(filter.name, filter.value)
              }
            >
              {filter.label}
            </FilterTag>
          ))}
          {!isMobile && <ClearFilters />}
        </>
      )}
    </div>
  );
};
