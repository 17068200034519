import { FC, memo, CSSProperties } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import PointIcon from "assets/images/icons/point.svg";
import { googleMapStyles } from "./helpers";
import { AreaArray, Coordinates } from "types/areas.types";
import { mapOptions } from "constants/googleMap";
import { MapPlaceholder } from "./Placeholder/MapPlaceholder";

const containerStyle = {
  width: "100%",
  height: "466px",
  borderRadius: "10px",
};

const checkLocation = (location: Coordinates) =>
  location.lat !== null && location.lng !== null;

const checkMarkers = (markers: AreaArray) =>
  markers.every(
    ({ latitude, longitude }) => latitude !== null && longitude !== null
  );

interface Props {
  location: Coordinates;
  markers: AreaArray;
  styles?: CSSProperties;
  markerOptions?: string | google.maps.Icon | google.maps.Symbol;
  zoom?: number;
}

const Map: FC<Props> = ({
  location,
  markers,
  styles = containerStyle,
  markerOptions = {
    url: PointIcon,
    anchor: { x: 91, y: 91, equals: () => true },
  },
  zoom = 5,
}) => {
  const { isLoaded, loadError } = useJsApiLoader(mapOptions);

  return checkLocation(location) && checkMarkers(markers) ? (
    isLoaded && !loadError ? (
      <GoogleMap
        mapContainerStyle={styles}
        center={location}
        zoom={zoom}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          styles: googleMapStyles,
        }}
      >
        {markers.map((marker) => (
          <Marker
            icon={markerOptions}
            key={marker.id}
            position={{ lat: marker.latitude, lng: marker.longitude }}
          />
        ))}
      </GoogleMap>
    ) : (
      <MapPlaceholder text="No connection" />
    )
  ) : (
    <MapPlaceholder text="Invalid coordinates" />
  );
};

export default memo(Map);
