import React from "react";
import ToggleAudioButton from "common/components/Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "common/components/Buttons/ToggleVideoButton/ToggleVideoButton";
import useRoomState from "hooks/useRoomState";
import {
  makeStyles,
  createStyles,
  Hidden,
  Typography,
  Menu,
  Grid,
} from "@mui/material";
import EndCallButton from "common/components/Buttons/EndCallButton/EndCallButton";
import useParticipants from "hooks/useParticipants";
import useVideoContext from "hooks/useVideoContext";
import styles from "./MenuBar.module.scss";

export default function MenuBar() {
  const roomState = useRoomState(null);
  const isReconnecting = roomState === "reconnecting";
  const { room } = useVideoContext();
  const participants = useParticipants();

  return (
    <>
      <footer className={styles.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1">
                {room!.name} | {participants.length + 1} participant
                {participants.length ? "s" : ""}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
