import { useEffect, useRef, useState } from "react";
import useVideoEffect from "../videoCommon";
import { blockEvent, isIOS, Nullable } from "common/utils";
import styles from "./VideoRecorder.module.scss";
import cn from "classnames";
import videoIcon from "assets/images/icons/video-Regular.svg";
import { useMountedState } from "hooks/misc";
import { padStart } from "lodash";

type Props = {
  onRecorded: (recording: Blob) => void;
  isFullscreen?: boolean;
  maxDuration?: number;
  isDisabled?: boolean;
};

function completeNum(num: number) {
  return padStart(num.toString(), 2, "0");
}

function VideoRecorder({
  onRecorded,
  maxDuration,
  isFullscreen,
  isDisabled,
}: Props) {
  const [isRecorderReady, setIsRecorderReady] = useState(false);
  const [isRecording, setIsRecording] = useMountedState<boolean>(false);
  const [passedTime, setPassedTime] = useMountedState(0);
  const [countDown, setCountDown] = useState(3);
  const [shouldDisplayCountDown, setShouldDisplayCountDown] = useState(false);
  const [error, setError] = useState<string>();

  const videoRef = useRef<HTMLVideoElement>(null);

  const mediaRecorderRef = useRef<Nullable<MediaRecorder>>(null);

  const durationTimeoutRef = useRef<any>(null);
  const startTimeoutRef = useRef<any>(null);

  const passedTimeUpdaterRef = useRef<any>(null);
  const countDownUpdaterRef = useRef<any>(null);

  function thisIsReady() {
    return isRecorderReady && !isDisabled;
  }

  useVideoEffect(videoRef, (video) => {
    const initStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        video.srcObject = stream;

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: isIOS() ? "video/mp4" : "video/webm",
        });
        mediaRecorder.ondataavailable = (e) => {
          onRecorded(e.data);
        };
        mediaRecorderRef.current = mediaRecorder;

        setIsRecorderReady(true);
      } catch (error) {
        const { name } = error as DOMException;
        setError(name);
      }
    };

    initStream();
  });

  function stopRecording() {
    [durationTimeoutRef, startTimeoutRef].forEach((r) =>
      clearTimeout(r.current)
    );
    [passedTimeUpdaterRef, countDownUpdaterRef].forEach((r) =>
      clearInterval(r.current)
    );
    const mediaRecorder = mediaRecorderRef.current;
    setIsRecording(false);
    if (!mediaRecorder) {
      return;
    }
    mediaRecorder.stream.getTracks().forEach((track) => {
      if (track.readyState === "live") {
        track.stop();
      }
    });
    if (mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
    }
  }

  useEffect(() => stopRecording, []);

  function startRecording() {
    const mediaRecorder = mediaRecorderRef.current;
    if (
      !thisIsReady() ||
      !mediaRecorder ||
      mediaRecorder.state === "recording" ||
      isRecording
    ) {
      return;
    }
    setShouldDisplayCountDown(true);
    countDownUpdaterRef.current = setInterval(
      () => setCountDown((curr) => curr - 1),
      1000
    );
    startTimeoutRef.current = setTimeout(() => {
      setShouldDisplayCountDown(false);
      mediaRecorder.start();
      setIsRecording(true);
      durationTimeoutRef.current = setTimeout(stopRecording, maxDuration);
      passedTimeUpdaterRef.current = setInterval(
        () => setPassedTime((curr) => curr + 1),
        1000
      );
    }, 3000);
  }

  if (error === "NotFoundError" || error === "NotAllowedError") {
    return (
      <div className={styles.error}>
        <h2>Can't access to camera or mic</h2>
        <h4>Please check permissions</h4>
      </div>
    );
  }
  return (
    <div
      onClick={blockEvent}
      className={cn(styles.container, isFullscreen && styles.fullscreen)}
    >
      {isRecording && (
        <div className={styles.passedTimePanel}>
          <div />
          <span>
            00:{completeNum(passedTime)} / 00:
            {completeNum((maxDuration as number) / 1000)}
          </span>
        </div>
      )}
      {shouldDisplayCountDown && (
        <div className={styles.countDown}>{countDown}</div>
      )}
      <video
        ref={videoRef}
        autoPlay
        playsInline
        height="100%"
        width="100%"
        muted
      />
      <button
        type="button"
        disabled={!thisIsReady() || shouldDisplayCountDown}
        className={cn(
          styles.roundButton,
          isRecording ? styles.inProgressButton : styles.startRecordingButton
        )}
        onClick={() => (!isRecording ? startRecording() : stopRecording())}
      >
        {isRecording ? (
          <svg viewBox="0 0 98 98" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx={49}
              cy={49}
              r={46.5}
              style={{ animationDuration: `${maxDuration}ms` }}
            />
          </svg>
        ) : (
          <img src={videoIcon} alt="video-icon" />
        )}
      </button>
    </div>
  );
}

VideoRecorder.defaultProps = {
  maxDuration: 60000,
};

export default VideoRecorder;
