import { Navigate, useParams } from "react-router-dom";

interface Props {
  withId?: boolean;
  to: string;
  external?: boolean;
}

export const Redirect = ({ to, withId, external }: Props) => {
  const { id } = useParams();
  if (external) {
    window.location.href = to;
  }
  return <Navigate to={`${to}${withId ? `/${id}` : ""}`} />;
};
