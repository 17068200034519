type NotificationType =
  | "success_purchase"
  | "failed_purchase"
  | "failed_renewal"
  | "subscription_cancel"
  | "custom"
  | "esa"

interface LineItems {
  lineItems: {
    name: string;
    itemId: string;
    quantity: string;
    unitPrice: string;
  };
}

export interface Notification {
  id: number;
  notification: {
    id: number;
    type: NotificationType;
    custom_text: string;
    subject?: string
    transaction?: {
      error_code: string;
      error: string;
      id: number;
      trans_id: string;
      amount: string;
      line_items: readonly LineItems[];
    };
    subscription?: {
      id: number;
      status: string;
      interval: number;
      start_at: string;
      cancelled_at: string;
      name: string;
    };
  };
  is_viewed: boolean;
  created_at: string;
}

export interface NotificationData {
  count: number;
  next: string;
  previous: string;
  results: Notification[];
}

export interface Unread {
  unread: false;
}

export enum Status {
  SENT = 1,
  READ = 2,
  UNSET = 3
}
