import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useResponsive } from "hooks/useResponsive";
import { Tab, TabList, TabPanel, Tabs } from "common/UIKit/Tabs";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";
import { personalInformationPath } from "../PersonalInformation";
import { patientInformationPath } from "../PatientsInformation";
import styles from "./MyAccountLayout.module.scss";

export const MyAccountLayout: FC = ({ children }) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAuth().user as Patient;

  const selectedIndex = location.pathname.includes(patientInformationPath)
    ? 1
    : 0;
  const handleSelect = (index: number) =>
    navigate(`..${[personalInformationPath, patientInformationPath][index]}`);

  return (
    <div className={styles.myAccountLayout}>
      {!isMobile && (
        <>
          <h1 className={styles.title}>
            Welcome, {user.user.first_name} {user.user.last_name}!
          </h1>
          <Tabs
            className={styles.tabs}
            selectedIndex={selectedIndex}
            onSelect={handleSelect}
          >
            <TabList className={styles.tabList}>
              <Tab>Personal information</Tab>
              <Tab>Patient’s information</Tab>
            </TabList>
            <TabPanel />
            <TabPanel />
          </Tabs>
        </>
      )}
      {children}
    </div>
  );
};
