import { FC, ReactElement, useState } from "react";
import cn from "classnames";

import styles from "./Tooltip.module.scss";
import { useResponsive } from "hooks/useResponsive";
import { MobileTooltip } from "./MobileTooltip";

interface Props {
  trigger: ReactElement;
}

export const Tooltip: FC<Props> = ({ children, trigger }) => {
  const { isMobile } = useResponsive();

  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const showTooltip = () => setIsTooltipShown(true);
  const hideTooltip = () => setIsTooltipShown(false);

  return (
    <div className={styles.tooltipContainer}>
      {isMobile ? (
        <MobileTooltip visible={isTooltipShown} onClose={hideTooltip}>
          {children}
        </MobileTooltip>
      ) : (
        <>
          <div
            className={cn(styles.tooltip, {
              [styles.disabled]: !isTooltipShown,
            })}
          >
            {children}
          </div>
          <div
            className={cn(styles.triangle, {
              [styles.disabled]: !isTooltipShown,
            })}
          />
        </>
      )}
      <div
        className={styles.trigger}
        onMouseEnter={isMobile ? undefined : showTooltip}
        onMouseLeave={isMobile ? undefined : hideTooltip}
        onClick={isMobile ? showTooltip : undefined}
      >
        {trigger}
      </div>
    </div>
  );
};
