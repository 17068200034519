import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardNavigationContext } from "contexts/DashboardNavigationContext";

type Options = {
  isMainTab?: boolean;
  isNavigationHidden?: boolean;
  edit?: "append_edit" | (() => void);
  goBack?: "pop_path" | (() => void);
};
export const useDashboardNavigation = (
  tabName: string,
  { isMainTab = false, isNavigationHidden = false, edit, goBack }: Options = {}
) => {
  const navigate = useNavigate();
  const {
    setTabName,
    setIsMainTab,
    setIsNavigationHidden,
    setEdit,
    setGoBack,
    ...rest
  } = useContext(DashboardNavigationContext);

  useEffect(() => {
    setTabName(tabName);
    setIsMainTab(isMainTab);
    setIsNavigationHidden(isNavigationHidden);
    setEdit(() => (edit === "append_edit" ? () => navigate("./edit") : edit));
    setGoBack(() => (goBack === "pop_path" ? () => navigate("./..") : goBack));
  }, [tabName]);

  return rest;
};
