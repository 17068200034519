const TOKEN_KEY = "token";

type tokenHandler = (token: string | null) => void;

const subscribers = new Set<tokenHandler>();

const getToken = () => localStorage.getItem(TOKEN_KEY);
const setToken = (newToken: string) => {
  localStorage.setItem(TOKEN_KEY, newToken);
  subscribers.forEach((cb) => cb(newToken));
};
const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  subscribers.forEach((cb) => cb(null));
};

export const tokenService = {
  get: getToken,
  set: setToken,
  remove: removeToken,
  sync: (cb: tokenHandler) => {
    cb(getToken());
    subscribers.add(cb);

    return () => subscribers.delete(cb);
  },
} as const;
