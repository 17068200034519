import { HttpService } from "./httpService";
import { Test } from "../types/testProcess.types";
import { proxy } from "valtio";
import { TestAttempt } from "types/testAttempt.types";

export class TestProcessService extends HttpService {
  test: Test | null = null;
  testAttempt: TestAttempt | null = null;
  isProcessing = false;

  async getById(id: number) {
    this.isProcessing = true;
    if (this.test?.id !== id) {
      this.test = await this.http.get(`/v1/psychology-tests/${id}/`);
    }
    this.isProcessing = false;

    return this.test;
  }
}

export const testProcessService = proxy(new TestProcessService());
