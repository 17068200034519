import { proxy } from "valtio";
import { HttpService } from "./httpService";

class EmailService extends HttpService {
  private endpoint = "/v1/email/";
  newEmail = "";
  newToken = "";

  async changeEmail(data: { email: string }) {
    await this.http.post(`${this.endpoint}change/`, data);

    return this;
  }

  async resendEmail() {
    await this.http.post(`${this.endpoint}change/resend/`);

    return this;
  }

  async confirmChange(data: { code: string }) {
    const { token } = (await this.http.post(
      `${this.endpoint}confirm-change/`,
      data
    )) as { token: string };

    this.newToken = token;
    return this.newToken;
  }
}

export const emailService = proxy(new EmailService());
