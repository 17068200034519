import { Link } from "react-router-dom";
import { useModal } from "hooks/useModal";
import { tokenService } from "services/tokenService";
import { history } from "utils/history";
import { LoggedOutContainer } from "../LoggedOutContainer/LoggedOutContainer";
import styles from "./LoggedOutAndBuyRateCard.module.scss";
import { esaTokenService } from "services/esaTokenService";

export const LoggedOutAndBuyRateCard = () => {
    const { isOpen, close } = useModal("LOGGED_OUT_AND_BUY_RATECARD");

    const onClose = () => {
        close();
        tokenService.remove();
        esaTokenService.remove();
    };

    const handleButtonClick = () => {
        onClose();
        history.push("/patient/login");
    };

    return (
        <LoggedOutContainer
          subTitle={
            <div className={styles.textContainer}>
              <span>
                You need to be logged in to register for a session.
              </span>
               <span>
                Click{" "}
                 <Link to="/patient/signup" onClick={onClose}>
                  here{" "}
                </Link>
                   to register.
              </span>
              <span>
                If you are already a member,{" "}
                <Link to="/patient/login" onClick={onClose}>
                  log in.
                </Link>
              </span>
            </div>
          }
            className={styles.loggedOutToPatients}
            isOpen={isOpen}
            close={() => close()}
            onButtonClick={handleButtonClick}
        />
    );
};
