import { Contact } from "types/contact.types";
import { proxy } from "valtio";
import { HttpService } from "./httpService";

class ContactFormService extends HttpService {
  private endpoint = "/v1/contact-form/";

  async submit_contact(values: Contact) {
    await this.http.post(this.endpoint, values);
    return this
  }
}

export const contactFormService = proxy(new ContactFormService());
