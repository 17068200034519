import { Route, Routes } from "react-router-dom";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { Overview, testsMainPath } from "./Overview";
import { TakeTest, takeTestPath } from "./TakeTest";
import { useDocumentTitle } from "hooks/useDocumentTitle";

export const testsPath = "/tests";

export const Tests = () => {
  useDashboardNavigation("Tests", {
    goBack: "pop_path",
  });
  useDocumentTitle("TheraPass - Tests");

  return (
    <Routes>
      <Route path={testsMainPath} element={<Overview />} />
      <Route path={`${takeTestPath}/*`} element={<TakeTest isIntake={false}/>} />
    </Routes>
  );
};
