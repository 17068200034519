import { FC } from "react";
import { TestNumeric } from "types/testProcess.types";
import Input from "common/UIKit/Input";
import { useTest } from "hooks/useTest";
import styles from "./Numeric.module.scss";

interface Props {
  question: TestNumeric;
  disabled?: boolean;
}

export const Numeric: FC<Props> = ({ question, disabled }) => {
  const { registerQuestion, errors } = useTest();

  return (
    <Input
      type="numeric"
      placeholder={question.placeholder === "0" ? "" : question.placeholder}
      adornment={question.adornment}
      className={styles.numeric}
      maxLength={3}
      {...registerQuestion(question, {
        valueAsNumber: true,
        max: {
          message: "Ensure this value is less than or equal to 168",
          value: 168,
        },
      })}
      error={errors[question.id]?.message}
      disabled={disabled}
    />
  );
};
