import { FC } from "react";

import { useResponsive } from "hooks/useResponsive";
import { MyAccountLayout } from "../MyAccountLayout";
import { PersonalInformationDesktop } from "./PersonalInformationDesktop";
import { PersonalInformationMobile } from "./PersonalInformationMobile";

export const personalInformationPath = "/personal-information";

interface Props {
  onEdit: () => void;
  onEditBilling: () => void;
  onEditShipping: () => void;
  onChangePassword: () => void;
}

export const PersonalInformation: FC<Props> = ({
  onEdit,
  onEditBilling,
  onEditShipping,
  onChangePassword,
}) => {
  const { isMobile } = useResponsive();

  return (
    <MyAccountLayout>
      {isMobile ? (
        <PersonalInformationMobile
          onEdit={onEdit}
          onEditBilling={onEditBilling}
          onEditShipping={onEditShipping}
        />
      ) : (
        <PersonalInformationDesktop
          onEdit={onEdit}
          onEditBilling={onEditBilling}
          onEditShipping={onEditShipping}
          onChangePassword={onChangePassword}
        />
      )}
    </MyAccountLayout>
  );
};
