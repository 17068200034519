import { stepsUi } from "../components";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { CardRadio } from "common/UIKit/CardRadio";
import { startCase } from "lodash";
import { PAYMENT_ICONS } from "constants/icons";
import useTherapistStep6, { Inputs } from "./useTherapistStep6";
import { FormOfPaymentEnum } from "types/therapist.types";
import Input from "common/UIKit/Input";
import { ChangeEvent } from "react";
import styles from "./TherapistStep6.module.scss";

type CardSectionProps = {
  title: string;
  subtitle: string;
  description?: string;
  control: Control<Inputs>;
  name: "inPerson" | "onlineIndividual";
};

export function CardSection({
  title,
  subtitle,
  description,
  control,
  name,
}: CardSectionProps) {
  return (
    <>
      <h3 className={styles.cardSectionTitle}>{title}</h3>
      <div className={styles.cardSection}>
        <h4 className={styles.cardSectionSubtitle}>{subtitle}</h4>
        {!!description && (
          <p className={styles.cardSectionDescription}>{description}</p>
        )}
        <Controller
          control={control}
          rules={{
            validate: {
              nonNegative: (value) =>
                value === null || value >= 0 || "Values must be non-negative",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              value={field?.value ? field.value : 0}
              error={error?.message}
              adornment="$"
              adornmentVariant="black"
              withLeftAdornment
              className={styles.financeRangeInput}
              min={0}
              maxLength={8}
              isValid={!error}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const inputValue = e.target.value;

                if (!isNaN(+inputValue)) {
                  field.value = inputValue === "" ? null : +inputValue;
                  field.onChange(field.value);
                }
              }}
            />
          )}
          name={name}
        />
      </div>
    </>
  );
}

export function TherapistStep6() {
  const { handleSubmit, control, actionsProps } = useTherapistStep6();

  return (
    <>
      <stepsUi.Header>Fees</stepsUi.Header>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <div className={styles.cardBody}>
          <CardSection
            title="In-Person Session Cost"
            subtitle="In-Person Session Cost"
            control={control}
            name="inPerson"
          />
          <CardSection
            title="Teletherapy Fees"
            subtitle="Online Individual Session Cost"
            description="Enter your fee for a 45-60 minute online therapy session"
            control={control}
            name="onlineIndividual"
          />
        </div>

        <h4 className={styles.financeTypeLabel}>
          Forms of payment you accept:
        </h4>
        <Controller
          control={control}
          render={({ field }) => (
            <CardRadio
              className={styles.financeType}
              {...field}
              options={Object.entries(FormOfPaymentEnum).map(
                ([text, value]) => ({
                  // @ts-ignore
                  text: startCase(text),
                  value,
                  icon: PAYMENT_ICONS[value],
                })
              )}
              onChange={(event) => {
                const { checked, value } = event.currentTarget;
                if (checked) {
                  field.onChange([...field.value, value]);
                } else {
                  field.onChange(field.value.filter((v) => v !== value));
                }
              }}
            />
          )}
          name="form_of_payment"
        />
      </stepsUi.Form>
    </>
  );
}
