export const isIOS = () => {
  if (navigator.userAgent.match(/iPhone|iPod|iPad/i)) {
    return true;
  } else {
    return false;
  }
};

export const getCurrentPosition = (): Promise<{
  latitude: number;
  longitude: number;
}> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }: GeolocationPosition) => {
        resolve({ latitude: coords.latitude, longitude: coords.longitude });
      },
      (error) => reject(error)
    );
  });
};
