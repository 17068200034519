import Button from "common/UIKit/Button";
import image from "assets/images/cta1.png";
import { ReactComponent as HeartBoxIcon } from "assets/images/heart-box.svg";
import { ReactComponent as PeopleStarsIcon } from "assets/images/people-with-stars.svg";
import { ReactComponent as PeopleChatIcon } from "assets/images/phone-with-chat.svg";
import { LandingHeader } from "../Auth/components/LandingHeader/LandingHeader";
import { Footer } from "../Auth/components/Footer/Footer";
import { contactUsPath } from "../ContactUs";
import styles from "./About.module.scss";

export const aboutPath = "/about";

export const About = () => {
  return (
    <main className={styles.root}>
      <LandingHeader />
      <div className={styles.inner}>
        <section className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.column}>
              <h1 className={styles.title}>Our mission</h1>
              <p className={styles.text}>
                TheraPass is an on-demand mental health and wellness platform
                connecting people from all walks of life with the licensed
                professional therapist of their choice. We do not use impersonal
                algorithms or artificial intelligence to match you with a
                therapist. Our patient-therapist matching service is free to
                everyone, always. We never charge a fee and no subscription is
                required. Sign up and get access to professional mental health
                support immediately rather than days or months. In order to help
                you decide which therapist is best suited for your care, you can
                message, phone or video interview our therapists at no charge
                before you decide. First session is always free and afterwards
                deal directly with your therapist concerning schedule, mode of
                therapy and fees. No middle man, additional fees or surcharges.
              </p>
              <Button isLink href={contactUsPath} className={styles.button}>
                Contact Us
              </Button>
            </div>
            <img
              src={image}
              className={styles.image}
              alt="Good morning! How can I help you today?"
            />
          </div>
        </section>
        <section className={styles.benefitsRow}>
          <div className={styles.benefitsColumn}>
            <PeopleChatIcon className={styles.benefitsIcon} />
            <h4 className={styles.benefitsTitle}>+125,000</h4>
            <p className={styles.benefitsText}>Therapy patients</p>
          </div>
          <div className={styles.benefitsColumn}>
            <HeartBoxIcon className={styles.benefitsIcon} />
            <h4 className={styles.benefitsTitle}>+1,500,000</h4>
            <p className={styles.benefitsText}>Psychological evaluations</p>
          </div>
          <div className={styles.benefitsColumn}>
            <PeopleStarsIcon className={styles.benefitsIcon} />
            <h4 className={styles.benefitsTitle}>+5 Years</h4>
            <p className={styles.benefitsText}>+1,000 therapists, 50 states</p>
          </div>
        </section>
      </div>
      <Footer />
    </main>
  );
};
