import { FC } from "react";
import { Controller } from "react-hook-form";
import { EditPatientInformationForm } from "../useEditPatientInformation";
import Radiobutton from "common/UIKit/Radiobutton";
import { PRICE_OPTIONS } from "constants/options";
import styles from "../EditPatientInformation.module.scss";

export const Price: FC<EditPatientInformationForm> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="preferred_price"
      render={({ field: { value, onChange } }) => (
        <>
          {PRICE_OPTIONS.map((price) => (
            <Radiobutton
              key={price.value}
              className={`patient-step__checkbox-border ${styles.price}`}
              id={`value-${price.value}`}
              checked={value === price.value}
              onChange={() => onChange(price.value)}
            >
              {price.label}
            </Radiobutton>
          ))}
        </>
      )}
    />
  );
};
