type key = string | number | string[] | number[] | boolean;

export const toQuery = (params: { [key: string]: key }) => {
  const queryArray = [];
  for (const key in params) {
    if (Array.isArray(params[key])) {
      const array = params[key] as any[];

      array.map((param: any[]) => {
        const checkedParam = typeof param !== "string" ? param.toString() : param;
        return queryArray.push([key, checkedParam]);
      });
    } else {
      const checkedParam = typeof params[key] !== "string" ? params[key].toString() : (params[key] as string);
      queryArray.push([key, checkedParam]);
    }
  }
  return queryArray;
};

export const getPathWithParams = (
  path = "",
  prevParams: { [key: string]: any } = {},
  newParams: { [key: string]: any } = {}
) => {
  const unitedParams = { ...prevParams, ...newParams };
  const query = toQuery(unitedParams);

  const searchParams = new URLSearchParams(query).toString();
  return searchParams ? `${path}?${searchParams}` : path;
};
