import classNames from "classnames";
import { useSnapshot } from "valtio";
import TherapiListIcon1 from "assets/images/peoples/therapilist-icon1.png";
import TherapiListIcon2 from "assets/images/peoples/therapilist-icon2.png";
import TherapiListIcon3 from "assets/images/peoples/therapilist-icon3.png";
import TherapiListIcon4 from "assets/images/peoples/therapilist-icon4.png";
import { therapistListingService } from "services/therapistListingService";
import { ListOption } from "constants/options";
import styles from "./TherapyList.module.scss";

interface Props {
  onClick: (option: ListOption) => void;
}

export const THERAPIES = [
  {
    value: "individuals",
    label: "Individuals Therapy",
    icon: TherapiListIcon2,
  },
  { value: "couples", label: "Couples Therapy", icon: TherapiListIcon1 },
  { value: "family", label: "Family Therapy", icon: TherapiListIcon3 },
  { value: "group", label: "Group Therapy", icon: TherapiListIcon4 },
];

export const TherapyList = ({ onClick }: Props) => {
  const { therapyValue } = useSnapshot(therapistListingService);

  return (
    <div className={styles.therapyList}>
      {THERAPIES.map((therapy) => (
        <div
          key={therapy.value}
          className={classNames(styles.therapyListItem)}
          onClick={() => onClick(therapy)}
        >
          <img
            className={classNames(styles.therapyListIcon, {
              [styles.active]: therapyValue.find(
                ({ value }) => value === therapy.value
              ),
            })}
            src={therapy.icon}
            alt={therapy.label}
          />
          <p className={styles.therapyListName}>{therapy.label}</p>
        </div>
      ))}
    </div>
  );
};
