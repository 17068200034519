import SwiperCore, { Navigation, Pagination,  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ElementType, ReactNode, useState } from "react";
import { ReactComponent as IconAngleLeft } from "assets/images/icons/angle-left.svg";
import { ReactComponent as IconAngleRight } from "assets/images/icons/angle-right.svg";
import styles from "./Slider.module.scss";
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import cn from "classnames";
SwiperCore.use([Navigation, Pagination]);

interface Props {
  nodes: ReactNode[];
  LeftIcon?: ElementType;
  RightIcon?: ElementType;
  spaceBetween?: number;
  className?: string;
  buttonClass?: string;
  slidesPerGroup?: number;
  slidesPerView?: number;
  sliderClassname?: string;
  isPagination?: boolean;
  isFeatured?: boolean;
}

export const Slider = ({
  nodes,
  LeftIcon = IconAngleLeft,
  RightIcon = IconAngleRight,
  spaceBetween = 20,
  className,
  buttonClass,
  slidesPerGroup,
  slidesPerView,
  sliderClassname,
  isPagination = true,
  isFeatured = false,
}: Props) => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  return (
    <div className={cn(styles.root, className && className)}>
        { isPagination &&
            <button
            className={cn(
                styles.button,
                styles.buttonPrev,
                buttonClass && buttonClass
            )}
            type="button"
            ref={(node) => setPrevEl(node)}
            >
                <LeftIcon/>
            </button>
        }
      <Swiper
        navigation={{
          prevEl,
          nextEl,
        }}
        loop
        slidesPerGroup={slidesPerGroup}
        modules={[Pagination, Navigation]}
        pagination={{ clickable: true, dynamicBullets: isFeatured || nodes.length > 3 }}
        className={cn(styles.slider, sliderClassname && sliderClassname)}
        slidesPerView={slidesPerView}
        breakpoints={{
            320: { slidesPerView, spaceBetween: 10 },
            1025: { spaceBetween, slidesPerView: slidesPerView },
        }}
      >
        {nodes.map((node, ind) => (
          <SwiperSlide className={styles.slide} key={ind}>
            {node}
          </SwiperSlide>
        ))}
      </Swiper>
        {isPagination &&
            <button
            className={cn(
                styles.button,
                styles.buttonNext,
                buttonClass && buttonClass
            )}
            type="button"
            ref={(node) => setNextEl(node)}
            >
                <RightIcon/>
            </button>
        }
    </div>
  );
};
