import { Route, Routes, useNavigate } from "react-router-dom";
import { AnimalsInformation } from "./AnimalsInformation";

import {
  AddAnimal,
  addAnimalPath,
} from "./AddAnimal";

import {
  EditAnimal,
  editAnimalPath,
} from "./EditAnimal";
import { useState } from "react";

export const myAnimalsPath = "/my-animals";

export const MyAnimals = () => {
  const navigate = useNavigate();
  const [clientAnimalId, setClientAnimalId] = useState(0);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AnimalsInformation
            onAddAnimal={() => navigate(`.${addAnimalPath}`)}
            onEditAnimal={(clientAnimalId: number) => {
              setClientAnimalId(clientAnimalId);
              navigate(`.${editAnimalPath}`);
            }}
          />
        }
      />
      <Route
        path={addAnimalPath}
        element={<AddAnimal />}
      />
      <Route
        path={editAnimalPath}
        element={<EditAnimal clientAnimalId={clientAnimalId}/>}
      />
    </Routes>
  );
};
