import {
  NotificationData,
  Notification,
  Unread,
} from "types/notifications.types";
import { proxy } from "valtio";
import { HttpService } from "./httpService";

class NotificationsService extends HttpService {
  private endpoint = "v1/user-notifications/";
  isFetching = false;
  notifications: Notification[] = [];
  unread: boolean = false;

  constructor(args?: any) {
    super({
      ...args,
    });
  }

  async list() {
    try {
      const data = (await this.http.get(
        `${this.endpoint}`
      )) as NotificationData;
      this.notifications = data.results;
      this.isFetching = true;
    } catch (error) {
      this.isFetching = false;
    }

    return this.notifications;
  }

  async readAll() {
    try {
      await this.http.post(`${this.endpoint}read-all/`, {});
      this.unread = false;
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUnread() {
    const data = (await this.http.get(`${this.endpoint}unread/`)) as Unread;
    this.unread = data.unread;

    return this.unread;
  }

  async readById(id: string) {
    try {
      await this.http.post(`${this.endpoint}${id}/read/`);
      this.isFetching = false;
    } catch (error) {
      console.log(error);
    }
  }
}

export const notificationsService = proxy(new NotificationsService());
