import { Slider } from "common/components/Slider/Slider";
import styles from "./Testimonials.module.scss";
import { ReactNode } from "react";
import cn from "classnames";
import { useResponsive } from "hooks/useResponsive";

interface Props {
  sliderNodes: ReactNode[];
  className?: string;
  spaceBetween: number;
  sliderClassname?: string;
  titleClassname?: string;
}

export const Testimonials = ({
  sliderNodes,
  className,
  spaceBetween,
  sliderClassname,
  titleClassname,
}: Props) => {
    const { isMobile } = useResponsive()

  return (
    <section className={cn(styles.root, className && className)}>
      <h3 className={cn(styles.title, titleClassname && titleClassname)}>
        Testimonials
      </h3>
      <Slider
        className={styles.slider}
        spaceBetween={spaceBetween}
        nodes={sliderNodes}
        slidesPerView={sliderNodes.length === 1 ? 1 : sliderNodes.length === 2 ? 2 : sliderNodes.length > 2 && isMobile ? 2 : 3}
        isPagination={sliderNodes.length > 3}
        sliderClassname={sliderClassname}
      />
    </section>
  );
};
