import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { AxiosResponse } from "axios";
import { parseISO, format } from "date-fns";

import { Modal } from "common/components/Modal";
import Button from "common/UIKit/Button";

import { useModal } from "hooks/useModal";

import { subscriptionService } from "services/subscriptionService";
import { plansService } from "services/plansService";

import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { ReactComponent as IconInfo } from "assets/images/icons/info-circle.svg";

import styles from "./ConfirmCancellationModal.module.scss";

export const ConfirmCancellationModal = () => {
  const { isOpen, close, closeAll } = useModal("CANCEL_SUBSCRIPTION_PLAN");
  const { open: openChangeSubscriptionSuccess } = useModal(
    "CHANGE_SUBSCRIPTION_SUCCESS"
  );

  const { isFetched: isSubscriptionFetched } = useSnapshot(subscriptionService);
  const { isFetched: isPlanFetched, planInfo } = useSnapshot(plansService);
  const isFetched = isSubscriptionFetched || isPlanFetched;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    !isFetched && subscriptionService.list();
  }, [isFetched]);

  useEffect(() => {
    plansService.clearPlanInfo();
  }, [isOpen]);

  const handleConfirm = async () => {
    try {
      await setIsLoading(true);
      if (planInfo) {
        await subscriptionService.cancel();
        closeAll();
        openChangeSubscriptionSuccess();
      } else {
        await plansService.changePlanInfo({ is_cancel: true });
      }
    } catch (error) {
      const { data } = error as AxiosResponse;
      Array.isArray(data.errors) && setError(data.errors?.join(". "));
    } finally {
      await setIsLoading(false);
    }
  };

  return (
    <Modal className={styles.wrapper} visible={isOpen} onClose={close}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={close}>
          <CloseIcon />
        </button>
        <h1>Are you sure you really want to cancel your subscription?</h1>
        {planInfo && (
          <div className={styles.warning}>
            <div className={styles.warningIcon}>
              <IconInfo />
            </div>
            <p className={styles.warningInfo}>
              {planInfo.text.replace(
                "{effective_date}",
                `${format(
                  parseISO(planInfo.effective_date),
                  "MMMM d, yyyy"
                )} at ${format(parseISO(planInfo.effective_date), "h:mm a")} `
              )}
            </p>
          </div>
        )}
        <div className={styles.buttons}>
          <Button
            text={planInfo ? "Confirm" : "Yes"}
            color="green-light"
            onClick={handleConfirm}
            isLoading={isLoading}
          />
          <Button text={planInfo ? "Cancel" : "No"} onClick={close} />
        </div>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </Modal>
  );
};
