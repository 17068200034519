import React from "react";
import clsx from "clsx";
import { Button } from "@mui/material";
import useVideoContext from "hooks/useVideoContext";

export default function EndCallButton(props: { className?: string }) {
  const { room } = useVideoContext();

  return (
    <Button
      onClick={() => room!.disconnect()}
      className={clsx(props.className)}
      data-cy-disconnect
    >
      Disconnect
    </Button>
  );
}
