import cn from "classnames";
import { Link } from "react-router-dom";
import { aboutPath } from "pages/About";
import { contactUsPath } from "pages/ContactUs";
import { termsOfUsePath } from "pages/TermsOfUse";
import { privacyPolicyPath } from "pages/PrivacyPolicy";
import { faqPath } from "pages/FAQ";
import styles from "./Footer.module.scss";

interface Props {
  className?: string;
  variant?: "default" | "dashboard";
}

export const Footer = ({ variant = "default", className }: Props) => {
  return (
    <footer className={cn(styles.root, className, styles[variant])}>
      <div className={styles.row}>
        <ul className={styles.column}>
          <li className={styles.title}>Support</li>
          <li className={styles.link}>
            <Link to="/patient/login">User Login</Link>
          </li>
          <li className={styles.link}>
            <Link to={aboutPath}>About</Link>
          </li>
          <li className={styles.link}>
            <Link to={contactUsPath}>Contact</Link>
          </li>
          <li className={styles.link}>
            <Link to={faqPath}>FAQ</Link>
          </li>
        </ul>
        <ul className={styles.column}>
          <li className={styles.title}>Therapists</li>
          <li className={styles.link}>
            <Link to="/therapist/login">Login</Link>
          </li>
          <li className={styles.link}>
            <Link to="/therapist">Home</Link>
          </li>
          <li className={styles.link}>
            <Link to="/therapists/subscription-plans">Plans</Link>
          </li>
        </ul>
        <ul className={styles.column}>
          <li className={styles.title}>Legal</li>
          <li className={styles.link}>
            <Link to={privacyPolicyPath}>Privacy</Link>
          </li>
          <li className={styles.link}>
            <Link to={termsOfUsePath}>Terms of Use</Link>
          </li>
        </ul>
      </div>
      <p className={styles.copy}>© TheraPass 2022-2023. All right reserved</p>
    </footer>
  );
};
