import Button from "common/UIKit/Button";
import { ArticleItem } from "./blocks/ArticleItem";
import styles from "./ArticlesSection.module.scss";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { articlesService } from "services/articlesService";

export const ArticlesSection = () => {
  const navigate = useNavigate();
  const { featuredArticles } = useSnapshot(articlesService);

  return (
    <section className={styles.root}>
      <h3 className={styles.title}>Featured Articles</h3>
      <div className={styles.row}>
        <ul className={styles.column}>
          {featuredArticles.map((item) => (
            <ArticleItem key={item.id} {...item} />
          ))}
        </ul>
      </div>
      <Button onClick={() => navigate("/articles")} className={styles.button}>
        All Articles
      </Button>
    </section>
  );
};
