import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { Tab, TabList, Tabs, TabPanel } from "common/UIKit/Tabs";
import { useResponsive } from "hooks/useResponsive";
import { Notifications } from "../Notifications";
import { Availability } from "../Availability";
import { notificationsPath } from "../Notifications";
import { availabilityPath } from "../Availability";
import { ChangePassword, changePasswordPath } from "../ChangePassword";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./Info.module.scss";

const tabs = [notificationsPath, availabilityPath, changePasswordPath];

export const Info: FC = () => {
  useDashboardNavigation("Settings", {
    goBack: "pop_path",
  });
  const { isMobile } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedIndex = tabs.findIndex((tab) =>
    tab.includes(location.pathname.split("/").reverse()[0])
  );
  const onTabChange = (index: number) => navigate(`..${tabs[index]}`);

  return (
    <div className={styles.settings}>
      {!isMobile && <h1 className={styles.title}>Settings</h1>}
      <Tabs
        className={styles.tabs}
        selectedIndex={selectedIndex}
        onSelect={onTabChange}
      >
        <TabList className={styles.tabList}>
          <Tab>Notifications</Tab>
          <Tab>Availability</Tab>
          <Tab>Password</Tab>
        </TabList>

        <TabPanel />
        <TabPanel />
        <TabPanel />
      </Tabs>
      <div className={styles.content}>
        <Routes>
          <Route path={notificationsPath} element={<Notifications />} />
          <Route path={availabilityPath} element={<Availability />} />
          <Route
              path={changePasswordPath}
              element={<ChangePassword />}
          />
          <Route
            path="*"
            element={<Navigate to={`.${notificationsPath}`} />}
          />
        </Routes>
      </div>
    </div>
  );
};
