import { Controller } from "react-hook-form";
import cn from "classnames";
import InputMask from "react-input-mask";
import isCreditCard from "validator/es/lib/isCreditCard";
import { useModal } from "hooks/useModal";
import { useResponsive } from "hooks/useResponsive";
import { useModalDrag } from "hooks/useModalDrag";
import { usePaymentWithNewCard } from "./usePaymentWithNewCard";
import { isDateNotInPast } from "common/utils";
import { Modal } from "common/components/Modal";
import Button from "common/UIKit/Button";
import Input from "common/UIKit/Input";
import Checkbox from "common/UIKit/Checkbox";
import { ErrorMessage } from "common/UIKit/ErrorMessage";

import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { Link } from "react-router-dom";
import styles from "./AddCreditCard.module.scss";

export type AddCardHandler = (args: {
  card_token: string;
  descriptor: string;
  cardholder_name: string;
  card_code: string;
}) => Promise<any>;

interface Props {
  onPayOnce?: AddCardHandler;
  onAddCard: AddCardHandler;
}

export const AddCreditCard = ({ onPayOnce, onAddCard }: Props) => {
  const { isOpen, close } = useModal("ADD_CREDIT_CARD");
  const { isMobile } = useResponsive();
  const {
    register,
    handleSubmit,
    handleUseOnlyOnce,
    errors,
    isSubmitting,
    isSubmittingPayOnce,
    control,
  } = usePaymentWithNewCard(onAddCard, onPayOnce);

  const { bind, percentageToClose, transformStyle } = useModalDrag({
    moveDistanceToClose: 300,
    onClose: close,
  });

  return (
    <Modal
      className={styles.wrapper}
      visible={isOpen}
      onClose={close}
      fadePercentage={percentageToClose}
    >
      <div className={styles.addCreditCard} {...bind()} style={transformStyle}>
        {isMobile && <div className={styles.mobileLine} />}
        <button className={styles.closeButton} onClick={close}>
          <CloseIcon />
        </button>

        <h1>Credit Card Info</h1>

        {errors.global && <ErrorMessage>{errors.global}</ErrorMessage>}
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            label="Name on Card"
            placeholder=""
            className={styles.input}
            {...register("cardHolder", {
              required: "This field is required.",
            })}
            error={errors.cardHolder?.message}
          />
          <Controller
            name="cardNumber"
            control={control}
            rules={{
              required: "This field is required.",
              validate: (value) =>
                isCreditCard(value.replace(/[\s_]/g, "")) ||
                "Incorrect card number.",
            }}
            render={({ field: { value, onChange } }) => (
              <InputMask
                mask="9999 9999 9999 9999"
                value={value}
                onChange={onChange}
                className={styles.input}
                placeholder="1234 1234 1234 1234"
              >
                {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    label="Card number"
                    error={errors.cardNumber?.message}
                  />
                )}
              </InputMask>
            )}
          />
          <div className={styles.twoColumns}>
            <Controller
              name="expiry"
              control={control}
              rules={{
                required: "This field is required.",
                validate: {
                  isValid: (value) =>
                    /^(0[1-9]|1[0-2])\/?(\d{2})$/.test(value) ||
                    "Incorrect expiry date",
                  isNotExpired: (value) =>
                    isDateNotInPast(value) || "This card is expired.",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <InputMask
                  mask="99/99"
                  value={value}
                  onChange={onChange}
                  className={styles.input}
                  placeholder="MM/YY"
                >
                  {(inputProps: any) => (
                    <Input
                      {...inputProps}
                      label="Expiration"
                      error={errors.expiry?.message}
                    />
                  )}
                </InputMask>
              )}
            />
            <Input
              label="CVV"
              type="password"
              maxLength={4}
              description="3 or 4 digits"
              className={styles.input}
              {...register("CVV", {
                required: "This field is required.",
                validate: (value) =>
                  /^(\d{3,4})$/.test(value) || "Incorrect CVV.",
              })}
              error={errors.CVV?.message}
            />
          </div>
          <Checkbox
            id="isAccepted"
            className={styles.confirmation}
            size="sm"
            {...register("isAccepted", { required: "This field is required." })}
            error={errors.isAccepted?.message}
          >
            <span>
              I agree to the{" "}
              <Link
                to="/terms"
                className="g-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy"
                className="g-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
              .
            </span>
          </Checkbox>

          {/*{onPayOnce && (*/}
          {/*  <Button*/}
          {/*    className={cn(styles.addAnotherCard, "w-100")}
          {/*    text="Use only once"*/}
          {/*    color="outline-gray"*/}
          {/*    disabled={isSubmitting}*/}
          {/*    isLoading={isSubmittingPayOnce}*/}
          {/*    onClick={handleUseOnlyOnce}*/}
          {/*  />*/}
          {/*)}*/}

          <Button
            className={cn(styles.addAnotherCard, "w-100")}
            text="Save"
            type="submit"
            disabled={isSubmittingPayOnce}
            isLoading={isSubmitting}
          />
        </form>
      </div>
    </Modal>
  );
};
