import { ChangeEventHandler, useState } from "react";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";

type PatientKey =
  | "issues"
  | "therapy_types"
  | "therapist_preferences"
  | "preferred_sexualities"
  | "preferred_languages";

export const useSelectPreferences = (
  patientFieldName: PatientKey,
  noPrefernceKey = "none"
) => {
  const patient = useAuth().user as Patient;
  const [selected, setSelected] = useState(
    patient[patientFieldName].length
      ? patient[patientFieldName]
      : [noPrefernceKey]
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, checked } = event.currentTarget;

    if (name === noPrefernceKey && checked) {
      setSelected([noPrefernceKey]);
      return;
    }

    if (name !== noPrefernceKey && !checked) {
      setSelected((prevSelected) => {
        const nextSelected = prevSelected.filter((value) => value !== name);

        return nextSelected.length > 0 ? nextSelected : [noPrefernceKey];
      });
    }

    if (name !== noPrefernceKey && checked) {
      setSelected((prevSelected) => [
        ...prevSelected.filter((v) => v !== noPrefernceKey),
        name,
      ]);
    }
  };

  return { selected: selected as string[], handleChange };
};
