import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { signupPath } from "../commonLinks";

export type Section = {
  title: string;
  content: ReactNode;
};

export const sections: Section[] = [
  {
    title: "What is TheraPass?",
    content:
      "TheraPass is an on-demand mental health and wellness platform connecting people from all walks of life with the licensed professional therapist of their choice.",
  },
  {
    title: "Who will be helping me?",
    content:
      "After you sign up, we will match you to an available therapist who fits your objectives, preferences, and the type of issues you are dealing with. Different therapists have different approaches and areas of focus, so it's important to find the right person who can achieve the best results for you. We have found that we are able to provide a successful match most of the time; however, if you start the process and you feel your therapist isn't a good fit for you, you may elect to be matched to a different therapist.",
  },
  {
    title: "How much does it cost?",
    content:
      "It is based on your location, preferences, and therapist availability. You can enter what price range is more preferable for you.",
  },
  {
    title: "How will I communicate with my therapist?",
    content: (
      <>
        <p>You can get therapy in four ways:</p>
        <ul>
          <li>Exchanging messages with your therapist</li>
          <li>Chatting live with your therapist</li>
          <li>Speaking over the phone with your therapist</li>
          <li>Video conferencing with your therapist</li>
        </ul>
        <p>
          You can use different ways at different times as you wish, based on
          your needs, availability, and convenience.
        </p>
      </>
    ),
  },
  {
    title: "How long can I use TheraPass?",
    content:
      "This depends on your needs and varies a lot from one person to another. Some people feel they get most of the value after just a few weeks, while others prefer to stick to the program for an extended period of time. This is completely up to you.",
  },
  {
    title: "How can I be sure that this is an effective form of therapy?",
    content: (
      <>
        <p>
          There are many studies that confirm the effectiveness of the online
          medium for making life changes. For example, a study published by JMIR
          Publications and conducted by researchers from University of
          California - Berkeley, University of California - San Francisco, and
          the San Francisco General Hospital, concluded that “users of TheraPass
          experienced significantly reduced depression symptom severity after
          engaging with the platform.”
        </p>
        <p>
          Our confidence in the platform comes primarily from the feedback and
          testimonials we receive from users. Every day we are excited to hear
          from more people about the way this service helped them make a
          tremendous change in their lives.
        </p>
      </>
    ),
  },
  {
    title: "How is my privacy protected?",
    content:
      "We take your privacy and confidentiality seriously. We have built state-of-the-art technology, operations, and infrastructure with the goal of protecting your privacy and safeguarding the information you provide. It is important for us that you know exactly how your privacy and confidentiality is treated, for more information see our Privacy Policy.",
  },
  {
    title: "Can I stay anonymous?",
    content: (
      <>
        <p>
          When you sign up we do not ask you for your full name or contact
          information. You may pick any "nick name" which will identify you in
          the system. When you decide to start the therapy process, we will ask
          you for your contact information for emergency situations. This is
          kept safe in the system so it can be used if your therapist believes
          that you or someone else might be in danger.
        </p>
        <p>
          Your therapist may request additional specific information about you
          as required by their licensing board guidelines. This information is
          kept strictly confidential and protected by our secure system and
          patient data protection laws.
        </p>
      </>
    ),
  },
  {
    title: "How can I get started with TheraPass?",
    content: (
      <>
        Click <Link to={`/patient${signupPath}`}>here</Link> to get started.
      </>
    ),
  },
  {
    title:
      "I'm a licensed therapist. How can I provide services using TheraPass?",
    content: (
      <>
        Please start the application process by clicking{" "}
        <Link to={`/therapist${signupPath}`}>here</Link>.
      </>
    ),
  },
];
