import { Modal } from "common/components/Modal/Modal";
import Button from "common/UIKit/Button";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import useChangeEmail from "./useChangeEmail";
import Input from "common/UIKit/Input";
import isEmail from "validator/lib/isEmail";
import styles from "./ChangeEmailPopup.module.scss";

export const ChangeEmailPopup = () => {
  const { isOpen, onClose, onSubmit, register, errors } = useChangeEmail();

  return (
    <Modal className={styles.root} visible={isOpen}>
      <form onSubmit={onSubmit} className={styles.popup}>
        <button className={styles.popupCloseButton} onClick={onClose}>
          <CloseIcon />
        </button>
        <h3 className={styles.popupTitle}>Change email</h3>
        <sub className={styles.popupSubtitle}>New email</sub>
        <Input
          type="email"
          placeholder=""
          {...register("email", {
            required: "This field is required.",
            validate: (value) =>
              !value || isEmail(value) || "This email is incorrect.",
          })}
          error={errors.email?.message}
          className={styles.popupInput}
        />
        <Button className={styles.popupButton} type="submit">
          Change
        </Button>
      </form>
    </Modal>
  );
};
