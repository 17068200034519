import { ReactComponent as Wallet } from "assets/images/icons/wallet-black.svg";
import { ReactComponent as Dollar } from "assets/images/icons/dollar-without-line.svg";
import { ReactComponent as Document } from "assets/images/icons/document-list.svg";
import { pathStripe } from "../pages/EditStripe/EditStripe";
import { pathFees } from "../pages/EditFees";
import { fullPaymentsPath, ProfileCard } from "../../helpers";
import { pathRateCards } from "../pages/EditRateCards/EditRateCards";

export const paymentsCardData: ProfileCard[] = [
  {
    id: 1,
    name: "Fees",
    description: "How much do you charge? Specify forms of payment you accept.",
    Icon: Wallet,
    link: fullPaymentsPath(`${pathFees}/edit`),
  },
  {
    id: 2,
    name: "Stripe Connect",
    description: "Manage your Stripe account.",
    Icon: Dollar,
    link: fullPaymentsPath(`${pathStripe}/edit`),
    props: "dollar",
  },
  {
    id: 3,
    name: "Rate Cards",
    description: "Create Rate Cards to offer your services to the clients.",
    Icon: Document,
    link: fullPaymentsPath(`${pathRateCards}/edit`),
    props: "dollar",
  },
];
