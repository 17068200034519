import React, { FC } from "react";
import { Controller } from "react-hook-form";

import { ETHNICITY_OPTIONS } from "constants/options";
import Radiobutton from "common/UIKit/Radiobutton";
import { EditPatientInformationForm } from "../useEditPatientInformation";

export const Ethnicity: FC<EditPatientInformationForm> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="preferred_ethnicities"
      render={({ field: { value, onChange } }) => (
        <>
          {ETHNICITY_OPTIONS.map((item) => (
            <Radiobutton
              key={item.value}
              className="patient-step__radio"
              name="ethnicity"
              id={item.value}
              isOutlined={false}
              checked={item.value === value}
              onChange={() => onChange(item.value)}
            >
              {item.label}
            </Radiobutton>
          ))}
        </>
      )}
    />
  );
};
