import { ReactComponent as IconCheck } from "assets/images/icons/verified.svg";
import { ReactComponent as IconCross } from "assets/images/icons/gray-cross.svg";
import styles from "./CompareSection.module.scss";
import { useResponsive } from "hooks/useResponsive";

export const CompareSection = () => {
  const { isMobile } = useResponsive();
  return (
    <div className={styles.root}>
      <section className={styles.inner}>
        <h3 className={styles.title}>Why use TheraPass?</h3>
        <div className={styles.row}>
          <ul className={styles.list}>
            <li className={styles.item}>Reach a therapist immediately</li>
            <li className={styles.item}>No fees or subscriptions</li>
            <li className={styles.item}>Only pay for the therapy you use</li>
            <li className={styles.item}>
              Flexible therapy (video, chat, phone)
            </li>
            <li className={styles.item}>
              Access therapists who accept your insurance
            </li>
          </ul>
          <ul
            className={`${styles.list} ${styles.listColumn} ${styles.active}`}
          >
            <li className={styles.item}>TheraPass</li>
            <li className={styles.item}>
              <IconCheck />
            </li>
            <li className={styles.item}>
              <IconCheck />
            </li>
            <li className={styles.item}>
              <IconCheck />
            </li>
            <li className={styles.item}>
              <IconCheck />
            </li>
            <li className={styles.item}>
              <IconCheck />
            </li>
          </ul>
          <ul className={`${styles.list} ${styles.listColumn}`}>
            <li className={styles.item}>BetterHelp</li>
            <li className={styles.item}>
              <IconCross />
            </li>
            <li className={styles.item}>
              <IconCross />
            </li>
            <li className={styles.item}>
              <IconCross />
            </li>
            <li className={styles.item}>
              <IconCheck />
            </li>
            <li className={styles.item}>
              <IconCross />
            </li>
          </ul>
          {!isMobile && (
            <ul className={`${styles.list} ${styles.listColumn}`}>
              <li className={styles.item}>TalkSpace</li>
              <li className={styles.item}>
                <IconCross />
              </li>
              <li className={styles.item}>
                <IconCross />
              </li>
              <li className={styles.item}>
                <IconCross />
              </li>
              <li className={styles.item}>
                <IconCheck />
              </li>
              <li className={styles.item}>
                <IconCheck />
              </li>
            </ul>
          )}
        </div>
      </section>
    </div>
  );
};
