import { CSSProperties, useEffect, useState } from "react";
import { useDrag } from "@use-gesture/react";

import { useResponsive } from "./useResponsive";

type Config = {
  moveDistanceToClose?: number;
  speedToClose?: number;
  onClose?: () => void;
};

export const useModalDrag = ({
  moveDistanceToClose = 250,
  onClose = () => {},
}: Config) => {
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState(0);
  const [percentageToClose, setPercentageToClose] = useState(0);
  const { isMobile } = useResponsive();

  const bind = useDrag(
    ({ last, movement: [, my] }) => {
      setIsDragging(true);
      setOffset(my > 0 ? my : 0);

      if (last) {
        setIsDragging(false);
        if (my > moveDistanceToClose) {
          onClose();
        }
      }
    },
    {
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  useEffect(() => {
    if (isDragging) {
      setPercentageToClose(Math.min(offset / moveDistanceToClose, 1));
    } else {
      setPercentageToClose(0);
    }
  }, [offset, moveDistanceToClose, isDragging]);

  const transformStyle: CSSProperties = {
    transform: `translateY(${isDragging ? offset : 0}px)`,
    touchAction: "none",
  };

  return {
    bind: isMobile ? bind : () => ({}),
    isDragging,
    offset,
    percentageToClose,
    transformStyle: isMobile ? transformStyle : {},
  };
};
