import { useFormContext } from "react-hook-form";
import { ToggleCheckbox } from "common/UIKit/ToggleCheckbox";
import styles from "./AvailabilitySwitches.module.scss";

interface Props {
  isChatAccess: boolean;
}

export const AvailabilitySwitches = ({ isChatAccess }: Props) => {
  const { register } = useFormContext();

  return (
    <div className={styles.backdrop}>
      <h2>Available therapy modes</h2>
      <div className={styles.field}>
        <p>In-person session</p>
        <ToggleCheckbox {...register("in_person_session_availability")} />
      </div>
      <div className={styles.field}>
        <p>Telephone session</p>
        <ToggleCheckbox {...register("telephone_session_availability")} />
      </div>
      <div className={styles.field}>
        <p>Video session</p>
        <ToggleCheckbox {...register("video_session_availability")} />
      </div>
      <div className={styles.field}>
        <p>Chat Connect sessions</p>
        <ToggleCheckbox
          disabled={!isChatAccess}
          {...register("online_chat_sessions_availability")}
        />
      </div>
    </div>
  );
};
