import { proxy } from "valtio";
import { HttpService } from "./httpService";
import { Patient, PatientId } from "../types/patient.types";
import { TherapistFile } from "../types/therapist.types";

interface PatientProfile extends Patient {
  avatar?: TherapistFile | null;
}

type Cache = { [key: PatientId]: PatientProfile };

class PatientProfileService extends HttpService {
  private cache: Cache = {};
  isFetching = false;
  currentProfile: PatientProfile | null = null;

  async getById(id: PatientId): Promise<Patient> {
    this.isFetching = true;
    if (this.cache[id]) {
      this.currentProfile = this.cache[id];
    } else {
      this.currentProfile = null;
    }
    const patient: Patient = await this.http.get(`/v1/patients/${id}/`);

    this.cache[id] = {
      ...patient,
    };

    this.isFetching = false;

    return (this.currentProfile = this.cache[id]);
  }
}

export const patientProfileService = proxy(new PatientProfileService());
