import { proxy } from "valtio";
import { HttpService } from "./httpService";

class ConnectStripeService extends HttpService {
  private prefix = "v1/therapists/";
  private connectEndpoint = "connect-to-stripe/";
  private disconnectEndpoint = "disconnect-from-stripe/";
  isFetching = false;

  constructor(args?: any) {
    super({ ...args });
  }

  async connect(id: string) {
    this.isFetching = true;
    try {
      const response = await this.http.post(
        `${this.prefix}${id}/${this.connectEndpoint}`
      );
      this.isFetching = false;

      return response;
    } catch (error) {
      this.isFetching = false;
    }
  }

  async disconnect(id: string) {
    this.isFetching = true;
    try {
      await this.http.post(`${this.prefix}${id}/${this.disconnectEndpoint}`);
      this.isFetching = false;
    } catch (error) {
      this.isFetching = false;
    }
  }
}

export const connectStripeService = proxy(new ConnectStripeService({}));
