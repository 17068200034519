type Feature = {
  name: string;
  value: boolean;
};

export const features: Feature[][] = [
  [
    {
      name: "Easier access for clients",
      value: true,
    },
    {
      name: "Set your own fees",
      value: true,
    },
    {
      name: "Control your scheduling",
      value: true,
    },
    {
      name: "Move up in therapist search results",
      value: false,
    },
    {
      name: "More client referrals",
      value: false,
    },
    {
      name: "Communicate via Chat Connect",
      value: false,
    },
    {
      name: "Set real-time availability via Chat Connect",
      value: false,
    },
    {
      name: "Guarded privacy via Chat Connect",
      value: false,
    },
    {
      name: "Create custom offers for patient billing via Chat Connect",
      value: false,
    },
  ],
  [
    {
      name: "Easier access for clients",
      value: true,
    },
    {
      name: "Set your own fees",
      value: true,
    },
    {
      name: "Control your scheduling",
      value: true,
    },
    {
      name: "Move up in therapist search results",
      value: true,
    },
    {
      name: "More client referrals",
      value: true,
    },
    {
      name: "Communicate via Chat Connect",
      value: true,
    },
    {
      name: "Set real-time availability via Chat Connect",
      value: true,
    },
    {
      name: "Guarded privacy via Chat Connect",
      value: true,
    },
    {
      name: "Create custom offers for patient billing via Chat Connect",
      value: true,
    },
  ],
  [
    {
      name: "Easier access for clients",
      value: true,
    },
    {
      name: "Set your own fees",
      value: true,
    },
    {
      name: "Control your scheduling",
      value: true,
    },
    {
      name: "Move up in therapist search results",
      value: true,
    },
    {
      name: "More client referrals",
      value: true,
    },
    {
      name: "Communicate via Chat Connect",
      value: false,
    },
    {
      name: "Set real-time availability via Chat Connect",
      value: false,
    },
    {
      name: "Guarded privacy via Chat Connect",
      value: false,
    },
    {
      name: "Create custom offers for patient billing via Chat Connect",
      value: false,
    },
  ],
];
