import { EditHeader } from "common/components/EditHeader";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, Controller, Path } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { educationService } from "services/educationService";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { Education } from "types/education.types";
import { fullProfilePath } from "../../Profile";
import { AxiosResponse } from "axios";
import { goThroughErrors } from "common/utils";
import styles from "./AddEducation.module.scss";
import { toast } from "react-toastify";
import { useResponsive } from "hooks/useResponsive";

type Inputs = {
  year_graduated: number | null;
  degree_diploma: string;
  school_graduated: string;
};

const defaultValues: Inputs = {
  year_graduated: null,
  degree_diploma: "",
  school_graduated: "",
};

export const AddEducation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  useDashboardNavigation(`${id ? "Edit" : "Add"} Education`, {
    goBack: "pop_path",
    isNavigationHidden: true,
  });
  const auth = useAuth();
  const { isMobile } = useResponsive();
  const user = auth.user as Therapist | null;
  const [education, setEducation] = useState<Education | null>(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    reset,
    setError,
  } = useForm<Inputs>({ defaultValues });

  useEffect(() => {
    if (id && user?.id) {
      (async () => {
        await educationService
          .getById(user.id, Number(id))
          .then(setEducation)
          .catch((err) => {
            console.log(err);
          });
      })();
    }
  }, [id, user?.id]);

  useEffect(() => {
    if (id && education) {
      reset({
        ...(education.degree_diploma && {
          degree_diploma: education.degree_diploma,
        }),
        ...(education.school_graduated && {
          school_graduated: education.school_graduated,
        }),
        ...(education.year_graduated && {
          year_graduated: education.year_graduated,
        }),
      });
    }
  }, [education, id, reset]);

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    if (!user) return;

    try {
      education?.id
        ? await educationService.patch(user.id, education.id, data)
        : await educationService.create(user.id, data);
      navigate(`${fullProfilePath}/education/edit`);
      toast("Information has been updated", {
        type: "info",
        autoClose: isMobile ? 3000 : 5000,
        position: isMobile ? "top-center" : "top-right",
      });
    } catch (error) {
      const { data, status } = error as AxiosResponse;
      if (status === 400) {
        goThroughErrors(data, (fieldName, errorMessage) =>
          setError(fieldName as Path<Inputs>, {
            message: errorMessage,
            type: "custom",
          })
        );
      }
    }
  };

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
        <stepsUi.Grid>
          <stepsUi.Row label="School graduated" className={styles.row}>
            <Input
              type="text"
              placeholder=""
              {...register("school_graduated")}
              error={errors.school_graduated?.message}
            />
          </stepsUi.Row>
          <stepsUi.Row label="Degree / Diploma" className={styles.row}>
            <Input
              type="text"
              placeholder=""
              {...register("degree_diploma")}
              error={errors.degree_diploma?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Row label="Year graduated" className={styles.lastRow}>
          <Controller
            name="year_graduated"
            control={control}
            rules={{
              validate: {
                nonNegative: (value) =>
                  value === null ||
                  (value && value >= 0) ||
                  "Values must be non-negative",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder="YYYY"
                type="text"
                error={errors.year_graduated?.message}
                maxLength={4}
                isValid={!error}
                value={field?.value ? field.value : ""}
                name={field.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const inputValue = e.target.value;

                  if (!isNaN(+inputValue)) {
                    field.value = inputValue === "" ? 0 : +inputValue;
                    field.onChange(field.value);
                  }
                }}
              />
            )}
          />
        </stepsUi.Row>
        <Button
          className={styles.button}
          type="submit"
        >
          Save
        </Button>
      </form>
    </section>
  );
};
