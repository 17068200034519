import { Patient, PatientFile, PatientId } from "types/patient.types";
import { CrudService } from "./crudService";
import { ListResponse } from "types/common.types";
import { Nullable } from "common/utils";
import axios from "axios";
import { authService } from "./authService";

export class PatientService extends CrudService<PatientId, Patient> {
  private static filesEndpoint = "/files";
  private static prefix = "/v1/patients";
  file: PatientFile | null = null;
  fetchedFile = false;
  processingFile = false;
  uploadProgress: Nullable<number> = null;
  uploadStartTime: Nullable<number> = null;

  constructor(args?: any) {
    super({
      ...args,
      prefix: PatientService.prefix,
    });
  }

  async changePassword() {
    try {
      return await this.http.post("/v1/change-password/");
    } catch (error) {
      console.error(error);
    }
  }

  async getFile(useCache = true): Promise<PatientFile | null> {
    if (!this.fetchedFile || !useCache) {
      this.processingFile = true;
      const data = (await this.http.get(
        `${PatientService.prefix}/${this.entity?.id}${PatientService.filesEndpoint}/`
      )) as ListResponse<PatientFile[]>;

      this.file = data.results[0];
      this.processingFile = false;
      this.fetchedFile = true;
    }

    return this.file;
  }

  async deleteFile(patientId: PatientId, id: number) {
    await this.http.delete<any, null>(
      `${PatientService.prefix}/${patientId}${PatientService.filesEndpoint}/${id}/`
    );
    this.file = null;
    await authService.getCurrentUser();

    return this.file;
  }

  async changeFile(patientId: PatientId, file: File, fileId?: number) {
    const formData = new FormData();
    formData.append("s3key", file.name);
    formData.append("mime", file.type);
    this.uploadProgress = 0;
    this.uploadStartTime = Date.now();

    const config = {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        this.uploadProgress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        if (this.uploadProgress === 100) {
          setTimeout(() => {
            this.uploadProgress = null;
          }, 500);
        }
      },
      headers: {
        "content-type": file.type,
      },
    };

    return await (fileId
      ? this.http.put<any, PatientFile>(
          `${PatientService.prefix}/${patientId}${PatientService.filesEndpoint}/${fileId}/`,
          formData
        )
      : this.http.post<any, PatientFile>(
          `${PatientService.prefix}/${patientId}${PatientService.filesEndpoint}/`,
          formData
        )
    ).then(async (results) => {
      await axios.put(results.s3_upload_presign, file, config);
      await authService.getCurrentUser();

      return results;
    });
  }
}

export const patientService = new PatientService();
patientService.init();
