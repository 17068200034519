import { DashboardLayoutDesktopHeader } from "common/components/DashboardLayout/DesktopHeader";
import { DashboardLayoutMobileHeader } from "common/components/DashboardLayout/MobileHeader";
import Button from "common/UIKit/Button";
import { useWindowResize } from "hooks/useWindowResize";
import { Block } from "pages/Patients/PatientsDashboard/Tests/TakeTest/components/Block";
import { TestProvider } from "providers/TestProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { testProcessService } from "services/testProcessService";
import { patientProfileService } from "services/patientProfileService";
import { useEffect } from "react";
import { therapistName } from "common/utils";
import { useAuth } from "hooks/useAuth";
import { isPatient } from "services/authService";
import { testAttemptService } from "services/testAttemptService";
import { format } from "date-fns";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./TherapistPatientTest.module.scss";

export const TherapistPatientTest = () => {
  const navigate = useNavigate();
  const { test } = useSnapshot(testProcessService);
  const { entity } = useSnapshot(testAttemptService);
  const { userId } = useParams();
  const { user } = useAuth();
  const { currentProfile } = useSnapshot(patientProfileService);
  const { width } = useWindowResize();
  const isSmallWindow = width < 1200;
  const nonIntroBlocks = test?.blocks;
  const id = user && isPatient(user) ? user.id : userId;
  useDocumentTitle("TheraPass - Test");

  useEffect(() => {
    if (id) {
      patientProfileService.getById(id);
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <TestProvider>
      <div className={styles.root}>
        {!isSmallWindow ? (
          <DashboardLayoutDesktopHeader withLogo className={styles.logo} />
        ) : (
          <DashboardLayoutMobileHeader tabs={[]} />
        )}
        <section className={styles.wrapper}>
          <div className={styles.row}>
            <button
              className={styles.goBackButton}
              onClick={() => navigate(-1)}
            >
              <div className={styles.angle} />
            </button>
            <h1 className={styles.title}>{test?.name}</h1>
          </div>
          {entity?.finished_at && (
            <time className={styles.date}>
              {`Completed: ${format(
                new Date(entity.finished_at),
                "MMMM d, yyyy h:mma"
              )}`}
            </time>
          )}
          {user && !isPatient(user) && (
            <p className={styles.text}>
              Answers of{" "}
              {therapistName({
                first_name: currentProfile?.user.first_name,
                last_name: currentProfile?.user.last_name,
              })}
              :
            </p>
          )}
          {nonIntroBlocks?.map((block) => (
            <Block
              placeholder=" "
              block={block}
              key={block.id}
              displayAll={true}
              disabled
            />
          ))}
          <Button
            className={styles.btn}
            type="button"
            text={
              user && isPatient(user)
                ? "Return to Tests"
                : "Return to Patient Details"
            }
            onClick={() => navigate(-1)}
          />
        </section>
      </div>
    </TestProvider>
  );
};
