import { Route, Routes } from "react-router-dom";
import { PatientsHome } from "./PatientsHome";
import { PatientSteps, stepsPath } from "./PatientSteps";
import { ErrorPage } from "../ErrorPage";
import { PatientsDashboard, patientsDashboardPath } from "./PatientsDashboard";
import { BuyTest, buyTestPath } from "./PatientsDashboard/Tests/BuyTest";
import { TherapistPatientTest } from "pages/Therapists/TherapistPatientTest/TherapistPatientTest";

export { patientsPath } from "./patientsPath";

export const Patients = () => {
  return (
    <Routes>
      <Route path="/" element={<PatientsHome />} />
      <Route path={`${stepsPath}/*`} element={<PatientSteps />} />
      <Route
        path={`${patientsDashboardPath}/*`}
        element={<PatientsDashboard />}
      />
      <Route
        path={`${patientsDashboardPath}/tests${buyTestPath}`}
        element={<BuyTest />}
      />
      <Route path={`/test/:id`} element={<TherapistPatientTest />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
