import { ChangeEvent, useEffect } from "react";
import Input from "common/UIKit/Input";
import { stepsUi } from "../../components";
import {
  UseFormRegister,
  FieldErrors,
  Control,
  UseFormWatch,
  UseFormSetValue,
  Controller,
  FieldValues,
} from "react-hook-form";
import { VERIFIED_CREDENTIAL_OPTIONS } from "constants/options";
import { Inputs } from "../TherapistStep3_1";
import Radiobutton from "common/UIKit/Radiobutton/Radiobutton";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import {
  ORGANIZATION_LABEL,
  ORGANIZATION_FIELD,
  NUMBER_FIELD,
  NUMBER_LABEL,
  NO_LICENSE_YEAR,
} from "constants/fields";
import styles from "./TherapistStepLicense.module.scss";

interface Props {
  register: UseFormRegister<Inputs>;
  errors: FieldErrors;
  control: Control<Inputs, FieldValues>;
  watch: UseFormWatch<Inputs>;
  setValue: UseFormSetValue<Inputs>;
}

export const TherapistStepNoLicensed = ({
  errors,
  register,
  watch,
  setValue,
  control,
}: Props) => {
  const credentialWatcher = watch("no_license_type");

  useEffect(() => {
    VERIFIED_CREDENTIAL_OPTIONS.filter(
      (option) => option.value !== credentialWatcher
    ).forEach(({ value }) => {
      setValue(`no_license_${ORGANIZATION_FIELD[value]}`, "");
      setValue(`no_license_${NUMBER_FIELD[value]}`, "");
    });
  }, [credentialWatcher, setValue]);

  return (
    <>
      <stepsUi.Row label="Verified Credential">
        <stepsUi.Row>
          <stepsUi.Line>
            {VERIFIED_CREDENTIAL_OPTIONS.map((c) => (
              <Radiobutton
                key={c.value}
                isOutlined
                className={styles.radio}
                {...register("no_license_type")}
                value={c.value}
              >
                {c.label}
              </Radiobutton>
            ))}
          </stepsUi.Line>
          <ErrorMessage>
            {errors.verified_credential_type?.message}
          </ErrorMessage>
        </stepsUi.Row>
      </stepsUi.Row>
      {credentialWatcher && (
        <>
          <stepsUi.Row
            label={ORGANIZATION_LABEL[credentialWatcher]}
            className={styles.row}
          >
            <Input
              type="text"
              {...register(
                `no_license_${ORGANIZATION_FIELD[credentialWatcher]}`
              )}
              error={
                errors.main_other_credential_for_no_license?.[
                  ORGANIZATION_FIELD[credentialWatcher]
                ]?.message
              }
              maxLength={160}
            />
          </stepsUi.Row>
          <stepsUi.Row
            label={NUMBER_LABEL[credentialWatcher]}
            className={styles.row}
          >
            <Input
              type="text"
              {...register(`no_license_${NUMBER_FIELD[credentialWatcher]}`)}
              error={
                errors.main_other_credential_for_no_license?.[
                  NUMBER_FIELD[credentialWatcher]
                ]?.message
              }
              placeholder={credentialWatcher === "membership" ? "ID" : ""}
              maxLength={160}
            />
          </stepsUi.Row>
          <stepsUi.Grid className={styles.row}>
            <stepsUi.Row
              className={styles.withoutLabel}
              label={NO_LICENSE_YEAR[credentialWatcher]}
            >
              <Controller
                name="no_license_year"
                control={control}
                rules={{
                  validate: {
                    nonNegative: (value) =>
                      value === null ||
                      (value && value >= 0) ||
                      "Values must be non-negative",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    placeholder=""
                    type="text"
                    error={
                      errors.main_other_credential_for_no_license?.year?.message
                    }
                    maxLength={4}
                    isValid={!error}
                    value={field?.value ? field.value : undefined}
                    name={field.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value;

                      if (!isNaN(+inputValue)) {
                        field.value = inputValue === "" ? 0 : +inputValue;
                        field.onChange(field.value);
                      }
                    }}
                  />
                )}
              />
            </stepsUi.Row>
          </stepsUi.Grid>
        </>
      )}
    </>
  );
};
