import { FC } from "react";
import classNames from "classnames";

import styles from "./SimpleCard.module.scss";

type Props = {
  className?: string;
};

export const SimpleCard: FC<Props> = ({ className, children }) => {
  return <div className={classNames(styles.root, className)}>{children}</div>;
};
