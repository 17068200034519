import { createContext, Dispatch, SetStateAction } from "react";
import { User } from "services/authService";
import { BaseUser, UserAuthStatus, UserType } from "types/auth.types";

interface IAuthContext {
  baseUser: BaseUser | null;
  user: User | null;
  authStatus: UserAuthStatus;
  prevSessionUserType: UserType | null;
  isProfileCompleted: boolean;
  setIsProfileCompleted: Dispatch<SetStateAction<boolean>>;
  isSendingMessage: boolean;
  setIsSendingMessage: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<IAuthContext>({
  baseUser: null,
  user: null,
  authStatus: "unknown",
  prevSessionUserType: null,
  isProfileCompleted: false,
  setIsProfileCompleted: () => {},
  isSendingMessage: false,
  setIsSendingMessage: () => {},
});
