import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useNavigate } from "react-router-dom";
import { EditHeader } from "common/components/EditHeader";
import Button from "common/UIKit/Button";
import { ReactComponent as StripeIcon } from "assets/images/stripe.svg";
import { ReactComponent as CheckIcon } from "assets/images/check.svg";
import cn from "classnames";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { connectStripeService } from "services/stripeConnectService";
import { ErrorPage } from "pages/ErrorPage";
import { useSnapshot } from "valtio";
import { authService, stripeStatus } from "services/authService";
import { useModal } from "hooks/useModal";
import { fullPaymentsPath } from "../../Payments";
import { onRequestToNewWindow } from "common/utils/async";
import styles from "./EditStripe.module.scss";
import { useEffect } from "react";

export const pathStripe = "/stripe";

export const EditStripe = () => {
  const { open } = useModal("DISCONNECT_STRIPE");
  const navigate = useNavigate();
  useDashboardNavigation("Edit Stripe Connect", {
    goBack: () => navigate(fullPaymentsPath),
    isNavigationHidden: true,
  });
  const user = useAuth().user as Therapist;
  const { isFetching } = useSnapshot(connectStripeService);

  useEffect(() => {
    const intervalRequest = setInterval(
      async () => await authService.getCurrentUser(),
      15000
    );

    return () => clearInterval(intervalRequest);
  }, []);

  const onConnectClick = async () => {
    try {
      await onRequestToNewWindow(connectStripeService.connect(user.id));
      await authService.getCurrentUser();
    } catch (error) {}
  };

  if (!user?.id) return <ErrorPage />;

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <h2 className={styles.title}>Your Stripe Connect Status</h2>
      <div className={styles.info}>
        <StripeIcon />
        <div
          className={cn(
            styles.status,
            stripeStatus(user, "completed") && styles.active,
            stripeStatus(user, "not_completed") && styles.warning
          )}
        >
          <div className={styles.icon}>
            {stripeStatus(user, "completed") ? (
              <CheckIcon />
            ) : (
              <div className={styles.cross} />
            )}
          </div>
          {stripeStatus(user, "completed") && "Connected to Stripe Account"}
          {stripeStatus(user, "not_completed") && (
            <>
              Stripe Registration is not complete. <br /> Select "Manage Stripe" below to complete.
            </>
          )}
          {stripeStatus(user, "disconnected") &&
            "Not Connected to Stripe Account"}
        </div>
      </div>
      <div className={styles.row}>
        {!stripeStatus(user, "disconnected") ? (
          <>
            {!stripeStatus(user, "completed") && (
              <Button
                className={styles.button}
                onClick={onConnectClick}
                type="button"
                isLoading={isFetching}
              >
                Manage Stripe
              </Button>
            )}
            <Button
              className={styles.button}
              color="outline-gray"
              onClick={open}
              isLoading={isFetching}
              type="button"
            >
              Disconnect Stripe
            </Button>
          </>
        ) : (
          <Button
            className={styles.button}
            onClick={onConnectClick}
            type="button"
            isLoading={isFetching}
          >
            Connect to Stripe
          </Button>
        )}
      </div>
    </section>
  );
};
