import { useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import { useSnapshot } from "valtio";
import { AxiosResponse } from "axios";
import { FieldValues, SubmitHandler, useForm, UseFormProps } from "react-hook-form";
import { authService } from "services/authService";
import { DeepPartial, getDeepDiff, goThroughErrors } from "common/utils";
import { BaseUser } from "types/auth.types";
import { Patient } from "types/patient.types";
import {
  makeDefaultValues,
  makeDiffToSend,
  PatientFields,
} from "../../PatientSteps/util";
import { esaProfileService } from "services/esaProfileService";

type Options<TInputs> = {
  toFields?: (
    v: DeepPartial<Patient> & DeepPartial<BaseUser>
  ) => DeepPartial<TInputs>;
  toApi?: (v: DeepPartial<TInputs>) => DeepPartial<Patient>;
  mimeGroup?: string[];
  submitWithoutButton?: boolean;
  useFormProps?: UseFormProps<TInputs>;
};

export const useProfileForm = <TInputs extends FieldValues>(
  coveredFields: PatientFields,
  onSuccess?: () => void,
  updateSection?: string,
  options?: Options<TInputs>,
) => {
  const patient = useSnapshot(authService).user as Patient;
  const { toApi, toFields } = options || {};

  const defaultValues: any = useMemo(() => {
    const df = makeDefaultValues(patient, coveredFields);
    return toFields?.(df) || df;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm<TInputs>({ ...options?.useFormProps, defaultValues, mode: "onChange" });

  const submitHandler: SubmitHandler<TInputs> = async (values: any) => {
    const toSend =
      toApi?.(values as any) || makeDiffToSend(values, coveredFields);
    const diff = getDeepDiff(toSend, patient);

    const nothingToSend = Object.values(diff).every((v) => {
      const t = typeof v;
      if (t === "boolean") {
        return false;
      }
      if (t === "object") {
        return isEmpty(v);
      }
      return !v;
    });
    // if (nothingToSend) {
    //   return;
    // }

    try {
      await authService.patch({ diff });
      switch (updateSection) {
        case "client":
          await esaProfileService.updateClientData(toSend);
          break;
        case "billing address":
          await esaProfileService.updateClientAddress(toSend, 2);
          break;
        case "shipping address":
          await esaProfileService.updateClientAddress(toSend, 1);
          break;
        default:
          break;
      }
      onSuccess && onSuccess();
    } catch (e) {
      const { data, status } = e as AxiosResponse;
      if (status === 400) {
        goThroughErrors(data, (fieldName, errorMessage) =>
          setError(fieldName as any, { message: errorMessage, type: "custom" })
        );
      }
    }
  };

  useEffect(() => {
    if (options?.submitWithoutButton) {
      //@ts-ignore
      const subscription = watch(handleSubmit(submitHandler)) as Path;
      return () => subscription.unsubscribe();
    }
  }, [handleSubmit, watch, options]);

  return {
    register,
    handleSubmit: handleSubmit(submitHandler),
    control,
    errors,
    isSubmitting,
    setValue,
  };
};
