import { FormatProfileField } from "common/components/FormatProfileField";
import { FC } from "react";

interface Props {
  label: string;
  isVisible?: boolean;
}

export const TabItemBlock: FC<Props> = ({ children, label, isVisible }) => {
  return children || isVisible ? (
    <p className="patients-therapist-tabs__subject">
      {label}{" "}
      <FormatProfileField shouldLimitLength={false}>
        {children}
      </FormatProfileField>
    </p>
  ) : null;
};
