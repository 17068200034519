import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Typography.module.scss";

export const Typography = ({
  variant,
  component,
  textAlign,
  className,
  children,
}) => {
  const Component = component || variant;
  return (
    <Component
      className={classNames(
        className,
        styles[variant],
        styles[`text-align-${textAlign}`]
      )}
    >
      {children}
    </Component>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "p"]),
  component: PropTypes.element,
  textAlign: PropTypes.oneOf(["left", "center", "right", "justify"]),
  className: PropTypes.string,
  children: PropTypes.node,
};

Typography.defaultProps = {
  variant: "p",
  textAlign: "left",
};
