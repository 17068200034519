import Button from "common/UIKit/Button";
import styles from "./TestingSection.module.scss";
import { isTherapist } from "services/authService";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { useNavigate } from "react-router-dom";
import { history } from "utils/history";

export const TestingSection = () => {
  const { user } = useAuth();
  const { open } = useModal("LOGGED_AS_THERAPIST_BUY_TEST")
  const navigate = useNavigate()
  const handleClick = () => {
    if (!user) {
      localStorage.setItem("redirect_to", "/patients/dashboard/tests?tab_index=0");
      history.push("patient/login");
    } else {
      isTherapist(user) ? open() : navigate("/patients/dashboard/tests?tab_index=0");
    }
  }
  return (
    <section className={styles.root}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h4 className={styles.title}>Free psychological tests</h4>
          <p className={styles.subtitle}>
            Understand your mental health needs & personality with our library
            of psychological tests. Test for MMPI, Myers-Briggs, Anxiety, and
            more.
          </p>
        </div>
        <Button onClick={handleClick} className={styles.button}>
          Get started
        </Button>
      </div>
    </section>
  );
};
