import { FC } from "react";
import cn from "classnames";

import { CreditCard } from "types/creditCards.types";
import Button from "common/UIKit/Button";
import { useResponsive } from "hooks/useResponsive";
import { useModal } from "hooks/useModal";
import { CreditCardCompanyIcon } from "common/components/CreditCardCompanyIcon";
import { formatCardExpirationDate } from "common/utils";
import styles from "./CardInfo.module.scss";

interface Props {
  card?: CreditCard;
  className?: string;
}

export const CardInfo: FC<Props> = ({ card, className }) => {
  const { open } = useModal("CHOOSE_CREDIT_CARD");
  const { isMobile } = useResponsive();

  return (
    <div className={cn(styles.cardInfo, className)}>
      <div className={styles.card}>
        <CreditCardCompanyIcon
          className={styles.icon}
          company={card?.card_type}
        />
        <div>
          <h3>{`${card?.card_type} ending in ${card?.last_four}`}</h3>
          <p>
            {`Expires on ${
              card && formatCardExpirationDate(card.expires_at, true)
            }`}
          </p>
        </div>
      </div>
      <Button
        className={styles.changeCard}
        text={isMobile ? "Change" : "CHANGE"}
        onClick={open}
      />
    </div>
  );
};
