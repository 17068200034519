import { TherapistCollectionService } from "./therapistCollectionService";
import { FileType, TherapistFile, TherapistId } from "../types/therapist.types";
import { isFile } from "../common/utils";
import axios, { AxiosRequestConfig } from "axios";
import { authService } from "./authService";
import { ListResponse } from "../types/common.types";
import { proxy } from "valtio";

type TherapistFileId = number;

class FilesService extends TherapistCollectionService<
  TherapistFileId,
  TherapistFile
> {
  makeMainEndpoint = "/make_as_main_photo";
  uploadProgress: number | null = null;
  uploadStartTime: number = 0;
  abortUpload: (() => void) | null = null;
  uploadType?: string;

  constructor(props?: any) {
    super({ ...props, collectionString: "/files" });
  }

  async createFiles(
    therapistId: TherapistId,
    files: (File | Blob)[],
    fileType: FileType,
    license?: string
  ) {
    const requests = files.map(async (f) => {
      const formData = new FormData();
      formData.set("type", fileType);
      formData.append("s3key", isFile(f) ? f.name : "recording.webm");
      formData.append("mime", f.type);
      license && formData.append("license", license);
      this.uploadType = f.type;
      this.uploadProgress = 0;
      this.uploadStartTime = Date.now();

      const controller = new AbortController();
      this.abortUpload = () => {
        controller.abort();
        this.uploadProgress = null;
      };
      const config: AxiosRequestConfig<File | Blob> = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          if (progressEvent.loaded === progressEvent.total) {
            this.uploadProgress = null;
          } else {
            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          }
        },
        signal: controller.signal,
        headers: {
          "content-type": f.type,
        },
      };

      const newFile = await this.http.post<any, TherapistFile>(
        `${this.prefix}/${therapistId}${this.collectionString}/`,
        formData
      );

      try {
        await axios.put(newFile.s3_upload_presign, f, config);
      } catch (error: any) {
        if (error.message === "canceled") {
          await this.deleteFile(therapistId, newFile.id);
        }
        throw error;
      }

      return newFile;
    });

    await Promise.all(requests);
    this.abortUpload = null;

    const oldFiles = this.data;
    const newFiles = await this.list(therapistId, fileType, false);
    const createdFiles = newFiles.filter(
      (newFile) => !oldFiles.some((oldFile) => oldFile.id === newFile.id)
    );
    await authService.getCurrentUser();

    return createdFiles;
  }

  async list(
    therapistId: TherapistId,
    fileType?: FileType,
    useCache = true
  ): Promise<TherapistFile[]> {
    if (!this.isFetched || !useCache) {
      this.isProcessing = true;
      const data = (await this.http.get(
        `${this.prefix}/${therapistId}${this.collectionString}/`,
        {
          params: fileType ? { type: fileType } : undefined,
        }
      )) as ListResponse<TherapistFile[]>;
      this.data = data.results;
      this.isProcessing = false;
      this.isFetched = true;
    }

    return this.data;
  }

  async deleteFile(therapistId: TherapistId, fileId: TherapistFileId, type?: string) {
    await this.http.delete<any, null>(
      `${this.prefix}/${therapistId}${this.collectionString}/${fileId}/`
    );
    this.data = this.data.filter((file) => {
      if (type === "video") {
        return file.id !== fileId && file.mime.includes("image")
      } else {
        return file.id !== fileId
      }
    });
    return this.data;
  }

  async makeAsMainPhoto(therapistId: TherapistId, fileId: TherapistFileId) {
    await this.http.post(
      `${this.prefix}/${therapistId}${this.collectionString}/${fileId}${this.makeMainEndpoint}/`
    );
  }
}

export const filesService = proxy(new FilesService());
