import axios from "axios";
import { AxiosResponse, AxiosError } from "axios";
import { tokenService } from "services/tokenService";
import { generalLogicService } from "services/generalLogicService";
import { apiEndpoint } from "./env";
import { stringify } from "qs";

const authHeaderName = "authorization";

const interceptorSuccessCb = (response: AxiosResponse) => response.data;

const interceptorFailureCb = (err: AxiosError) => {
  if (!err.response) {
    return Promise.reject(err);
  }

  const { status, data } = err.response;
  const errorMessage = data.detail || err.message;
  const authProblem = [401, 403].includes(status);

  const serverError = status >= 500;
  if (serverError) {
    generalLogicService.setError({
      status,
      message: errorMessage,
      type: "critical",
    });
  }
  if (authProblem && !data?.detail.match("permission")) {
    tokenService.remove();
  }

  throw err.response;
};

const axiosInstanceFabric = ({ baseURL }: { baseURL: string }) => {
  const instance = axios.create({
    baseURL,
    paramsSerializer: (params) => stringify(params, { arrayFormat: "comma" }),
    // withCredentials: true,
  });

  instance.interceptors.response.use(
    interceptorSuccessCb,
    interceptorFailureCb
  );

  const clear = tokenService.sync((token) => {
    if (token) {
      instance.defaults.headers.common[authHeaderName] = `Bearer ${token}`;
    } else {
      delete instance.defaults.headers.common[authHeaderName];
    }
  });

  return {
    instance,
    clear,
  };
};
export const http = axiosInstanceFabric({ baseURL: apiEndpoint }).instance;

export const createSource = () => axios.CancelToken.source();
