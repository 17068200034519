import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSnapshot } from "valtio";

import { ReactComponent as PlusBig } from "assets/images/icons/plus-big.svg";
import { ReactComponent as StarFill } from "assets/images/icons/star-fill.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";

import Button from "common/UIKit/Button";
import Textarea from "common/UIKit/Textarea/Textarea";
import { Modal } from "common/components/Modal";
import { Ranking } from "common/components/Ranking";
import { SimpleCard } from "common/UIKit/SimpleCard";
import AddButton from "common/UIKit/AddButton";

import { useModalDrag } from "hooks/useModalDrag";
import { useResponsive } from "hooks/useResponsive";

import { testimonialService } from "services/testimonialService";

import { Testimonial } from "types/testimonials.types";

import { ReactComponent as Pencil } from "./images/pencil.svg";

import styles from "./UserTestimonial.module.scss";

// TODO: remove prop to enable component
export const UserTestimonial = ({ hidden = true }: { hidden?: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<Testimonial>();

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const onSubmit = async (values: Testimonial) => {
    try {
      if (values.id) {
        await testimonialService.patch(values.id, values);
      } else {
        await testimonialService.create(values);
      }
    } catch {
      console.log("Error during submitting testimonial");
    } finally {
      handleClose();
    }
  };

  const { isMobile } = useResponsive();
  const { bind, percentageToClose, transformStyle } = useModalDrag({
    moveDistanceToClose: 300,
    onClose: handleClose,
  });

  useEffect(() => {
    testimonialService.list();
  }, []);

  const { data } = useSnapshot(testimonialService);

  const handleClickEdit = (testimonial: Testimonial) => {
    reset(testimonial);
    setIsModalOpen(true);
  };

  // TODO: also remove this when there is no need to hide component
  if (hidden) return null;

  return (
    <div className={styles.root}>
      {data.length ? (
        data.map((testimonial) => (
          <SimpleCard key={testimonial.id} className={styles.card}>
            <div className={styles.testimonialRank}>
              {Array.from(Array(testimonial.rank).keys()).map((index) => (
                <StarFill key={index} />
              ))}
            </div>
            <p>{testimonial.text}</p>
            <AddButton
              icon={<Pencil />}
              onClick={() => handleClickEdit(testimonial)}
            >
              Edit Testimonial
            </AddButton>
          </SimpleCard>
        ))
      ) : (
        <Button color="outline-gray" className={styles.addButton} onClick={handleOpen}>
          <PlusBig />
          Add Testimonial
        </Button>
      )}

      <Modal
        visible={isModalOpen}
        onClose={handleClose}
        fadePercentage={percentageToClose}
        closeOnClickOutside={false}
      >
        {/* TODO: move shared styling of Modal to another component */}
        <div className={styles.modal} {...bind()} style={transformStyle}>
          {isMobile && <div className={styles.mobileLine} />}
          <button className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </button>
          <h1>Add Testimonials</h1>

          <Controller
            name="rank"
            control={control}
            render={({ field }) => <Ranking {...field} />}
          />

          <Controller
            name="text"
            control={control}
            rules={{
              required: "Message is required",
            }}
            render={({ field, fieldState }) => (
              <Textarea
                className={styles.textarea}
                {...field}
                placeholder="Type something about TheraPass"
                currentLength={field.value?.length || 0}
                maxLength={200}
                error={fieldState.error?.message}
              />
            )}
          />
          <Button
            className={styles.saveButton}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};
