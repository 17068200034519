import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";

import {
  AgeCode,
  Ethnicity,
  Faith,
  FaithOption,
  FAITH_OPTIONS,
  ListOption,
  ETHNICITY_OPTIONS,
} from "constants/options";
import { EditHeader } from "common/components/EditHeader";
import AppSelect from "common/UIKit/Select/Select";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import ControlledMultiSelect from "common/components/ControlledMultiSelect";
import Checkbox from "common/UIKit/Checkbox";
import Button from "common/UIKit/Button";
import { AgeCardCheckbox } from "common/UIKit/AgeCardCheckbox/AgeCardCheckbox";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { useProfileForm } from "../../useProfileForm";
import { Group } from "types/therapist.types";
import useFetch from "hooks/useFetch";
import { dictionaryService } from "services/dictionaryService";
import { toOptions, updateOptionsArray } from "common/utils";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./EditClientFocus.module.scss";

const coveredFields: TherapistFields = [
  "ages",
  "preferred_faith",
  "preferred_languages",
  "allied_groups",
  "preferred_ethnicities",
];

type Inputs = {
  ages: AgeCode[];
  preferred_faith: Faith;
  preferred_languages: string[];
  allied_groups: Group[];
  preferred_ethnicities: Ethnicity[];
};

export const EditClientFocus = () => {
  useDashboardNavigation("Edit Client Focus", {
    goBack: "pop_path",
  });
  const { control, handleSubmit, errors } =
    useProfileForm<Inputs>(coveredFields);
  const alliedGroups = useFetch<ListOption[]>(
    () => dictionaryService.getAlliedGroups(),
    {
      transformer: toOptions,
    }
  );

  const languages = useFetch<ListOption[]>(
    () => dictionaryService.getLanguages(),
    {
      transformer: (data) =>
        toOptions(data).filter((l) => l.value !== "no" && l.value !== "en"),
    }
  );

  return (
    <section className={styles.editClientFocus}>
      <EditHeader />
      <form onSubmit={handleSubmit}>
        <AgeCardCheckbox
          className={styles.editClientFocusCards}
          control={control}
        />
        <stepsUi.Row
          className={styles.editClientFocusRow}
          label="Faith Organization"
          subLabel="Do you offer special insight for clients of the following faith?"
        >
          <Controller
            name="preferred_faith"
            control={control}
            render={({ field }) => (
              <AppSelect
                placeholder="Select"
                options={FAITH_OPTIONS}
                {...field}
                value={FAITH_OPTIONS.find((o) => o.value === field.value)}
                onChange={(newValue) => {
                  const { value } = newValue as FaithOption;
                  field.onChange(value);
                }}
                error={errors.preferred_faith?.message}
                menuIsOpen={true}
              />
            )}
          />
        </stepsUi.Row>
        <stepsUi.Row
          className={styles.editClientFocusRow}
          label="Groups"
          subLabel="Are you allied with or have experience supporting clients in these groups?"
        >
          <ControlledMultiSelect
            name="allied_groups"
            control={control}
            options={alliedGroups?.data}
            placeholder="Select"
          />
        </stepsUi.Row>
        <stepsUi.Row
          label="Ethnicity of Your clients"
          className={styles.editClientFocusEthnicity}
        >
          <Controller
            control={control}
            name="preferred_ethnicities"
            render={({ field: { value, onChange } }) => (
              <div className={styles.editClientFocusEthnicityList}>
                {ETHNICITY_OPTIONS.map((ethnicity) => (
                  <Checkbox
                    key={ethnicity.value}
                    name={ethnicity.value}
                    checked={value?.includes(ethnicity.value)}
                    id={ethnicity.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onChange(
                        updateOptionsArray(
                          event.currentTarget.checked,
                          ethnicity.value,
                          value,
                          "none"
                        )
                      );
                    }}
                  >
                    {ethnicity.label}
                  </Checkbox>
                ))}
              </div>
            )}
          />
        </stepsUi.Row>
        <stepsUi.Row
          label="Languages Spoken (besides English)"
          className={styles.editClientFocusRow}
        >
          <ControlledMultiSelect
            name="preferred_languages"
            control={control}
            options={languages?.data}
            placeholder="Select"
          />
        </stepsUi.Row>
        <Button className={styles.editClientFocusButton} type="submit">
          Save
        </Button>
      </form>
    </section>
  );
};
