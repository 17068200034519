import styles from "./InputSearchAddress.module.scss";
import { ForwardedRef, forwardRef, MutableRefObject, useRef } from "react";
import { ReactComponent as Search } from "assets/images/icons/search.svg";
import cn from "classnames";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { State } from "constants/options";
import { Nullable } from "../../utils";
import { mapOptions } from "constants/googleMap";

export type Place = {
  name?: string;
  address?: string;
  city?: string;
  zip?: string;
  state?: State;
  phoneNumber?: string;
  phoneExtension?: string;
  latitude: number;
  longitude: number;
};

type Props = {
  onSelect: (p: Place) => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  className?: string;
  type?: "(regions)" | "establishment" | "address";
};

const parseInternationalPhoneNumber = (
  internationalPhone?: string
): { phoneNumber?: string; phoneExtension?: string } => {
  if (!internationalPhone) {
    return {};
  }

  const [extension, number] = internationalPhone.split(" ");
  return {
    phoneExtension: extension.replace(/\D/g, ""),
    phoneNumber: number.replace(/\D/g, ""),
  };
};

function InputSearchAddressComponent(
  { inputRef, onSelect, className, type = "establishment" }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { isLoaded, loadError } = useJsApiLoader(mapOptions);

  const autocompleteRef =
    useRef<Nullable<google.maps.places.Autocomplete>>(null);

  function onLoad(loadedAutocomplete: google.maps.places.Autocomplete) {
    autocompleteRef.current = loadedAutocomplete;
  }

  function onPlaceChanged() {
    const autocomplete = autocompleteRef.current;
    if (!autocomplete) {
      return;
    }

    const { address_components, international_phone_number, name, geometry } =
      autocomplete.getPlace();
    if (!address_components) {
      return;
    }

    const street = address_components.find((component) =>
      component.types.includes("route")
    )?.long_name;
    const streetNumber = address_components.find((component) =>
      component.types.includes("street_number")
    )?.long_name;
    const subpremise = address_components.find((component) =>
      component.types.includes("subpremise")
    )?.long_name;
    const house = [subpremise, streetNumber].filter(Boolean).join("/");

    onSelect({
      name,
      address: [house, street].filter(Boolean).join(" "),
      city:
        address_components.find((component) =>
          component.types.includes("locality")
        )?.long_name ||
        address_components.find((component) =>
          component.types.includes("administrative_area_level_2")
        )?.long_name,
      zip: address_components.find((component) =>
        component.types.includes("postal_code")
      )?.long_name,
      state: address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.short_name as State,
      ...parseInternationalPhoneNumber(international_phone_number),
      latitude: geometry?.location?.lat() ? geometry?.location?.lat() : 0,
      longitude: geometry?.location?.lng() ? geometry?.location?.lng() : 0,
    });
  }

  return (
    <div className={cn(styles.container, className)}>
      <Search className={styles.icon} />
      {isLoaded && !loadError && (
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          restrictions={{ country: "us" }}
          fields={[
            "address_components",
            "name",
            "geometry",
            "international_phone_number",
          ]}
          types={[type]}
        >
          <input
            className={styles.input}
            placeholder="Start typing your address"
            ref={inputRef || ref}
          />
        </Autocomplete>
      )}
    </div>
  );
}

const InputSearchAddress = forwardRef(InputSearchAddressComponent);

export default InputSearchAddress;
