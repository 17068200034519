import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { AxiosResponse } from "axios";

import { smsSessionService } from "services/smsSessionService";
import { UserId } from "types/auth.types";

export const useSessionControls = (patientUserId?: UserId) => {
  const { data: sessions, currentSession } = useSnapshot(smsSessionService);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    smsSessionService.setFilters({ patient: patientUserId });
    smsSessionService.fetch();
    smsSessionService.getCurrentSession();
  }, [patientUserId]);

  const handle = (func: () => any) => async () => {
    await setIsLoading(true);
    await setError("");
    try {
      await func();
    } catch (error) {
      const { data, status } = error as AxiosResponse;
      if (status === 403) {
        setError(data.detail);
      } else {
        setError(data.errors.join(". "));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const start = handle(
    () => patientUserId && smsSessionService.start(patientUserId)
  );
  const pause = handle(
    () => currentSession?.id && smsSessionService.pause(currentSession.id)
  );
  const resume = handle(
    () => currentSession?.id && smsSessionService.resume(currentSession.id)
  );
  const terminate = handle(
    () => currentSession?.id && smsSessionService.terminate(currentSession.id)
  );

  return {
    sessions,
    currentSession,
    isLoading,
    error,
    start,
    pause,
    resume,
    terminate,
  };
};
