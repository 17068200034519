import { MouseEventHandler } from "react";
import styles from "./parts.module.scss";

export type ActionProps = {
  startBrowsing: MouseEventHandler;
  disabled?: boolean;
  label?: string;
};

export function BrowseButton({
  startBrowsing,
  disabled,
  label = "Browse files",
}: ActionProps) {
  return (
    <button
      className={styles.browseButton}
      disabled={disabled}
      onClick={startBrowsing}
    >
      {label}
    </button>
  );
}
