import { LandingHeader } from "pages/Auth/components/LandingHeader/LandingHeader";
import styles from "./ListingHeader.module.scss";
import { useSnapshot } from "valtio";
import { therapistListingService } from "services/therapistListingService";
import cn from "classnames";

export const ListingHeader = () => {

  const { showFilters } = useSnapshot(therapistListingService);

  return (
    <div className={cn(styles.listingHeader, showFilters && styles.hideHeader)}>
      <LandingHeader className={styles.landingHeader} />
    </div>
  );
};
