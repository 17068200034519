import { FC, ReactNode } from "react";
import cn from "classnames";
import styles from "../TherapistSteps.module.scss";

type Props = { className?: string; buttons?: ReactNode };

export const TherapistStepsHeader: FC<Props> = ({
  children,
  className,
  buttons,
}) => (
  <div className={styles.headerWrapper}>
    <h1 className={cn(styles.title, className)}> {children} </h1>
    {buttons}
  </div>
);
