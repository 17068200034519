import React from "react";
import clsx from "clsx";
import MainParticipant from "../MainParticipant/MainParticipant";
import ParticipantList from "../ParticipantList/ParticipantList";
import styles from "./Room.module.scss";
import { ParticipantAudioTracks } from "../ParticipantAudioTracks/ParticipantAudioTracks";

export default function Room() {
  return (
    <div className={clsx(styles.container)}>
      {/* 
        This ParticipantAudioTracks component will render the audio track for all participants in the room.
        It is in a separate component so that the audio tracks will always be rendered, and that they will never be 
        unnecessarily unmounted/mounted as the user switches between Gallery View and speaker View.
      */}
      <ParticipantAudioTracks />
      <MainParticipant />
      <ParticipantList />
    </div>
  );
}
