import { forwardRef, ReactNode, ForwardedRef } from "react";
import cn from "classnames";
import Select, { Props as ReactSelectProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import "./Select.scss";

interface Props extends ReactSelectProps {
  error?: string;
  label?: ReactNode;
  size?: "medium" | "small";
  isCreatable?: boolean;
  onCreateOption?: (inputValue: string) => void;
  placeholderAsLabel?: boolean;
}

function AppSelectComponent(
  { error, label, size, className, isCreatable, placeholderAsLabel, menuIsOpen,  ...restProps }: Props,
  ref: ForwardedRef<HTMLDivElement>
) {
  const Component = isCreatable ? CreatableSelect : Select;

  return (
    <div className={cn("react-select", className)} ref={ref}>
      {label && <label className="label">{label}</label>}
      <Component
        {...restProps}
        className={cn("react-select__container", size === "small" && "small", placeholderAsLabel && "placeholder-as-label", menuIsOpen && "high-index")}
        classNamePrefix="react-select"
      />
      {error && <p className="error_msg">{error}</p>}
    </div>
  );
}

const AppSelect = forwardRef(AppSelectComponent);

export default AppSelect;
