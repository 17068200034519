import Input from "common/UIKit/Input";
import {
  NUMBER_FIELD,
  NUMBER_LABEL,
  ORGANIZATION_FIELD,
  ORGANIZATION_LABEL,
} from "constants/fields";
import {
  CredentialOption,
  VERIFIED_CREDENTIAL_OPTIONS,
} from "constants/options";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import { useNavigate, useParams } from "react-router-dom";
import { Credential, CredentialType } from "types/credential.types";
import { useAuth } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, Path } from "react-hook-form";
import { credentialService } from "services/credentialService";
import { goThroughErrors } from "common/utils";
import { AxiosResponse } from "axios";
import AppSelect from "common/UIKit/Select/Select";
import { ButtonProps } from "common/UIKit/Button/Button";
import { step3_3FullPath } from "../TherapistStep3_3";
import styles from "./TherapistAddOtherCredential.module.scss";

interface Inputs {
  type: CredentialType;
  year?: number | null;
  membership_organization?: string;
  membership_id?: string;
  license_issuer?: string;
  license_number?: string;
  accrediting_institution?: string;
  certificate_number?: string;
  diploma_school_graduated?: string;
  diploma_degree_type?: string;
}

const defaultValues: Inputs = {
  type: "membership",
  year: null,
  membership_organization: "",
  membership_id: "",
  license_issuer: "",
  license_number: "",
  accrediting_institution: "",
  certificate_number: "",
  diploma_school_graduated: "",
  diploma_degree_type: "",
};

export const TherapistAddOtherCredential = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [credential, setCredential] = useState<Credential | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<Inputs>({ defaultValues });
  const type = watch("type");

  useEffect(() => {
    if (id && user) {
      credentialService
        .getById(user.id, Number(id))
        .then(setCredential)
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id, user]);

  useEffect(() => {
    if (id && credential) {
      reset({
        ...credential,
      });
    }
  }, [id, credential, reset]);

  const submitHandler: SubmitHandler<Inputs> = async (values) => {
    if (!user) return;

    const data = {
      ...values,
      year: values.year ? +values.year : null,
    };

    try {
      credential?.id
        ? await credentialService.patch(user.id, +credential.id, data)
        : await credentialService.create(user.id, data);

      navigate(step3_3FullPath);
    } catch (error) {
      const { data } = error as AxiosResponse;
      goThroughErrors(data, (fieldName, errorMessage) =>
        setError(fieldName as Path<Inputs>, {
          message: errorMessage,
          type: "custom",
        })
      );
    }
  };

  const setValues = (v: Partial<Inputs>) => {
    Object.entries(v).forEach(([fName, fVal]) =>
      setValue(fName as keyof Inputs, fVal)
    );
  };

  const buttons: ButtonProps[] = [
    {
      type: "button",
      color: "green-light",
      children: "Back",
      className: "btn-color-blue",
      isLink: true,
      to: step3_3FullPath,
      disabled: isSubmitting,
    },
    {
      type: "submit",
      children: "Save",
      isLoading: isSubmitting,
    },
  ];

  return (
    <>
      <stepsUi.Header>Credentials</stepsUi.Header>
      <stepsUi.Hint className="therapist-step-3__hint">
        ADD OTHER CREDENTIALS 3/3
      </stepsUi.Hint>
      <stepsUi.Form
        actions={<stepsUi.Actions buttons={buttons} />}
        handleSubmit={handleSubmit(submitHandler)}
        className={styles.root}
      >
        <stepsUi.Grid>
          <stepsUi.Row label="Credential Type">
            <Controller
              control={control}
              name="type"
              render={({ field, fieldState: { error } }) => (
                <AppSelect
                  error={error?.message}
                  placeholder="Select"
                  options={VERIFIED_CREDENTIAL_OPTIONS}
                  value={VERIFIED_CREDENTIAL_OPTIONS.find(
                    (o) => o.value === field.value
                  )}
                  onChange={(nextValue) => {
                    const { value } = nextValue as CredentialOption;
                    setValues({
                      type: value,
                      ...(type && { [ORGANIZATION_FIELD[type]]: "" }),
                      ...(type && { [NUMBER_FIELD[type]]: "" }),
                    });
                  }}
                />
              )}
            />
          </stepsUi.Row>
          {type && (
            <stepsUi.Row label={type && ORGANIZATION_LABEL[type]}>
              <Input
                type="text"
                placeholder=""
                {...register(ORGANIZATION_FIELD[type])}
                error={type && errors[ORGANIZATION_FIELD[type]]?.message}
              />
            </stepsUi.Row>
          )}
        </stepsUi.Grid>
        <stepsUi.Grid>
          {type && (
            <stepsUi.Row label={type && NUMBER_LABEL[type]}>
              <Input
                type="text"
                placeholder="ID"
                {...register(NUMBER_FIELD[type])}
                optional="OPTIONAL"
              />
            </stepsUi.Row>
          )}
          <stepsUi.Row label="Year">
            <Input
              type="text"
              placeholder="YYYY"
              {...register("year")}
              error={errors.year?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
      </stepsUi.Form>
    </>
  );
};
