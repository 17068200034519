import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import { getShallowDiff } from "common/utils";
import { useAuth } from "hooks/useAuth";
import { ChatStatus, Therapist } from "types/therapist.types";
import { authService } from "services/authService";

type ChangeAvailabilityValues = {
  chat_status: ChatStatus;
  in_person_session_availability: boolean;
  telephone_session_availability: boolean;
  video_session_availability: boolean;
  online_chat_sessions_availability: boolean;
};

export const useChangeAvailability = () => {
  const auth = useAuth();
  const user = auth.user as Therapist | null;
  const defaultValues: ChangeAvailabilityValues = {
    chat_status: user?.chat_status || "offline",
    in_person_session_availability: user
      ? user.in_person_session_availability
      : false,
    telephone_session_availability: user
      ? user.telephone_session_availability
      : false,
    video_session_availability: user ? user.video_session_availability : false,
    online_chat_sessions_availability: user
      ? user.online_chat_sessions_availability
      : false,
  };

  const methods =
    useForm<ChangeAvailabilityValues>({
      defaultValues,
    });

  const onSubmit: SubmitHandler<ChangeAvailabilityValues> = async (values) => {
    try {
      const changedValues = getShallowDiff(values, defaultValues);
      if (!isEmpty(changedValues)) {
        await authService.patch({ diff: changedValues });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const subscription = methods.watch(() => methods.handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  });

  return methods;
};
