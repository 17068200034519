import {
  ControlProps,
  CSSObjectWithLabel,
  GroupBase,
  OptionProps,
} from "react-select";

export const insuranceOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const availabilityOptions = [
  { label: "In-person session", value: "in_person_session_availability" },
  { label: "Telephone session", value: "telephone_session_availability" },
  { label: "Video session", value: "video_session_availability" },
  { label: "Chat Connect session", value: "online_chat_sessions_availability" },
];

export const priceOptions = [
  { label: "Intake Session ($0)", value: "0" },
  { label: "$25 - $50", value: "25-50" },
  { label: "$50 - $75", value: "50-75" },
  { label: "$75 - $100", value: "75-100" },
  { label: "$100 - $200", value: "100-200" },
  { label: "> $200", value: "200" },
];

export const milesOptions = [
  { label: "5 miles", value: "5" },
  { label: "10 miles", value: "10" },
  { label: "15 miles", value: "15" },
  { label: "25 miles", value: "25" },
  { label: "50 miles", value: "50" },
  { label: "100 miles", value: "100" },
];

export const experienceOptions = [
  { label: "Over 1 year", value: "1" },
  { label: "Over 5 years", value: "5" },
  { label: "Over 10 years", value: "10" },
  { label: "Over 15 years", value: "15" },
  { label: "Over 25 years", value: "25" },
];

export const multiSelectStyles = (rightPosition = "5%", top = "35px") => ({
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "28px 48px",
  }),
  menu: (base: CSSObjectWithLabel) => ({ ...base }),
  menuPortal: (): CSSObjectWithLabel => ({
    maxWidth: "604px",
    width: "100%",
    position: "absolute",
    top,
    left: rightPosition,
    maxHeight: "314px",
    zIndex: 1,
  }),
  option: (
    base: CSSObjectWithLabel,
    { isFocused }: OptionProps<unknown, boolean, GroupBase<unknown>>
  ) => ({
    ...base,
    background: 0,
    color: isFocused ? "#26D5B6" : "#68768B",
    fontFamily: "Public Sans",
    fontSize: "16px",
    padding: "12px 0",
  }),
});

export const selectStyles = {
  option: (
    base: CSSObjectWithLabel,
    { isFocused }: OptionProps<unknown, boolean, GroupBase<unknown>>
  ) => ({
    ...base,
    background: 0,
    color: isFocused ? "#26D5B6" : "#68768B",
    fontFamily: "Public Sans",
    fontSize: "16px",
    padding: "12px 12px",
  }),
};

export const selectStylesMobile = {
  option: (
    base: CSSObjectWithLabel,
    { isFocused }: OptionProps<unknown, boolean, GroupBase<unknown>>
  ) => ({
    ...base,
    background: 0,
    color: isFocused ? "#26D5B6" : "#68768B",
    fontFamily: "Public Sans",
    fontSize: "16px",
    padding: "12px 12px",
  }),
  control: (
    base: CSSObjectWithLabel,
    { isFocused }: ControlProps<unknown, boolean, GroupBase<unknown>>
  ) => ({
    ...base,
    padding: "0 !important",
    border: "0 !important",
    borderBottom: "1px solid #F1F7FF !important",
    boxShadow: isFocused ? "transparent !important" : "transparent !important",
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    fontFamily: "Public Sans",
    fontSize: "16px",
    color: "#1F342F",
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: "30px 0 !important",
  }),
  menu: (base: CSSObjectWithLabel) => ({ ...base, margin: " 0 0 0 -6px" }),
};
