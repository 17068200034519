import cn from "classnames";
import React, { forwardRef, HTMLProps } from "react";
import styles from "./Textarea.module.scss";

interface TextareaProps extends HTMLProps<HTMLTextAreaElement> {
  currentLength?: number;
  maxLength?: number;
  error?: string;
  withoutLength?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      id,
      label,
      className,
      currentLength,
      maxLength = 300,
      error,
      withoutLength,
      ...restProps
    },
    ref
  ) => {
    return (
      <div className={cn(styles.root, className)}>
        {label && (
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
        )}
        <textarea
          ref={ref}
          id={id}
          className={cn(styles.textarea, { [styles["--error"]]: error })}
          maxLength={maxLength}
          {...restProps}
        />
        {!withoutLength && (
          <p className={styles.length}>
            {currentLength}&nbsp;of&nbsp;{maxLength}&nbsp;characters
          </p>
        )}
        {error && <p className={styles.error}>{error}</p>}
      </div>
    );
  }
);

export default Textarea;
