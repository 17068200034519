import { FC } from "react";

import { ReactComponent as Offline } from "assets/images/icons/offline.svg";
import { ReactComponent as Phone } from "assets/images/icons/phone-gray.svg";
import { ReactComponent as Video } from "assets/images/icons/video-gray.svg";
import { ReactComponent as Text } from "assets/images/icons/text.svg";
import { ReactComponent as Issues } from "assets/images/icons/issues.svg";
import { ReactComponent as Camera } from "assets/images/icons/camera.svg";
import { ReactComponent as Globe } from "assets/images/icons/globe.svg";
import { ReactComponent as Faith } from "assets/images/icons/faith.svg";
import { ReactComponent as Shield } from "assets/images/icons/shield.svg";
import { ReactComponent as Client } from "assets/images/icons/user-Regular.svg";
import { ReactComponent as VideoStroke } from "assets/images/icons/video-Regular.svg";

import { ReactComponent as Person } from "assets/images/icons/single-person.svg";
import { ReactComponent as Family } from "assets/images/icons/family.svg";
import { ReactComponent as Groups } from "assets/images/icons/groups-regular.svg";

import { ReactComponent as Browser } from "assets/images/icons/browser.svg";

import { ReactComponent as Toddler } from "assets/images/icons/toddler.svg";
import { ReactComponent as Child } from "assets/images/icons/children.svg";
import { ReactComponent as Teen } from "assets/images/icons/teen.svg";
import { ReactComponent as Adult } from "assets/images/icons/adult.svg";
import { ReactComponent as Elder } from "assets/images/icons/elder.svg";
import { ReactComponent as Couples } from "assets/images/icons/couples.svg";

import { ReactComponent as Inbox } from "assets/images/icons/inbox.svg";
import { ReactComponent as Wallet } from "assets/images/icons/wallet.svg";
import { ReactComponent as CreditCard } from "assets/images/icons/credit-card.svg";
import { ReactComponent as File } from "assets/images/icons/file.svg";
import { ReactComponent as Education } from "assets/images/icons/education.svg";
import { ReactComponent as Case } from "assets/images/icons/case.svg";
import visaIcon from "assets/images/payment/visa-icon.svg";
import cashIcon from "assets/images/payment/cash-icon.svg";
import masterCardIcon from "assets/images/payment/master-card-icon.svg";
import americanExpressIcon from "assets/images/payment/american-express-icon.svg";
import zelleIcon from "assets/images/payment/zelle-icon.svg";
import venmoIcon from "assets/images/payment/venmo-icon.svg";
import { AgeCode } from "./options";
import { ModalitiesEnum } from "types/therapist.types";

export const OPTIONS_ICONS: {
  name:
    | "telephone_session_availability"
    | "video_session_availability"
    | "online_chat_sessions_availability"
    | "in_person_session_availability";
  component: FC;
}[] = [
  { name: "in_person_session_availability", component: Offline },
  { name: "telephone_session_availability", component: Phone },
  { name: "video_session_availability", component: Video },
  { name: "online_chat_sessions_availability", component: Text },
];

export const THERAPIST_ICONS = {
  Issues,
  Camera,
  Globe,
  Faith,
  Groups,
  Shield,
  Client,
  VideoStroke,
  Person,
  Browser,
  Inbox,
  Wallet,
  CreditCard,
  File,
  Education,
  Case,
};

export const AGE_ICONS: {
  value: AgeCode;
  Icon: FC;
  label: string;
  age: string;
}[] = [
  {
    value: "toddler",
    Icon: Toddler,
    label: "Toddlers / Preschooler",
    age: "0-6 years",
  },
  { value: "child", Icon: Child, label: "Children", age: "6-10 years" },
  { value: "preteen", Icon: Teen, label: "Preteen /Tween", age: "11-13 years" },
  { value: "teen", Icon: Teen, label: "Teen", age: "14-19 years" },
  { value: "adult", Icon: Adult, label: "Adult", age: "20-65 years" },
  { value: "adults", Icon: Adult, label: "Adult", age: "20-65 years" },
  { value: "elder", Icon: Elder, label: "Elder 65+", age: "+65 years" },
];

export const MODALITY_ICONS = [
  {
    value: ModalitiesEnum.individuals,
    label: "Individuals",
    Icon: Person,
  },
  { value: ModalitiesEnum.couples, label: "Couples", Icon: Couples },
  { value: ModalitiesEnum.family, label: "Family", Icon: Family },
  { value: ModalitiesEnum.group, label: "Groups", Icon: Groups },
];

export const PAYMENT_ICONS = {
  visa: visaIcon,
  cash: cashIcon,
  mastercard: masterCardIcon,
  americanexpress: americanExpressIcon,
  zelle: zelleIcon,
  venmo: venmoIcon
};
