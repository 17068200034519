import { FC } from "react";
import Input from "common/UIKit/Input";
import { zipCodeValidator } from "common/validators/addressValidators";
import { EditPatientInformationForm } from "../useEditPatientInformation";
import styles from "../EditPatientInformation.module.scss";

export const ZipCode: FC<EditPatientInformationForm> = ({
  register,
  errors,
}) => {
  return (
    <Input
      type="text"
      className={styles.zip}
      id="zip"
      placeholder=""
      label="Zip code"
      {...register("zip_code", zipCodeValidator)}
      error={errors.zip_code?.message}
    />
  );
};
