import { stepsUi } from "../components";
import useTherapistStep4_2 from "./useTherapistStep4_2";
import ControlledMultiSelect from "common/components/ControlledMultiSelect";
import styles from "./TherapistStep4_2.module.scss";
import { ModalityCardCheckbox } from "common/UIKit/ModalityCardCheckbox";

export function TherapistStep4_2() {
  const {
    isProcessing,
    availableTherapyTypes,
    handleSubmit,
    control,
    actionsProps,
  } = useTherapistStep4_2();

  return (
    <>
      <stepsUi.Header>Specialties & Treatment Preferences</stepsUi.Header>
      <stepsUi.Hint>Select your preferred methods of treatment.</stepsUi.Hint>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <stepsUi.Row
          label="Types of therapy"
          subLabel="You can choose a few types of therapy"
        >
          <ControlledMultiSelect
            name="therapies"
            control={control}
            options={availableTherapyTypes}
            placeholder="Choose therapy types"
            isDisabled={isProcessing}
            className={styles.therapiesSelect}
          />
        </stepsUi.Row>
        <ModalityCardCheckbox
          control={control}
          className={styles.modalitiesSelect}
        />
      </stepsUi.Form>
    </>
  );
}
