import { DashboardTab } from "./useDashboardTabs";
import { MyAccount, myAccountPath } from "./MyAccount";
import { MyAnimals, myAnimalsPath } from "./MyAnimals";
import { Letters, lettersPath } from "./Letters";
import { testsPath, Tests } from "./Tests";
import { Favorites, favoritesPath } from "./Favorites";
import { Messages, messagesPath } from "./Messages";
import { Recommended, recommendedPath } from "./Recommended";
import { LandlordForms, landlordFormsPath } from "./LandlordForms";
import { Settings, settingsPath } from "./Settings";
import { OrderHistory, orderHistoryPath } from "./OrderHistory";
import { ContactUs, contactUsPath } from "./ContactUs";

import { ReactComponent as SinglePerson } from "assets/images/icons/single-person.svg";
import { ReactComponent as MyAnimalIcon } from "assets/images/icons/my-animals.svg";
import { ReactComponent as LettersIcon } from "assets/images/icons/letters.svg";
import { ReactComponent as TestsIcon } from "assets/images/icons/tests.svg";
import { ReactComponent as FavoritesIcon } from "assets/images/icons/heart.svg";
import { ReactComponent as MessagesIcon } from "assets/images/icons/message.svg";
import { ReactComponent as RecommendedIcon } from "assets/images/icons/star.svg";
import { ReactComponent as LandlordFormsIcon } from "assets/images/icons/landlord-forms.svg";
import { ReactComponent as SettingsIcon } from "assets/images/icons/gear.svg";
import { ReactComponent as DoubleWallet } from "assets/images/icons/double-wallet.svg";
import { ReactComponent as ContactUsIcon } from "assets/images/icons/contact-us.svg";

export const tabs: DashboardTab[] = [
  {
    icon: SinglePerson,
    label: "My Account",
    value: myAccountPath,
    component: MyAccount,
  },
  {
    icon: MyAnimalIcon,
    label: "My animals",
    value: myAnimalsPath,
    component: MyAnimals,
    esaOnly: true,
  },
  {
    icon: LettersIcon,
    label: "Letters",
    value: lettersPath,
    component: Letters,
    esaOnly: true,
  },
  {
    icon: TestsIcon,
    label: "Tests",
    value: testsPath,
    component: Tests,
    esaOnly: true,
  },
  {
    icon: FavoritesIcon,
    label: "Favorites",
    value: favoritesPath,
    component: Favorites,
  },
  {
    icon: MessagesIcon,
    label: "Messages",
    value: messagesPath,
    component: Messages,
  },
  {
    icon: RecommendedIcon,
    label: "Recommended",
    value: recommendedPath,
    component: Recommended,
  },
  {
    icon: LandlordFormsIcon,
    label: "Landlord forms",
    value: landlordFormsPath,
    component: LandlordForms,
    esaOnly: true,
  },
  {
    icon: SettingsIcon,
    label: "Settings",
    value: settingsPath,
    component: Settings,
  },
  {
    icon: DoubleWallet,
    label: "Order History",
    value: orderHistoryPath,
    component: OrderHistory,
  },
  {
    icon: ContactUsIcon,
    label: "Contact Us",
    value: contactUsPath,
    component: ContactUs,
    esaOnly: true,
  },
];
