import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { TestsTableMobile } from "../TestsTableMobile";
import { TestsTable } from "../TestsTable";
import { useWindowResize } from "hooks/useWindowResize";
import { Pagination } from "common/UIKit/Pagination";
import { testPsychologyListService } from "services/testPsychologyListService";

export const AvailableTests = () => {
  const { data, pageCount, page, sort } = useSnapshot(
    testPsychologyListService
  );
  const { width } = useWindowResize();

  useEffect(() => {
    testPsychologyListService.fetch();
  }, []);

  const onSortChange = (
    sortBy: "name" | "finished_at" | "time_to_complete" | "started_at"
  ) => {
    if (sortBy === "name") {
      testPsychologyListService.clearOrder("time_to_complete", false);
    } else {
      testPsychologyListService.clearOrder("name", false);
    }
    testPsychologyListService.setOrder(sortBy);
  };

  return (
    <>
      {width <= 1300 ? (
        <TestsTableMobile rows={data} />
      ) : (
        <TestsTable onSortChange={onSortChange} sort={sort} rows={data} />
      )}
      <Pagination
        pageCount={pageCount}
        currentPage={page}
        goTo={(page) => testPsychologyListService.goTo(page)}
      />
    </>
  );
};
