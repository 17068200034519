import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { therapistListingService } from "services/therapistListingService";
import { favoriteTherapistsService } from "services/favoriteTherapistsService";
import { TherapistCard } from "common/components/TherapistCard";
import { useResponsive } from "hooks/useResponsive";
import { FormatProfileField } from "common/components/FormatProfileField";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { MessagePopup } from "common/components/MessagePopup";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./Favorites.module.scss";

export const favoritesPath = "/favorites";

export const Favorites = () => {
  useDashboardNavigation("Favorites", { goBack: "pop_path" });
  const { isMobile } = useResponsive();
  const { data } = useSnapshot(favoriteTherapistsService);
  useDocumentTitle("TheraPass - Favorites");

  useEffect(() => {
    favoriteTherapistsService.list(false);
  }, []);

  const onLike = async (id: string) => {
    try {
      await therapistListingService.like(id);
      await favoriteTherapistsService.list(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.favorites}>
      <MessagePopup />
      {!isMobile && <h1 className={styles.header}>Favorites</h1>}
      <div className={styles.cards}>
        <FormatProfileField
          className={styles.noFavorites}
          fallbackText="You have not added any favorites yet."
        >
          {data.map((therapist) => (
            <TherapistCard
              className={styles.card}
              therapist={therapist}
              isFavorite={therapist.is_my_favourite}
              key={therapist.id}
              onLike={() => onLike(therapist.id)}
            />
          ))}
        </FormatProfileField>
      </div>
    </div>
  );
};
