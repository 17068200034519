import { TherapistStepsActions } from "./TherapistStepsActions";
import { TherapistStepsForm } from "./TherapistStepsForm";
import { TherapistStepsHeader } from "./TherapistStepsHeader";
import { TherapistStepsHint } from "./TherapistStepsHint";
import { TherapistStepsLayout } from "./TherapistStepsLayout";
import { TherapistStepsContainer } from "./TherapistStepsContainer";
import { TherapistStepsRow } from "./TherapistStepsRow";
import { TherapistStepsGrid } from "./TherapistStepsGrid";
import { TherapistStepsRowShort } from "./TherapistStepsRowShort";
import { TherapistStepsLine } from "./TherapistStepsLine";

export const stepsUi = {
  Actions: TherapistStepsActions,
  Form: TherapistStepsForm,
  Container: TherapistStepsContainer,
  Row: TherapistStepsRow,
  RowShort: TherapistStepsRowShort,
  Grid: TherapistStepsGrid,
  Header: TherapistStepsHeader,
  Hint: TherapistStepsHint,
  Layout: TherapistStepsLayout,
  Line: TherapistStepsLine,
};
