import { proxy } from "valtio";
import { TestAnswer, TestInputs } from "../types/testProcess.types";
import { CrudService } from "./crudService";
import { TestAttempt, TestAttemptId } from "types/testAttempt.types";
import { TestId } from "types/availableTests.types";
import { CreateCreditCardData, CreditCardId } from "types/creditCards.types";
import { creditCardService } from "./creditCardService";

export function formValuesToTestAnswers(values: TestInputs): TestAnswer[] {
  return Object.entries(values)
    .filter(([_, value]) => value != null && !Number.isNaN(value))
    .map(([key, value]) => {
      let answer: TestAnswer = {
        question: +key,
      };

      if (typeof value === "number") {
        answer.int_value = value;
      } else if (typeof value === "string") {
        answer.str_value = value;
      } else if (Array.isArray(value) && typeof value[0] === "number") {
        answer.int_list_value = value as number[];
      } else {
        answer.str_list_value = value as string[];
      }

      return answer;
    });
}

class TestAttemptService extends CrudService<TestAttemptId, TestAttempt> {
  constructor(args?: any) {
    super({
      ...args,
      pageSize: 1,
      prefix: "/v1/test-attempts",
    });
  }

  async start(testId: TestId) {
    const res = (await this.http.post(`${this.prefix}/`, {
      test: testId,
    })) as TestAttempt;

    return res;
  }

  async buyWithNewCard(
    values: CreateCreditCardData & {
      psychology_test: TestId;
      save_card: boolean;
    }
  ) {
    const res = (await this.http.post(
      `${this.prefix}/buy/new-card/`,
      values
    )) as TestAttempt;
    this.data.push(res);
    await creditCardService.list(false);
    return res;
  }

  async buyWithSavedCard(
    values: { bank_card: CreditCardId } & { psychology_test: TestId }
  ) {
    const res = (await this.http.post(
      `${this.prefix}/buy/saved-card/`,
      values
    )) as TestAttempt;
    this.data.push(res);
    return res;
  }
}

export const testAttemptService = proxy(new TestAttemptService());
testAttemptService.init();
