import { Fragment } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { ChangePhonePopup } from "./components/ChangePhonePopup/ChangePhonePopup";
import { EmailSuccessPopup } from "./components/EmailSuccessPopup/EmailSuccessPopup";
import { MessageResultPopup } from "./components/MessageResultPopup/MessageResultPopup";
import { MessageTherapistPopup } from "./components/MessageTherapistPopup/MessageTherapistPopup";

import { ContactTherapistValues } from "services/communicationService";
import { VerifyPhoneMessagePopup } from "./components/VerifyPhoneMessagePopup/VerifyPhoneMessagePopup";
import { ChangePhoneWithVerifyPopup } from "./components/ChangePhoneWithVerifyPopup/ChangePhoneWithVerifyPopup";
import { ClaimProfilePopup } from "./components/ClaimProfilePopup/ClaimProfilePopup";

const defaultValues: ContactTherapistValues = {
  message: "",
  captcha: null,
};

export const MessagePopup = () => {
  const messageFormPack = useForm<ContactTherapistValues>({
    defaultValues,
  });
  return (
    <Fragment>
      <FormProvider {...messageFormPack}>
        <MessageTherapistPopup />
        <MessageResultPopup />
        <EmailSuccessPopup />
        <ChangePhonePopup />
        <ChangePhoneWithVerifyPopup />
        <VerifyPhoneMessagePopup />
        <ClaimProfilePopup />
      </FormProvider>
    </Fragment>
  );
};
