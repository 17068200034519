export interface ProfileCard {
  id: number;
  name: string;
  description: string;
  Icon: React.SFC<React.SVGProps<SVGSVGElement>>;
  link: string;
  props?: string;
}

export const fullProfilePath = (path: string) =>
  `/therapists/dashboard/profile${path}`;

export const fullPaymentsPath = (path: string) =>
  `/therapists/dashboard/payments${path}`;

export const editPath = (path: string) => `${path}/edit`;
export const newPath = (path: string) => `${path}/new`;
