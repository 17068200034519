import { FC } from "react";

import { EditHeader } from "common/components/EditHeader";
import { ChangePasswordForm } from "common/components/ChangePasswordForm";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./ChangePassword.module.scss";

export const changePasswordPath = "/change-password";

export const ChangePassword: FC = () => {
  const { goBack } = useDashboardNavigation("Change password", {
    goBack: "pop_path",
  });
  return (
    <div className={styles.changePassword}>
      <EditHeader />
      <ChangePasswordForm onClose={goBack} />
    </div>
  );
};
