import React from 'react';
import styles from "./RoundScale.module.scss";

interface ScaleOptions {
  diameter: number;
  strokeWidth: number;
  textFontSize: number;
}

interface RoundScaleProps {
  scaleValue: number;
  scaleOptions?: ScaleOptions;
}

const pi = 3.14;

const getCircumference = (radius: number) => {
  return 2 * pi * radius;
}

const defaultScaleOptions: ScaleOptions = {
  diameter: 111,
  strokeWidth: 1,
  textFontSize: 30
}

function RoundScale({ scaleValue, scaleOptions = defaultScaleOptions }: RoundScaleProps) {

  const { diameter, strokeWidth, textFontSize } = scaleOptions;
  const scaleRadius = diameter / 2;
  const circumference = getCircumference(scaleRadius);
  const step = circumference / 100;
  const scaleLength = step * scaleValue;
  const emptyScaleLength = circumference - scaleLength;
  const strokeDashOffset = step * 25;

  return (
    <div className={styles.circleScaleWrapper} style={{
      width: `${diameter + strokeWidth}px`,
      height: `${diameter + strokeWidth}px`
    }}>
      <svg width={diameter + strokeWidth} height={diameter + strokeWidth}>
        <circle className={styles.scale} strokeWidth={strokeWidth} strokeDasharray={`${circumference} 0`} r={scaleRadius} cx="50%" cy="50%"/>
        <circle className={styles.stroke} strokeWidth={strokeWidth} strokeDasharray={`${scaleLength} ${emptyScaleLength}`} r={scaleRadius} cx="50%" cy="50%" strokeDashoffset={strokeDashOffset}/>
      </svg>
      <div className={styles.text} style={{
        fontSize: `${textFontSize}px`
      }}>{scaleValue}%</div>
    </div>
  );
}

export default RoundScale;
