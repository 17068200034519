import { FC, useState} from "react";
import { Modal } from "common/components/Modal";
import Button from "common/UIKit/Button";
import { useModal } from "hooks/useModal";
import { useModalDrag } from "hooks/useModalDrag";
import styles from './VerifyPhoneMessagePopup.module.scss'
import { phoneVerifyService } from "services/phoneVerifyService";
import { useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import { useSnapshot } from "valtio";
import { therapistService } from "services/therapistService";
import { verificationPath } from "pages/VerificationSent";

export const VerifyPhoneMessagePopup: FC = () => {
    const { isOpen, close } = useModal("VERIFY_PHONE_POPUP");
    const navigate = useNavigate();
    const { data } = useSnapshot(therapistService);
    const [error, setError] = useState()

    const { percentageToClose } = useModalDrag({
        moveDistanceToClose: 200,
        onClose: close,
    });

    const onClickHandler = async () => {
        try {
            const consent = await phoneVerifyService.getConsent()
            if (consent) {
                await authService.resendEmail(data[data.length - 1]?.user.email);
                close()
                navigate({
                    pathname: verificationPath,
                    search: `?email=${data[data.length - 1]?.user.email}`,
                });
            } else {
                console.log(consent)
            }

        } catch (e: any) {
            setError(e.data.errors[0])
        }
    }

    return (
        <Modal visible={isOpen} onClose={close} fadePercentage={percentageToClose}>
            <div className={styles.modal} >
                <h1>Verify Phone</h1>
                <small>To complete registration you must agree to use sms text messaging on your phone by answering "Yes". <br />
                    Once you sent sms please enter Confirm button</small>
                <Button text="Confirm" onClick={onClickHandler} />
            </div>
        </Modal>
    );
};
