import { useContext } from "react";
import { TestContext } from "contexts/TestContext";

export const useTest = () => {
  const context = useContext(TestContext);
  if (!context) {
    throw new Error("useTest must be within TestProvider");
  }

  return context;
};
